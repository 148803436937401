
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { UserProfile } from '~/models/user/UserProfile';

declare let jdenticon: any;

  @Component
export default class MutantProfileImage extends Vue {
    @Prop()
      profile!: UserProfile;

    @Prop()
      size!: number;

    @Prop()
    public borderSize?: number;

    @Prop(Boolean)
    public isActive?: boolean;

    @Prop(Boolean)
    public isDraggable?: boolean;

    @Prop(Boolean)
    public hasBorder?: boolean;

    @Prop(Boolean)
    public hasHover?: boolean;

    @Prop(Boolean)
    public hasShadow?: boolean;

    @Prop(Boolean)
    public isDragOver?: boolean;

    @Prop(Boolean)
    public isDroppedOn?: boolean;

    @Prop(Boolean)
    public isFlattened?: boolean;

    public imageSrc = '';
    public isHover = false;

    mounted() {
      if (this.profile?.hasProfilePicture) {
        this.imageSrc = this.$imageLoader.getProfileImageSelfUrl();
      }
    }

    public get hasProfilePicture(): boolean {
      return this.profile?.hasProfilePicture ?? false;
    }

    public get imageStyle() {
      let border;
      if (this.hasBorder) {
        const borderSize = this.borderSize ? this.borderSize : 5;
        if (this.isActive) {
          border = `${borderSize}px solid #ccb250`;
        } else if (this.hasHover && this.isHover) {
          border = `${borderSize}px solid rgba(204,178,80, 0.5)`;
        } else {
          border = `${borderSize}px solid rgb(9%, 9%, 9%)`;
        }
      }
      return {
        width: `${this.size}px`,
        height: `${this.size * (this.isFlattened ? 0.90 : 1)}px`,
        border,
        filter: this.hasShadow ? 'drop-shadow(-8px 8px 15px rgba(0,0,0,0.5))' : '',
      };
    }

    public get guestImageStyle() {
      return {
        ...this.imageStyle,
        backgroundImage: 'url(/guest-image-4.webp)',
      };
    }

    public get identiconSvg() {
      return jdenticon.toSvg(this.profile.username, this.size);
    }

    @Watch('profile', { immediate: true })
    public watchUserChange(profile: UserProfile) {
      if (profile?.hasProfilePicture && profile?.base64 != null) {
        this.imageSrc = profile.base64;
      }
    }
}
