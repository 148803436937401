
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator';
import VueSlider from 'vue-slider-component';

  @Component({
    components: {
      VueSlider,
    },
  })
export default class MutantSlider extends Vue {
    @Prop()
    public value!: number;

    @Prop()
    public min!: number;

    @Prop()
    public max!: number;

    @Prop()
    public interval!: number;

    @Prop()
    public title!: string;

    @Prop()
    public disabled!: boolean;

    @Prop() // 'left' or 'right'
    public valuePosition: string;

    @Prop(Boolean)
    public isVertical?: boolean;

    public isHover = false;
    public tempValue: number = null;

    constructor() {
      super();
    }

    mounted() {
      this.tempValue = this.value;
    }

    public get showValueOnLeftSide() {
      return this.valuePosition === undefined || this.valuePosition === 'left';
    }

    public updateTempValue(newValue: number) {
      this.tempValue = newValue;
      this.$emit('change', newValue);
    }

    @Watch('value')
    onValueChange() {
      this.tempValue = this.value;
    }

    public get railStyle() {
      return this.isVertical
        ? {
            alignItems: 'center',
            display: 'flex',
            background: 'linear-gradient(to right, rgba(133, 85, 0, 0) 0%, rgba(133, 85, 0, 0.5) 50%, rgba(133, 85, 0, 0) 100%)',
            width: '3px',
          }
        : {
            alignItems: 'center',
            display: 'flex',
            background: 'linear-gradient(to bottom, rgba(133, 85, 0, 0) 0%, rgba(133, 85, 0, 0.5) 50%, rgba(133, 85, 0, 0) 100%)',
            height: '3px',
          };
    }

    public get processStyle() {
      return this.isVertical
        ? {
            background: 'linear-gradient(to top, rgba(251, 159, 0, 0.3) 0%, rgba(251, 159, 0, 1) 100%), rgba(0,0,0, 0.9)',
            width: '3px',
          }
        : {
            background: 'linear-gradient(to right, rgba(251, 159, 0, 0.3) 0%, rgba(251, 159, 0, 1) 100%), rgba(0,0,0, 0.9)',
            height: '3px',
          };
    }
}
