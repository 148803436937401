
import { Component, Vue } from 'nuxt-property-decorator';
import Column from '../../ui/Column.vue';
import MutantContextMenu from '../../ui/MutantContextMenu.vue';
import Row from '../../ui/Row.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import { ContextMenuType } from '~/store/context/state';
import ContextMenuButton from '~/components/ui/buttons/ContextMenuButton.vue';
import InfoButton from '~/components/ui/buttons/InfoButton.vue';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { UploadData, UploadItems } from '~/store/file/actions';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import MutantSlider from '~/components/ui/MutantSlider.vue';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { Asset, CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';
import { UnitSize } from '~/models/UnitSize';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import ProgressCircle from '~/components/ui/progress/ProgressCircle.vue';
import { ViewProgress } from '~/store/state';
import CancelButton from '~/components/dialogs/upload/CancelButton.vue';
import SharedLinkProgress from '~/components/ui/progress/SharedLinkProgress.vue';
import { UploadProcessStatus } from '~/store/file/state';
import UploadCircle from '~/components/dialogs/upload/UploadCircle.vue';
import { FileProcessingPipeline, PipelineInfoLabel } from '~/models/pipeline/FileProcessingPipeline';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import { TransferOption } from '~/models/TransferOption';
import { ObjectId } from '~/models/ObjectId';
import IndexingProgressBar from '~/components/dialogs/upload/IndexingProgressBar.vue';
import { PIPELINE_STATUS, PipelineProgress } from '~/store/getters';
import MatchAndUploadContextMenu from '~/components/dialogs/upload/MatchAndUploadContextMenu.vue';
import InfoBadge from '~/components/dialogs/download/InfoBadge.vue';
import CheckboxButton from '~/components/ui/buttons/CheckboxButton.vue';
import CheckboxButtonBadge from '~/components/ui/buttons/CheckboxButtonBadge.vue';
import ThumbnailSlider from '~/components/ui/ThumbnailSlider.vue';
import { byIncludesVersions } from '~/models/asset/filters/byIncludesVersions';
import { AssetListBuilder } from '~/models/asset/AssetListBuilder';
import DownloadAllContextMenu from '~/components/dialogs/download/DownloadAllContextMenu.vue';
import CheckmarkBox from '~/components/ui/buttons/CheckmarkBox.vue';
import { UploadOption } from '~/models/UploadOption';
import { LinkData } from '~/store/link/state';
import ShortcutButton from '~/components/ui/ShortcutButton.vue';
import { PipelineItem } from '~/models/PipelineItem';
import { SwipeEvent } from '~/plugins/swipe';

@Component({
  components: {
    ShortcutButton,
    CheckmarkBox,
    DownloadAllContextMenu,
    ThumbnailSlider,
    CheckboxButtonBadge,
    CheckboxButton,
    InfoBadge,
    MatchAndUploadContextMenu,
    IndexingProgressBar,
    UploadCircle,
    SharedLinkProgress,
    CancelButton,
    ProgressCircle,
    MutantSlider,
    InfoButton,
    ContextMenuHeaderFlashy,
    Column,
    MutantContextMenu,
    ContextMenuButton,
    Row,
  },
})
export default class UploadControlMenu extends Vue {
  private uploadHandlerInProgress = false;
  public isDragOver = false;
  public thumbnailWidthChanged = 1280;
  public allResolutions = [768, 1024, 1280, 1536, 1792, 2048, 2304, 2560];
  public isHoverReplaceExistingFiles = false;
  public isHoverIgnoreExistingFiles = false;
  public pipelineCommandType = PipelineCommandType;
  public controlId = 'upload-control-dialog';
  private uploadControlElement: HTMLElement;

  public get isUploadProcessExceedingUploadLimit() {
    const uploadSizeInBytes = this.$store.getters['file/uploadProcessItems'](this.versionsToUpload).assetList.asByteSize;
    return this.$store.getters['file/isUploadSizeExceedingUploadLimit'](uploadSizeInBytes);
  }

  public get startPosition() {
    return { top: window.innerHeight - 800, left: 100 };
  }

  public get ignoreExisingFiles(): boolean {
    return this.$store.state.uploadMenu.ignoreExisingFiles;
  }

  public get replaceExisingFiles(): boolean {
    return this.$store.state.uploadMenu.replaceExisingFiles;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isExpanded(): boolean {
    return this.$store.state.uploadMenu.isExpanded;
  };

  public toggleReplaceExisingFiles() {
    if (!this.optionsDisabled) {
      this.$store.commit('uploadMenu/toggleReplaceExisingFiles');
    }
  }

  public toggleIgnoreExistingFiles() {
    if (!this.optionsDisabled) {
      this.$store.commit('uploadMenu/toggleIgnoreExisingFiles');
    }
  }

  onSwipe(swipeEvent: SwipeEvent) {
    const {
      type,
      direction,
      distanceY,
    } = swipeEvent;
    let translateY = 0;
    if (type === 'move' && direction === 'down') {
      translateY = distanceY;
      this.uploadControlElement.style.transform = `translateY(${translateY}px)`;
    } else if (type === 'finish') {
      if (distanceY >= this.uploadControlElement.offsetHeight / 2 && direction === 'down') {
        this.close();
      } else {
        this.uploadControlElement.style.transform = 'translateY(0)';
      }
    }
  }

  mounted() {
    this.thumbnailWidthChanged = this.selectedThumbnailWidth;
    this.uploadControlElement = document.getElementById(this.controlId);
    if (this.isUnsupportedBrowserVersion) {
      this.$sentry.captureMessage('Unsupported Browser detected, no upload possible');
    }
  }

  public get selectedThumbnailWidth() {
    return this.$store.state.file.thumbnailQualityConfig.width;
  }

  public async chooseThumbnailWidth(value: number) {
    await this.$store.dispatch('file/setThumbnailQualityConfig', { width: value });
  }

  public get isUser(): boolean {
    return this.$roleAwareness.isUser;
  }

  public get isUnsupportedBrowserVersion(): boolean {
    return !this.$store.state.workerSupport;
  }

  public get showUploadProgress(): boolean {
    return this.isUploading;
  }

  public get showFileSizeApproximation(): boolean {
    return !this.indexingComplete;
  }

  public get isUploadDisabled(): boolean {
    return this.pipelineOptions.length === 0 || this.offlineItemsNotProcessed.length === 0;
  }

  public get menuTitle() {
    if (this.showUploadProgress) {
      if (this.progressSteps[this.progressSteps.length - 1]?.percentage === 100) {
        return 'UPLOADED';
      }
      return 'UPLOADING...';
    }
    return 'FAST CONTACT SHEET';
  }

  public get isIndexingStepMissing(): boolean {
    return this.progressSteps[0]?.label !== PipelineInfoLabel.CREATE_THUMBNAILS;
  }

  public get isUploading(): boolean {
    return this.$store.state.file.uploadProcess?.status === UploadProcessStatus.UPLOADING;
  }

  public get isIndexing(): boolean {
    return this.$store.state.file.uploadProcess?.status === UploadProcessStatus.INDEXING;
  }

  public get uploadSuccess(): boolean {
    return this.$store.state.file.uploadProcess && this.isUploadFinished;
  }

  public get isUploadFinished(): boolean {
    return this.$store.getters.pipelineFinished(this.uploadProcessObjectId?.toUuid());
  }

  public get showOptions() {
    return this.$store.getters['folder/onlineFolderItemsById'](this.currentCloudObjectId).length > 0;
  }

  public get itemsInUploadProcess() {
    return this.$store.state.file.uploadProcess?.items || [];
  }

  public get updateViewThumbnail() {
    if (this.indexingComplete) {
      return 'Update thumbnails in view to reflect selected thumbnail size';
    } else {
      return 'Can not update thumbnail size while items are still indexed, please wait a few moments';
    }
  }

  public get optionsToolTip() {
    return this.optionsDisabled ? { placement: 'bottom', content: 'select option before adding files' } : {};
  }

  public get optionsDisabled(): boolean {
    return this.itemsToUploadCount > 0;
  }

  public get itemsToUploadCount(): number {
    return this.itemsInUploadProcess.length || 0;
  }

  public get assetDetails(): { originals: { count: number; size: string }, raws: { count: number; size: string }, thumbnails: { count: number; size: string } } {
    const assets = this.assetsToUpload;
    return this.buildCountsForAssets(assets);
  }

  public get allAssetDetails(): { originals: { count: number; size: string }, raws: { count: number; size: string }, thumbnails: { count: number; size: string } } {
    const assets = this.allPossibleAssets;
    return this.buildCountsForAssets(assets);
  }

  private buildCountsForAssets(assets: Asset[]) {
    const originalAssets = new AssetListBuilder()
      .withAssets(assets)
      .withFilter(byIncludesVersions([ORIGINAL_ASSET_VERSION]))
      .build();
    const rawAssets = new AssetListBuilder()
      .withAssets(assets)
      .withFilter(byIncludesVersions([RAW_ASSET_VERSION]))
      .build();
    const thumbnailAssets = new AssetListBuilder()
      .withAssets(assets)
      .withFilter(byIncludesVersions([CUSTOM_SUB_VERSION]))
      .build();
    return {
      originals: {
        count: originalAssets.assets.length,
        size: originalAssets.sizeToString,
      },
      raws: {
        count: rawAssets.assets.length,
        size: rawAssets.sizeToString,
      },
      thumbnails: {
        count: thumbnailAssets.assets.length,
        size: thumbnailAssets.sizeToString,
      },
    };
  }

  public get assetsToUpload(): Asset[] {
    return this.$store.getters['file/uploadProcessItems'](this.versionsToUpload).assetList.assets;
  }

  public get allPossibleAssets(): Asset[] {
    return this.$store.getters['file/uploadProcessItems']([RAW_ASSET_VERSION, ORIGINAL_ASSET_VERSION, CUSTOM_SUB_VERSION]).assetList.assets;
  }

  public get rawFileCount(): number {
    return this.allAssetDetails.raws.count;
  }

  public get originalFileCount(): number {
    return this.allAssetDetails.originals.count;
  }

  public get hasRawFilesToUpload(): boolean {
    return this.$store.state.file.uploadProcess?.hasRawFiles;
  }

  public get indexingComplete(): boolean {
    const indexingStep = this.progressSteps.find(step => step.label === PipelineInfoLabel.CREATE_THUMBNAILS);
    return !indexingStep || indexingStep.percentage === 100;
  }

  public get uploadProgress(): ViewProgress {
    const activeProgress = this.progressSteps.find(step => step.percentage > 0 && step.percentage < 100) || this.progressSteps[this.progressSteps.length - 1];
    return this.progressSteps?.length
      ? {
          progressSteps: [activeProgress],
          totalSteps: 1,
        }
      : null;
  }

  public get progressSteps(): PipelineProgress[] {
    return this.$store.getters.pipelineProgress(this.uploadProcessObjectId?.toUuid()) || [];
  }

  public get progressPercentage(): number {
    return this.uploadProgress?.progressSteps[0]?.percentage;
  }

  public get uploadProcessObjectId(): ObjectId {
    return this.$store.state.file.uploadProcess?.objectId;
  }

  public get versionsToUpload(): number[] {
    const commands = this.isUploading ? this.$store.state.file.uploadProcess.pipelineOptions : this.pipelineOptions;
    return FileProcessingPipeline.commandsToVersions(commands);
  }

  public get filesToUploadSize(): UnitSize {
    return this.$store.getters['file/uploadProcessItems'](this.versionsToUpload).assetList.asRoundedUnitSize;
  }

  public get filesUploadedSize(): string {
    return this.itemsUploaded.assetList.sizeToString;
  }

  public get filesUploadedCount(): number {
    const activeType = this.progressSteps.find(s => s.status === PIPELINE_STATUS.ACTIVE);
    if (!activeType) {
      return this.itemsToUploadCount;
    }
    return byIncludesVersions([this.mapActiveTypeToVersion(activeType.label)])(this.itemsUploaded.assetList.assets).length;
  }

  private mapActiveTypeToVersion(label: PipelineInfoLabel) {
    switch (label) {
      case PipelineInfoLabel.UPLOAD_THUMBNAILS:
        return CUSTOM_SUB_VERSION;
      case PipelineInfoLabel.UPLOAD_ORIGINALS:
        return ORIGINAL_ASSET_VERSION;
      default:
        return RAW_ASSET_VERSION;
    }
  }

  public get itemsUploaded() {
    return this.$store.getters['file/itemsUploaded'];
  }

  public get rawsUploadedCount(): number {
    return byIncludesVersions([RAW_ASSET_VERSION])(this.itemsUploaded.assetList.assets).length;
  }

  public get isUploadOriginalsActive() {
    return this.pipelineOptions.includes(PipelineCommandType.UPLOAD_ORIGINALS);
  }

  public get isUploadRawsActive() {
    return this.pipelineOptions.includes(PipelineCommandType.UPLOAD_RAWS);
  }

  public get isUploadFastContactSheetActive() {
    return this.pipelineOptions.includes(PipelineCommandType.UPLOAD_THUMBNAILS);
  }

  public startUpload() {
    if (!this.isUploadDisabled) {
      const pipelineOptions = !this.hasRawFilesToUpload && this.pipelineOptions.includes(PipelineCommandType.UPLOAD_RAWS)
        ? this.pipelineOptions.filter(option => option !== PipelineCommandType.UPLOAD_RAWS)
        : this.pipelineOptions;
      this.$store.commit('uploadMenu/resetState');
      this.$store.dispatch('file/updateUploadProcess', { status: UploadProcessStatus.UPLOADING, pipelineOptions });
      this.$store.dispatch<ActionPayload<UploadItems>>({
        type: 'file/uploadItems',
        payload: {
          itemsWithPosition: this.offlineItemsNotProcessed,
          pipelineId: this.uploadProcessObjectId.toUuid(),
          pipelineOptions: [PipelineCommandType.CREATE_THUMBNAILS, ...pipelineOptions],
        },
      });
    }
  }

  public reindex() {
    if (this.indexingComplete) {
      this.$store.dispatch('file/reindexPipelineItems', this.uploadProcessObjectId.toUuid());
      this.thumbnailWidthChanged = this.selectedThumbnailWidth;
    }
  }

  private get offlineItemsNotProcessed(): ItemWithPosition[] {
    return this.$store.getters['folder/offlineFolderItemsById'](this.uploadProcessObjectId?.toUuid()).filter((i: ItemWithPosition) => this.itemsInUploadProcess.find((itemInUpload: PipelineItem) => itemInUpload.id === i.item.id));
  }

  public get sharedLink(): LinkData {
    const objectId = this.cloudObject.objectId.toString();
    return this.$store.getters['link/linkData'](objectId);
  }

  public async shareContent() {
    if (!this.isShared) {
      await this.$store.dispatch('link/createSharedLinkForCloudObject', this.cloudObject);
    }
    if (typeof navigator.share === 'function' && this.$store.getters.isMobile) {
      await this.$store.dispatch('link/shareWithMobile', this.sharedLink.accessId);
    } else {
      this.$store.dispatch('cloud/copySharedLink', this.uploadProcessObjectId);
    }
  }

  public get isShared() {
    return this.cloudObject?.object?.isShared;
  }

  public get isOffline() {
    return !this.cloudObject?.object?.isSynced;
  }

  public get cloudObject(): CloudObject<Folder | Selection> {
    return this.$store.getters['cloud/cloudObject'](this.uploadProcessObjectId);
  }

  public openLogin() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { showLogin: true } });
  }

  public openAccountMenu() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.ACCOUNT_OVERVIEW });
  }

  public addMore() {
    this.endFinishedUploadProcess();
  }

  public cancelUpload() {
    if (this.itemsToUploadCount > 0) {
      this.$store.dispatch('file/dismissUpload');
    }
    this.close();
  }

  public toggleOption(option: PipelineCommandType): void {
    this.$store.commit('uploadMenu/togglePipelineOption', option);
  }

  public toggleExpandedState(): void {
    this.$store.commit('uploadMenu/toggleExpandedState');
  };

  public get pipelineOptions(): PipelineCommandType[] {
    return this.$store.state.uploadMenu?.pipelineOptions ?? [PipelineCommandType.UPLOAD_THUMBNAILS];
  }

  public get userSwitchedFolder(): boolean {
    return this.uploadProcessObjectId?.toUuid() !== this.currentCloudObjectId;
  }

  public get currentCloudObjectId(): string {
    return this.$store.getters['cloud/currentCloudObjectId']?.toUuid();
  }

  public browse() {
    if (this.userSwitchedFolder) {
      this.$store.dispatch('cloud/addToPane', { objectIds: [this.uploadProcessObjectId], setDefaultView: true });
    }
    this.$store.dispatch('setBrowseMode');
    this.close();
  }

  private endFinishedUploadProcess() {
    if (this.isUploadFinished) {
      this.$store.dispatch('file/endUploadProcess');
    }
  }

  public close() {
    this.endFinishedUploadProcess();
    this.$store.dispatch('context/closeMenu', ContextMenuType.UPLOAD_CONTROL);
    this.uploadControlElement.style.transform = `translateY(${this.uploadControlElement.offsetHeight}px)`;
  }

  public triggerFileUpload() {
    const fileInput = document.getElementById('file-input-remote-control');
    if (fileInput) {
      fileInput.click();
    }
  }

  public triggerFolderUpload() {
    const folderInput = document.getElementById('folder-input-remote-control');
    if (folderInput) {
      folderInput.click();
    }
  }

  cancelDragOver(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const { top, left, width, height } = this.$el.getBoundingClientRect();
    const leaveEventOffset = 0;
    if (event.clientX <= left + leaveEventOffset || event.clientX >= left + width - leaveEventOffset || event.clientY <= top + leaveEventOffset || event.clientY >= top + height - leaveEventOffset) {
      this.isDragOver = false;
    }
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.dataTransfer.types.some(t => t === TransferOption.MUTANT_TRANSFER_TYPE.toLowerCase() || t === 'Files')) {
      this.isDragOver = true;
    }
  }

  public get buildUploadOptions(): UploadOption[] {
    const result: UploadOption[] = [];
    if (this.ignoreExisingFiles) {
      result.push(UploadOption.IGNORE_EXISTING_FILES);
    } else if (this.replaceExisingFiles) {
      result.push(UploadOption.REPLACE_EXISTING_FILES);
    }
    return result;
  }

  async uploadFiles(event: InputEvent | DragEvent) {
    event.preventDefault();
    const pipelineCommands = this.isUploadFinished || this.isIndexing ? [PipelineCommandType.CREATE_THUMBNAILS] : [PipelineCommandType.CREATE_THUMBNAILS, ...this.pipelineOptions];
    this.isDragOver = false;
    this.endFinishedUploadProcess();
    if (!this.uploadHandlerInProgress) {
      this.uploadHandlerInProgress = true;
      if (this.$routeAwareness.isHomepageView) {
        await this.uploadFilesToNewFolder(event);
      } else {
        await this.uploadFilesToMainView(event, pipelineCommands);
      }
      setTimeout(() => { this.uploadHandlerInProgress = false; }, 100);
    }
  }

  private async uploadFilesToNewFolder(event: InputEvent | DragEvent): Promise<void> {
    await this.$store.dispatch('folder/uploadToNewFolder', new AddExternalContentEvent(event, { order: 0 }));
  }

  private async uploadFilesToMainView(event: InputEvent | DragEvent, pipelineCommands: PipelineCommandType[]): Promise<void> {
    const externalContentEvent = new AddExternalContentEvent(event, { order: this.$store.getters['cloud/currentViewItems'](ViewIdentifier.MAIN_VIEW).length });

    await this.$store.dispatch<ActionPayload<UploadData>>({
      type: 'file/upload',
      payload: {
        windowId: ViewIdentifier.MAIN_VIEW,
        event: externalContentEvent,
        pipelineCommands,
        uploadOptions: this.buildUploadOptions,
      },
    });
  }
}
