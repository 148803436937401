var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"badge-button",class:{
    'badge-button--active': _vm.isActive,
    'badge-button--filled': _vm.isFilled,
    'badge-button--slim': _vm.isSlim,
    'badge-button--light': _vm.isLight,
    'badge-button--border': _vm.hasBorder
  },style:({ width: _vm.width ? `${_vm.width}px` : 'fit-content'}),attrs:{"draggable":_vm.isDraggable},on:{"click":function($event){return _vm.$emit('click', $event)},"dragstart":function($event){return _vm.$emit('dragstart', $event)},"drag":function($event){return _vm.$emit('drag', $event)},"dragend":function($event){return _vm.$emit('dragend', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }