
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantContextMenuHeader from '~/components/ui/MutantContextMenuHeader.vue';
import MutantContextMenuItem from '~/components/ui/MutantContextMenuItem.vue';
import { ObjectId } from '~/models/ObjectId';
import Selection from '~/models/selection/Selection';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: {
    MutantContextMenuDivider,
    MutantContextMenuItem,
    MutantContextMenu,
    MutantContextMenuHeader,
  },
})
export default class MutantSelectionContextMenu extends Vue {
  public isSharedLinkRequestProcessed = false;

  public get startPosition() {
    const { pageX, pageY } = this.$store.getters['context/menu'](ContextMenuType.SELECTION)?.event || { pageX: 0, pageY: 0 };
    return {
      left: pageX + 5,
      top: pageY + 5,
    };
  }

  public get selection(): Selection {
    return this.$store.getters['context/menu'](ContextMenuType.SELECTION)?.data?.object;
  }

  public get existsSharedLink(): boolean {
    return this.$store.getters['selection/linkDataExists'](this.selection.id);
  }

  public get isSelectionOwner(): boolean {
    return this.$store.getters['selection/isSelectionOwner'](this.selection.id);
  }

  async visitSharedLinkOfSelection() {
    const selectionId = ObjectId.fromSelectionId(this.selection.id).toString();
    const sharedLink = this.$store.getters['link/linkData'](selectionId);
    await this.$router.push({
      name: 'cloud-id',
      params: {
        id: selectionId,
      },
      query: {
        'shared-link-id': sharedLink.accessId,
      },
    });
    await this.$store.dispatch('cloud/initializeCloudContent');
    this.closeMenu();
  }

  removeSharedLink() {
    const selectionId = ObjectId.fromSelectionId(this.selection.id).toString();
    const sharedLink = this.$store.getters['link/linkData'](selectionId);
    this.$store.dispatch('link/delete', {
      objectId: ObjectId.fromSelectionId(this.selection.id),
      accessId: sharedLink.accessId,
    });
    this.closeMenu();
  }

  copySharedLink() {
    this.$store.dispatch('link/copyToClipboardForSelectionId', this.selection.id);
    this.closeMenu();
  }

  async deleteSelection() {
    await this.$store.dispatch('selection/removeSelection', this.selection);
    this.closeMenu();
  }

  async createSharedLink() {
    await this.$store.dispatch('link/createSharedLinkForSelection', { selectionId: this.selection.id });
    this.closeMenu();
  }

  closeMenu() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.SELECTION);
  }

  @Watch('selection', { immediate: true })
  public async watchSelection(newSelection, oldSelection) {
    if (!oldSelection || (oldSelection?.id !== newSelection.id)) {
      this.isSharedLinkRequestProcessed = false;
      await this.$store.dispatch('selection/loadSharedLinks', newSelection.id);
      this.isSharedLinkRequestProcessed = true;
    }
  }
}
