
import { Component, Vue } from 'nuxt-property-decorator';
import { FilterOption } from '~/store/cloud/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { RatingsFileTypes } from '~/store/cloud/getters';

@Component
export default class ReviewFilterCommon extends Vue {
  public get selectedFilter(): FilterOption[] {
    return this.$store.getters['cloud/reviewFilter']?.length ? this.$store.getters['cloud/reviewFilter'] : [FilterOption.NO_FILTER];
  }

  public activateReviewFilter(filter: FilterOption[]) {
    const isReviewFilterActive = this.$store.getters['cloud/isReviewFilterActive'];
    if (filter.length > 0 && !filter.includes(FilterOption.NO_FILTER) && !isReviewFilterActive) {
      this.$store.commit('cloud/setReviewFilterState', { windowId: ViewIdentifier.MAIN_VIEW, active: true });
    }
  }

  public isSelected(filter: FilterOption) {
    return this.selectedFilter.includes(filter);
  }

  public countForRating(rating: FilterOption): RatingsFileTypes {
    return this.$store.getters['cloud/countsForRating'](rating) ?? { raws: 0, originals: 0, total: 0 };
  }

  public select(filterOption: FilterOption) {
    let filter = this.selectedFilter;
    if (filterOption === FilterOption.NO_FILTER) {
      filter = [filterOption];
    } else if (filter.includes(FilterOption.NO_FILTER)) {
      filter = [filterOption];
    } else if (filter.includes(filterOption)) {
      filter = filter.filter(f => f !== filterOption);
      filter = filter.length === 0 ? [FilterOption.NO_FILTER] : filter;
    } else {
      filter = [...filter, filterOption];
    }
    this.activateReviewFilter(filter);
    this.$store.dispatch('cloud/setReviewFilter', { windowId: ViewIdentifier.MAIN_VIEW, reviewFilter: filter });
    this.$store.dispatch('cloud/setFirstItemCentered');
  }
}
