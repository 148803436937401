
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { WebConfig } from '~/Config';
import { ObjectId } from '~/models/ObjectId';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { LinkData } from '~/store/link/state';

@Component({})
export default class SharedLinkCreation extends Vue {
  public copied = false;
  private copiedTimeout = null;

  @Prop(Boolean)
  public slim: boolean;

  public get objectId(): ObjectId {
    return this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW).objectIds[0];
  }

  public get sharedLink(): LinkData {
    const objectId = this.objectId?.toString();
    return this.$store.getters['link/linkData'](objectId);
  }

  public get linkUrl() {
    return this.sharedLink?.accessId ? `${WebConfig.URL}/shared/${this.sharedLink?.accessId}` : null;
  }

  public copy() {
    this.copied = false;
    clearTimeout(this.copiedTimeout);
    this.copied = true;
    this.copyLink();
    this.copiedTimeout = setTimeout(() => {
      this.copied = false;
    }, 2750);
  }

  public copyLink() {
    if (this.sharedLink?.accessId != null) {
      this.$store.dispatch('link/copyToClipboard', this.sharedLink.accessId);
    }
  }

  public async shareLink() {
    if (this.sharedLink?.accessId == null) {
      if (this.objectId?.isSelectionId) {
        await this.$store.dispatch('link/createSharedLinkForSelection', { selectionId: this.objectId.toUuid() });
        this.copyLink();
      } else if (this.objectId?.isFolderId) {
        await this.$store.dispatch('link/createSharedLink', { folderId: this.objectId.toUuid() });
        this.copyLink();
      }
    }
  }
}
