
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';

@Component
export default class ProgressPulse extends Vue {
  public animationInProgress = false;
  private intervalHandler = null;
  private timeoutHandler = null;

  @Prop({ type: Boolean, default: false })
  isActive;

  @Prop()
  interval: number;

  @Prop()
  timeout: number;

  @Prop({ type: Boolean, default: true })
  show: boolean;

  constructor() {
    super();
    if ((this.interval != null && this.interval > 0) || (this.timeout != null && this.timeout > 0)) {
      this.animationInProgress = true;
    }
  }

  beforeDestroy() {
    clearInterval(this.intervalHandler);
    clearTimeout(this.timeoutHandler);
  }

  public get showPulse() {
    return this.show && this.animationInProgress;
  }

  private endAnimation() {
    this.animationInProgress = false;
  }

  @Watch('interval', { immediate: true })
  watchInterval(interval: number | null) {
    if (interval != null && interval > 0) {
      clearInterval(this.intervalHandler);
      this.intervalHandler = setInterval(() => {
        if (!this.isActive) {
          this.endAnimation();
          clearInterval(this.intervalHandler);
        }
      }, interval);
    }
  }

  @Watch('timeout', { immediate: true })
  watchTimeout(timeout: number | null) {
    if (timeout != null && timeout > 0) {
      clearTimeout(this.intervalHandler);
      this.intervalHandler = setTimeout(() => {
        this.endAnimation();
      }, timeout);
    }
  }
}
