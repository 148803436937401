
import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class ResendMailButton extends Vue {
  public resendMailButtonActive = true;
  public response = null;

  public async resendMail() {
    this.resendMailButtonActive = false;
    try {
      await this.$store.dispatch('user/resendMail');
      this.response = 'Email has been sent. Check your inbox';
    } catch (error) {
      this.response = parseInt(error?.response?.status) === 429 ? 'Blocked due to too many requests. Try again later' : 'Error occurred. Try again later';
    }
    setTimeout(() => {
      this.resendMailButtonActive = true;
      this.response = null;
    }, 10000);
  };
}
