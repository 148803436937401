
import { Component, Vue } from 'nuxt-property-decorator';
import { LayoutType } from '~/models/LayoutType';

@Component({
  components: { },
})
export default class ExitReviewButton extends Vue {
  public exitReview() {
    if (this.$roleAwareness.isGuestUser) {
      this.$store.dispatch('visitHomepage');
    } else {
      this.$store.dispatch('chooseLayout', LayoutType.ESSENTIALS);
    }
  }
}
