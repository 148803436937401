
import { Component } from 'nuxt-property-decorator';
import ReviewFilterCommon from './ReviewFilterCommon.vue';
import ReviewStars from '~/components/ui/ReviewStars.vue';
import MutantBadge from '~/components/ui/MutantBadge.vue';
import { FilterOption, STAR_RATING } from '~/store/cloud/state';
import ReviewSectionHeader from '~/components/menu/right/tabs/review/ReviewSectionHeader.vue';
import TagWithBadge from '~/components/menu/right/tabs/review/folder-tags/TagWithBadge.vue';

@Component({
  components: {
    TagWithBadge,
    ReviewSectionHeader,
    MutantBadge,
    ReviewStars,
  },
})
export default class RatingSection extends ReviewFilterCommon {
  private isExpanded = true;
  public STAR_RATING = STAR_RATING;
  public FilterOption = FilterOption;

  public get orderedFilterOptions(): FilterOption[] {
    return [
      FilterOption.ONE_STAR,
      FilterOption.TWO_STARS,
      FilterOption.THREE_STARS,
      FilterOption.FOUR_STARS,
      FilterOption.FIVE_STARS,
    ];
  }

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}
