
import { Component, Vue } from 'nuxt-property-decorator';
import { Background } from '~/models/views/Background';
import { ContextMenuType } from '~/store/context/state';
import { WebConfig } from '~/Config';

@Component({
  components: {
  },
})
export default class UserPanelDropdown extends Vue {
  public get backgrounds(): Background[] {
    return this.$store.state.backgrounds;
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public openAccountOverview() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.ACCOUNT_OVERVIEW });
  }

  get adminDashboardUrl(): string {
    return WebConfig.ADMIN_DASHBOARD_WEB_URL;
  }

  public async logout() {
    await this.$store.dispatch('user/manualLogout');
    this.$emit('close');
  }

  public async sendFeedback() {
    await this.$store.dispatch('context/openMenu', { type: ContextMenuType.SEND_FEEDBACK });
    this.$emit('close');
  }
}
