
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ActionButtonModel from '~/models/ActionButtonModel';

@Component
export default class ActionButton extends Vue {
  @Prop()
  public item: ActionButtonModel;

  @Prop()
  public margin: any;

  @Prop()
  public color: any;

  @Prop()
  public padding: any;

  @Prop({ default: 12 })
  public fontSize: string;

  @Prop({ default: false })
  public deactivated: boolean;

  public get styleButton() {
    let obj = { 'font-size': this.fontSize + 'px' };
    if (this.color != null) {
      obj = { ...obj, ...this.color };
    }
    if (this.padding != null) {
      obj = { ...obj, ...this.padding };
    }
    return obj;
  }

  public get style() {
    let obj = {};
    if (this.margin != null) {
      obj = this.margin;
    }
    return obj;
  }

  public triggerAction(event: Event) {
    if (!this.deactivated) {
      this.item.action(event);
    }
  }
}
