

import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({})
export default class FullscreenControl extends Vue {
  public applicationBaseElement: HTMLElement | null = null;
  public fullscreenActive: boolean = false;
  @Prop()
  public iconSize: 'medium' | 'large';

  mounted() {
    this.handleFullscreenChange();
    window.addEventListener('fullscreenchange', this.handleFullscreenChange, false);
    this.applicationBaseElement = document.body;
  }

  beforeDestroy() {
    window.removeEventListener('fullscreenchange', this.handleFullscreenChange, false);
  }

  private handleFullscreenChange() {
    this.fullscreenActive = !!document.fullscreenElement;
    this.$emit('fullscreen-state-change', { fullscreenActive: this.fullscreenActive });
  }

  public get fullscreenOptionAvailable(): boolean {
    return typeof document.documentElement.requestFullscreen === 'function';
  }

  public get faIconSize(): string {
    return this.iconSize === 'large' ? 'lg' : 'sm';
  }

  public get tooltip() {
    const text = this.fullscreenActive ? 'Close Fullscreen Mode' : 'Open Fullscreen Mode';
    return { placement: 'bottom', content: text };
  }

  public async toggleFullscreen() {
    if (this.fullscreenActive) {
      await document.exitFullscreen();
    } else {
      await this.applicationBaseElement?.requestFullscreen();
    }
  }
}
