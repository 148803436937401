
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  components: { },
})
export default class MutantBurger extends Vue {
  @Prop(Boolean)
    selected: boolean;
}
