
import { Vue, Component, Prop } from 'nuxt-property-decorator';

  @Component
export default class MutantControlButton extends Vue {
    @Prop(Boolean)
    public isActive?: boolean;

    @Prop()
    public tooltip: any;
}
