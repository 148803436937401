var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"context-menu-button",class:{
    'context-menu-button--green': _vm.green,
    'context-menu-button--orange': _vm.orange,
    'context-menu-button--small': _vm.small,
    'context-menu-button--dark-green': _vm.darkGreen,
    'context-menu-button--active': _vm.isActive,
    'context-menu-button--disabled': _vm.isDisabled
  },on:{"click":_vm.handleClick}},[_vm._t("default"),_vm._v(" "),(_vm.hasDropup)?_c('div',{staticClass:"context-menu-button__icon"},[_c('fa',{attrs:{"icon":['fas', 'caret-up']}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }