
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ViewType } from '~/models/views/ViewType';

interface MutantIcon {
  class: string,
  viewType: ViewType,
  isOnlySelection: boolean,
}

@Component({})
export default class IconList extends Vue {
  @Prop()
  public isSelection: boolean;

  @Prop()
  public activeViewType: ViewType;

  public viewType = ViewType;

  public get iconList(): MutantIcon[] {
    const array = [
      { class: 'icon-moodboard', isOnlySelection: true, viewType: ViewType.MOODBOARD },
      { class: 'icon-contact-sheet', isOnlySelection: false, viewType: ViewType.CONTACT_SHEET },
      { class: 'icon-grid', isOnlySelection: false, viewType: ViewType.GRID },
      { class: 'icon-mosaic', isOnlySelection: false, viewType: ViewType.MOSAIC },
      { class: 'icon-horizontal', isOnlySelection: false, viewType: ViewType.HORIZONTAL },
    ];
    return array.filter(i => i.isOnlySelection ? this.isSelection : true);
  }

  private get activeWindowId() {
    return this.$store.state.cloud.activeWindowId ?? ViewIdentifier.MAIN_VIEW;
  }

  public changeView(viewType: ViewType) {
    if (viewType !== this.activeViewType) {
      this.$store.dispatch('cloud/changeView', { windowId: this.activeWindowId, view: viewType });
    }
  }
}
