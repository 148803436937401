
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import ShortcutButton from '~/components/ui/ShortcutButton.vue';
import { ContextMenuType } from '~/store/context/state';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import { LinkData } from '~/store/link/state';
import DarkMode from '~/components/ui/icons/LightDarkMode.vue';
import LightDarkMode from '~/components/ui/icons/LightDarkMode.vue';

@Component({
  components: {
    LightDarkMode,
    DarkMode,
    ShortcutButton,
  },
})
export default class MainViewSideMobileActions extends Vue {
  @Prop()
  private viewId: ViewIdentifier;

  public open = false;
  public routeWasSet = false;

  public get isHomepageView(): boolean {
    return this.$routeAwareness.isHomepageView;
  }

  public get cloudMenuOpen(): boolean {
    return this.$store.state.menuOpen;
  }

  public get isSharedLinkView() : boolean {
    return !!this.$router.currentRoute.query['shared-link-id'] || this.$router.currentRoute.name === 'shared-id';
  }

  public get isShared() {
    return this.cloudObject?.object?.isShared;
  }

  public get cloudObject(): CloudObject<Folder | Selection> {
    return this.$store.getters['cloud/currentCloudObject'];
  }

  public get sharedLink(): LinkData {
    const objectId = this.cloudObject.objectId.toString();
    return this.$store.getters['link/linkData'](objectId);
  }

  public get viewHasItems(): boolean {
    return this.$store.getters['cloud/viewHasItems'](ViewIdentifier.MAIN_VIEW);
  }

  public get isOwner(): boolean {
    return this.$store.getters['cloud/isOwnerOfView'](ViewIdentifier.MAIN_VIEW);
  }

  public setReceiveContentDestination() {
    const currentView = this.$store.getters['cloud/currentMainView'];
    if (currentView != null) {
      const folderId = currentView?.folderId;
      const selectionId = currentView?.selectionId;
      this.$store.dispatch('user/addMessageRoute', { folderId, selectionId });
      this.routeWasSet = true;
      setTimeout(() => {
        this.routeWasSet = false;
      }, 1000);
    }
  }

  public toggleLightMode() {
    this.$store.commit('toggleLightMode');
  }

  public cycleColumnCount() {
    this.$store.commit('cloud/cycleMobileColumnCount');
  }

  public download() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.DOWNLOAD_ALL });
    this.close();
  }

  public upload() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.UPLOAD_CONTROL });
    this.close();
  }

  public async share() {
    if (this.$roleAwareness.isGuestUser) {
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.UPLOAD_CONTROL });
      return;
    }
    if (!this.isShared) {
      await this.$store.dispatch('link/createSharedLinkForCloudObject', this.cloudObject);
    }
    if (navigator.share) {
      await this.$store.dispatch('link/shareWithMobile', this.sharedLink.accessId);
    } else {
      this.$store.dispatch('link/copyToClipboard', this.sharedLink.accessId);
    }
    this.close();
  }

  public close() {
    this.open = false;
  }

  public toggle() {
    this.open = !this.open;
  }
}
