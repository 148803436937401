
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ScrollDownButton from '~/components/homepage/feature-highlights/ScrollDownButton.vue';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: { ScrollDownButton },
})
export default class HomepageWebSplit extends Vue {
  @Prop(Boolean)
    isActive: boolean;

  @Prop(Boolean)
    showOverlayFlash: boolean;

  @Prop()
    id: string;

  public isHoverWebVideo = false;

  public downloadDesktopApp() {
    if (this.$roleAwareness.isGuestUser) {
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { isLogin: false, isSignupForDesktop: true } });
    } else {
      this.$store.dispatch('downloadDesktopApp');
    }
  }

  public signUp() {
    if (this.$store.getters['context/menu'](ContextMenuType.LOGIN)?.data?.showLogin) {
      this.$store.dispatch('context/updateMenuData', { type: ContextMenuType.LOGIN, isOpen: true, data: { showLogin: false } });
    } else {
      this.$store.dispatch('context/toggleMenu', { type: ContextMenuType.LOGIN, data: { showLogin: false } });
    }
  }
}
