
import { Component, Vue } from 'nuxt-property-decorator';
import { ViewType } from '~/models/views/ViewType';
import { ViewTypeIconClasses } from '~/models/views/ViewTypeIconClasses';
import IconDropDown from '~/components/ui/IconDropDown.vue';
import { IconDropDownModel } from '~/models/IconDropDownModel';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';

enum CloudObject {
  FOLDER = 'FOLDER',
  SELECTION = 'SELECTION'
}

type IconModel = Omit<IconDropDownModel, 'isActive' | 'hasSelectedItems'>;

@Component({
  components: {
    IconDropDown,
  },
})
export default class CloudMenuNewObject extends Vue {
  private selectionInputInProgress = false;
  private addNewSelectionId = 'cloud-menu-new-cloud-object';
  private isObjectSelectionCollapsed = false;
  private currentCloudObject:CloudObject = CloudObject.FOLDER;
  private cloudObjectName:string = '';

  public isViewTypeDropdownCollapsed = false;
  public activeViewTypeIcon = ViewType.HORIZONTAL;

  placeholderInput() {
    return `ADD ${this.currentCloudObject}`;
  }

  collapseViewTypeDropDown() {
    this.isViewTypeDropdownCollapsed = !this.isViewTypeDropdownCollapsed;
  }

  public selectViewType(viewType: any) {
    this.activeViewTypeIcon = viewType.value;
  }

  public get viewTypeIcons(): IconDropDownModel[] {
    return (this.currentCloudObject === CloudObject.FOLDER ? this.viewTypeInfoFolder : this.viewTypeInfoSelection).map(viewTypeInfo => {
      return {
        ...viewTypeInfo,
        isActive: this.activeViewTypeIcon === viewTypeInfo.value,
        hasSelectedItems: false,
      };
    });
  }

  public get cloudObjectIcons(): IconDropDownModel[] {
    return [{
      text: 'FOLDER',
      value: 'FOLDER',
      iconClass: 'mutant-icon icon-folder',
      isActive: true,
      hasSelectedItems: false,
    }];
  }

  private get viewTypeInfoSelection(): IconModel[] {
    return Object.values(ViewType).map(viewType => ({ text: viewType, value: viewType, iconClass: ViewTypeIconClasses[viewType] }));
  }

  private get viewTypeInfoFolder(): IconModel[] {
    return Object.values(ViewType).filter(viewType => viewType.toString() !== ViewType[ViewType.MOODBOARD]).map(viewType => ({ text: viewType, value: viewType, iconClass: ViewTypeIconClasses[viewType] }));
  }

  collapseObjectSelection() {
    this.isObjectSelectionCollapsed = !this.isObjectSelectionCollapsed;
  }

  changeSelectionInputProgress() {
    this.cloudObjectName = '';
    this.selectionInputInProgress = false;
  }

  async addCloudObject() {
    if (this.selectionInputInProgress) {
      if (this.cloudObjectName.length > 0) {
        if (this.currentCloudObject === CloudObject.SELECTION) {
          await this.$store.dispatch('selection/create', { name: this.cloudObjectName, viewType: this.activeViewTypeIcon, windowId: ViewIdentifier.MAIN_VIEW });
        } else if (this.currentCloudObject === CloudObject.FOLDER) {
          await this.$store.dispatch('folder/createFolder', {
            name: this.cloudObjectName,
            isPublic: false,
            sync: true,
            viewType: this.activeViewTypeIcon,
            windowId: ViewIdentifier.MAIN_VIEW,
          });
        }
      }
    }
    this.selectionInputInProgress = false;
    this.cloudObjectName = '';
  }

  changeCloudObjectToType(cloudObject: IconDropDownModel) {
    this.currentCloudObject = cloudObject.value;
    this.collapseObjectSelection();
  }
}
