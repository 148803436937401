
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { NotificationType } from '~/models/Notification';

@Component
export default class NotificationBanner extends Vue {
  public notificationType = NotificationType;

  @Prop()
    notificationId: string;

  @Prop()
    message: string;

  @Prop()
    type: string;
}
