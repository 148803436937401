
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import DesktopInstructionContextMenu from '~/components/dialogs/desktop-instruction/DesktopInstructionContextMenu.vue';
import { ContextMenuType } from '~/store/context/state';
import AcceptTermsCheckmark from '~/components/dialogs/desktop-instruction/AcceptTermsCheckmark.vue';
import TermsOfUseDesktop from '~/components/dialogs/desktop-instruction/TermsOfUseDesktop.vue';
import SignUpSection from '~/components/dialogs/sign-up/sections/SignUpSection.vue';
import LoginSection from '~/components/dialogs/sign-up/sections/LoginSection.vue';
import ContactInfoContextMenu from '~/components/dialogs/contact-terms-privacy/ContactInfoContextMenu.vue';
import TermsOfUseWeb from '~/components/dialogs/contact-terms-privacy/TermsOfUseWeb.vue';
import PrivacyPolicy from '~/components/dialogs/contact-terms-privacy/PrivacyPolicy.vue';
import ContactImprint from '~/components/dialogs/contact-terms-privacy/ContactImprint.vue';
import { ContactInfoMenuPoint } from '~/models/ContactInfoMenuPoint';

@Component({
  components: {
    ContactImprint,
    PrivacyPolicy,
    TermsOfUseWeb,
    ContactInfoContextMenu,
    LoginSection,
    SignUpSection,
    TermsOfUseDesktop,
    AcceptTermsCheckmark,
    DesktopInstructionContextMenu,
  },
})
export default class ContactInfoMenu extends Vue {
  public menuPoint = ContactInfoMenuPoint.CONTACT;
  public menuPoints = ContactInfoMenuPoint;

  public changeMenu(menuPoint: ContactInfoMenuPoint) {
    this.menuPoint = menuPoint;
  }

  created() {
    this.menuPoint = this.menuData;
  }

  public get menuData(): ContactInfoMenuPoint {
    return this.$store.getters['context/menu'](ContextMenuType.TERMS_PRIVACY_CONTACT)?.data as ContactInfoMenuPoint;
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.TERMS_PRIVACY_CONTACT);
  }

  @Watch('menuData')
  watchMenuData(value: ContactInfoMenuPoint) {
    this.menuPoint = value;
  }
}
