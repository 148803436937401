
import { Component, Vue } from 'nuxt-property-decorator';
import _throttle from 'lodash.throttle';
import MutantLogo from '~/components/layout/header/MutantLogo.vue';
import TopNavigationMenu from '~/components/layout/header/TopNavigationMenu.vue';
import { LayoutType } from '~/models/LayoutType';
import { MenuEntry } from '~/models/MenuEntry';
import { ContextMenuType } from '~/store/context/state';
import { ContactInfoMenuPoint } from '~/models/ContactInfoMenuPoint';
import MutantBurger from '~/components/ui/MutantBurger.vue';
import UploadVolumeStatus from '~/components/layout/header/UploadVolumeStatus.vue';

@Component({
  components: {
    UploadVolumeStatus,
    MutantBurger,
    TopNavigationMenu,
    MutantLogo,
  },
})
export default class HeaderNavigation extends Vue {
  public static HEIGHT: number = 30;
  public LayoutType = LayoutType;
  public menuEntry = MenuEntry;

  private throttleToggle;

  mounted() {
    this.throttleToggle = _throttle(() => this.$store.dispatch('toggleMenu'), 800, { leading: true, trailing: true });
  }

  public get isLightMode() {
    return this.isMobile && this.$store.state.isLightMode;
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get currentLayout(): LayoutType {
    return this.$store.getters.currentLayout;
  }

  public chooseLayout(layout: LayoutType) {
    this.$store.dispatch('chooseLayout', layout);
  }

  public get isMenuOpen(): boolean {
    return this.$store.state.menuOpen;
  }

  public get isHomepageView(): boolean {
    return this.$routeAwareness.isHomepageView;
  }

  public toggleMenu() {
    if (!this.$roleAwareness.isPayingRole) {
      this.$store.dispatch('visitHomepage');
    } else {
      this.throttleToggle();
    }
  }

  public openTermsOfUse() {
    const data = { type: ContextMenuType.TERMS_PRIVACY_CONTACT, data: ContactInfoMenuPoint.TERMS };
    if (this.$store.getters['context/isOpen'](data.type)) {
      this.$store.dispatch('context/updateMenuData', data);
    }
    this.$store.dispatch('context/openMenu', data);
  }

  public openPrivacyPolicy() {
    const data = { type: ContextMenuType.TERMS_PRIVACY_CONTACT, data: ContactInfoMenuPoint.PRIVACY };
    if (this.$store.getters['context/isOpen'](data.type)) {
      this.$store.dispatch('context/updateMenuData', data);
    }
    this.$store.dispatch('context/openMenu', data);
  }

  public openContact() {
    const data = { type: ContextMenuType.TERMS_PRIVACY_CONTACT, data: ContactInfoMenuPoint.CONTACT };
    if (this.$store.getters['context/isOpen'](data.type)) {
      this.$store.dispatch('context/updateMenuData', data);
    }
    this.$store.dispatch('context/openMenu', data);
  }

  public visitHomepage() {
    this.$store.dispatch('visitHomepage');
  }
}
