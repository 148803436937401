
import { Vue, Component } from 'nuxt-property-decorator';
import { ObjectId } from '~/models/ObjectId';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import Selection from '~/models/selection/Selection';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';

@Component
export default class SelectionList extends Vue {
  public lastClicked = null;
  public timeoutHandler = null;

  mounted() {
    if (!this.$store.state.selection.selections.length) {
      this.$store.dispatch('selection/loadSelections');
    }
  }

  public get selections(): Selection[] {
    return this.$store.getters['selection/selectionsByLastModified'];
  }

  public isActive(selection: Selection): boolean {
    return this.lastClicked === selection.id || this.$store.getters.isActiveObject(ObjectId.fromSelectionId(selection.id));
  }

  async chooseSelection(selection: Selection) {
    clearTimeout(this.timeoutHandler);
    this.lastClicked = selection.id;
    this.timeoutHandler = setTimeout(() => {
      this.lastClicked = null;
    }, 2000);
    const objectId = ObjectId.fromSelectionId(selection.id);
    await this.$store.dispatch('cloud/addToPane', { windowId: ViewIdentifier.MAIN_VIEW, objectIds: [objectId], setDefaultView: true });
  }

  dragSelection(event: DragEvent, selection: Selection) {
    event.dataTransfer.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.SELECTION);
    event.dataTransfer.setData(TransferOption.MUTANT_ID, selection.id);
  }
}
