
import { Vue, Component, Prop } from 'nuxt-property-decorator';

  @Component
export default class MutantButton extends Vue {
    @Prop()
    width!: string;

    public get buttonStyle() {
      return this.width
        ? {
            width: this.width,
          }
        : {};
    }
}
