
import { Component } from 'nuxt-property-decorator';
import MutantVerticalTab from '~/components/ui/MutantVerticalTab.vue';
import { ResizableWindow } from '~/components/window/ResizableWindow';
import MutantGrabberMenu, { Direction } from '~/components/menu/left/MutantGrabberMenu.vue';

@Component({
  components: {
    MutantGrabberMenu,
    MutantVerticalTab,
  },
})
export default class SidePane extends ResizableWindow {
  public direction = Direction;

  constructor() {
    super();
  }

  public get width(): number {
    return this.$store.state.cloudSidePaneWidth;
  }

  public persistSidePane() {
    this.$store.dispatch('persistSidePane');
  }

  public updateWidth(value: number) {
    this.$store.dispatch('updateSidePaneWidth', value);
    this.$emit('set-width', value);
  }

  mounted() {
    this.handleRegisteringOfResizer();
  }

  public get isOpen() {
    return this.$store.state.cloudSidePaneOpen;
  }

  public get isLayoutDragged(): boolean {
    return this.$store.state.isLayoutDragged;
  }

  public get isCloudMenuOpen(): boolean {
    return this.$store.state.cloudMenuRightOpen;
  }

  public toggleCloudMenuRight() {
    this.$store.dispatch('toggleCloudMenuRight');
  }

  togglePane() {
    this.$store.dispatch('toggleSidePane');
    this.$emit('set-width', this.width);
    this.handleRegisteringOfResizer();
  }

  private handleRegisteringOfResizer() {
    if (this.isOpen) {
      this.reRegisterResizeObserver();
    } else {
      this.unregisterResizeObserver();
    }
  }
}
