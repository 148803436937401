
import { Component, Vue } from 'nuxt-property-decorator';
import { ContextMenuType } from '~/store/context/state';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import ContextMenuHeader from '~/components/dialogs/ui/ContextMenuHeader.vue';
import Column from '~/components/ui/Column.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import { ValidationData } from '~/store/user/actions';

@Component({
  components: {
    MutantContextMenuDivider,
    Column,
    ContextMenuHeader,
    MutantContextMenu,
  },
})
export default class ValidationContextMenu extends Vue {
  private get validationData(): ValidationData {
    return this.$store.getters['context/menu'](ContextMenuType.VALIDATION)?.data?.payload;
  }

  public get isValidationError() {
    return this.validationData?.isError;
  }

  public get validationMessage() {
    return this.validationData?.message;
  }

  public get validationName() {
    return this.validationData?.validationName;
  }

  public close(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.$store.dispatch('context/closeMenu', ContextMenuType.VALIDATION);
  }
}
