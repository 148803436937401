
import { Component, Vue } from 'nuxt-property-decorator';
import throttle from 'lodash.throttle';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';

@Component
export default class EmptyWindow extends Vue {
  private throttledDragOverTimer = null;
  private uploadHandlerInProgress = false;
  public isDragOver = false;
  public throttledDragOver = throttle(this.dragover, 100, { leading: true, trailing: false });

  private hasExternalData(dragEvent: DragEvent) {
    return dragEvent.dataTransfer?.items?.length > 0;
  }

  private dragover(dragEvent: DragEvent) {
    if (this.hasExternalData(dragEvent)) {
      this.isDragOver = true;
      clearTimeout(this.throttledDragOverTimer);
      this.throttledDragOverTimer = setTimeout(() => {
        this.isDragOver = false;
      }, 250);
    }
  }

  public async handleDrop(dragEvent: DragEvent) {
    if (this.hasExternalData(dragEvent)) {
      dragEvent.preventDefault();
      if (!this.uploadHandlerInProgress) {
        this.uploadHandlerInProgress = true;
        await this.$store.dispatch('folder/uploadToNewFolder', new AddExternalContentEvent(dragEvent, { order: 0 }));
        setTimeout(() => {
          this.uploadHandlerInProgress = false;
        }, 1000);
      }
    }
  }
}
