
import { Component, Vue } from 'nuxt-property-decorator';
import Row from '~/components/ui/Row.vue';
import MutantBadge from '~/components/ui/MutantBadge.vue';
import { FolderTagHierarchy } from '~/store/folder/getters';
import TagWithBadge from '~/components/menu/right/tabs/review/folder-tags/TagWithBadge.vue';
import Tag from '~/components/menu/right/tabs/review/folder-tags/Tag.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { TagState } from '~/store/cloud/state';
import TagHeaderBadge from '~/components/menu/right/tabs/review/folder-tags/TagHeaderBadge.vue';
import ReviewSectionHeader from '~/components/menu/right/tabs/review/ReviewSectionHeader.vue';
import { RatingsFileTypes } from '~/store/cloud/getters';

@Component({ components: { ReviewSectionHeader, TagHeaderBadge, Tag, TagWithBadge, MutantBadge, Row } })
export default class TagsSection extends Vue {
  public isExpanded = true;
  public tagState = TagState;

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public get tagFilterState(): TagState {
    return this.$store.getters['cloud/folderTagFilter'].state;
  }

  public clearFolderTags() {
    this.$store.commit('cloud/clearFolderTags', { windowId: ViewIdentifier.MAIN_VIEW, tagState: TagState.CLEAR_TAGS });
  }

  public selectNoTags() {
    this.$store.commit('cloud/clearFolderTags', { windowId: ViewIdentifier.MAIN_VIEW, tagState: TagState.NO_TAGS });
  }

  public get allTagFilteredCount(): RatingsFileTypes {
    return this.$store.getters['cloud/countAllTagWithFilter'];
  }

  public get noTagFilteredCount(): RatingsFileTypes {
    return this.$store.getters['cloud/countNoTagWithFilter'];
  }

  public get allTags(): FolderTagHierarchy[] {
    return this.$store.getters['folder/tagsByMainView'];
  }
}
