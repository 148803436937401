
import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import Folder from '~/models/Folder';
import { Contact } from '~/models/user/Contact';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import Selection from '~/models/selection/Selection';
import { CloudObject } from '~/models/cloud/CloudObject';

declare let jdenticon: any;

  @Component
export default class MutantDraggable extends Vue {
    @Prop(Boolean)
      draggable?: boolean;

    @Prop()
      user?: Contact;

    @Prop()
      folder?: Folder;

    @Prop()
      selection?: Selection;

    @Prop()
      cloudObject?: CloudObject<Folder | Selection>;

    async handleDragStart(event: DragEvent) {
      if (this.folder || this.cloudObject.isFolder) {
        await this.handleDragFolder(event);
      } else if (this.selection || this.cloudObject.isSelection) {
        await this.handleDragSelection(event);
      }
      this.$emit('dragstart', event);
    };

    public handleDragSelection(event: DragEvent) {
      event.dataTransfer.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.SELECTION);
      event.dataTransfer.setData(TransferOption.MUTANT_ID, this.selection?.id ?? this.cloudObject.object.id);
    }

    public handleDragFolder(event: DragEvent) {
      event.dataTransfer.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.FOLDER);
      event.dataTransfer.setData(TransferOption.MUTANT_ID, this.folder?.id ?? this.cloudObject.object.id);
    }

    private xvgToImageUrl(xvg: string) {
      return 'data:image/svg+xml,' + encodeURIComponent(xvg);
    }
}
