
import { Vue, Component } from 'nuxt-property-decorator';
import Column from '~/components/ui/Column.vue';
import Row from '~/components/ui/Row.vue';

@Component({
  components: { Column, Row },
})
export default class ContextMenuHeader extends Vue {
}
