
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import NavigationElementNew from '~/components/dialogs/ui/NavigationElementNew.vue';
import NavigationElements from '~/components/dialogs/ui/NavigationElements.vue';
import Column from '~/components/ui/Column.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantTagButton from '~/components/ui/MutantTagButton.vue';
import Row from '~/components/ui/Row.vue';
import Selection from '~/models/selection/Selection';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: {
    MutantContextMenuDivider,
    NavigationElementNew,
    NavigationElements,
    MutantTagButton,
    Row,
    Column,
  },
})
export default class ChangePasswordSection extends Vue {
  private static FORM_IDENTIFIER = 'change-password';

  @Prop()
  private isResetByOldPassword;

  private static formIdentifier(id: string) {
    return `${ChangePasswordSection.FORM_IDENTIFIER}-${id}`;
  }

  public get passwordResetCode(): Selection {
    return this.$store.getters['context/menu'](ContextMenuType.CHANGE_PASSWORD)?.data;
  }

  public formFieldIds = {
    oldPassword: ChangePasswordSection.formIdentifier('oldPassword'),
    password: ChangePasswordSection.formIdentifier('password'),
    confirmPassword: ChangePasswordSection.formIdentifier('confirmPassword'),
  };

  public oldPassword: string = '';
  public password: string = '';
  public confirmPassword: string = '';
  public userIsTyping = false;

  constructor() {
    super();
  }

  created() {
    window.addEventListener('keydown', this.keyEventHandler);
  }

  mounted() {
    document.getElementById(this.formFieldIds.password)?.focus();
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyEventHandler);
  }

  async keyEventHandler(e: KeyboardEvent) {
    this.userIsTyping = true;
    if (e.key === 'Enter' && this.password.length && this.confirmPassword.length) {
      await this.changePassword();
    }
  }

  public get isInputValid(): boolean {
    return (this.isResetByOldPassword ? this.oldPassword.length >= 6 : true) && this.password.length >= 6 && this.confirmPassword.length >= 6 && this.password === this.confirmPassword;
  }

  public async changePassword() {
    if (this.isInputValid) {
      await this.$store.dispatch('user/changePassword', {
        oldPassword: this.isResetByOldPassword ? this.oldPassword : undefined,
        newPassword: this.password,
        token: this.isResetByOldPassword ? undefined : this.passwordResetCode,
      });
      if (this.isResetByOldPassword) {
        this.$emit('change-password-success');
      }
      this.userIsTyping = false;
    }
  }
}
