
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantContextMenuHeader from '~/components/ui/MutantContextMenuHeader.vue';
import MutantContextMenuItem from '~/components/ui/MutantContextMenuItem.vue';
import Folder from '~/models/Folder';
import { ObjectId } from '~/models/ObjectId';
import { ContextMenuType } from '~/store/context/state';
import { DeleteFolderData } from '~/store/folder/actions';

@Component({
  components: {
    MutantContextMenuHeader,
    MutantContextMenuDivider,
    MutantContextMenuItem,
    MutantContextMenu,
  },
})
export default class MutantFolderItemContextMenu extends Vue {
  public get startPosition() {
    const { pageX, pageY } = this.$store.getters['context/menu'](ContextMenuType.FOLDER)?.event || { pageX: 0, pageY: 0 };
    return {
      left: pageX + 5,
      top: pageY + 5,
    };
  }

  public get folder(): Folder {
    return this.$store.getters['context/menu'](ContextMenuType.FOLDER)?.data?.object;
  }

  public get existsSharedLink(): boolean {
    return this.$store.getters['folder/linkDataExists'](this.folder.id);
  }

  async visitSharedLinkOfFolder() {
    const folderId = ObjectId.fromFolderId(this.folder.id).toString();
    const sharedLink = this.$store.getters['link/linkData'](folderId);
    await this.$router.push({
      name: 'cloud-id',
      params: {
        id: folderId,
      },
      query: {
        'shared-link-id': sharedLink.accessId,
      },
    });
    await this.$store.dispatch('cloud/initializeCloudContent');
    this.closeMenu();
  }

  removeSharedLink() {
    const folderId = ObjectId.fromFolderId(this.folder.id).toString();
    const sharedLink = this.$store.getters['link/linkData'](folderId);
    this.$store.dispatch('link/delete', {
      objectId: ObjectId.fromFolderId(this.folder.id),
      accessId: sharedLink.accessId,
    });
    this.closeMenu();
  }

  copySharedLink() {
    this.$store.dispatch('link/copyToClipboardForFolderId', this.folder.id);
    this.closeMenu();
  }

  downloadFolder() {
    this.$store.dispatch('folder/downloadFolder', this.folder.id);
    this.closeMenu();
  }

  async deleteFolder() {
    await this.$store.dispatch('folder/removeFolder', { folderId: this.folder.id } as DeleteFolderData);
    this.closeMenu();
  }

  async createSharedLink() {
    await this.$store.dispatch('link/createSharedLink', { folderId: this.folder.id });
    this.closeMenu();
  }

  closeMenu() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.FOLDER);
  }

  @Watch('folder', { immediate: true })
  public async watchFolder(newFolder, oldFolder) {
    if (oldFolder == null || (oldFolder?.id !== newFolder?.id)) {
      await this.$store.dispatch('folder/loadSharedLinks', newFolder.id);
    }
  }
}
