
import { Component, Vue } from 'nuxt-property-decorator';
import Column from '../ui/Column.vue';
import MutantContextMenu from '../ui/MutantContextMenu.vue';
import Row from '../ui/Row.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import { ContextMenuType } from '~/store/context/state';
import ContextMenuButton from '~/components/ui/buttons/ContextMenuButton.vue';
import SharedLinkInfo from '~/components/menu/right/tabs/shared-link/SharedLinkInfo.vue';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import ExpandDownloadButtons from '~/components/ui/buttons/ExpandDownloadButtons.vue';
import Selection from '~/models/selection/Selection';
import ThumbnailSlider from '~/components/ui/ThumbnailSlider.vue';
import { UnitSize } from '~/models/UnitSize';
import ItemSize from '~/components/ui/ItemSize.vue';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import InfoButton from '~/components/ui/buttons/InfoButton.vue';
import DownloadInfo from '~/components/dialogs/download/DownloadInfo.vue';
import { AssetListBuilder } from '~/models/asset/AssetListBuilder';
import { RAW_ASSET_VERSION } from '~/models/Asset';
import { LayoutType } from '~/models/LayoutType';

@Component({
  components: {
    DownloadInfo,
    InfoButton,
    MutantCheckMark,
    ItemSize,
    ThumbnailSlider,
    ExpandDownloadButtons,
    SharedLinkInfo,
    ContextMenuHeaderFlashy,
    Column,
    MutantContextMenu,
    ContextMenuButton,
    Row,
  },
})
export default class SharedLinkReceiverContextMenu extends Vue {
  public get itemsInObject(): number {
    return this.cloudObject?.object?.itemCount;
  }

  public get fileCountWithName(): string {
    return this.itemsInObject > 1 ? this.itemsInObject + ' images' : this.itemsInObject + ' image';
  }

  public get linkOwnerName() {
    return this.$store.getters['link/linkOwnerName'] ?? '';
  }

  public get rawAssetCountInSelectedAssets(): number {
    return new AssetListBuilder()
      .withAssets(this.$store.getters['cloud/assetsForCloudObjectAndVersion'](this.cloudObject, RAW_ASSET_VERSION))
      .build()
      .assets
      .length;
  }

  public get cloudObject(): CloudObject<Folder | Selection> {
    return this.$store.getters['cloud/currentCloudObject'];
  }

  public get accumulatedAssetSize(): UnitSize {
    return this.$store.getters['cloud/accumulatedSizeLargestAssets'](this.cloudObject);
  }

  public get isGuest(): boolean {
    return this.$roleAwareness.isGuestUser;
  }

  openLogin() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { showLogin: true } });
  }

  openSignup() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { showLogin: false } });
  }

  rateImages() {
    if (!this.$store.getters.isReviewMode) {
      this.$store.dispatch('setActiveLayoutType', LayoutType.REVIEW_MODE);
    }
    this.close();
  }

  download() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.DOWNLOAD_ALL });
  }

  public get startPosition() {
    return {
      top: window.innerHeight - (window.innerHeight / 1.5),
      left: 200,
    };
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.SHARED_LINK_RECEIVER);
  }
}
