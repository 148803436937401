
import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class SharedPage extends Vue {
  async created() {
    const sharedLinkAccessId = this.$route.params.id;
    await this.$store.dispatch('link/initializeSharedLinkData', sharedLinkAccessId);
    const objectId = this.$store.getters['link/objectIdByAccessId'](sharedLinkAccessId);
    if (objectId != null) {
      await this.$router.push({
        name: 'cloud-id',
        params: {
          id: objectId,
        },
        query: {
          'shared-link-id': sharedLinkAccessId,
        },
      });
    }
  }
}
