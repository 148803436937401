
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ExternalProgressIndicator from '~/components/ui/progress/ExternalProgressIndicator.vue';
import MutantCounter from '~/components/ui/MutantCounter.vue';

@Component({
  components: {
    MutantCounter,
    ExternalProgressIndicator,
  },
})
export default class SharedLinkProgress extends Vue {
  @Prop({ default: 50 })
    radius: number;

  @Prop({ default: 2000 })
    duration: number;

  @Prop(Boolean)
    isShared: boolean;

  @Prop(Boolean)
    isOwner: boolean;

  @Prop({ default: true })
    hasTooltip: boolean;

  @Prop({ default: true })
    isOffline: boolean;

  @Prop(Boolean)
    bounce: boolean;

  public showCopiedFeedback = false;
  public progress: number = 0;
  public transformation = false;
  public stroke: number = 3.5;
  public isLoading = false;
  public copyLinkText = { name: 'COPY LINK' };
  private static PROGRESS_STEPS = 100;
  private progressTimer;

  constructor() {
    super();
    this.progress = 0;
  }

  public get showIsShared() {
    return !this.isLoading && !this.isShared && (this.isOwner || this.isOffline);
  }

  public get showCopy() {
    return !this.isLoading && this.isShared && this.isOwner;
  }

  public get showDownload() {
    return !this.isLoading && this.isShared && !this.isOwner;
  }

  get tooltipText() {
    if (!this.isShared && this.$roleAwareness.isPayingRole) {
      return 'CREATE AND COPY SHARED LINK';
    } else if (this.isShared && !this.isOwner) {
      return 'DOWNLOAD ALL';
    } else if (this.isShared && this.isOwner) {
      return this.copyLinkText.name;
    } else if (!this.isShared && !this.$roleAwareness.isPayingRole) {
      return 'This is a pro feature, please upgrade your account';
    }
    return '';
  }

  resetParams() {
    this.isLoading = false;
    this.progress = 0;
    this.transformation = false;
  }

  public get displayProgress() {
    return this.progress < 10 ? '0' + this.progress : this.progress;
  }

  public get tooltip() {
    return this.hasTooltip ? { placement: 'bottom', content: this.tooltipText } : null;
  }

  startProgress() {
    if (this.isShared && this.isOwner) {
      this.showCopiedFeedback = true;
    }
    if (this.progress === 0) {
      if (!this.isShared && this.$roleAwareness.isUser) {
        this.isLoading = true;
        this.progress = 0;
        this.progressTimer = setInterval(() => this.incrementProgress(), this.duration / SharedLinkProgress.PROGRESS_STEPS);
      } else {
        this.$emit('toggleProgress');
      }
    }
  }

  public get dimensions() {
    return {
      'min-width': this.normalizedRadius * 2 + this.stroke * 1.34 + 'px',
      'min-height': this.normalizedRadius * 2 + this.stroke * 1.34 + 'px',
    };
  }

  public get buttonDimensions() {
    return {
      transform: `scale(${this.normalizedRadius / 15})`,
    };
  }

  public get copyButtonDimensions() {
    return {
      'min-width': this.normalizedRadius * 3 * 0.5 + this.stroke + 'px',
      'min-height': this.normalizedRadius * 2 * 0.5 + this.stroke + 'px',
    };
  }

  incrementProgress() {
    if (this.progress < SharedLinkProgress.PROGRESS_STEPS) {
      this.progress += 1;
    } else {
      if (this.bounce) {
        this.transformation = true;
      }
      this.$emit('toggleProgress');
      setTimeout(() => this.resetParams(), 1500);
      clearInterval(this.progressTimer);
    }
  }

  public get normalizedRadius() {
    return this.radius - this.stroke * 2;
  }

  public get circumference() {
    return this.normalizedRadius * 2 * Math.PI;
  }

  public get strokeDashoffset() {
    return this.circumference - this.progress / SharedLinkProgress.PROGRESS_STEPS * this.circumference;
  }
}
