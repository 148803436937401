
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ViewType } from '~/models/views/ViewType';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ViewTypeIconClasses } from '~/models/views/ViewTypeIconClasses';
import IconDropDown from '~/components/ui/IconDropDown.vue';

@Component({
  components: {
    IconDropDown,
  },
})
export default class ViewTypeControls extends Vue {
  @Prop()
  private view: MutantContentView;

  @Prop(Boolean)
  public collapse: boolean;

  public viewType = ViewType;
  public isViewTypeDropDownCollapsed = false;

  public collapseViewDropDown() {
    this.isViewTypeDropDownCollapsed = !this.isViewTypeDropDownCollapsed;
  }

  public get currentView(): ViewType {
    return this.view.viewOptions.activeViewType;
  }

  private get selectionId(): string {
    return this.view.selectionId;
  }

  public updateView(view: ViewType) {
    this.$store.dispatch('cloud/changeView', { windowId: this.view.id, view });
  }

  public selectViewType(event: any) {
    if (event.value !== this.currentView) {
      this.updateView(event.value);
      this.isViewTypeDropDownCollapsed = false;
    }
  }

  public get viewTypeIcons() {
    const hasSelectedItems = this.$store.getters['cloud/hasObjectSelectedItems'](this.view.objectIds[0]);
    return (this.view.isSingleFolderView ? this.viewTypeInfoFolder : this.viewTypeInfoSelection).map(viewTypeInfo => {
      return {
        ...viewTypeInfo,
        isActive: this.currentView === viewTypeInfo.value,
        hasSelectedItems,
      };
    });
  }

  private get viewTypeInfoSelection() {
    return Object.values(ViewType).map(viewType => ({ text: viewType, value: viewType, iconClass: ViewTypeIconClasses[viewType] }));
  }

  private get viewTypeInfoFolder() {
    return Object.values(ViewType).filter(viewType => viewType.toString() !== ViewType[ViewType.MOODBOARD]).map(viewType => ({ text: viewType, value: viewType, iconClass: ViewTypeIconClasses[viewType] }));
  }
}
