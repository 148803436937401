
import { Component, Vue } from 'nuxt-property-decorator';
import InfoSection from '~/components/dialogs/sections/InfoSection.vue';
import Column from '~/components/ui/Column.vue';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import ContextMenuHeader from '~/components/dialogs/ui/ContextMenuHeader.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import { ContextMenuType } from '~/store/context/state';
import ProgressPulse from '~/components/ui/progress/ProgressPulse.vue';
import { WebConfig } from '~/Config';

@Component({
  components: {
    ProgressPulse,
    InfoSection,
    MutantContextMenu,
    ContextMenuHeader,
    ContextMenuHeaderFlashy,
    Column,
  },
})
export default class AccountOptionsMenu extends Vue {
  showPayPalButton = true;
  paypalScript = document.createElement('script');

  mounted() {
    this.loadPaypalScript();
  }

  beforeDestroy() {
    this.removePaypalScript();
  }

  public get upgradeIsInProgress(): boolean {
    return this.$store.state.user.userRoleUpgradePending && !this.$store.getters['user/isUser'];
  }

  public get isAccountUpgradeOptionGiven(): boolean {
    return this.$store.getters['user/isAccountUpgradeOptionGiven'];
  }

  public get contextMenuTitle(): string {
    return this.showPayPalButton ? 'Account Upgrade' : 'Welcome to MUTANT';
  }

  public loadPaypalScript() {
    this.paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${WebConfig.PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
    this.paypalScript.async = true;
    this.paypalScript.defer = true;
    this.paypalScript.referrerPolicy = 'no-referrer';
    this.$nuxt.$el.appendChild(this.paypalScript);
    this.paypalScript.addEventListener('load', () => this.renderPaypalButton());
  }

  public removePaypalScript() {
    this.paypalScript.removeEventListener('load', () => this.renderPaypalButton());
    this.paypalScript.parentNode.removeChild(this.paypalScript);
  }

  renderPaypalButton() {
    const userId = this.$roleAwareness.currentUser.id;
    // @ts-ignore
    paypal.Buttons({
      createSubscription(_, actions) {
        return actions.subscription.create({
          plan_id: WebConfig.PAYPAL_PLAN_ID,
          custom_id: userId,
        });
      },
      onApprove: (data) => {
        this.showPayPalButton = false;
        this.$store.commit('user/setUserRoleUpgradePending', { subscriptionId: data.subscriptionID, isPending: true });
        this.$store.dispatch('user/upgradeAccount', data.subscriptionID);
        this.close();
      },
      onCancel: () => {
        this.showPayPalButton = true;
      },
    }).render('#paypal-button');
  }

  close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.ACCOUNT_UPGRADE);
  }
}
