var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"badge",class:{
    'badge--grey': _vm.color === 'grey',
    'badge--red': _vm.color === 'red',
    'badge--jungle': _vm.color === 'jungle',
    'badge--orange': _vm.color === 'orange',
    'badge--yellow': _vm.color === 'yellow'
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }