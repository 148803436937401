

import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { CloudObjectFilter, FilterType, mergeFilters, SortType } from '~/models/cloud/CloudObjectFilter';
import MutantCheckbox from '~/components/ui/MutantCheckbox.vue';
import CloudMenuHeader from '~/components/menu/left/CloudMenuHeader.vue';
import MutantBadgeButton from '~/components/ui/MutantBadgeButton.vue';
import MutantRadiantBadge from '~/components/ui/MutantRadiantBadge.vue';
import MutantRadiantButton from '~/components/ui/MutantRadiantButton.vue';
import CloudSortButton from '~/components/menu/left/CloudSortButton.vue';

@Component({
  components: {
    CloudSortButton,
    MutantRadiantBadge,
    MutantBadgeButton,
    MutantCheckbox,
    CloudMenuHeader,
    MutantRadiantButton,
  },
})
export default class CloudMenuFilter extends Vue {
  @Prop()
    activeFilter: CloudObjectFilter;

  isSortCollapsed: boolean = false;
  isFilterCollapsed: boolean = false;

  filters: Map<FilterType, boolean> = new Map<FilterType, boolean>();
  sortOptions: Map<SortType, boolean> = new Map<SortType, boolean>();

  sortType: string;

  created() {
    this.sortOptions.set(SortType.ALPHABETICALLY_ASC, this.activeFilter.sortType === SortType.ALPHABETICALLY_ASC);
    this.sortOptions.set(SortType.ALPHABETICALLY_DESC, this.activeFilter.sortType === SortType.ALPHABETICALLY_DESC);
    this.sortOptions.set(SortType.MODIFIED_ASC, this.activeFilter.sortType === SortType.MODIFIED_ASC);
    this.sortOptions.set(SortType.MODIFIED_DESC, this.activeFilter.sortType === SortType.MODIFIED_DESC);
  }

  displayCurrentFilter() {
    return this.activeFilter.filterType.length > 0 ? this.activeFilter.filterType.map(f => f.toUpperCase() + 'S').join('/ ') : 'FOLDERS/ SELECTIONS';
  }

  setActiveFilter(filterType?: FilterType) {
    let sortType: SortType;
    this.sortOptions.forEach((value, key) => {
      if (value) {
        sortType = key;
      }
    });
    const activeFilter: CloudObjectFilter = {
      sortType,
      filterType: filterType != null ? mergeFilters(this.activeFilter.filterType, filterType) : this.activeFilter.filterType,
    };
    this.sortOptions = new Map(this.sortOptions);
    this.$emit('change-filter', activeFilter);
  }

  setSortOptionLastModifiedASC() {
    this.sortOptions.set(SortType.MODIFIED_ASC, !this.sortOptions.get(SortType.MODIFIED_ASC));
    this.sortOptions.forEach((_value, key, map) => {
      if (key !== SortType.MODIFIED_ASC) {
        map.set(key, false);
      }
    });
    this.setActiveFilter();
  }

  setSortOptionLastModifiedDESC() {
    this.sortOptions.set(SortType.MODIFIED_DESC, !this.sortOptions.get(SortType.MODIFIED_DESC));
    this.sortOptions.forEach((_value, key, map) => {
      if (key !== SortType.MODIFIED_DESC) {
        map.set(key, false);
      }
    });
    this.setActiveFilter();
  }

  setSortOptionAlphabeticallyAsc() {
    this.sortOptions.set(SortType.ALPHABETICALLY_ASC, !this.sortOptions.get(SortType.ALPHABETICALLY_ASC));
    this.sortOptions.forEach((_value, key, map) => {
      if (key !== SortType.ALPHABETICALLY_ASC) {
        map.set(key, false);
      }
    });
    this.setActiveFilter();
  }

  setSortOptionByAlphabeticallyDesc() {
    this.sortOptions.set(SortType.ALPHABETICALLY_DESC, !this.sortOptions.get(SortType.ALPHABETICALLY_DESC));
    this.sortOptions.forEach((_value, key, map) => {
      if (key !== SortType.ALPHABETICALLY_DESC) {
        map.set(key, false);
      }
    });
    this.setActiveFilter();
  }

  setFilterByContactSheet() {
    this.setActiveFilter(FilterType.CONTACT_SHEET);
  }

  setFilterByFolder() {
    this.setActiveFilter(FilterType.FOLDER);
  }

  setFilterBySelection() {
    this.setActiveFilter(FilterType.SELECTION);
  }

  public collapse(event) {
    this.$emit('click', event);
  }

  public collapseSort(event) {
    event.stopPropagation();
    this.isFilterCollapsed = false;
    this.isSortCollapsed = !this.isSortCollapsed;
  }

  public get filterTypes(): FilterType[] {
    return this.activeFilter.filterType;
  }

  public get filterBySelection() {
    return this.filterTypes.includes(FilterType.SELECTION);
  }

  public get filterByFolder() {
    return this.filterTypes.includes(FilterType.FOLDER);
  }

  public get filterByContactSheet() {
    return this.filterTypes.includes(FilterType.CONTACT_SHEET);
  }

  public get sortByAlphabeticallyDesc() {
    return this.sortOptions.get(SortType.ALPHABETICALLY_DESC);
  }

  public get sortByAlphabeticallyAsc() {
    return this.sortOptions.get(SortType.ALPHABETICALLY_ASC);
  }

  public get sortByLastModifiedAsc() {
    return this.sortOptions.get(SortType.MODIFIED_ASC);
  }

  public get sortByLastModifiedDesc() {
    return this.sortOptions.get(SortType.MODIFIED_DESC);
  }
}
