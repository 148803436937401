import { render, staticRenderFns } from "./SignUpSection.vue?vue&type=template&id=729462ba&scoped=true&"
import script from "./SignUpSection.vue?vue&type=script&lang=ts&"
export * from "./SignUpSection.vue?vue&type=script&lang=ts&"
import style0 from "./SignUpSection.vue?vue&type=style&index=0&id=729462ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729462ba",
  null
  
)

export default component.exports