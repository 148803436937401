
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ErrorKey } from '~/models/error/ErrorKey';
import { errorMap } from '~/models/error/ErrorMap';
import { ErrorDescription } from '~/models/error/ErrorDescription';

interface HttpErrorResponse {
  statusCode: number,
  message: string,
  errorKey: ErrorKey;
}
@Component({
  layout: 'default',
})
export default class NuxtError extends Vue {
  @Prop()
    error: any;

  mounted() {
    this.$store.dispatch('setLayoutInitialized', true);
  }

  public get errorData(): ErrorDescription {
    let errorDescription;
    const httpErrorResponse: HttpErrorResponse = this.error.response?.data;
    if (httpErrorResponse?.errorKey) {
      errorDescription = errorMap.get(httpErrorResponse.errorKey);
    } else if (this.error.errorKey) {
      errorDescription = errorMap.get(this.error.errorKey);
    } else if (this.error.statusCode === 404) {
      errorDescription = errorMap.get(ErrorKey.RESOURCE_NOT_FOUND);
    } else {
      errorDescription = {
        title: 'Error occurred',
        text: `Error message: ${this.error.message}. Please try later or contact support`,
      };
    }
    return errorDescription;
  }

  public refreshPage() {
    window.location.reload();
  }

  public visitHomepage() {
    this.$store.dispatch('visitHomepage');
  }
}
