
import { Component, Vue } from 'nuxt-property-decorator';
import { ContextMenuType } from '~/store/context/state';
import { ContactInfoMenuPoint } from '~/models/ContactInfoMenuPoint';

@Component
export default class LegalFooter extends Vue {
  public openTermsOfUse() {
    const data = { type: ContextMenuType.TERMS_PRIVACY_CONTACT, data: ContactInfoMenuPoint.TERMS };
    if (this.$store.getters['context/isOpen'](data.type)) {
      this.$store.dispatch('context/updateMenuData', data);
    }
    this.$store.dispatch('context/openMenu', data);
  }

  public openPrivacyPolicy() {
    const data = { type: ContextMenuType.TERMS_PRIVACY_CONTACT, data: ContactInfoMenuPoint.PRIVACY };
    if (this.$store.getters['context/isOpen'](data.type)) {
      this.$store.dispatch('context/updateMenuData', data);
    }
    this.$store.dispatch('context/openMenu', data);
  }

  public openContact() {
    const data = { type: ContextMenuType.TERMS_PRIVACY_CONTACT, data: ContactInfoMenuPoint.CONTACT };
    if (this.$store.getters['context/isOpen'](data.type)) {
      this.$store.dispatch('context/updateMenuData', data);
    }
    this.$store.dispatch('context/openMenu', data);
  }
}
