
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { PlaceItemsEvent } from '~/models/PlaceItemsEvent';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ObjectId } from '~/models/ObjectId';
import { ResizeItemEvent } from '~/models/ResizeItemEvent';
import { SortItemsEvent } from '~/models/item/SortItemsEvent';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import { ViewSortingOption } from '~/store/cloud/state';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { UploadData } from '~/store/file/actions';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';

@Component({
  components: {},
})
export default class MutantView extends Vue {
  @Prop()
  private mutantView: MutantContentView;

  @Prop()
  private drag: boolean;

  private uploadHandlerInProgress = false;
  private isDragOver = false;

  public fileInputId = 'file-input-id-mutant-view-' + uuid();

  constructor() {
    super();
  }

  created() {
    this.$on('sort-items', this.sortItems);
    this.$on('place-items', this.placeItems);
    this.$on('resize-item', this.resizeItem);
    this.$on('add-external-content', this.addExternalContent);
  }

  beforeDestroy() {
    this.$off('sort-items', this.sortItems);
    this.$off('place-items', this.placeItems);
    this.$off('resize-item', this.resizeItem);
    this.$off('add-external-content', this.addExternalContent);
  }

  uploadFiles(e: any) {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (e.dataTransfer || e.target) {
      this.$store.dispatch<ActionPayload<UploadData>>({
        type: 'file/upload',
        payload: { windowId: this.mutantView.id, event: new AddExternalContentEvent(e, { order: this.viewItems.length }), pipelineCommands: [PipelineCommandType.CREATE_THUMBNAILS] },
      });
    }
  }

  public get isMobileWidth(): boolean {
    return this.$store.getters.isMobileWidth;
  }

  public get viewId(): string {
    return this.mutantView.id;
  }

  public get viewItems(): ItemWithPosition[] {
    return this.$store.getters['cloud/viewItemsMap'][this.mutantView.id].items;
  }

  public get showEmptyViewInfo(): boolean {
    return (this.mutantView.isEmpty || this.mutantView.isSingleFolderView || this.mutantView.isSingleSelectionView)
      && !this.viewItems?.length && this.mutantView.id === ViewIdentifier.MAIN_VIEW;
  }

  public get isColorFilterActive(): boolean {
    return this.$store.state.cloud.registeredWindows[this.mutantView.id]?.colorFilter;
  }

  public get isFilterForActiveSelectionsActive(): boolean {
    return this.$store.state.cloud.registeredWindows[this.mutantView.id]?.filterForActiveSelections;
  }

  public get isFilteredView(): boolean {
    return this.$store.getters['cloud/isFilteredButHasItems'](this.viewId);
  }

  public get isOwnerOfView(): boolean {
    return this.$store.getters['cloud/isOwnerOfView'](this.mutantView.id);
  }

  public triggerFileUpload(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.showEmptyViewInfo && !this.isFilteredView) {
      this.$store.commit('cloud/setActiveWindowId', this.viewId);
      const fileInput = document.getElementById(this.fileInputId);
      if (fileInput) {
        fileInput.click();
      }
    }
  }

  cancelDragOver(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const { top, left, width, height } = this.$el.getBoundingClientRect();
    const leaveEventOffset = 50;
    if (event.clientX <= left + leaveEventOffset || event.clientX >= left + width - leaveEventOffset || event.clientY <= top + leaveEventOffset || event.clientY >= top + height - leaveEventOffset) {
      this.isDragOver = false;
    }
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.drag) {
      const viewIdentifier = TransferOption.MUTANT_VIEW_ID.toLowerCase() + '_' + this.viewId.toLowerCase();
      if (!event.dataTransfer.types.includes(viewIdentifier)
        && event.dataTransfer.types.some(t => t === TransferOption.MUTANT_TRANSFER_TYPE.toLowerCase() || t === 'Files')) {
        this.isDragOver = true;
      }
    }
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.drag) {
      this.isDragOver = false;
      const hasExternalData = event.dataTransfer && (event.dataTransfer.items?.length || event.dataTransfer.files?.length || event.dataTransfer.getData('URL'));
      if (hasExternalData) {
        this.addExternalContent(new AddExternalContentEvent(event, { order: 0 }));
      } else {
        this.addCloudObjectToPane(event);
      }
    }
  }

  public addCloudObjectToPane(event: DragEvent | InputEvent) {
    const mutantTransferType = event.dataTransfer?.getData(TransferOption.MUTANT_TRANSFER_TYPE) || this.$store.state.dragInfo?.transferType;
    if (mutantTransferType) {
      switch (mutantTransferType) {
        case TransferType.FOLDER: {
          const folderId = event.dataTransfer?.getData(TransferOption.MUTANT_ID) || this.$store.state.dragInfo.mutantId;
          this.$store.dispatch('cloud/addToPane', { windowId: this.mutantView.id, objectIds: [ObjectId.fromFolderId(folderId)] });
          break;
        }
        case TransferType.SELECTION: {
          const selectionId = event.dataTransfer?.getData(TransferOption.MUTANT_ID) || this.$store.state.dragInfo.mutantId;
          this.$store.dispatch('cloud/addToPane', { windowId: this.mutantView.id, objectIds: [ObjectId.fromSelectionId(selectionId)] });
          break;
        }
      }
    }
  }

  public sortItems(event: SortItemsEvent) {
    const originalSortOrder = this.$store.getters['cloud/window'](this.viewId).sortedBy;
    if (this.mutantView.isGlobalSelectionView) {
      this.$store.dispatch('selection/sortGlobalSelectionItems', {
        event,
        originalSortOrder: ViewSortingOption.CUSTOM_ORDER,
      });
    } else if (this.mutantView.isSingleFolderView) {
      this.$store.dispatch('folder/sortItems', {
        windowId: this.mutantView.id,
        folderId: this.mutantView.folderId,
        event,
        originalSortOrder,
      });
    } else if (this.mutantView.isSingleSelectionView) {
      this.$store.dispatch('selection/sortItems', {
        windowId: this.mutantView.id,
        selectionId: this.mutantView.selectionId,
        event,
        originalSortOrder,
      });
    } else if (this.mutantView.isEmpty) {
      const originView: MutantContentView = this.$store.getters['cloud/view'](this.$store.state.dragInfo.viewId);
      if (originView.isSelectionView) {
        this.$store.dispatch('selection/create', {
          items: event.items,
          windowId: this.mutantView.id,
        });
      } else if (originView.isFolderView) {
        this.$store.dispatch('folder/saveItemsAsNewFolder', {
          items: event.items,
          windowId: this.mutantView.id,
        });
      }
    }
  }

  // triggered when items are placed from other views into a moodboard view
  public placeItems(event: PlaceItemsEvent) {
    if (this.mutantView.isGlobalSelectionView) {
      // TODO: place/move items into global selection moodboard
    } else if (this.mutantView.isSelectionView && this.mutantView.isSingleSelectionView) {
      this.$store.dispatch('selection/moveItemsToPosition', {
        event,
        selectionId: this.mutantView.selectionId,
      });
    } else if (this.mutantView.isFolderView && this.mutantView.isSingleFolderView) {
      // TODO: check if this is needed at all since folders currently don't support moodboards
      for (const item of event.items) {
        if (item.item.folderId !== this.mutantView.folderId) {
          this.$store.dispatch('folder/addExternalItemTo', {
            folderId: this.mutantView.folderId,
            itemId: item.itemId || item.item.id,
          });
        }
      }
    }
  }

  resizeItem({ width, rotation, item, x, y }: ResizeItemEvent) {
    if (this.mutantView.isSelectionView && this.mutantView.isSingleSelectionView) {
      this.$store.dispatch('selection/updateItemPositions', {
        selectionId: this.mutantView.selectionId,
        items: [{
          ...item,
          position: {
            ...item.position,
            x,
            y,
            width,
            rotation,
          },
        }],
      });
    }
  }

  public addExternalContent(event: AddExternalContentEvent) {
    const e = event.event;
    if (e.dataTransfer && !this.uploadHandlerInProgress) {
      this.uploadHandlerInProgress = true;
      if (e.dataTransfer.files?.length) {
        this.$store.dispatch<ActionPayload<UploadData>>({ type: 'file/upload', payload: { windowId: this.mutantView.id, event, pipelineCommands: [PipelineCommandType.CREATE_THUMBNAILS] } });
      } else {
        const url = e.dataTransfer.getData('URL');
        if (url) {
          if (this.mutantView.folderId) {
            this.$store.dispatch('folder/extractUrl', {
              folderId: this.mutantView.folderId,
              url,
              originalEvent: e,
            });
          } else if (this.mutantView.selectionId) {
            this.$store.dispatch('selection/extractUrlTo', {
              selectionId: this.mutantView.selectionId,
              url,
              addExternalContentEvent: event,
            });
          }
        } else {
          this.addCloudObjectToPane(e);
        }
      }
      setTimeout(() => {
        this.uploadHandlerInProgress = false;
      }, 1000);
    }
  }
}
