
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class MutantHighlightBox extends Vue {
  @Prop(Boolean)
  small: boolean;

  @Prop(Boolean)
  medium: boolean;

  @Prop(Boolean)
  large: boolean;

  @Prop(Boolean)
  info: boolean;

  @Prop(Boolean)
  darkened: boolean;
}
