
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class ProgressBarNew extends Vue {
  private progressStepCount = 50;

  @Prop()
  width: number;

  @Prop()
  progress: number;

  public get progressWidth() {
    return this.width ? `${this.width}px` : '100%';
  }

  public get progressSteps() {
    return Array.from({ length: this.progressStepCount }, (v, k) => k);
  }

  public isProgressAboveStep(step: number) {
    return this.progress >= step * 100 / this.progressStepCount;
  }
}
