
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator';

@Component({})
export default class ExternalProgressIndicator extends Vue {
  @Prop()
  progress: number;

  @Prop()
  title: string;

  @Prop(Boolean)
  isLarge: boolean;

  @Prop({ default: 2000 })
  minLoadingTime: number

  @Prop({ default: 500 })
  stepTransitionTime: number;

  @Prop({ default: 2500 })
  resetTime: number;

  private startedLoadingTime = null;
  private loadingTimeAdjustedStepTransitionTime = 500;
  private adjustedProgress = 0;
  private resetTimeoutHandler = null;

  public get progressStyle() {
    return {
      width: `${Math.round(this.adjustedProgress)}%`,
      transition: `width ${this.loadingTimeAdjustedStepTransitionTime}ms`,
    };
  }

  @Watch('progress', { immediate: true })
  watchProgress(newValue, oldValue) {
    if (newValue !== 100 && this.resetTimeoutHandler) {
      clearTimeout(this.resetTimeoutHandler);
    }
    if (newValue === oldValue) {
      return;
    }
    if (newValue && !this.startedLoadingTime) {
      this.startedLoadingTime = Date.now();
    }
    if (oldValue && newValue < oldValue) {
      this.startedLoadingTime = null;
      return;
    }
    this.adjustedProgress = newValue < 100 && (oldValue === undefined || oldValue === null) ? 0 : newValue;
    if (this.startedLoadingTime) {
      const currentLoadingTime = Date.now() - this.startedLoadingTime;
      const loadingStepTransitionTime = this.minLoadingTime > currentLoadingTime
        ? this.minLoadingTime - currentLoadingTime
        : this.stepTransitionTime;
      this.loadingTimeAdjustedStepTransitionTime = loadingStepTransitionTime < this.stepTransitionTime
        ? this.stepTransitionTime
        : loadingStepTransitionTime;
    }
  }
}
