
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ProgressCircle from '~/components/ui/progress/ProgressCircle.vue';

@Component({
  components: {
    ProgressCircle,
  },
})
export default class ProgressCircleAnimating extends Vue {
  public progressCount = 0;
  private progressInterval = null;
  private progressSteps = 100;

  @Prop({ default: 2000 })
  public duration: number;

  @Prop({ default: 50 })
  public radius: number;

  @Prop({ default: 5 })
  public stroke: number;

  public get progress() {
    return { progressSteps: [{ step: 0, percentage: this.progressCount }], totalSteps: 1 };
  }

  mounted(): void {
    this.startProgress();
  }

  private startProgress(): void {
    this.progressInterval = setInterval(() => this.incrementProgress(), this.duration / this.progressSteps);
  }

  private incrementProgress(): void {
    if (this.progressCount < this.progressSteps) {
      this.progressCount += 1;
    } else {
      clearInterval(this.progressInterval);
    }
  }
}
