
import { Component, Vue } from 'nuxt-property-decorator';
import ReceiveContentSection from '~/components/dialogs/sections/ReceiveContentSection.vue';
import RecentContentSection from '~/components/dialogs/sections/RecentContentSection.vue';
import ViewSection from '~/components/dialogs/sections/ViewSection.vue';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantContextMenuHeader from '~/components/ui/MutantContextMenuHeader.vue';
import MutantContextMenuItem from '~/components/ui/MutantContextMenuItem.vue';
import { ViewType } from '~/models/views/ViewType';
import { ContextMenuType } from '~/store/context/state';
import MutantActionButton from '~/components/ui/MutantActionButton.vue';
import MutantBadgeButton from '~/components/ui/MutantBadgeButton.vue';
import MutantRadiantButton from '~/components/ui/MutantRadiantButton.vue';
import MutantButton from '~/components/ui/MutantButton.vue';

@Component({
  components: {
    MutantButton,
    MutantRadiantButton,
    MutantBadgeButton,
    MutantActionButton,
    ReceiveContentSection,
    ViewSection,
    MutantContextMenuHeader,
    MutantContextMenuItem,
    MutantContextMenu,
    MutantContextMenuDivider,
    RecentContentSection,
  },
})
export default class FolderIndicatorContextMenu extends Vue {
  public ViewType = ViewType;

  public get contextMenuEvent() {
    return this.$store.getters['context/menu'](ContextMenuType.VIEW)?.event;
  }

  public get startPosition() {
    const offsetY = 30;
    const { pageX, pageY } = this.contextMenuEvent;
    return {
      top: pageY - offsetY,
      left: pageX,
    };
  }

  public get viewId() {
    return this.$store.getters['context/menu'](ContextMenuType.VIEW)?.data;
  }

  public get viewWindow() {
    return this.$store.getters['cloud/window'](this.viewId);
  }

  public get viewType(): ViewType {
    return this.viewWindow.viewOptions.activeViewType;
  }

  public get viewTypeReadable(): string {
    return this.viewType?.toLowerCase();
  }

  public downloadView() {
    this.$store.dispatch('cloud/downloadSnapshotOfView', this.viewId);
    this.close();
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.VIEW);
  }
}
