
import { Component, Vue } from 'nuxt-property-decorator';
import EmptyWindow from '~/components/window/EmptyWindow.vue';

@Component({
  components: { EmptyWindow },
})
export default class IndexPage extends Vue {
  constructor() {
    super();
  }
}
