
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class CheckboxButtonBadge extends Vue {
  @Prop(Boolean)
    green?: boolean;

  @Prop(Boolean)
    red?: boolean;
}
