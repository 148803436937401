
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class MutantTabSection extends Vue {
  @Prop()
    maxHeight: number;

  public get style(): any {
    const obj: any = {};
    if (this.maxHeight) {
      obj.height = this.maxHeight + 'px';
      obj.maxHeight = this.maxHeight + 'px';
    } else {
      obj.flex = '1';
    }
    return obj;
  }
}
