
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ViewOptionsDropdownItem } from '~/models/ViewOptionsDropDownItem';

@Component({ })
export default class ViewOptionsDropDown extends Vue {
  public showDropdown = false;

  @Prop()
  public activeItem: string;

  @Prop({ default: false })
  public deactivated: boolean;

  @Prop()
  public items: ViewOptionsDropdownItem[];

  public toggleDropdown() {
    if (!this.deactivated) {
      this.showDropdown = !this.showDropdown;
    }
  }

  public closeDropDown() {
    this.showDropdown = false;
  }
}
