
import { Component, Vue } from 'nuxt-property-decorator';
import ViewOptions from '~/components/window/view/header/ViewOptions.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import ExitReviewButton from '~/components/layout/header/ExitReviewButton.vue';

@Component({
  components: { ViewOptions, ExitReviewButton },
})
export default class ReviewViewHeader extends Vue {
  viewId = ViewIdentifier.MAIN_VIEW;
}
