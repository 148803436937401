
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class SwitchButtonLandingPage extends Vue {
  @Prop() public activeEntry: string;

  @Prop(Boolean) public light: boolean;

  public isHoverDesktopSwitch = false;
  public isHoverWebSwitch = false;
  public lastHover = null;
  public timeoutHandler = null;

  public get isSwitchActive(): boolean {
    return this.activeEntry != null || this.isHoverDesktopSwitch || this.isHoverWebSwitch;
  }

  public get isHighlighted(): boolean {
    if (!this.isHoverWebSwitch && !this.isHoverDesktopSwitch) {
      return this.activeEntry != null;
    }
    return (this.activeEntry === 'web' && this.isHoverWebSwitch) || (this.activeEntry === 'desktop' && this.isHoverDesktopSwitch);
  }

  public get backgroundPosition(): string {
    if (this.isHoverDesktopSwitch) {
      return '50%';
    }
    if (this.isHoverWebSwitch) {
      return '0';
    }
    if (this.activeEntry == null && this.lastHover === 'web') {
      return '0';
    }
    if (this.activeEntry == null && this.lastHover === 'desktop') {
      return '50%';
    }
    return this.activeEntry === 'web' ? '0' : '50%';
  }

  public handleEnterSwitch() {
    clearTimeout(this.timeoutHandler);
  }

  public handleLeaveSwitch() {
    clearTimeout(this.timeoutHandler);
    this.timeoutHandler = setTimeout(() => {
      this.lastHover = null;
    }, 500);
  }
}
