
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import SliderDrag from '~/models/slider/SliderDrag';

@Component({
  components: {},
})
export default class ThumbnailSlider extends Vue {
  public sliderId = 'slider-' + uuid();

  @Prop()
  public allValues: number[];

  @Prop()
  public currentValue: number;

  public sliderDrag: SliderDrag;

  public width: number = 40;

  constructor() {
    super();
  }

  mounted() {
    // Style is not ready in dev, according to issue it is ready in prod, but for now just add small timeout
    // https://github.com/vuejs/Discussion/issues/394
    setTimeout(() => {
      this.width = document.getElementById(this.sliderId)?.clientWidth;
      this.sliderDrag = new SliderDrag(this.sliderId, this.width, this.currentValue, 0, this.max, this.callback);
    }, 300);
  }

  updateSlider() {
    this.sliderDrag = new SliderDrag(this.sliderId, this.width, this.currentValue, 0, this.max, this.callback);
  }

  callback(value: number) {
    this.$emit('change-value', this.allValues[value]);
  }

  dragStart(event: DragEvent) {
    this.sliderDrag.removeGhost(event);
  }

  mouseDown(event: MouseEvent) {
    this.sliderDrag.dragStart(event);
  }

  click(event) {
    this.sliderDrag.clickSlider(event);
  }

  public get max(): number {
    return this.allValues.length - 1;
  }

  public get sliderPosition() {
    const percentage = (this.allValues.indexOf(this.currentValue) / (this.allValues.length)) * 100;
    const MAX_VALUE = 98;
    const MIN_VALUE = 2;
    let left = percentage + '%';
    if (percentage > MAX_VALUE) {
      left = 'calc(' + percentage + '% - 45px)';
    } else if (percentage < MIN_VALUE) {
      left = 'calc(' + percentage + '% + 5px)';
    }
    return {
      left,
      width: (this.width / this.allValues.length) + 'px',
    };
  }

  @Watch('allValues')
  public watchAllValues(_values: number[]) {
    this.updateSlider();
  }
}
