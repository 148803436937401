
import { Component, Vue } from 'nuxt-property-decorator';
import FormValidationCircle from '~/components/dialogs/ui/FormValidationCircle.vue';
import NavigationElementNew from '~/components/dialogs/ui/NavigationElementNew.vue';
import NavigationElements from '~/components/dialogs/ui/NavigationElements.vue';
import Column from '~/components/ui/Column.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantTagButton from '~/components/ui/MutantTagButton.vue';
import ProgressBar from '~/components/ui/progress/ProgressBar.vue';
import Row from '~/components/ui/Row.vue';

@Component({
  components: {
    FormValidationCircle,
    MutantContextMenuDivider,
    NavigationElementNew,
    NavigationElements,
    MutantTagButton,
    Row,
    Column,
    ProgressBar,
  },
})
export default class ReaderLoginSection extends Vue {
  constructor() {
    super();
  }

  public async download() {
    await this.$store.dispatch('homepage/downloadReader');
  }
}
