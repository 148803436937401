
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ActionButtonModel from '~/models/ActionButtonModel';

@Component
export default class ActionButtonFooter extends Vue {
  @Prop()
  public item: ActionButtonModel;

  @Prop({ default: false })
  public deactivated: boolean;

  public triggerAction(event: Event) {
    if (!this.deactivated) {
      this.item.action(event);
    }
  }
}
