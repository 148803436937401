
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import TextField from '~/components/ui/TextField.vue';

@Component({
  components: { TextField },
})
export default class ViewOptionsBox extends Vue {
  @Prop()
  public displayValue;

  @Prop()
  public allowedValues: RegExp;

  @Prop({ default: false })
  public isDeactivated: boolean;

  public contentId = 'view-options-box-' + uuid();

  public changeValue(value: number) {
    if (!this.isDeactivated) {
      this.$emit('change-value', value);
    }
  }

  public increaseValue(value: number) {
    if (!this.isDeactivated) {
      this.$emit('increase-value', value);
    }
  }

  public decreaseValue(value: number) {
    if (!this.isDeactivated) {
      this.$emit('decrease-value', value);
    }
  }
}
