
import { Component, Vue } from 'nuxt-property-decorator';
import CloudMenuDivider from '~/components/menu/left/CloudMenuDivider.vue';
import CloudMenuObjects from '~/components/menu/left/sections/CloudMenuObjects.vue';
import MutantProfileImage from '~/components/ui/MutantProfileImage.vue';
import MutantRadiantButton from '~/components/ui/MutantRadiantButton.vue';
import Folder from '~/models/Folder';
import { CloudObjectFilter, FilterType, SortType } from '~/models/cloud/CloudObjectFilter';
import CloudMenuFilter from '~/components/menu/left/CloudMenuFilter.vue';
import CloudMenuInfo from '~/components/menu/left/CloudMenuInfo.vue';
import CloudMenuNewObject from '~/components/menu/left/CloudMenuNewObject.vue';
import { CloudObject } from '~/models/cloud/CloudObject';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import Selection from '~/models/selection/Selection';
import { CloudMenuRoute } from '~/models/CloudMenuRoute';

declare let jdenticon: any;

@Component({
  components: {
    CloudMenuFilter,
    MutantProfileImage,
    MutantRadiantButton,
    CloudMenuObjects,
    CloudMenuDivider,
    CloudMenuInfo,
    CloudMenuNewObject,
  },
})
export default class CloudMenu extends Vue {
  private defaultFilter: CloudObjectFilter = { filterType: [FilterType.FOLDER], sortType: SortType.MODIFIED_ASC };
  activeFilter: CloudObjectFilter = this.defaultFilter;

  public get isCloudMenu(): boolean {
    return this.$store.state.menuRoute === CloudMenuRoute.CLOUD;
  }

  public get isSharedLink(): boolean {
    return this.$store.state.menuRoute === CloudMenuRoute.SHARED_LINK;
  }

  public get username(): string {
    return this.$roleAwareness.currentUser.username;
  }

  public get currentCloudObject(): CloudObject<Folder | Selection> {
    return this.cloudObjects.find(o => o.objectId?.toUuid() === this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW)?.objectIds[0]?.toUuid());
  }

  public get cloudObjects(): CloudObject<Folder | Selection>[] {
    return this.$store.getters['cloud/cloudObjects'](this.activeFilter);
  }

  created() {
    if (!this.$roleAwareness.isGuestUser) {
      if (Object.keys(this.$store.state.folder.folders).length === 0) {
        this.$store.dispatch('folder/loadFolders');
      }
      if (Object.keys(this.$store.state.selection.selections).length === 0) {
        this.$store.dispatch('selection/loadSelections');
      }
      this.$store.dispatch('cloud/loadCustomCloudObjects');
    }
    this.activeFilter = this.defaultFilter;
  }

  public setActiveFilter(activeFilter: CloudObjectFilter) {
    this.activeFilter = activeFilter;
  }
}
