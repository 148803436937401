
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';

@Component
export default class ActionButtonBase extends Vue {
  @Prop({ default: uuid() })
  public id: string;

  @Prop({ default: false })
  public deactivated: boolean;

  @Prop({ default: false })
  public isHighlighted: boolean;

  public emitClick(event) {
    if (this.deactivated) {
      event.preventDefault();
      event.stopImmediatePropagation();
    } else {
      this.$emit('click', event);
    }
  }
}
