
import { Component, Vue } from 'nuxt-property-decorator';
import MutantProfileImage from '~/components/ui/MutantProfileImage.vue';
import { Contact } from '~/models/user/Contact';

@Component({
  components: { MutantProfileImage },
})
export default class ContactsMenuMobile extends Vue {
  public isOpen = false;
  public profilePictures: { userId: string; url: string }[] = [];
  public sentToUser = null;
  public showNewContactInput = false;
  public newContactInput = '';

  async created() {
    if (!this.$store.state.user.contacts.length) {
      await this.$store.dispatch('user/loadContacts');
      await this.preLoadProfilePicturesForContacts();
    }
  }

  public sendToUser(userId: string) {
    this.sentToUser = userId;
    const items = this.$store.getters['selection/globalSelectionItems'];
    this.$store.dispatch('file/sendItems', { items, destination: userId });
    setTimeout(() => {
      this.sentToUser = null;
    }, 2000);
  }

  public get selectedItemsCount(): number {
    return this.$store.getters['selection/globalSelectionItems'].length;
  }

  public async inputNewContact() {
    this.showNewContactInput = true;
    await this.$nextTick();
    document.getElementById('new-contact-input').focus();
  }

  public createNewContact() {
    if (this.newContactInput.length > 0 && !this.contacts.some(c => c.username === this.newContactInput)) {
      this.$store.dispatch('user/addContact', this.newContactInput);
    }
    this.newContactInput = '';
    this.showNewContactInput = false;
  }

  public toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  public hasProfilePicture(userId: string) {
    return this.profilePictures.some(p => p.userId === userId);
  }

  public profilePicture(userId: string) {
    return this.profilePictures.find(p => p.userId === userId)?.url;
  }

  public async preLoadProfilePicturesForContacts(): Promise<void> {
    for (const contact of this.contacts) {
      try {
        const { data, headers } = await this.$api.get(`users/${contact.userId}/profile/picture`, { responseType: 'arraybuffer' });
        const mimeType = headers['content-type'].toLowerCase();
        const base64ImageData = Buffer.from(data, 'binary').toString('base64');
        const url = 'data:' + mimeType + ';base64,' + base64ImageData;
        this.profilePictures.push({ userId: contact.userId, url });
      } catch (_err) {}
    }
  }

  public get contacts(): Contact[] {
    const userProfile = this.$store.state.profile?.data;
    const contacts = this.$store.state.user.contacts;
    return userProfile ? [{ userId: userProfile.id, username: userProfile.username }].concat(contacts) : contacts;
  }
}
