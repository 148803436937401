
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ContextMenuType } from '~/store/context/state';

@Component
export default class MutantPane extends Vue {
  @Prop()
  private mutantView: MutantContentView;

  constructor() {
    super();
    window.addEventListener('keydown', this.handleKeyUp, false);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyUp);
  }

  public toggleContextMenu(event) {
    if (!this.$store.getters.isMobile) {
      event.preventDefault();
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.VIEW, event, data: this.mutantView.id });
    }
  }

  handleKeyUp(e: KeyboardEvent) {
    const rating = parseInt(e.key, 10);
    const RATING_RANGE = [0, 1, 2, 3, 4, 5];
    if ((e.target as HTMLElement)?.tagName === 'BODY' && ['Delete', 'Backspace'].includes(e.key)) {
      this.$store.dispatch('deleteItemsFromView', { viewId: this.mutantView.id });
      e.stopImmediatePropagation();
    } else if (e.metaKey && e.key === 'a') {
      this.$store.dispatch('selection/selectAllItems');
      e.stopImmediatePropagation();
      e.preventDefault();
    } else if (RATING_RANGE.includes(rating)) {
      this.$store.dispatch('rateItems', { viewId: this.mutantView.id, rating });
      e.stopImmediatePropagation();
    }
  }
}
