
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import throttle from 'lodash.throttle';
import { isTouchEvent } from '~/models/views/SnippetMover';

@Component({})
export default class ViewOptionsSlider extends Vue {
  private innerPadding = 5;

  public sliderId = 'view-options-slider-' + uuid();

  @Prop()
  public deactivated: boolean;

  @Prop()
  public min: number;

  @Prop()
  public currentValue: number;

  @Prop()
  public max: number;

  @Prop()
  public barWidth;

  @Prop()
  public valueType: string;

  private dragStartClientX;
  private isDragging;

  public internalCurrentValue = 0;
  private throttleEmitValue = throttle(this.emitValue, 500, { leading: false, trailing: true });

  mounted() {
    this.internalCurrentValue = this.currentValue;
  }

  public clickSlider(event: PointerEvent) {
    const boundingRect = document.getElementById(this.sliderId).getBoundingClientRect();
    const xValue = event.clientX;
    const difference = xValue - boundingRect.x - this.touchElementOffset;
    this.internalCurrentValue = this.findNearestValue(difference);
    this.emitValue(this.internalCurrentValue);
  }

  public findNearestValue(difference: number) {
    if (difference <= (this.touchElementWidth / 2)) {
      return this.min;
    } else if (difference >= this.barWidth - this.touchElementWidth) {
      return this.max;
    }
    return Math.ceil(difference / (this.barWidth / (this.max - this.min + 1)));
  }

  public emitValue(value: number) {
    this.$emit('change-value', value);
  }

  removeGhost(event: DragEvent) {
    const dragImage = document.createElement('div');
    dragImage.style.visibility = 'hidden';
    event.dataTransfer.setDragImage(dragImage, 0, 0);
    event.dataTransfer.effectAllowed = 'move';
  }

  dragStart(event: MouseEvent) {
    const boundingRect = document.getElementById(this.sliderId).getBoundingClientRect();
    event.preventDefault();
    event.stopImmediatePropagation();
    this.dragStartClientX = event.clientX;
    this.isDragging = true;

    let clientX;
    const dragMenu = (moveEvent: DragEvent | TouchEvent) => {
      if (isTouchEvent(moveEvent)) {
        if (moveEvent.changedTouches) {
          const left = moveEvent.changedTouches[0].clientX;
          if (left > 0) {
            clientX = left;
          }
        }
      } else {
        moveEvent.preventDefault();
        moveEvent.stopImmediatePropagation();
        if (moveEvent.clientX > 0) {
          clientX = moveEvent.clientX;
        }
      }
    };
    let animationFrame;
    const adjustSliderPosition = () => {
      if (clientX && clientX > 0 && clientX <= window.innerWidth) {
        const difference = clientX - boundingRect.x - this.touchElementOffset;
        this.internalCurrentValue = this.findNearestValue(difference);
        this.throttleEmitValue(this.internalCurrentValue);
      }
      animationFrame = requestAnimationFrame(adjustSliderPosition);
    };
    animationFrame = requestAnimationFrame(adjustSliderPosition);
    const endDragSlider = (moveEvent) => {
      moveEvent.preventDefault();
      moveEvent.stopImmediatePropagation();
      cancelAnimationFrame(animationFrame);
      document.removeEventListener('touchmove', dragMenu);
      document.removeEventListener('mousemove', dragMenu);
      document.removeEventListener('touchend', endDragSlider);
      document.removeEventListener('mouseup', endDragSlider);
      this.isDragging = false;
    };
    document.addEventListener('touchmove', dragMenu);
    document.addEventListener('mousemove', dragMenu, false);
    document.addEventListener('touchend', endDragSlider, false);
    document.addEventListener('mouseup', endDragSlider);
  }

  public get touchElementWidth() {
    return this.barWidth / (this.max - this.min + 1);
  }

  public get touchElementOffset() {
    return this.touchElementWidth * 2 / 3;
  }

  public get displayCurrentValue(): string | number {
    return this.valueType === 'percentage' ? `${this.internalCurrentValue * 10}%` : this.internalCurrentValue;
  }

  public get barStyle() {
    if (!this.deactivated) {
      let left;
      if (this.internalCurrentValue === this.min) {
        left = this.innerPadding;
      } else if (this.internalCurrentValue === this.max) {
        left = this.barWidth - this.touchElementWidth - this.innerPadding;
      } else {
        left = (this.internalCurrentValue - this.min) * this.touchElementWidth;
      }
      return {
        left: left + 'px',
        width: this.touchElementWidth + 'px',
      };
    } else {
      return {};
    }
  }

  @Watch('currentValue')
  public watchCurrentValue(value: number) {
    if (value !== this.internalCurrentValue) {
      this.internalCurrentValue = value;
    }
  }
}
