
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import CloudMenuHeader from '~/components/menu/left/CloudMenuHeader.vue';
import Selection from '~/models/selection/Selection';
import CloudMenuRow from '~/components/menu/left/CloudMenuRow.vue';
import CloudMenuScrollWrapper from '~/components/menu/left/CloudMenuScrollWrapper.vue';
import MutantDraggable from '~/components/ui/MutantDraggable.vue';
import Folder from '~/models/Folder';
import { ContextMenuType } from '~/store/context/state';
import { AvailableCloudObject, CloudObject } from '~/models/cloud/CloudObject';
import { CloudObjectFilter } from '~/models/cloud/CloudObjectFilter';
import { ViewType } from '~/models/views/ViewType';
import { ViewTypeIconClasses } from '~/models/views/ViewTypeIconClasses';
import IconDropDown from '~/components/ui/IconDropDown.vue';
import { DeleteFolderData } from '~/store/folder/actions';

@Component({
  components: {
    CloudMenuHeader,
    CloudMenuScrollWrapper,
    CloudMenuRow,
    MutantDraggable,
    IconDropDown,
  },
})
export default class CloudMenuObjects extends Vue {
    @Prop()
      title: string;

    @Prop()
      activeFilter: CloudObjectFilter;

    @Prop()
      cloudObjects: CloudObject<Folder|Selection>[];

    public collapsedDefaultViewDropdown = null;
    public droppedOnObjectId: string = null;
    public hasMultiselect = false;

    private multiselectArrayIds: { [key in string]: AvailableCloudObject } = Object.create(null);
    private uploadHandlerInProgress = false;
    private _internalCloudObjects: CloudObject<Folder | Selection>[] = [];
    private _internalFilter: CloudObjectFilter;

    public scrapbook(item: CloudObject<Folder | Selection>): boolean {
      return item.object.name === 'scrapbook';
    }

    public get deleteTooltip() {
      const selectedObjectsLength = Object.keys(this.multiselectArrayIds)?.length;
      return {
        placement: 'top',
        content: `Delete ${selectedObjectsLength ?? 0} object${selectedObjectsLength > 1 ? 's' : ''}`,
      };
    }

    public get hasSelected(): boolean {
      return Object.keys(this.multiselectArrayIds).length > 0;
    }

    public get allSelected(): boolean {
      return Object.keys(this.multiselectArrayIds).length === this.cloudObjects.length - 1;
    }

    public toggleMultiselect() {
      this.hasMultiselect = !this.hasMultiselect;
      if (!this.hasMultiselect) {
        this.deselectAll();
      }
    }

    public selectAll() {
      this.multiselectArrayIds = this.cloudObjects.filter(c => !this.scrapbook(c)).reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
    }

    public deselectAll() {
      this.multiselectArrayIds = {};
    }

    public get noItemsToDelete() {
      return Object.keys(this.multiselectArrayIds).length === 0;
    }

    public deleteSelected() {
      for (const [id, cloudObject] of Object.entries(this.multiselectArrayIds)) {
        if (cloudObject.isFolder) {
          this.$store.dispatch('folder/removeFolder', { folderId: id } as DeleteFolderData);
        } else {
          this.$store.dispatch('selection/removeSelection', cloudObject?.object);
        }
      }
      this.deselectAll();
    }

    public defaultViewTypeIcons(item: CloudObject<Folder | Selection>) {
      const defaultViewType = item.object.viewType;
      const hasSelectedItems = this.$store.getters['cloud/hasObjectSelectedItems'](item.objectId);
      return (item.isFolder ? this.viewTypeInfoFolder : this.viewTypeInfoSelection).map(viewTypeInfo => {
        return {
          ...viewTypeInfo,
          isActive: defaultViewType === viewTypeInfo.value,
          hasSelectedItems,
        };
      });
    }

    public setMultiselect(cloudObject: AvailableCloudObject) {
      if (this.multiselectArrayIds[cloudObject.id]) {
        delete this.multiselectArrayIds[cloudObject.id];
      } else {
        this.multiselectArrayIds[cloudObject.id] = cloudObject;
      }
      this.multiselectArrayIds = { ...this.multiselectArrayIds };
    };

    public get isMobile() {
      return this.$store.getters.isMobile;
    }

    private get viewTypeInfoSelection() {
      return Object.values(ViewType).map(viewType => ({ text: viewType, value: viewType, iconClass: ViewTypeIconClasses[viewType] }));
    }

    private get viewTypeInfoFolder() {
      return Object.values(ViewType).filter(viewType => viewType.toString() !== ViewType[ViewType.MOODBOARD]).map(viewType => ({ text: viewType, value: viewType, iconClass: ViewTypeIconClasses[viewType] }));
    }

    public collapseViewDropDown(event: any, item: CloudObject<Folder | Selection>): void {
      event.preventDefault();
      this.collapseViewDropDownFor(item);
    }

    private collapseViewDropDownFor(item: CloudObject<Folder | Selection>) {
      this.collapsedDefaultViewDropdown = item.object.id === this.collapsedDefaultViewDropdown ? null : item.object.id;
    }

    selectViewType(event: { value: ViewType}, item: CloudObject<Folder | Selection>) {
      this.collapseViewDropDownFor(item);
      const newViewType = event.value;
      if (item.object.viewType !== newViewType) {
        if (item.isSelection) {
          this.$store.dispatch('selection/updateSelectionViewType', { selectionId: item.objectId.toUuid(), viewType: newViewType });
        } else {
          this.$store.dispatch('folder/updateViewType', { folderId: item.objectId.toUuid(), viewType: newViewType });
        }
      }
    }

    public openContextMenu(event: MouseEvent, cloudObject: CloudObject<Folder|Selection>) {
      if (!this.isMobile) {
        event.preventDefault();
        this.openItemContextMenu(event, cloudObject);
      }
    }

    private openItemContextMenu(event: Event, cloudObject: CloudObject<Folder | Selection>) {
      if (cloudObject.isFolder) {
        this.$store.dispatch('context/openMenu', { type: ContextMenuType.FOLDER, data: cloudObject, event });
      } else {
        this.$store.dispatch('context/openMenu', { type: ContextMenuType.SELECTION, data: cloudObject, event });
      }
    }

    public chooseCloudObject(cloudObject: CloudObject<Folder|Selection>) {
      this.$store.dispatch('cloud/addToPane', { objectIds: [cloudObject.objectId], setDefaultView: true });
      if (this.$store.getters.isMobileWidth) {
        this.$store.dispatch('toggleMenu');
      }
    }

    public handleDrop(cloudObject: CloudObject<Folder|Selection>, event: DragEvent) {
      if (!this.isMobile) {
        event.stopImmediatePropagation();
        event.preventDefault();
        if ((event.dataTransfer || event.target) && !this.uploadHandlerInProgress) {
          this.uploadHandlerInProgress = true;
          this.droppedOnObjectId = cloudObject.object.id;
          this.$store.dispatch('folder/handleDropOnFolderEvent', { folderId: cloudObject.object.id, event });
          setTimeout(() => {
            this.uploadHandlerInProgress = false;
            this.droppedOnObjectId = null;
          }, 1000);
        }
      }
    }

    public get internalCloudObjects() {
      if (this.activeFilter !== this._internalFilter) {
        this._internalFilter = this.activeFilter;
        this._internalCloudObjects = this.cloudObjects;
      } else if (this._internalCloudObjects?.length > 0 && this.cloudObjects && this.hasSameIds(this._internalCloudObjects, this.cloudObjects)) {
        this._internalCloudObjects = this._internalCloudObjects?.map(i => {
          const newElement: CloudObject<Folder | Selection> = this.cloudObjects.find(n => i.objectId.toUuid() === n.objectId.toUuid());
          const newObject = {
            ...i.object,
            ...newElement.object,
          } as Folder | Selection;
          return newElement
            ? new CloudObject(
              i.objectId,
              newObject
            )
            : i;
        });
      } else {
        this._internalCloudObjects = this.cloudObjects;
      }
      return this._internalCloudObjects;
    }

    private hasSameIds(compareA: CloudObject<Folder | Selection>[], compareB: CloudObject<Folder | Selection>[]) {
      let sameList = true;
      if (compareA.length === compareB.length) {
        compareA.forEach(a => {
          if (compareB.find(b => b.object.id === a.object.id) == null) {
            sameList = false;
          }
        });
      } else {
        sameList = false;
      }
      return sameList;
    }

    public set internalCloudObjects(cloudObjects: CloudObject<Folder | Selection>[]) {
      this._internalCloudObjects = cloudObjects;
    }
}
