
import throttle from 'lodash.throttle';
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import debounce from 'lodash.debounce';
import MutantPane from '~/components/window/view/MutantPane.vue';
import MutantView from '~/components/window/view/MutantView.vue';
import SnippetView from '~/components/window/view/SnippetView.vue';
import { Asset, getLargestThumbnailAsset } from '~/models/Asset';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { MoodboardOptions } from '~/models/views/moodboard/MoodboardOptions';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ViewHandler } from '~/models/views/MutantView';
import { ViewType } from '~/models/views/ViewType';
import { HighlightInfo } from '~/store/cloud/state';
import { BackgroundFit } from '~/models/views/BackgroundFit';
import Snippet from '~/models/Snippet';
import { LayoutType } from '~/models/LayoutType';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ViewRange } from '~/models/views/ViewRange';

@Component({
  components: {
    SnippetView,
    MutantView,
    MutantPane,
  },
})
export default class MainView extends Vue {
  ViewType = ViewType;

  @Prop({ default: true })
  public hasFocusFrames: boolean;

  @Prop({ default: false })
  public disableSelectionBorders: boolean;

  @Prop()
  public mutantView?: MutantContentView;

  @Prop()
  public width: number;

  @Prop()
  public height: number;

  @Prop()
  private drag: boolean;

  public isScrolling = false;
  public throttledScroll = null;
  private currentViewRange: ViewRange = {
    start: 0,
    end: 0,
    exactStart: 0,
    exactEnd: 0,
    width: 0,
    height: 0,
  };

  private isViewInitialized = false;
  private visibleSnippets: Snippet[];
  private previouslyHighlighted: Snippet = null;
  private throttledAdjustViewRangeToViewSize = null;
  private throttledAdjustViewRangeAfterLongVisit = null;
  private viewScrollingTimeoutHandler = null;
  private backgroundAssetLoaded = null;
  private backgroundBase64 = null;
  private throttledScrollToPosition = null;
  private lastScrollPosition = 0;

  public get hasViewTransition() {
    return !this.$store.state.adjustWindowsToDrag;
  }

  constructor() {
    super();
    this.throttledScroll = throttle(this.onScroll, 200, { leading: true, trailing: true });
    this.throttledAdjustViewRangeToViewSize = debounce(this.adjustViewRangeToViewSize, 500, {
      leading: true,
      trailing: false,
    });
    this.throttledAdjustViewRangeAfterLongVisit = debounce(this.adjustViewRangeToViewSize, 3000, {
      leading: false,
      trailing: true,
    });
    this.throttledScrollToPosition = throttle(this.scrollToPosition, 400, { leading: true, trailing: true });
  }

  // This is necessary, because otherwise the view range will not be initialized
  mounted() {
    if (this.isReferencedToSharedLink) {
      this.isViewInitialized = true;
      this.throttledAdjustViewRangeToViewSize.cancel();
      this.throttledAdjustViewRangeToViewSize();
    } else {
      setTimeout(() => {
        this.isViewInitialized = true;
      }, 3000);
      this.throttleViewRange();
    }
  }

  cancelThrottle() {
    this.throttledAdjustViewRangeToViewSize.cancel();
    this.throttledAdjustViewRangeAfterLongVisit.cancel();
    this.throttledScroll.cancel();
    this.throttledScrollToPosition.cancel();
  }

  public get scrollViewId(): string {
    return `${this.mutantView.id}-scroll-view`;
  }

  public get items(): ItemWithPosition[] {
    return this.$store.getters['cloud/viewItemsMap'][this.mutantView.id].items;
  }

  public get isHoverDisabled(): boolean {
    return !!this.$store.state.dragInfo;
  }

  public get isInNavigationMode(): boolean {
    return this.mutantView.isInNavigationMode;
  }

  public get highlightItemId(): string {
    const highlightInfo: HighlightInfo = this.$store.state.cloud.highlightInfo;
    return highlightInfo
    && (this.mutantView.id !== highlightInfo.originView || this.mutantView.id === highlightInfo.targetView)
      ? highlightInfo.item?.id
      : null;
  }

  public get isBarView(): boolean {
    return this.viewType === ViewType.HORIZONTAL;
  }

  public get isMoodboard(): boolean {
    return this.viewType === ViewType.MOODBOARD;
  }

  public get viewMarginBottom(): number {
    if (!this.isBarView && !this.isMoodboard) {
      return 120;
    }
    return 0;
  }

  public get moodboardOptions() {
    const backgroundAsset = this.moodboardBackgroundAsset;
    const options: Partial<MoodboardOptions> = {
      width: this.width,
      height: this.height,
      backgroundFit: this.$store.getters['cloud/window'](this.mutantView.id).backgroundFit,
    };
    if (backgroundAsset) {
      options.backgroundWidth = backgroundAsset.width;
      options.backgroundHeight = backgroundAsset.height;
    }
    return options;
  }

  public get isReferencedToSharedLink(): boolean {
    return !!this.$router.currentRoute.query['shared-link-id'];
  }

  public get viewType() {
    return this.mutantView.viewOptions.activeViewType;
  }

  public get viewOptions() {
    return this.mutantView.viewOptions;
  }

  public setVisibleSnippets(snippets: Snippet[]) {
    this.visibleSnippets = snippets;
  }

  private scrollToPosition(element: HTMLElement, position: { left: number, top: number }, scrollBehavior: ScrollBehavior) {
    element.scrollTo({
      top: position.top,
      left: position.left,
      behavior: scrollBehavior,
    });
  }

  onItemHighlightPosition(highlightItemPosition: { left: number, top: number, width: number, height: number, scrollBehavior: ScrollBehavior }) {
    if (this.$store.state.cloud.highlightInfo.targetView === this.mutantView.id || this.$store.getters.isReviewMode) {
      const scrollElement = document.getElementById(this.scrollViewId);
      const width = highlightItemPosition.width;
      const height = highlightItemPosition.height;
      let { top, left } = highlightItemPosition;
      if (this.viewType === ViewType.HORIZONTAL) {
        left -= scrollElement.clientWidth / 2 - width / 2;
      } else {
        top -= scrollElement.clientHeight / 2 - height / 2;
      }
      if (this.$store.state.cloud.illuminationGrade === 0 && this.$store.getters.isReviewMode && this.mutantView.id === ViewIdentifier.MAIN_VIEW) {
        this.scrollToPosition(scrollElement, { left, top }, 'auto');
      } else {
        this.throttledScrollToPosition(scrollElement, { left, top }, highlightItemPosition.scrollBehavior ?? 'smooth');
      }
    }
  }

  public onScrollStart(event: Event) {
    clearTimeout(this.viewScrollingTimeoutHandler);
    if (this.viewType === ViewType.MOODBOARD) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }
    this.isScrolling = true;
  }

  private getSnippetHorizontalView(rightScroll: boolean): Snippet {
    const scrollViewElement = document.getElementById(this.scrollViewId);
    const exactStart = scrollViewElement.scrollLeft;
    const exactEnd = scrollViewElement.scrollLeft + scrollViewElement.clientWidth;
    const viewWidth = exactEnd - exactStart;
    const middle = exactEnd - (viewWidth / 2);
    const viewMarginOffset = this.mutantView.viewSpacing;
    const rightOffsetImage = (s: { snippet, index }) => s.snippet.item.viewPosition.left - viewMarginOffset < middle && s.snippet.item.viewPosition.left + s.snippet.item.viewPosition.width > middle;
    const leftOffsetImage = (s: { snippet, index }) => s.snippet.item.viewPosition.left < middle && s.snippet.item.viewPosition.left + s.snippet.item.viewPosition.width + viewMarginOffset > middle;
    const snippetWithIndex = this.visibleSnippets.map((s, i) => ({ snippet: s, index: i })).find((s) => rightScroll ? rightOffsetImage(s) : leftOffsetImage(s));
    let snippetMiddle = snippetWithIndex?.snippet;
    const snippetMiddleIndex = snippetWithIndex?.index;
    if (exactStart < 50) {
      snippetMiddle = this.visibleSnippets[0];
    } else if (snippetMiddle == null) {
      const lastSnippet = this.visibleSnippets[this.visibleSnippets.length - 1];
      if (lastSnippet.item.viewPosition.left + lastSnippet.item.viewPosition.width < middle) {
        snippetMiddle = lastSnippet;
      }
    } else if (snippetMiddle === this.previouslyHighlighted) {
      snippetMiddle = this.visibleSnippets[rightScroll ? snippetMiddleIndex + 1 : snippetMiddleIndex - 1];
    }
    return snippetMiddle;
  }

  private getSnippetVerticalView(bottomScroll: boolean): Snippet {
    const scrollViewElement = document.getElementById(this.scrollViewId);
    const exactStart = scrollViewElement.scrollTop;
    const exactEnd = scrollViewElement.scrollTop + scrollViewElement.clientHeight;
    const width = scrollViewElement.clientWidth;
    const viewWidth = exactEnd - exactStart;
    const middle = exactEnd - (viewWidth / 2);
    const horizontalMiddle = width / 2;
    const viewMarginOffset = this.mutantView.viewSpacing;
    const snippetHorizontalMiddle = (s: { snippet, index }) =>
      s.snippet.item.viewPosition.left < horizontalMiddle
      && s.snippet.item.viewPosition.left + s.snippet.item.viewPosition.width + viewMarginOffset > horizontalMiddle;
    const bottomScrollMiddleItem = (s: { snippet, index }) =>
      snippetHorizontalMiddle(s)
      && s.snippet.item.viewPosition.top - viewMarginOffset < middle
      && s.snippet.item.viewPosition.top + s.snippet.item.viewPosition.height > middle;
    const topScrollMiddleItem = (s: { snippet, index }) =>
      snippetHorizontalMiddle(s)
      && s.snippet.item.viewPosition.top < middle
      && s.snippet.item.viewPosition.top + s.snippet.item.viewPosition.height + viewMarginOffset > middle;
    const snippetWithIndex = this.visibleSnippets.map((s, i) => ({ snippet: s, index: i })).find((s) => bottomScroll ? bottomScrollMiddleItem(s) : topScrollMiddleItem(s));
    let snippetMiddle = snippetWithIndex?.snippet;
    if (exactStart < 50) {
      snippetMiddle = this.visibleSnippets[0];
    } else if (snippetMiddle == null) {
      const lastSnippet = this.visibleSnippets[this.visibleSnippets.length - 1];
      if (lastSnippet.item.viewPosition.left + lastSnippet.item.viewPosition.width < middle) {
        snippetMiddle = lastSnippet;
      }
    }
    return snippetMiddle;
  }

  public get horizontalScrollView() {
    return this.viewType === ViewType.HORIZONTAL;
  }

  public get isReviewMode() {
    return this.$store.state.currentLayoutType === LayoutType.REVIEW_MODE
      && this.mutantView.id === ViewIdentifier.MAIN_VIEW
      && this.horizontalScrollView;
  }

  private onScrollStopped(event: CustomEvent) {
    this.viewScrollingTimeoutHandler = setTimeout(() => {
      this.isScrolling = false;
      if (this.visibleSnippets?.length > 0) {
        const snippetMiddle = this.horizontalScrollView ? this.getSnippetHorizontalView(event.detail === 'right') : this.getSnippetVerticalView(event.detail === 'bottom');
        if (this.isReviewMode) {
          this.$store.dispatch('cloud/highlightItem', {
            originView: this.mutantView.id,
            item: snippetMiddle?.item?.item,
            targetView: this.mutantView.id,
            scroll: this.$store.state.currentLayoutType === LayoutType.REVIEW_MODE,
          });
          this.previouslyHighlighted = snippetMiddle;
        }
        if (snippetMiddle?.item?.item != null) {
          this.$store.commit('cloud/setCenteredItemInView', {
            position: this.items.indexOf(snippetMiddle.item.item) ?? 0,
            view: this.mutantView.id,
          });
        }
      }
    }, 50);
  }

  public onScroll(_event: Event) {
    this.adjustViewRangeToViewSize(true);
  }

  public get viewStyle() {
    const obj: any = {};
    if (this.hasViewBackground && this.backgroundBase64) {
      obj.backgroundImage = this.viewBackgroundGradient;
      obj.backgroundPosition = this.$store.getters['cloud/window'](this.mutantView.id).backgroundFit === BackgroundFit.CONTAIN ? 'center center' : 'top left';
      obj.backgroundSize = `${this.$store.getters['cloud/window'](this.mutantView.id).backgroundFit}`;
    }
    return obj;
  }

  public get hasViewBackground(): boolean {
    return this.viewType === ViewType.MOODBOARD && this.mutantView.backgrounds.length > 0;
  }

  public get moodboardBackgroundAsset(): Asset {
    return this.mutantView.backgrounds?.length ? getLargestThumbnailAsset(this.mutantView.backgrounds) : null;
  }

  public async loadMoodboardBackground(): Promise<void> {
    const backgroundAsset = this.moodboardBackgroundAsset;
    if (this.backgroundAssetLoaded !== backgroundAsset.id) {
      this.backgroundBase64 = await this.$imageLoader.loadAssetUrl(null, backgroundAsset);
      this.backgroundAssetLoaded = backgroundAsset.id;
    }
  }

  private get viewBackgroundGradient() {
    return this.hasViewBackground && this.backgroundBase64
      ? `url(${this.backgroundBase64}), url(/active_content_background_1.webp)`
      : 'url(/active_content_background_1.webp)';
  }

  private adjustViewRangeToViewSize(scroll = false) {
    const scrollElement = document.getElementById(this.scrollViewId);
    if (scrollElement) {
      const params = { viewType: this.viewType, scrollViewElement: scrollElement, currentViewRange: this.currentViewRange };
      if (!scroll || ViewHandler.scrollRequiresRecalculationOfView(params)) {
        this.currentViewRange = ViewHandler.calculateViewRange({
          downsizeViewRange: !this.isViewInitialized,
          previousViewRange: this.currentViewRange,
          viewType: this.viewType,
          scrollViewElement: scrollElement as HTMLElement,
        });
      }
      if (scroll) {
        const scrollTop = scrollElement.scrollTop;
        const scrollPositionBelowThreshold = scrollTop === 0 || scrollTop <= this.lastScrollPosition - window.innerHeight / 2;
        if (scrollPositionBelowThreshold) {
          if (!this.$store.state.showFolderTitle) {
            this.$store.commit('setShowFolderTitle', true);
          }
          this.lastScrollPosition = scrollElement.scrollTop;
        } else if (scrollTop > this.lastScrollPosition) {
          this.$store.commit('setShowFolderTitle', false);
          this.lastScrollPosition = scrollElement.scrollTop;
        }
      }
    }
  }

  private throttleViewRange() {
    this.throttledAdjustViewRangeToViewSize();
    this.throttledAdjustViewRangeAfterLongVisit();
  }

  private scrollUp() {
    const scrollView = document.getElementById(this.scrollViewId);
    if (scrollView) {
      scrollView.scrollTo({
        left: 0,
        top: 0,
      });
    }
  }

  public get hasToAdjustViewRangeToViewSize() {
    return this.width.toString() + this.height;
  }

  @Watch('items')
  onItemsChange(newItems: ItemWithPosition[], oldItems: ItemWithPosition[]) {
    if (!newItems.map(i => i.item.id).includes(this.highlightItemId)) {
      this.onScrollStopped(new CustomEvent('scrollfinished', { detail: 'right' }));
    }
    if (this.mutantView.isGlobalSelectionView && Math.abs(newItems.length - oldItems.length) === 1) {
      const selectionElement = document.getElementById(this.scrollViewId);
      selectionElement?.scrollTo({
        left: 0,
        top: selectionElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  @Watch('hasToAdjustViewRangeToViewSize', { immediate: true })
  watchSizeChange() {
    if (this.visibleSnippets?.length > 0) {
      this.throttleViewRange();
    }
  }

  @Watch('viewType')
  @Watch('mutantView', { immediate: true })
  watchForViewChange() {
    if (this.hasViewBackground) {
      this.loadMoodboardBackground();
      this.scrollUp();
    }
    if (this.mutantView.isFolderView && this.viewType === ViewType.MOODBOARD) {
      this.$store.dispatch('cloud/changeView', { windowId: this.mutantView.id, view: ViewType.MOSAIC });
    }
  }

  public get folderOrSelectionChanged(): string {
    return this.mutantView?.folderId ?? this.mutantView?.selectionId;
  }

  @Watch('viewType')
  onWatchViewChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && this.visibleSnippets?.length > 0) {
      this.throttleViewRange();
    }
  }

  @Watch('folderOrSelectionChanged')
  onWatchFolderOrSelectionChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && this.visibleSnippets?.length > 0) {
      this.cancelThrottle();
      this.$imageLoader.cancelPendingRequests(oldVal);
      // extend view range if user is longer on the folder/selection
      this.throttleViewRange();
      setTimeout(() => this.scrollUp());
    }
  }
}
