
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ViewType } from '~/models/views/ViewType';
import { NavigationInstructionDirection } from '~/models/views/NavigationInstruction';
import { OverviewType } from '~/store/cloud/getters';

@Component
export default class ViewNavigation extends Vue {
  @Prop()
  public viewId: ViewIdentifier;

  @Prop(Boolean)
  public displayMaxCount: boolean;

  public get itemCount(): number {
    const centeredItemInView = this.$store.state.cloud.centeredItemInView[this.viewId];
    return centeredItemInView != null ? centeredItemInView + 1 : 0;
  }

  public get itemMaxCount(): number {
    return this.$store.getters['cloud/itemCountOverview'](OverviewType.FILTERED).count;
  }

  public get isGridView() {
    return this.viewOptions.activeViewType === ViewType.GRID;
  }

  public navigateRight() {
    if (this.isGridView) {
      const scrollView = document.getElementById('main-view-scroll-view');
      const scrollDimension = scrollView.scrollTop + this.viewOptions.grid.rowHeight;
      scrollView.scrollTo({ top: scrollDimension < scrollView.scrollHeight ? scrollDimension : scrollView.scrollHeight, behavior: 'smooth' });
    } else {
      this.$store.dispatch('cloud/navigateView', { direction: NavigationInstructionDirection.RIGHT, view: this.viewId });
    }
  }

  public navigateLeft() {
    if (this.isGridView) {
      const scrollView = document.getElementById('main-view-scroll-view');
      const scrollDimension = scrollView.scrollTop - this.viewOptions.grid.rowHeight;
      scrollView.scrollTo({ top: scrollDimension > 0 ? scrollDimension : 0, behavior: 'smooth' });
    } else {
      this.$store.dispatch('cloud/navigateView', { direction: NavigationInstructionDirection.LEFT, view: this.viewId });
    }
  }

  public get isNotEmpty(): boolean {
    return this.$store.getters['cloud/viewItemsMap'][this.viewId]?.items?.length > 0 ?? false;
  }

  public navigateToEnd() {
    this.$store.dispatch('cloud/navigateToEnd', this.viewId);
  }

  public navigateToStart() {
    this.$store.dispatch('cloud/navigateToStart', this.viewId);
  }

  public get viewOptions() {
    return this.$store.getters['cloud/window'](ViewIdentifier.MAIN_VIEW).viewOptions;
  }
}
