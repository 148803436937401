
import throttle from 'lodash.throttle';
import { Component, Prop, Vue } from 'nuxt-property-decorator';

  @Component
export default class MutantResizeGrabber extends Vue {
    @Prop(Boolean)
    public isVertical?: boolean;

    @Prop({ default: 0 })
    public isThrottledBy?: number;

    @Prop({ default: 10 })
    public size?: number;

    public isHover = false;
    public isDragging = false;

    private throttledDrag: any;

    constructor() {
      super();
      this.throttledDrag = this.isThrottledBy ? throttle(this.drag, 50) : this.drag;
    }

    public get grabberStyle() {
      const obj: any = {};
      const size = `${this.size}px`;
      if (this.isVertical) {
        obj.width = size;
      } else {
        obj.height = size;
      }
      return obj;
    }

    public startDrag(event: DragEvent) {
      this.isDragging = true;
      this.$emit('dragstart', event);
    }

    public drag(event: DragEvent) {
      this.$emit('drag', event);
    }

    public endDrag(event: DragEvent) {
      this.$emit('dragend', event);
      this.isDragging = false;
    }
}
