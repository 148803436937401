
import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class CloudMenuInfo extends Vue {
  public get sizeInCloud() {
    const sizeObject = this.$store.getters['folder/totalSizeInCloud'];
    return `${sizeObject.size.toFixed(2)} ${sizeObject.unit}`;
  }

  public get folderCount() {
    return Object.values(this.$store.getters['folder/folders'])?.length;
  }

  public get folderItems() {
    return this.$store.getters['cloud/foldersItems'];
  }
}
