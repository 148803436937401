
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';

@Component
export default class MutantCheckMark extends Vue {
  public id: string;

  @Prop(Boolean)
  public isActive: boolean;

  @Prop({ default: false })
  public disabled: boolean;

  constructor() {
    super();
    this.id = `mutant-checkmark-${uuid()}`;
  }

  public wasClicked = false;

  public handleClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (!this.disabled) {
      this.wasClicked = true;
      this.$emit('click', event);
    }
  }

  public handleMouseLeave() {
    this.wasClicked = false;
  }
}
