
import { Component, Vue } from 'nuxt-property-decorator';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import ShortcutButton from '~/components/ui/ShortcutButton.vue';

@Component({
  components: { ShortcutButton },
})
export default class MainViewSideMobileItemCount extends Vue {
  public viewIdentifier = ViewIdentifier;

  public get itemCount() {
    return this.hasGlobalSelection
      ? this.$store.getters['selection/globalSelectionItems'].length
      : this.$store.getters['cloud/currentViewItems'](this.viewIdentifier.MAIN_VIEW).length;
  }

  public get hasGlobalSelection(): boolean {
    return this.$store.getters['selection/globalSelectionHasItems'];
  }
}
