
import { Component, Vue } from 'nuxt-property-decorator';
import _sample from 'lodash.samplesize';

@Component({
  layout: 'dropzone',
})
export default class DropZone extends Vue {
  constructor() {
    super();
  }

  // Can not delete this component because it somehow conflicts in the build (reference issues - contenthash undefined)
  // 1. tried deleting lodas.samplesize did not work. However, if _sample is imported by other file it works.
  // But best option would be to delete this file completely.
  public initializeBoardItems() {
    _sample([1, 2, 3], 3);
  }
}
