
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';

@Component
export default class TextFieldBig extends Vue {
  @Prop()
  private isEditable: boolean;

  @Prop({ default: () => /^.{0,255}$/g })
  private allowedValues: RegExp;

  @Prop({ default: '50px' })
  private minWidth: string;

  // Blocks input according to allowed values
  @Prop({ default: true })
  private blockIfNotAllowed: boolean;

  @Prop({ default: true })
  private hover: boolean;

  @Prop()
  private displayValue: string;

  @Prop()
  private contentId: string;

  @Prop()
  private placeholder: string;

  @Prop({ default: '' })
  private placeholderHover: string;

  @Prop({ default: true })
  private transitions: boolean;

  @Prop({ default: false })
  private isEllipsis: boolean;

  @Prop({ default: false })
  private isSingleLine: boolean;

  @Prop({ default: false })
  private hasFocusOnCreation: boolean;

  private isTyping = false;
  private isInvalid = false;
  private element: HTMLElement;

  public clickText(event: Event) {
    if (this.isEditable) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.isTyping = true;
    }
  }

  public updateValue() {
    if (this.isTyping && !this.isInvalid) {
      const value = this.element ? this.replaceCarriageReturnFromElement().trim() : '';
      this.$emit('change-value', value);
      this.isTyping = false;
    }
  }

  mounted() {
    this.isTyping = this.hasFocusOnCreation;
    this.element = document.getElementById(this.contentId);
    if (this.element != null) {
      if (this.hasFocusOnCreation) {
        this.element.focus();
      }
      if (this.displayValue != null) {
        this.element.innerText = this.displayValue;
      }
    }
  }

  public checkValidString() {
    if (this.allowedValues != null) {
      const value = this.replaceCarriageReturnFromElement();
      this.isInvalid = value !== '' && value.match(this.allowedValues) == null;
    }
  }

  // Webkit inserts carriage return, when deleting all characters and typing in new characters
  // So we remove the carriage return, which prevents flaky behavior
  public replaceCarriageReturnFromElement(): string {
    return this.element.innerText.replace(/([\n\r])/g, '');
  }

  private isAllowedKey(key: string) {
    switch (key) {
      case 'Enter':
      case 'Esc':
      case 'ArrowLeft':
      case 'ArrowRight':
        return true;
      default:
        return false;
    }
  }

  public checkInput(event: any) {
    if (!this.isAllowedKey(event.key)) {
      this.isTyping = true;
      if (event.key !== 'Backspace') {
        const innerText = this.replaceCarriageReturnFromElement();
        if (innerText !== '') {
          if (this.allowedValues != null
            && String(innerText + ' ').match(this.allowedValues) == null
            && this.blockIfNotAllowed) {
            event.stopImmediatePropagation();
            event.preventDefault();
          } else {
            this.updateValue();
          }
        }
      }
    }
  }

  public get inputStyle() {
    return {
      'min-width': this.minWidth,
      '--placeholder': this.placeholder,
      '--placeholder-hover': this.placeholderHover,
    };
  }

  @Watch('displayValue', { immediate: true })
  public watchDisplayValue(newValue: string) {
    if (this.element != null) {
      if (
        newValue != null
          && (this.allowedValues == null || String(newValue).match(this.allowedValues) != null || newValue === '')
      ) {
        this.isInvalid = false;
        this.element.innerText = newValue;
      } else if ((this.allowedValues != null && newValue !== '' && String(newValue).match(this.allowedValues) == null)) {
        this.isInvalid = true;
      }
    }
  }
}
