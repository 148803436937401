
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import MutantControlButton from '~/components/ui/MutantControlButton.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { MagnifyViewContentType } from '~/store/magnify/state';
import ViewTypeControls from '~/components/window/view/dialogs/ViewTypeControls.vue';

@Component({
  components: {
    ViewTypeControls,
    MutantControlButton,
  },
})
export default class ViewSection extends Vue {
  @Prop()
    viewId: ViewIdentifier;

  public get view() {
    return this.$store.getters['cloud/view'](this.viewId);
  }

  public get isActiveContentMagnified(): boolean {
    return this.$store.state.magnify.isOpen && this.$store.state.magnify.viewContent.type === MagnifyViewContentType.FOLDER;
  }

  public toggleMagnifyActiveContent() {
    if (this.isActiveContentMagnified) {
      this.$store.dispatch('magnify/close');
    } else {
      this.$store.dispatch('magnify/openViewContent', { type: MagnifyViewContentType.FOLDER });
    }
  }

  public get isShared(): boolean {
    return this.content?.isShared;
  }

  public get content(): { isShared?: boolean, accessId?: string } {
    if (this.isSelection) {
      return this.$store.getters['selection/selectionsById'](this.selectionId);
    }
    return this.$store.getters['folder/folderById'](this.folderId);
  }

  private get selectionId(): string {
    return this.$store.getters['cloud/view'](this.viewId).selectionId;
  }

  private get folderId(): string {
    return this.$store.getters['cloud/view'](this.viewId).folderId;
  }

  public get isSelection(): boolean {
    return !!this.$store.getters['cloud/view'](this.viewId).selectionId;
  }

  public copySharedLink(): void {
    this.$store.dispatch('link/copyToClipboard', this.content.accessId);
  }

  public createSharedLink(): void {
    if (this.content) {
      const { accessId } = this.content;
      if (this.isSelection) {
        this.$store.dispatch('link/createSharedLinkForSelection', { selectionId: this.selectionId, accessId });
      }
      this.$store.dispatch('link/createSharedLink', { folderId: this.folderId, accessId });
    }
  }

  public createNewFolder() {
    if (this.$roleAwareness.isGuestUser) {
      this.$store.dispatch('openCloudMenu');
      return;
    }
    this.$store.dispatch('folder/createFolder', {
      isPublic: false,
      sync: true,
      windowId: ViewIdentifier.MAIN_VIEW,
    });
  }

  public async createNewMoodboard() {
    await this.$store.dispatch('selection/createNewMoodboard', ViewIdentifier.MAIN_VIEW);
  }
}
