
import { Component, Vue } from 'nuxt-property-decorator';
import NotificationBanner from '~/components/ui/NotificationBanner.vue';
import { Notification, NotificationPosition } from '~/models/Notification';

@Component({
  components: { NotificationBanner },
})
export default class NotificationBannerWrapper extends Vue {
  public position = NotificationPosition;

  public get notifications(): [string, Notification][] {
    return Object.entries(this.notificationMap);
  }

  public get notificationMap() {
    return this.$store.state.notifications;
  }

  public get hasEntries() {
    return this.notificationMap.size !== 0;
  }
}
