
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { MutantContentView } from '~/models/views/MutantContentView';
import MainView from '~/components/window/view/MainView.vue';
import SidePane from '~/components/window/SidePane.vue';
import { ViewType } from '~/models/views/ViewType';
import MainViewHeader from '~/components/window/view/header/MainViewHeader.vue';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ItemType } from '~/models/item/ItemType';

@Component({
  components: {
    SidePane,
    MainView,
    MainViewHeader,
  },
})
export default class SidePaneWindow extends Vue {
  @Prop({ default: false })
  public isLoading: boolean;

  public paneSize: { width: number; height: number;} = { width: 0, height: 0 };

  public get mutantView(): MutantContentView {
    return this.$store.getters['selection/paneView'];
  }

  public get paneIsOpen() {
    return this.$store.state.cloudSidePaneOpen && this.paneSize !== null;
  }

  public setWidth(value: number) {
    this.paneSize.width = value;
  }

  public get height() {
    if (this.mutantView.viewOptions.activeViewType === ViewType.HORIZONTAL) {
      const footerOffset = parseInt(getComputedStyle(document.body).getPropertyValue('--footer-menu-height').split('px')[0], 10);
      return this.paneSize.height - footerOffset;
    }
    return this.paneSize.height;
  }

  public adjustSize(size: { width: number, height: number }) {
    this.paneSize.height = size.height;
  }

  public handleHeaderDrop(event: DragEvent) {
    const transferType = event.dataTransfer.getData(TransferOption.MUTANT_TRANSFER_TYPE);
    if (transferType === TransferType.ITEM && this.mutantView.viewOptions.activeViewType === ViewType.MOODBOARD) {
      const itemId = event.dataTransfer.getData(TransferOption.MUTANT_ID);
      const selectionId = this.mutantView.selectionId;
      const dragItems: ItemWithPosition[] = this.$store.state.currentDragItems;
      // Only images can be set as background images
      if (dragItems.some(item => item.item.id === itemId && item.item.type === ItemType.IMAGE)) {
        this.$store.dispatch('selection/updateMoodboardBackground', {
          itemId,
          selectionId,
        });
      }
    }
  }
}
