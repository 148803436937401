
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { InfoBadgeData, OverviewType } from '~/store/cloud/getters';
import InfoBadge from '~/components/window/view/header/InfoBadge.vue';

@Component({ components: { InfoBadge } })
export default class ItemOverviewBadges extends Vue {
  public overViewType = OverviewType;

  @Prop(Boolean)
  public showSizes: boolean;

  public get isViewFiltered(): boolean {
    return this.$store.getters['cloud/filterHasEntries'];
  }

  public get itemCountOverviewList(): InfoBadgeData[] {
    return this.$store.getters['cloud/itemCountOverviewList']([OverviewType.ALL, OverviewType.FILTERED, OverviewType.ORIGINALS]);
  }
}
