
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ExternalProgressIndicator from '~/components/ui/progress/ExternalProgressIndicator.vue';
import MutantCounter from '~/components/ui/MutantCounter.vue';

@Component({
  components: {
    MutantCounter,
    ExternalProgressIndicator,
  },
})
export default class UploadCircle extends Vue {
  @Prop({ default: 50 })
    radius: number;

  public stroke: number = 3;

  public get dimensions() {
    return {
      'min-width': this.normalizedRadius * 2 + this.stroke * 1.34 + 'px',
      'min-height': this.normalizedRadius * 2 + this.stroke * 1.34 + 'px',
    };
  }

  public get buttonDimensions() {
    return {
      transform: `scale(${this.normalizedRadius / 15})`,
    };
  }

  public get normalizedRadius() {
    return this.radius - this.stroke * 2;
  }

  public get circumference() {
    return this.normalizedRadius * 2 * Math.PI;
  }
}
