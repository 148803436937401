
import { Component, Vue } from 'nuxt-property-decorator';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ViewType } from '~/models/views/ViewType';

interface ColumnCount {
  name: string,
  count: number,
}

@Component
export default class ViewColumns extends Vue {
  showColumnCount = false;

  columnEntries: ColumnCount[] = this.createColumnEntries;

  public get createColumnEntries(): ColumnCount[] {
    const columnEntries: ColumnCount[] = [];
    for (let i = 1; i <= 18; i++) {
      columnEntries.push({ name: `${i} columns`, count: i });
    }
    return columnEntries;
  }

  public get isMosaicView() {
    return this.viewOptions.activeViewType === ViewType.MOSAIC;
  }

  public get isContactSheet() {
    return this.viewOptions.activeViewType === ViewType.CONTACT_SHEET;
  }

  public get viewOptions() {
    return this.$store.getters['cloud/window'](ViewIdentifier.MAIN_VIEW).viewOptions;
  }

  public get activeColumn(): ColumnCount {
    if (this.isMosaicView) {
      return this.columnEntries.find(c => c.count === (this.viewOptions.mosaic.columnCount || 1));
    } else if (this.isContactSheet) {
      return this.columnEntries.find(c => c.count === (this.viewOptions.contactSheet.columnCount || 1));
    } else {
      return { name: 'no columns', count: 0 };
    }
  }

  public setMosaicColumnCount(count: number) {
    if (this.isMosaicView) {
      this.$store.dispatch('cloud/setMosaicColumnCount', { windowId: ViewIdentifier.MAIN_VIEW, columnCount: count });
    } else if (this.isContactSheet) {
      this.$store.dispatch('cloud/setContactSheetColumnCount', { windowId: ViewIdentifier.MAIN_VIEW, columnCount: count });
    }
    this.showColumnCount = false;
  }

  public toggleColumnCount() {
    this.showColumnCount = !this.showColumnCount;
  }
}
