
import { Component, Vue } from 'nuxt-property-decorator';
import PlayButtonIsolated from '~/components/homepage/feature-highlights/PlayButtonIsolated.vue';
import MutantVideo from '~/components/homepage/feature-highlights/MutantVideo.vue';
import LegalFooter from '~/components/homepage/feature-highlights/LegalFooter.vue';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: { LegalFooter, MutantVideo, PlayButtonIsolated },
})
export default class DesktopPromo extends Vue {
  public signUp() {
    if (this.$store.getters['context/menu'](ContextMenuType.LOGIN)?.data?.showLogin) {
      this.$store.dispatch('context/updateMenuData', { type: ContextMenuType.LOGIN, isOpen: true, data: { showLogin: false } });
    } else {
      this.$store.dispatch('context/toggleMenu', { type: ContextMenuType.LOGIN, data: { showLogin: false } });
    }
  }

  public downloadDesktopApp() {
    if (this.$roleAwareness.isGuestUser) {
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { isLogin: false, isSignupForDesktop: true } });
    } else {
      this.$store.dispatch('downloadDesktopApp');
    }
  }
}
