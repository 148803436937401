
import { Component, Vue } from 'nuxt-property-decorator';
import MutantGrabberMenu from '~/components/menu/left/MutantGrabberMenu.vue';
import MutantTabSectionTab from '~/components/ui/tab-section/MutantTabSectionTab.vue';
import ViewOptionsSideMenu from '~/components/menu/right/tabs/view/ViewOptionsSideMenu.vue';
import { CloudMenuTab } from '~/models/CloudMenuTab';
import MutantTabSection from '~/components/ui/tab-section/MutantTabSection.vue';
import RecentCloudActivityList from '~/components/menu/right/tabs/recent/RecentCloudActivityList.vue';
import SelectionList from '~/components/menu/right/tabs/selection/SelectionList.vue';
import ItemList from '~/components/menu/right/tabs/items/ItemList.vue';
import SharedLinkInfo from '~/components/menu/right/tabs/shared-link/SharedLinkInfo.vue';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import ReviewTab from '~/components/menu/right/tabs/review/ReviewTab.vue';
import PipelineOptionsSideMenu from '~/components/menu/right/tabs/pipeline/PipelineOptionsSideMenu.vue';
import Selection from '~/models/selection/Selection';
import ContactsTab from '~/components/menu/right/tabs/contacts/ContactsTab.vue';

@Component({
  components: {
    ContactsTab,
    ReviewTab,
    SharedLinkInfo,
    ItemList,
    SelectionList,
    RecentCloudActivityList,
    MutantTabSection,
    ViewOptionsSideMenu,
    PipelineOptionsSideMenu,
    MutantTabSectionTab,
    MutantGrabberMenu,
  },
})
export default class MenuRightContent extends Vue {
  public cloudMenuTab = CloudMenuTab;

  public get activeCloudMenuTab() {
    return this.$store.getters.activeCloudMenuTab;
  }

  public get currentCloudObject(): CloudObject<Folder | Selection> {
    return this.$store.getters['cloud/currentCloudObject'];
  }

  public set activeCloudMenuTab(activeTab: CloudMenuTab) {
    this.$store.dispatch('setActiveCloudMenuTab', activeTab);
  }
}
