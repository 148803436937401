
import { Component, Vue } from 'nuxt-property-decorator';
import { Watch } from 'vue-property-decorator';
import { Prop } from '~/node_modules/nuxt-property-decorator';

@Component
export default class MutantCounter extends Vue {
  @Prop({ default: 0 })
  trackedValue: number;

  public value: number;
  private timer = null;

  constructor() {
    super();
    this.value = this.trackedValue;
  }

  // Credits to https://stackoverflow.com/questions/16994662/count-animation-from-number-a-to-b
  animateProgressCount(start, end, duration) {
    const that = this;
    const range = end - start;
    const minTimer = 50;
    let stepTime = Math.abs(Math.floor(duration / range));
    stepTime = Math.max(stepTime, minTimer);
    const startTime = new Date().getTime();
    const endTime = startTime + duration;

    function run() {
      const now = new Date().getTime();
      const remaining = Math.max((endTime - now) / duration, 0);
      const value = Math.round(end - (remaining * range));
      that.value = value;
      if (value == end) {
        clearInterval(that.timer);
      }
    }
    this.timer = setInterval(run, stepTime);
    run();
  }

  @Watch('trackedValue', { immediate: true })
  watchProgress(newValue) {
    if (newValue) {
      if (newValue !== this.value) {
        clearInterval(this.timer);
        this.animateProgressCount(this.value, newValue, 500);
      }
    }
  }
}
