
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ContextMenuBase from '~/components/ui/ContextMenuBase.vue';

@Component({
  components: { ContextMenuBase },
})
export default class DesktopInstructionContextMenu extends Vue {
  @Prop()
  private width: number;

  public get startPosition() {
    if (this.$store.getters.isMobile) {
      return null;
    }
    const defaultOffsetFromTop = window.innerHeight * 0.1;
    const defaultOffsetFromSide = this.width / 2;
    const safeDefaultLeft = 5;
    const safeDefaultTop = 20;
    let left = window.innerWidth / 2 - defaultOffsetFromSide - 100;
    let top = defaultOffsetFromTop;
    // if the defaults are too near the outside of the screen, move them to safe defaults
    if (left < safeDefaultLeft) {
      left = safeDefaultLeft;
    }
    if (top < safeDefaultTop) {
      top = safeDefaultTop;
    }
    return { top, left };
  }
}
