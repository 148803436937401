
import { Component, Vue } from 'nuxt-property-decorator';
import ActionButton from '../../../../layout/footer/ActionButton.vue';
import { FilterOption, ReviewFilter } from '~/store/cloud/state';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import { ContextMenuType } from '~/store/context/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import ActionButtonBase from '~/components/ui/ActionButtonBase.vue';
import SplitButton from '~/components/ui/SplitButton.vue';

@Component({ components: { ActionButtonBase, SplitButton, ActionButton, MutantCheckMark } })
export default class ReviewActions extends Vue {
  public isExtracting = false;
  public isOwner = this.$store.getters['cloud/isOwnerOfView'](ViewIdentifier.MAIN_VIEW);
  public selectedFilter: ReviewFilter = this.$store.getters['cloud/reviewFilter'] ?? [FilterOption.NO_FILTER];
  public useRawFiles = false;

  public get requestOriginalsTooltip() {
    return this.isOwner ? { placement: 'top', content: 'users can request files from you here' } : {};
  }

  public get extractButtonText() {
    if (this.isExtracting) {
      return 'EXTRACTING...';
    }
    return this.useRawFiles ? 'EXTRACT RAWS' : 'EXTRACT ORIGINALS';
  }

  public requestOriginals() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.REQUEST_ORIGINALS });
  }

  public get deactivateExtract(): boolean {
    return this.isExtracting || !this.hasItemsInView || !this.isOwner;
  }

  private get hasItemsInView(): boolean {
    return this.$store.getters['cloud/currentViewItems'](ViewIdentifier.MAIN_VIEW)?.length > 0;
  }

  toggleRawExtractionOption(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.useRawFiles = !this.useRawFiles;
  }

  public async extractFiles(event: any) {
    event.preventDefault();
    if (!event.target?.files) {
      return;
    }
    this.isExtracting = true;
    await this.$store.dispatch('cloud/extractOriginals', { extractRawFiles: this.useRawFiles, files: Array.from(event.target.files) });
    this.isExtracting = false;
    // forcing chrome to always fire change event, even if same file is selected again
    event.target.value = null;
  }
}
