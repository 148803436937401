
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import _throttle from 'lodash.throttle';

@Component
export default class ContextMenuBase extends Vue {
  @Prop(Boolean)
    vertical?: boolean;

  @Prop()
    width?: number;

  @Prop()
    startPosition?: { top: number; left?: number; right?: number; };

  @Prop(Boolean)
    openFromRight: boolean;

  @Prop(Boolean)
    isDraggable: boolean;

  @Prop(Boolean)
    animated: boolean;

  @Prop({
    default: () => ({
      left: 0, top: 150, bottom: 100, right: 0,
    }),
  })
    dragBounds: { left: number, top: number, right: number, bottom: number };

  public left: number;
  public top: number;
  public right: number;
  public bottom: number;
  public zIndex: number;

  public throttledDrag = null;

  private dragStart = null;

  constructor() {
    super();
    if (this.startPosition) {
      this.setWindowAdjustedStartPosition();
    }
    this.throttledDrag = _throttle(this.dragContextMenu, 10);
  }

  mounted() {
    this.$store.commit('context/increaseContextMenuOrderZindex');
    this.zIndex = this.$store.state.context.currentZindex;
    if (!this.startPosition) {
      this.setInitialStartingPosition();
    }
    this.$forceUpdate();
  }

  public contextMenuStyle(): any {
    const obj: any = {
      zIndex: this.zIndex,
    };
    if ((this.startPosition || this.left) && !this.$store.getters.isMobile) {
      obj.position = 'fixed';
      if (this.left) {
        obj.left = `${this.left}px`;
      }
      if (this.top) {
        obj.top = `${this.top}px`;
      }
      if (this.right) {
        obj.right = `${this.right}px`;
      }
      if (this.bottom) {
        obj.bottom = `${this.bottom}px`;
      }
    }
    return obj;
  }

  beforeDestroy() {
    document.removeEventListener('mousemove', this.throttledDrag);
  }

  public setInitialStartingPosition() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    if (this.$store.getters.isMobile) {
      this.left = (window.innerWidth != null && this.$el?.clientWidth != null) ? (window.innerWidth - this.$el.clientWidth) / 2 : 0;
      this.bottom = (window.innerHeight != null) ? (window.innerHeight - (window.innerHeight / 8)) / 2 : 0;
    } else if (this.$el) {
      this.left = windowWidth / 2 - this.$el.clientWidth / 2;
      this.top = windowHeight / 2 - this.$el.clientHeight / 2;
    } else {
      this.left = windowWidth / 2 - (this.width ? this.width / 2 : 500 / 2);
      this.top = 80;
    }
  }

  // TODO: use element height in calculation
  public setWindowAdjustedStartPosition() {
    const safeDialogHeight = 300;
    const safeDialogWidth = 300;
    const { left, top, right } = this.startPosition;
    if (left != null) {
      if (this.openFromRight || left > window.innerWidth - (this.width || safeDialogWidth)) {
        if (top > window.innerHeight - safeDialogHeight) {
          this.left = window.innerWidth - this.width;
          this.bottom = window.innerHeight - top;
        } else {
          this.left = window.innerWidth - this.width;
          this.top = top;
        }
      } else if (top > window.innerHeight - safeDialogHeight) {
        this.left = left;
        this.bottom = window.innerHeight - top;
      } else {
        this.left = left;
        this.top = top;
      }
    } else if (right != null) {
      if (right > window.innerHeight - (this.width || safeDialogWidth)) {
        if (top > window.innerHeight - safeDialogHeight) {
          this.left = window.innerWidth - right;
          this.bottom = window.innerHeight - top;
        } else {
          this.left = window.innerWidth - right;
          this.top = top;
        }
      } else if (top > window.innerHeight - safeDialogHeight) {
        this.right = right;
        this.bottom = window.innerHeight - top;
      } else {
        this.right = right;
        this.top = top;
      }
    }
  }

  public isInDragRange(event: DragEvent) {
    const { top, left, width, height } = this.$el.getBoundingClientRect();
    return event.clientY <= top + this.dragBounds.top
      || event.clientY >= top + height - this.dragBounds.bottom
      || event.clientX <= this.dragBounds.left + left
      || event.clientX >= left + width - this.dragBounds.right;
  }

  public handleDrag(event: DragEvent) {
    if (this.isInDragRange(event)) {
      document.addEventListener('mousemove', this.throttledDrag);
      this.dragStart = {
        x: event.clientX,
        y: event.clientY,
        left: this.left,
        top: this.top,
        right: this.right,
        bottom: this.bottom,
      };
      this.$store.commit('context/increaseContextMenuOrderZindex');
      this.zIndex = this.$store.state.context.currentZindex;
    }
  }

  public dragContextMenu(event: DragEvent) {
    if (this.dragStart && event.clientY > 0 && event.clientX > 0) {
      const { left, top, right, bottom, x, y } = this.dragStart;
      const offsetX = event.clientX - x;
      const offsetY = event.clientY - y;
      if (this.left !== undefined) {
        this.left = left + offsetX;
      }
      if (this.top !== undefined) {
        this.top = top + offsetY;
      }
      if (this.right !== undefined) {
        this.right = right - offsetX;
      }
      if (this.bottom !== undefined) {
        this.bottom = bottom - offsetY;
      }
      this.$forceUpdate();
    }
  }

  public dragEnd() {
    this.dragStart = null;
    document.removeEventListener('mousemove', this.throttledDrag);
  }
}
