
import { Component, Vue } from 'nuxt-property-decorator';
import ProgressIndicatorNew from '~/components/ui/progress/ProgressIndicatorNew.vue';
import ProgressIndicatorContextMenu from '~/components/dialogs/progress/ProgressIndicatorContextMenu.vue';

@Component({
  components: {
    ProgressIndicatorNew,
    ProgressIndicatorContextMenu,
  },
})
export default class ProgressContextMenu extends Vue {
  public isProgressWindowOpen = false;

  public toggleProgressWindow() {
    this.isProgressWindowOpen = !this.isProgressWindowOpen;
  }
}
