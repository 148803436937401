
import { Component, Vue } from 'nuxt-property-decorator';
import MutantHeader from '~/components/ui/MutantHeader.vue';

  @Component({
    components: {
      MutantHeader,
    },
  })
export default class AccountPage extends Vue {

}
