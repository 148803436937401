
import { Component, Vue } from 'nuxt-property-decorator';
import throttle from 'lodash.throttle';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';

@Component
export default class Dropzone extends Vue {
  private throttledDragOverTimer = null;
  private uploadHandlerInProgress = false;
  public isDragOver = false;
  public throttledDragOver = throttle(this.dragover, 100, { leading: true, trailing: false });

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public triggerFileSelection() {
    document.getElementById('file-input-homepage').click();
  }

  public triggerFolderSelection(event: MouseEvent) {
    event.stopImmediatePropagation();
    document.getElementById('folder-input-homepage').click();
  }

  private hasExternalData(dragEvent: DragEvent) {
    return dragEvent.dataTransfer?.items?.length > 0;
  }

  private dragover(dragEvent: DragEvent) {
    if (this.hasExternalData(dragEvent)) {
      this.isDragOver = true;
      clearTimeout(this.throttledDragOverTimer);
      this.throttledDragOverTimer = setTimeout(() => {
        this.isDragOver = false;
      }, 250);
    }
  }

  public async handleDrop(dragEvent: DragEvent) {
    if (this.hasExternalData(dragEvent)) {
      dragEvent.preventDefault();
      if (this.$store.state.menuOpen) {
        await this.$store.dispatch('toggleMenu', null, { root: true });
      }
      await this.handleFiles(dragEvent);
    }
  }

  public async handleFiles(event: DragEvent | InputEvent) {
    if (!this.uploadHandlerInProgress) {
      this.uploadHandlerInProgress = true;
      await this.$store.dispatch('folder/uploadToNewFolder', new AddExternalContentEvent(event, { order: 0 }));
      setTimeout(() => {
        this.uploadHandlerInProgress = false;
      }, 1000);
    }
  }
}
