
import { Component, Vue } from 'nuxt-property-decorator';
import Homepage from '~/components/homepage/Homepage.vue';
import { LayoutType } from '~/models/LayoutType';

@Component({
  components: {
    Homepage,
  },
  layout: 'default',
})
export default class IndexPage extends Vue {
  constructor() {
    super();
  }

  mounted() {
    this.$store.dispatch('setActiveLayoutType', LayoutType.ESSENTIALS, { root: true });
  }
}
