
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FolderTagHierarchy } from '~/store/folder/getters';
import MutantBadge from '~/components/ui/MutantBadge.vue';
import TagWithBadge from '~/components/menu/right/tabs/review/folder-tags/TagWithBadge.vue';
import TagBadgeEntry from '~/components/menu/right/tabs/review/folder-tags/TagBadgeEntry.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { RatingsFileTypes } from '~/store/cloud/getters';

// eslint-disable-next-line no-use-before-define
@Component({ name: 'Tag', components: { TagBadgeEntry, TagWithBadge, Tag, MutantBadge } })
export default class Tag extends Vue {
  name: 'tag';

  @Prop()
  public tag: FolderTagHierarchy;

  @Prop()
  public depth: number;

  public get paddingStyle() {
    return {
      'padding-left': this.depth * 10 + 'px',
    };
  }

  public get filteredCount(): RatingsFileTypes {
    return this.$store.getters['cloud/countTagWithFilter'](this.tag.tag.id);
  }

  public get isSelected(): boolean {
    return this.$store.getters['cloud/folderTagFilter'].tags.some(t => t.id === this.tag.tag.id);
  }

  public toggleTag(event: MouseEvent) {
    this.$store.dispatch('cloud/toggleTag', { windowId: ViewIdentifier.MAIN_VIEW, tag: this.tag, isAdditionalMode: event.metaKey });
  }
}
