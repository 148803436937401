
import { Component, Vue } from 'nuxt-property-decorator';
import { PipelineInfoLabel } from '~/models/pipeline/FileProcessingPipeline';
import { ObjectId } from '~/models/ObjectId';
import { PipelineProgress } from '~/store/getters';

@Component({})
export default class IndexingProgressBar extends Vue {
  public get uploadProcessObjectId(): ObjectId {
    return this.$store.state.file.uploadProcess?.objectId;
  }

  public get pipelineProgress(): PipelineProgress[] {
    return this.$store.getters.pipelineProgress(this.uploadProcessObjectId?.toUuid()) || [];
  }

  public get indexingStep(): PipelineProgress {
    return this.pipelineProgress.find(step => step.label === PipelineInfoLabel.CREATE_THUMBNAILS);
  }

  public get percentage(): number {
    return this.indexingStep?.percentage;
  }

  public get isIndexing(): boolean {
    return this.percentage > 0 && this.percentage < 100;
  }

  public get indexingComplete(): boolean {
    return !this.indexingStep || this.percentage === 100;
  }

  public get infoText(): string {
    return this.indexingComplete ? 'Indexing finished' : 'Indexing in progress';
  }
}
