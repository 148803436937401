var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.activeEntry != null)?_c('div',{staticClass:"homepage",class:{
    'homepage--is-dragover': _vm.isDragOver,
    'homepage--desktop': _vm.activeEntry === _vm.landingPageEntry.DESKTOP,
    'homepage--web': _vm.activeEntry === _vm.landingPageEntry.WEB
  },attrs:{"id":"homepage"},on:{"wheel":_vm.setScrollDirection,"drop":_vm.handleDrop,"dragover":_vm.throttledDragOver}},[_c('div',{staticClass:"switch-button-wrapper"},[_c('SwitchButtonLandingPage',{attrs:{"active-entry":_vm.activeEntry},on:{"toggle":function($event){return _vm.toggleSwitchButton($event)}}})],1),_vm._v(" "),(!_vm.isVideoPlaying)?_c('div',{staticClass:"play-button-wrapper"},[_c('PlayButtonIsolated',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.playTeaser.apply(null, arguments)}}})],1):_vm._e(),_vm._v(" "),_c('HomepageWebSplit',{attrs:{"id":_vm.webSplitId,"is-active":_vm.activeEntry === _vm.landingPageEntry.WEB || _vm.activeEntry === null,"show-overlay-flash":_vm.activeEntry !== _vm.landingPageEntry.WEB},on:{"scroll-to":_vm.scrollToWeb}},[_c('video',{staticClass:"mutant-video",class:{
        'mutant-video--landscape': _vm.isLandScapeMode,
        'mutant-video--playing': _vm.isVideoPlaying,
      },attrs:{"id":"mutant-video-web-teaser","poster":"/web-promo-poster.jpeg","preload":"none","width":"100%","height":"100%","controls":_vm.isVideoPlaying,"playsinline":""},on:{"pause":_vm.pausedVideo,"play":_vm.playVideo,"click":_vm.playWebTeaser,"timeupdate":_vm.progressVideo,"ended":_vm.endVideo}},[_c('source',{attrs:{"src":_vm.mutantVideo,"type":"video/mp4"}}),_vm._v("\n      Your browser does not support the video tag.\n    ")])]),_vm._v(" "),_c('HomepageDesktopSplit',{attrs:{"id":_vm.desktopSplitId,"is-active":_vm.activeEntry === _vm.landingPageEntry.DESKTOP,"show-overlay-flash":_vm.activeEntry !== _vm.landingPageEntry.DESKTOP},on:{"scroll-to":_vm.scrollToDesktop}},[_c('video',{staticClass:"mutant-video",class:{
        'mutant-video--landscape': _vm.isLandScapeMode,
        'mutant-video--playing': _vm.isVideoPlaying,
      },attrs:{"id":"mutant-video-desktop-teaser","poster":"/videos/0_promo_image.jpg","preload":"none","width":"100%","height":"100%","controls":_vm.isVideoPlaying,"playsinline":""},on:{"click":_vm.playDesktopTeaser,"pause":_vm.pausedVideo,"play":_vm.playVideo,"timeupdate":_vm.progressVideo,"ended":_vm.endVideo}},[_c('source',{attrs:{"src":_vm.mutantDesktopPromoVideo,"type":"video/mp4"}}),_vm._v("\n      Your browser does not support the video tag.\n    ")])]),_vm._v(" "),(_vm.activeEntry === _vm.landingPageEntry.DESKTOP)?_c('div',{staticClass:"desktop__content",attrs:{"id":_vm.desktopSplitId + '-content'}},[_c('DesktopPromo')],1):_vm._e(),_vm._v(" "),(_vm.activeEntry === _vm.landingPageEntry.WEB)?_c('div',{staticClass:"webapp__content",attrs:{"id":_vm.webSplitId + '-content'}},[_c('WebPromo')],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }