
import { Component, Vue } from 'nuxt-property-decorator';
import throttle from 'lodash.throttle';
import InteractiveFooter from '~/components/layout/footer/InteractiveFooter.vue';
import { isTouchEvent } from '~/models/views/SnippetMover';

@Component // TODO: refactor this and use it as generic 'grabber' / resize component for other components, could emit the current resize change value as event, should also support horizontal and vertical and receive "trigger area" and some visual options as input
export default class InteractiveFooterGrabber extends Vue {
  public isHover = false;
  public isDragging = false;

  private footerMarginAtStart: number = 0;
  private dragStartClientY: number = 0;
  private footerHeight: number;

  startDraggingFooter(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.$store.dispatch('dragLayout', true);
    this.$store.dispatch('adjustWindowsToDrag', true);
    this.isDragging = true;
    this.dragStartClientY = event.clientY;
    this.footerMarginAtStart = this.footerHeight ?? this.$store.state.interactiveFooterHeight;

    let clientY;

    const dragFooter = (dragFooterEvent: DragEvent | TouchEvent) => {
      if (isTouchEvent(dragFooterEvent)) {
        if (dragFooterEvent.changedTouches) {
          const top = dragFooterEvent.changedTouches[0].clientY;
          if (top > 0) {
            clientY = top;
          }
        }
      } else {
        dragFooterEvent.preventDefault();
        dragFooterEvent.stopImmediatePropagation();
        if (dragFooterEvent.clientY > 0) {
          clientY = dragFooterEvent.clientY;
        }
      }
    };

    let animationFrame;

    const adjustFooterHeight = () => {
      if (clientY) {
        const newHeight = this.footerMarginAtStart + this.dragStartClientY - clientY;
        this.setFooterHeight(newHeight > InteractiveFooter.MIN_HEIGHT ? newHeight : InteractiveFooter.MIN_HEIGHT);
      }
      animationFrame = requestAnimationFrame(adjustFooterHeight);
    };

    animationFrame = requestAnimationFrame(adjustFooterHeight);
    const throttledDrag = throttle(dragFooter, 15, { leading: true, trailing: false });

    const endDraggingFooter = (endDragFooterEvent) => {
      endDragFooterEvent.preventDefault();
      endDragFooterEvent.stopImmediatePropagation();
      cancelAnimationFrame(animationFrame);
      document.removeEventListener('touchmove', throttledDrag);
      document.removeEventListener('mousemove', throttledDrag);
      document.removeEventListener('touchend', endDraggingFooter);
      document.removeEventListener('mouseup', endDraggingFooter);
      this.isDragging = false;
      this.footerMarginAtStart = this.footerHeight;
      this.$store.commit('setInteractiveFooterHeight', this.footerHeight);
      this.$store.dispatch('dragLayout', false);
    };

    document.addEventListener('touchmove', throttledDrag);
    document.addEventListener('mousemove', throttledDrag, false);
    document.addEventListener('touchend', endDraggingFooter, false);
    document.addEventListener('mouseup', endDraggingFooter);
  }

  setFooterHeight(value: number) {
    this.footerHeight = value;
    document.documentElement.style.setProperty('--tabs-lane-height', value + 'px');
  }
}
