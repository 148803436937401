
import { Component, Vue } from 'nuxt-property-decorator';
import { MessageRoute } from '~/models/MessageRoute';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ObjectId } from '~/models/ObjectId';
import Folder from '~/models/Folder';
import ContactList from '~/components/menu/right/tabs/contacts/ContactList.vue';
import SwitchButton from '~/components/ui/buttons/SwitchButton.vue';
import SwitchButtonOption from '~/components/ui/buttons/SwitchButtonOption.vue';
import { StorageKey } from '~/models/storage/StorageKey';
import { User } from '~/models/user/User';

export interface Destination {
  id: string;
  name: string;
  userId: string;
}

@Component({
  components: { SwitchButtonOption, SwitchButton, ContactList },
})
export default class ContactsTab extends Vue {
  public radius = 100;
  public stroke = 6;
  public confirmationPrompt = true;
  public isHover = false;
  public confirmationConfig = null;
  public sendToContact = false;
  public dropElementId = 'dropzone-circle-element';
  public contactDestination: Destination = null;

  mounted() {
    this.$store.dispatch('user/fetchMessageRoutes');
    this.$store.dispatch('user/loadDropzoneInfo');
    this.confirmationPrompt = localStorage.getItem(StorageKey.SHOW_CONFIRMATION_PROMPT_SENDING) != null ? localStorage.getItem(StorageKey.SHOW_CONFIRMATION_PROMPT_SENDING) === 'true' : true;
  }

  toggleConfirmBeforeSending() {
    this.confirmationPrompt = !this.confirmationPrompt;
    localStorage.setItem(StorageKey.SHOW_CONFIRMATION_PROMPT_SENDING, String(this.confirmationPrompt));
  }

  public get isReceiveContentEnabled() {
    return this.$store.state.user.isReceiveContentEnabled;
  }

  public get defaultMessageRoute() {
    const id = this.$store.getters['user/currentUser']?.id ?? '';
    return { id, name: 'scrapbook', folderId: this.$store.getters['folder/scrapbook']?.id };
  }

  public get normalizedRadius() {
    return this.radius - this.stroke * 2;
  }

  public sendDirect(destination: Destination) {
    if (this.$store.state.dragInfo) {
      const element = document.getElementById(destination.id ?? destination.userId);
      const elementPosition = element.getBoundingClientRect();
      const dropInfo = {
        elementId: element.id,
        position: {
          left: elementPosition.left + elementPosition.width / 2,
          top: (elementPosition.top + elementPosition.height / 2) + (this.confirmationPrompt ? 100 : 0),
        },
      };
      if (destination.userId !== this.currentUser.id) {
        this.prepareSend(destination, dropInfo);
      } else {
        this.prepareSend(this.ownDestination, dropInfo);
      }
    }
  }

  get currentUser(): User {
    return this.$store.getters['user/currentUser'];
  }

  public get circumference() {
    return this.normalizedRadius * 2 * Math.PI;
  }

  public get ownDestination() {
    const id = this.currentUser?.id ?? '';
    return { userId: id, name: this.viewMessageRoute.name, id: null };
  }

  public get viewMessageRoute(): MessageRoute {
    return this.$store.state.user.messageRoutes[0] ?? this.defaultMessageRoute;
  }

  public async setIsReceiveContentEnabled() {
    await this.$store.dispatch('user/setIsReceiveContentEnabled');
  }

  public setDestination(event: Event, destination: Destination) {
    event?.preventDefault();
    event?.stopImmediatePropagation();
    if (destination.userId === this.currentUser.id) {
      this.sendToContact = false;
    } else {
      this.sendToContact = true;
      this.contactDestination = destination;
    }
  }

  public async visitMessageRoute() {
    const objectId = this.viewMessageRoute
      ? (this.viewMessageRoute.folderId && ObjectId.fromFolderId(this.viewMessageRoute.folderId))
      || (this.viewMessageRoute.selectionId && ObjectId.fromSelectionId(this.viewMessageRoute.selectionId))
      : ObjectId.fromFolderId(this.scrapbook.id);
    await this.$store.dispatch('cloud/addToPane', { windowId: ViewIdentifier.MAIN_VIEW, objectIds: [objectId] });
  }

  public declineSending() {
    this.confirmationConfig = null;
    this.$store.dispatch('stopDraggingItems');
  }

  public acceptSending() {
    this.sendItems();
  }

  public get destination() {
    return this.sendToContact ? this.contactDestination : this.ownDestination;
  }

  public get scrapbook(): Folder {
    return this.$store.getters['folder/scrapbook'];
  }

  public get isDragOver(): boolean {
    return this.isHover && this.$store.state.dragInfo != null;
  }

  public sendItems() {
    if (this.confirmationConfig?.items?.length > 0) {
      this.$store.dispatch('file/sendItems', { items: this.confirmationConfig.items, destination: this.confirmationConfig.destination.userId });
      this.confirmationConfig = null;
    }
  }

  public prepareSend(destination: Destination, dropInfo: { elementId: string, position: { left: number, top: number | string }}) {
    this.$store.commit('setDropInfo', dropInfo);
    this.confirmationConfig = {
      items: [...(this.$store.state.dragInfo?.items ?? {})],
      destination,
    };
    if (!this.confirmationPrompt) {
      this.sendItems();
    }
  }

  public onDrop(event: MouseEvent | DragEvent) {
    event.preventDefault();
    if (this.$store.state.dragInfo) {
      const element = document.getElementById(this.dropElementId);
      const elementPosition = element.getBoundingClientRect();
      const dropInfo = {
        elementId: this.dropElementId,
        position: {
          left: elementPosition.left + elementPosition.width / 2,
          top: elementPosition.top + elementPosition.height / 2,
        },
      };
      this.prepareSend(this.destination, dropInfo);
    }
  }
}
