
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { isEmail } from 'class-validator';
import NavigationElementNew from '~/components/dialogs/ui/NavigationElementNew.vue';
import NavigationElements from '~/components/dialogs/ui/NavigationElements.vue';
import Column from '~/components/ui/Column.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantTagButton from '~/components/ui/MutantTagButton.vue';
import Row from '~/components/ui/Row.vue';
import ProgressCircleAnimating from '~/components/ui/progress/ProgressCircleAnimating.vue';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: {
    ProgressCircleAnimating,
    MutantContextMenuDivider,
    NavigationElementNew,
    NavigationElements,
    MutantTagButton,
    Row,
    Column,
  },
})
export default class LoginSection extends Vue {
  private static FORM_IDENTIFIER = 'login-section';
  public static SHOW_LOADER_TIME = 1100;

  private static formIdentifier(id: string) {
    return `${LoginSection.FORM_IDENTIFIER}-${id}`;
  }

  public formFieldIds = {
    username: LoginSection.formIdentifier('username'),
    password: LoginSection.formIdentifier('password'),
    submit_login: LoginSection.formIdentifier('submit-login'),
    switch_to_signup: LoginSection.formIdentifier('switch-to-signup'),
  };

  public username: string = '';
  public password: string = '';
  public forgotPasswordString: string = '';
  public userIsTyping = false;
  public showForgotPasswordForm = false;
  public isPending = false;

  @Prop({ default: 'LOGIN' })
  public callToAction: string;

  constructor() {
    super();
  }

  created() {
    window.addEventListener('keydown', this.keyEventHandler);
  }

  mounted() {
    document.getElementById(this.formFieldIds.username)?.focus();
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyEventHandler);
  }

  async keyEventHandler(e: KeyboardEvent) {
    this.userIsTyping = true;
    if (e.key === 'Enter' && this.username.length && this.password.length) {
      await this.login();
    }
  }

  @Watch('$store.state.user.login.isPending', { immediate: true })
  public watchPendingState(isPending): void {
    setTimeout(() => {
      this.isPending = isPending;
    }, LoginSection.SHOW_LOADER_TIME);
  }

  public switchToSignup() {
    this.$store.dispatch('context/updateMenuData', { type: ContextMenuType.LOGIN, data: { showLogin: false } });
  }

  public toggleForgotPasswordForm() {
    this.showForgotPasswordForm = !this.showForgotPasswordForm;
  }

  public get hasLoginErrorMessage(): boolean {
    return this.$store.state.user.login.error != null && !this.userIsTyping && !this.showForgotPasswordForm;
  }

  public get forgotPasswordErrorMessage(): string {
    return this.$store.state.user.forgotPassword?.error?.message;
  }

  public get loginErrorMessage(): string {
    return this.$store.state.user.login.error?.message ?? '';
  }

  public get isInputValid(): boolean {
    return this.isUsernameValid() && this.isPasswordValid();
  }

  public get isForgotPasswordFieldValid(): boolean {
    return this.forgotPasswordString.length > 1 || (this.forgotPasswordString.includes('@') && isEmail(this.forgotPasswordString));
  }

  public isUsernameValid(): boolean {
    return this.username.length >= 2;
  }

  public isPasswordValid(): boolean {
    return this.password.length >= 6;
  }

  public forgotPassword() {
    if (this.isForgotPasswordFieldValid) {
      const obj: any = {};
      if (isEmail(this.forgotPasswordString)) {
        obj.email = this.forgotPasswordString;
      } else {
        obj.username = this.forgotPasswordString;
      }
      this.$store.dispatch('user/forgotPassword', obj);
      this.userIsTyping = false;
    }
  }

  public async login() {
    if (this.isInputValid) {
      await this.$store.dispatch('user/login', {
        username: this.username,
        password: this.password,
      });
      setTimeout(() => {
        if (!this.$store.state.user.login.error) {
          this.$store.dispatch('context/closeMenu', ContextMenuType.LOGIN);
        }
      }, LoginSection.SHOW_LOADER_TIME);
      this.userIsTyping = false;
      this.isPending = true;
      this.showForgotPasswordForm = false;
    }
  }
}
