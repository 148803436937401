
import { Component, Vue } from 'nuxt-property-decorator';
import MutantContextMenu from '../../ui/MutantContextMenu.vue';
import ReaderDownloadSection from '~/components/dialogs/mutant-reader/ReaderDownloadSection.vue';
import InfoSection from '~/components/dialogs/sections/InfoSection.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import Column from '~/components/ui/Column.vue';
import { ContextMenuType } from '~/store/context/state';
import SignUpSection from '~/components/dialogs/sign-up/sections/SignUpSection.vue';
import LoginSection from '~/components/dialogs/sign-up/sections/LoginSection.vue';

@Component({
  components: {
    LoginSection,
    ReaderDownloadSection,
    SignUpSection,
    InfoSection,
    Column,
    ContextMenuHeaderFlashy,
    MutantContextMenu,
  },
})
export default class DownloadReaderContextMenu extends Vue {
  public showLogin = false;

  public close() {
    this.$store.dispatch('homepage/resetReaderDownloadProgress');
    this.$store.dispatch('context/closeMenu', ContextMenuType.DOWNLOAD_READER);
  }
}
