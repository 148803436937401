
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ViewType } from '~/models/views/ViewType';
import ViewColumns from '~/components/window/view/header/ViewColumns.vue';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { MutantWindowPayload } from '~/models/MutantWindowPayload';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import MutantCircleButton from '~/components/ui/MutantCircleButton.vue';
import IlluminationControl from '~/components/window/view/header/IlluminationControl.vue';
import FullscreenControl from '~/components/window/view/header/FullscreenControl.vue';

@Component({
  components: {
    FullscreenControl,
    MutantCircleButton,
    ViewColumns,
    IlluminationControl,
  },
})
export default class ViewOptions extends Vue {
  @Prop()
  public viewId: string;

  @Prop()
  public iconSize: 'medium' | 'large';

  public viewType = ViewType;

  public get selectionId(): string | null {
    return this.$store.getters['cloud/view'](this.viewId).selectionId;
  }

  public get illuminationGrade(): number {
    return this.$store.state.cloud.illuminationGrade;
  }

  public get currentSelectedViewType(): ViewType {
    return this.$store.getters['cloud/window'](this.viewId).viewOptions.activeViewType;
  }

  public get faIconSize(): string {
    return this.iconSize === 'large' ? 'lg' : 'sm';
  }

  public get isHorizontalView(): boolean {
    return this.currentSelectedViewType === ViewType.HORIZONTAL;
  }

  public updateView(view: ViewType) {
    this.$store.dispatch('cloud/changeView', { windowId: this.viewId, view });
  }

  public zoomIn() {
    this.$store.dispatch<ActionPayload<MutantWindowPayload>>({ type: 'cloud/zoomIn', payload: { windowId: this.viewId as ViewIdentifier } });
  }

  public get zoomOutTooltip() {
    const text = this.isHorizontalView ? 'Disabled on Horizontal View' : 'Zoom Out';
    return { placement: 'bottom', content: text };
  }

  public get zoomInTooltip() {
    const text = this.isHorizontalView ? 'Disabled on Horizontal View' : 'Zoom In';
    return { placement: 'bottom', content: text };
  }

  public zoomOut() {
    this.$store.dispatch<ActionPayload<MutantWindowPayload>>({ type: 'cloud/zoomOut', payload: { windowId: this.viewId as ViewIdentifier } });
  }

  public toggleImmersionMode() {
    this.$store.dispatch('cloud/toggleImmersionMode', this.viewId);
  }

  public selectIlluminationGrade(grade: number) {
    this.$store.dispatch('cloud/setIlluminationGrade', grade);
  }
}
