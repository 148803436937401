
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class MutantTabSectionTab extends Vue {
  @Prop(Boolean)
    isActive: boolean;

  @Prop(Boolean)
    disabled: boolean;
}
