
import { Vue, Component } from 'nuxt-property-decorator';
import { ObjectId } from '~/models/ObjectId';
import { RecentObject } from '~/models/RecentObject';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';

@Component
export default class RecentCloudActivityList extends Vue {
  public lastClicked = null;
  public timeoutHandler = null;

  public get cloudObject(): RecentObject[] {
    return this.$store.getters.recentObjectActivity
      .slice(0, 15)
      .sort((a: RecentObject, b: RecentObject) => a.name > b.name ? 1 : -1);
  }

  public isActive(object: RecentObject): boolean {
    return this.lastClicked === object.objectId.toString() || this.$store.getters.isActiveObject(object.objectId);
  }

  public async chooseObject(objectId: ObjectId) {
    clearTimeout(this.timeoutHandler);
    this.lastClicked = objectId.toString();
    this.timeoutHandler = setTimeout(() => {
      this.lastClicked = null;
    }, 100);
    await this.$store.dispatch('cloud/addToPane', { objectIds: [objectId], setDefaultView: true });
  }

  public dragObject(event: DragEvent, objectId: ObjectId) {
    if (objectId.isSelectionId) {
      event.dataTransfer.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.SELECTION);
      event.dataTransfer.setData(TransferOption.MUTANT_ID, objectId.toUuid());
    } else {
      event.dataTransfer.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.FOLDER);
      event.dataTransfer.setData(TransferOption.MUTANT_ID, objectId.toUuid());
    }
  }
}
