

import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { CloudMenuTab } from '~/models/CloudMenuTab';

@Component({})
export default class FilterButton extends Vue {
  @Prop()
  public buttonSize: 'large' | 'small';

  @Prop()
  public viewId: string;

  @Prop()
  public collapsed: boolean;

  public get filterHasEntries(): boolean {
    return this.$store.getters['cloud/filterHasEntries'];
  }

  public get filterTitle(): string {
    return this.collapsed ? 'F' : this.filterTitleLong;
  }

  public get filterTitleLong(): string {
    return this.isReviewFilterActive ? 'FILTER ACTIVE' : 'FILTER INACTIVE';
  }

  public get isReviewFilterActive(): boolean {
    return this.$store.getters['cloud/isReviewFilterActive'];
  }

  public toggleReviewFilterState() {
    if (this.filterHasEntries) {
      this.$store.commit('cloud/setReviewFilterState', { windowId: this.viewId, active: !this.isReviewFilterActive });
      if (this.isReviewFilterActive) {
        this.$store.dispatch('setActiveCloudMenuTab', CloudMenuTab.REVIEW, { root: true });
      }
    }
  }
}
