
import { Component, Vue } from 'nuxt-property-decorator';
import { ContextMenuType } from '~/store/context/state';

@Component
export default class DownloadButton extends Vue {
  public wasMenuClosedAfterGlow = false;

  toggleMenu() {
    if (this.isMenuOpen) {
      this.wasMenuClosedAfterGlow = true;
    }
    this.$store.dispatch('context/toggleMenu', { type: ContextMenuType.DOWNLOAD_ALL });
  }

  public get isMenuOpen(): boolean {
    return this.$store.getters['context/isOpen'](ContextMenuType.DOWNLOAD_ALL);
  }

  public get isItemSelected(): boolean {
    return this.$store.getters['selection/globalSelectionHasItems'];
  }

  public get showDownloadProgress(): boolean {
    return this.$store.state.file.downloadProgress != null && this.downloadProgress !== 100;
  }

  public get downloadProgress(): number {
    return this.$store.state.file.downloadProgress.percentTransferred;
  }
}
