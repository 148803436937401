
import { Vue, Component } from 'nuxt-property-decorator';
import ProgressBarNew from '~/components/ui/progress/ProgressBarNew.vue';

@Component({
  components: {
    ProgressBarNew,
  },
})
export default class ProgressIndicatorNew extends Vue {
  public get externalProgress() {
    return this.$store.state.file.externalUploadProgress;
  }

  public get progress() {
    return this.$store.getters['file/activeProgress'];
  }

  public get progressInPercent() {
    return this.isExternalProgress ? this.externalProgress.progressInPercent : this.progress?.percentTransferred;
  }

  public get isExternalProgress(): boolean {
    return this.externalProgress && this.externalProgress.progressInPercent;
  }

  public get isProgressFinished(): boolean {
    return this.isExternalProgress ? this.externalProgress.progressInPercent === 100 : this.progress?.finished;
  }
}
