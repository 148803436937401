
import { Component, Vue } from 'nuxt-property-decorator';
import * as validator from 'class-validator';
import NavigationElementNew from '~/components/dialogs/ui/NavigationElementNew.vue';
import NavigationElements from '~/components/dialogs/ui/NavigationElements.vue';
import Column from '~/components/ui/Column.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantTagButton from '~/components/ui/MutantTagButton.vue';
import Row from '~/components/ui/Row.vue';

@Component({
  components: {
    MutantContextMenuDivider,
    NavigationElementNew,
    NavigationElements,
    MutantTagButton,
    Row,
    Column,
  },
})
export default class ForgotPasswordSection extends Vue {
  public forgotPasswordString: string = '';
  public userIsTyping = false;

  public get hasForgotPasswordErrorMessage(): boolean {
    return this.$store.state.user?.forgotPassword?.error;
  }

  public get forgotPasswordErrorMessage(): string {
    return this.$store.state.user?.forgotPassword?.error?.message;
  }

  public get isForgotPasswordEmailValid(): boolean {
    return this.forgotPasswordString.length > 1 || (this.forgotPasswordString.includes('@') && validator.isEmail(this.forgotPasswordString));
  }

  public forgotPassword() {
    const obj: any = {};
    if (validator.isEmail(this.forgotPasswordString)) {
      obj.email = this.forgotPasswordString;
    } else {
      obj.username = this.forgotPasswordString;
    }
    this.$store.dispatch('user/forgotPassword', obj);
    this.userIsTyping = false;
  }
}
