
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';

@Component
export default class MutantCheckbox extends Vue {
  @Prop({ default: `mutant-checkbox${uuid()}` })
  id: string;

  @Prop(Boolean)
  isActive: boolean;
}
