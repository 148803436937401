
import { Component, Vue } from 'nuxt-property-decorator';
import MutantGrabberMenu, { Direction } from '~/components/menu/left/MutantGrabberMenu.vue';

@Component({
  components: {
    MutantGrabberMenu,
  },
})
export default class MutantMenu extends Vue {
  public direction = Direction;

  public get menuWidth(): number {
    return this.$store.state.menuWidth;
  }

  public get isOpen(): boolean {
    return this.$store.state.menuOpen;
  }

  public updateWidth(value: number): void {
    this.$store.dispatch('updateCloudMenuWidth', value);
  }

  public persistWidth(): void {
    this.$store.dispatch('persistCloudMenu');
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isResized(): boolean {
    return this.$store.state.isLayoutDragged;
  }

  public get isLandingPage(): boolean {
    return this.$routeAwareness.isHomepageView;
  }

  public get isFloating(): boolean {
    return this.$store.state.isMenuFloating || this.isMobile || this.isLandingPage;
  }

  public get backgroundOverlayStyle() {
    return {
      background: `rgba(0, 0, 0, ${this.$store.state?.transparency?.menus ?? 0.1})`,
    };
  }
}
