
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class InteractiveFooterTab extends Vue {
  @Prop(Boolean)
    isActive: boolean;

  @Prop(Boolean)
    isInFocus: boolean;

  @Prop(Boolean)
    isClickable: boolean;

  @Prop(Boolean)
    isMain: boolean;

  @Prop(Boolean)
    isSub: boolean;

  @Prop()
    zIndex: number;

  public get style() {
    const obj: any = {};
    if (this.zIndex) {
      obj.zIndex = this.zIndex;
    }
    if (this.isSub) {
      obj.marginRight = '-45px';
    }
    return obj;
  }
}
