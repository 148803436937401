
import { Component } from 'nuxt-property-decorator';
import { ResizableWindow } from '~/components/window/ResizableWindow';

@Component
export default class ResizableWindowWrapper extends ResizableWindow {
  constructor() {
    super();
  }
}
