
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import ContextMenuBase from '~/components/ui/ContextMenuBase.vue';

@Component({
  components: {
    ContextMenuBase,
  },
})
export default class MutantContextMenu extends Vue {
  @Prop(Boolean)
    vertical?: boolean;

  @Prop()
    width?: number;

  @Prop(Boolean)
    hasRandomBackground?: boolean;

  @Prop()
    customBackground?: number;

  @Prop()
    startPosition?: { top: number, left: number };

  @Prop(Boolean)
    openFromRight: boolean;

  @Prop(Boolean)
    isDraggable: boolean;

  @Prop(Boolean)
    closable: boolean;

  @Prop(Boolean)
    animated: boolean;

  @Prop({
    default: () => ({
      left: 0, top: 150, bottom: 100, right: 0,
    }),
  })
    dragBounds: { left: number, top: number, right: number, bottom: number };

  private background = null;

  constructor() {
    super();
  }

  public contextMenuStyle(): any {
    const obj: any = {
    };
    if (this.background) {
      obj.backgroundImage = `url('/window-background${this.background}.webp'), linear-gradient(to bottom, rgb(0,0,0) 0%, rgb(0,0,0) 100%)`;
      obj.backgroundPosition = 'top left';
      obj.backgroundRepeat = 'no-repeat';
      obj.backgroundSize = 'auto 100%';
    }
    return obj;
  }

  public get contentStyle(): any {
    const obj: any = {
      flexDirection: this.vertical ? 'column' : 'row',
    };
    if (this.width) {
      if (!this.$store.getters.isMobile) {
        obj.width = `${this.width}px`;
      }
    }
    return obj;
  }

  @Watch('customBackground', { immediate: true })
  public watchCustomBackground(value) {
    if (value) {
      this.background = value;
    }
  }

  @Watch('hasRandomBackground', { immediate: true })
  public watchRandomBackground(value) {
    this.background = value
      ? Math.floor(Math.random() * this.$store.state.context.backgroundCount) + 1
      : this.customBackground;
  }
}
