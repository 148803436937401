
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import TextField from '~/components/ui/TextField.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { UnitSize } from '~/models/UnitSize';
import SharedLinkProgress from '~/components/ui/progress/SharedLinkProgress.vue';
import ActionButtonModel from '~/models/ActionButtonModel';
import ActionButton from '~/components/layout/footer/ActionButton.vue';
import { ContextMenuType } from '~/store/context/state';
import ItemSize from '~/components/ui/ItemSize.vue';
import { RAW_ASSET_VERSION } from '~/models/Asset';
import { LinkData } from '~/store/link/state';
import TagsSection from '~/components/menu/right/tabs/review/folder-tags/TagsSection.vue';
import SharedLinkCreation from '~/components/menu/right/tabs/shared-link/SharedLinkCreation.vue';

@Component({
  components: {
    SharedLinkCreation,
    TagsSection,
    ItemSize,
    ActionButton,
    SharedLinkProgress,
    TextField,
  },
})
export default class SharedLinkInfo extends Vue {
  @Prop()
  public cloudObject: CloudObject<Folder | Selection> | undefined;

  @Prop({ default: 65 })
  public shareCircleRadius: number;

  public copyLinkText = { name: 'COPY LINK' };

  public removeLink: ActionButtonModel = {
    key: 'shared-link-info-remove',
    name: () => 'REMOVE',
    action: () => this.doRemoveLink(),
    isHighlighted: false,
  };

  public get isScrapbook() {
    return this.$store.getters['folder/scrapbookId'] === this.cloudObject?.object?.id;
  }

  public get itemsInObject() {
    return this.cloudObject?.object?.itemCount;
  }

  public get largestAssetsSize(): UnitSize {
    return this.$store.getters['cloud/accumulatedSizeLargestAssets'](this.cloudObject);
  }

  public get uploadPendingItems(): number {
    return this.$store.getters['file/uploadPendingItems']?.length;
  }

  public get rawCount(): UnitSize {
    return this.$store.getters['cloud/assetSizeForVersion'](this.cloudObject, RAW_ASSET_VERSION);
  }

  private doRemoveLink() {
    this.$store.dispatch('link/delete', { accessId: this.sharedLink.accessId, objectId: this.cloudObject.objectId });
  }

  private copyLink() {
    this.$store.dispatch('link/copyToClipboard', this.sharedLink.accessId);
    this.copyLinkText = { name: 'COPIED LINK' };
    setTimeout(() => {
      this.copyLinkText = { name: 'COPY LINK' };
    }, 2000);
  }

  public get sharedLink(): LinkData {
    const objectId = this.cloudObject.objectId.toString();
    return this.$store.getters['link/linkData'](objectId);
  }

  public get username() {
    if (this.cloudObject != null) {
      if (this.isOwner) {
        return 'YOU';
      } else {
        return this.cloudObject?.object?.owner?.username;
      }
    }
    return '';
  }

  get isSharedLinkInfoReady() {
    return this.isShared ? !!this.sharedLink?.accessId : true;
  }

  public async share() {
    await this.$store.dispatch('link/createSharedLinkForCloudObject', this.cloudObject);
    this.copyLink();
  }

  public get isShared() {
    return this.cloudObject?.object?.isShared;
  }

  public openUploadMenu() {
    if (this.uploadPendingItems > 0) {
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.UPLOAD_CONTROL });
    }
  }

  public async actionButton() {
    if (this.isShared) {
      if (this.isOwner) {
        this.copyLink();
      } else {
        this.download();
      }
    } else if (this.$roleAwareness.isUser) {
      await this.share();
    } else if (this.$roleAwareness.isGuestUser) {
      await this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN });
    }
  }

  public download() {
    this.$store.dispatch('cloud/downloadActiveContent', ViewIdentifier.MAIN_VIEW);
  }

  public async updateDescription(description: string) {
    if (this.cloudObject.isFolder && description !== this.description) {
      await this.$store.dispatch('folder/updateDescription', { folderId: this.cloudObject.object.id, description });
    } else if (this.cloudObject.isSelection && description !== this.description) {
      await this.$store.dispatch('selection/updateDescription', {
        selectionId: this.cloudObject.object.id,
        description,
      });
    }
  }

  public get description() {
    return this.cloudObject?.object?.description;
  }

  public get isOwner() {
    return (this.cloudObject?.object?.owner?.id === this.$store.state.user?.user?.id)
      || (this.cloudObject?.object?.owner?.id == null && this.$store.state.user?.user?.id == null);
  }

  public get isOffline() {
    return !this.cloudObject?.object?.isSynced;
  }
}
