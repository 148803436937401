
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { UnitSize } from '~/models/UnitSize';

@Component
export default class ItemSize extends Vue {
  @Prop()
  public itemCount: number;

  @Prop()
  public largestAssetsSize: UnitSize;

  @Prop()
  public rawCount: UnitSize;

  public get itemText() {
    return this.itemCount === 1 ? 'FILE' : 'FILES';
  }
}
