var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.updateValue),expression:"updateValue"}],staticClass:"text-field",class:{'text-field--border': _vm.border, 'text-field--hover': _vm.isEditable && _vm.hover },style:(_vm.inputStyle),on:{"click":function($event){return _vm.clickText($event)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.stopPropagation();$event.preventDefault();return _vm.updateValue.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.stopPropagation();$event.preventDefault();return _vm.updateValue.apply(null, arguments)},function($event){return _vm.checkInput($event)}],"keyup":_vm.checkValidString}},[_c('div',{staticClass:"text-field__wrapper"},[_c('div',{staticClass:"text-field__value",class:{
        'text-field__value--transitions': _vm.transitions,
        'text-field__value--pointer-cursor': _vm.isEditable && !_vm.isTyping,
        'text-field__value--text-cursor': _vm.isTyping,
        'text-field__value--invalid': _vm.isInvalid,
        'text-field__value--ellipsis': _vm.isEllipsisInternal,
        'text-field__value--single-line': _vm.isSingleLine,
      },attrs:{"id":_vm.contentId,"contenteditable":_vm.isEditable,"spellcheck":"false"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }