
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class ReviewStars extends Vue {
  @Prop()
  public rating: number;

  @Prop()
  public maxRating: number;

  @Prop({ default: true })
  public hover: boolean;

  private currentStar: number = 0;
  private currentActive: number = 0;
  private currentInactive: number = 0;

  public starKey(type: string, index: number) {
    return `${type}-star-key-${index}`;
  }

  public currentHover(index: number) {
    if (this.hover) {
      this.currentStar = index;
      this.currentActive = 0;
    }
    if (index === 0) {
      this.currentActive = 0;
      this.currentInactive = 0;
    }
  }

  public toggleRating(index: number) {
    if (this.rating === index) {
      this.currentActive = 0;
      this.currentInactive = index;
    } else {
      this.currentActive = index;
      this.currentInactive = 0;
    }
    this.$emit('toggle-rating', index);
  }

  public starCssClasses(i: number) {
    return {
      'star--full': (i <= this.rating && this.currentStar === 0) || i <= this.currentActive,
      'star--hover': i <= this.currentStar && this.currentStar !== 0 && this.currentStar > this.currentActive && this.currentStar > this.currentInactive,
    };
  }
}
