
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import MutantLogo from '~/components/layout/header/MutantLogo.vue';
import MutantBadgeButton from '~/components/ui/MutantBadgeButton.vue';
import MutantCircleButton from '~/components/ui/MutantCircleButton.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ObjectId } from '~/models/ObjectId';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import Selection from '~/models/selection/Selection';
import { SelectionItem } from '~/models/selection/SelectionItem';
import { FolderItem } from '~/models/item/FolderItem';
import { groupItemsByFolder } from '~/models/item/groupItemsByFolder';
import { ContextMenuType } from '~/store/context/state';
import ActionButtonModel from '~/models/ActionButtonModel';
import ActionButton from '~/components/layout/footer/ActionButton.vue';
import ProgressContextMenu from '~/components/dialogs/progress/ProgressContextMenu.vue';
import MutantNewContentButton from '~/components/ui/MutantNewContentButton.vue';
import ActionButtonFooter from '~/components/layout/footer/ActionButtonFooter.vue';
import ContextActionButton from '~/components/layout/footer/ContextActionButton.vue';
import { UploadProcessStatus } from '~/store/file/state';
import ViewNavigation from '~/components/window/view/footer/ViewNavigation.vue';
import DownloadButton from '~/components/layout/footer/DownloadButton.vue';
import SwitchButtonLandingPage from '~/components/homepage/feature-highlights/SwitchButtonLandingPage.vue';
import UploadButton from '~/components/layout/footer/UploadButton.vue';
import OldUploadButton from '~/components/layout/footer/OldUploadButton.vue';

@Component({
  components: {
    SwitchButtonLandingPage,
    DownloadButton,
    ViewNavigation,
    ContextActionButton,
    OldUploadButton,
    UploadButton,
    ActionButtonFooter,
    ProgressContextMenu,
    MutantNewContentButton,
    ActionButton,
    MutantLogo,
    MutantCircleButton,
    MutantBadgeButton,
  },
})
export default class ActionFooter extends Vue {
  public actionButtonsWithIncreasedMarginLeft = ['share-link-button', 'isolate-items', 'copy-link'];

  public mainViewId = ViewIdentifier.MAIN_VIEW;

  public isolateButton: ActionButtonModel = {
    key: 'isolate-items',
    name: () => {
      const constantPart = `isolate ${this.selectedItemsInViewLength}`;
      return `${constantPart} ${(this.notSelectedItemsInViewCount === 1 ? 'item' : 'items')}`;
    },
    action: () => {
      if (this.objectId.isSelectionId) {
        this.$store.dispatch('selection/refineItemsOfSelection', {
          selectionId: this.objectId.toUuid(),
        });
      } else {
        this.$store.dispatch('folder/refineItemOfFolder', { folderId: this.objectId.toUuid() });
      }
    },
    isHighlighted: false,
  };

  public deleteItemsButton: ActionButtonModel = {
    key: 'delete-items',
    name: () => {
      const constantPart = `delete ${this.globalSelectedItemsCount}`;
      return `${constantPart} ${(this.globalSelectedItemsCount === 1 ? 'item' : 'items')}`;
    },
    action: () => {
      const selectionItemsToDelete = this.filterForSelection(Object.values(this.$store.state.selection.selections), Object.keys(this.$store.state.selection.globalSelectedItems));
      for (const [selectionId, selectionItem] of selectionItemsToDelete.entries()) {
        this.$store.dispatch('selection/removeItemsFromSelection', { items: selectionItem, selectionId });
      }
      const folderItemsToDelete: FolderItem[] = this.globalSelectedItems.filter(i => (i as FolderItem).folderId != null) as FolderItem[];
      const groupedItems = groupItemsByFolder(folderItemsToDelete);
      for (const items of groupedItems) {
        this.$store.dispatch('folder/removeItems', { folderId: (items[0] as FolderItem).folderId, items });
      }
    },
    isHighlighted: false,
  };

  public downloadAllButton: ActionButtonModel = {
    key: 'download-all',
    name: () => 'download all',
    action: () => { },
    isHighlighted: true,
  };

  public upgradeAccountButton: ActionButtonModel = {
    key: 'upgrade-account',
    name: () => 'UPGRADE',
    action: () => this.$store.dispatch('context/toggleMenu', { type: ContextMenuType.ACCOUNT_UPGRADE }),
    isHighlighted: false,
  };

  public get showLandingPageSwitchButton(): boolean {
    return this.isHomepageView && this.$store.state.homepage.showSwitchButtonInFooter;
  }

  public toggleSwitchButton(value: string) {
    this.scrollUp();
    this.$store.commit('homepage/toggleActiveEntry', value);
  }

  public scrollUp() {
    document.getElementById('homepage').scrollTo({
      top: 0,
    });
  }

  public signIn() {
    if (this.$store.getters['context/menu'](ContextMenuType.LOGIN)?.data?.showLogin) {
      this.$store.dispatch('context/updateMenuData', { type: ContextMenuType.LOGIN, isOpen: true, data: { showLogin: false } });
    } else {
      this.$store.dispatch('context/toggleMenu', { type: ContextMenuType.LOGIN, data: { showLogin: false } });
    }
  }

  private filterForSelection(selections: Selection[], itemIds: string[]) {
    const map = new Map<string, SelectionItem[]>();
    selections.forEach(s => {
      if (s.items != null && s.items.length > 0) {
        map.set(s.id, s.items.filter(i => itemIds.find(itemId => itemId === i.id)));
      }
    });
    return map;
  }

  public get isOwnerOfMainViewContent(): boolean {
    return this.$store.getters['cloud/isOwnerOfView'](ViewIdentifier.MAIN_VIEW);
  }

  public get disableUploadButton(): boolean {
    return this.isMatchAndUploadOpen || this.$store.state.file.matchingProcess != null;
  }

  public get disableMatchAndUploadButton(): boolean {
    return this.isUploadControlOpen || this.$store.state.file.uploadProcess?.status === UploadProcessStatus.INDEXING || this.viewIsEmpty;
  }

  public get viewIsEmpty(): boolean {
    return this.$store.getters['cloud/viewIsEmpty'](ViewIdentifier.MAIN_VIEW);
  }

  private get selectedItemsInView(): ItemWithPosition[] {
    return this.viewItems.filter(i => this.globalSelectedItems.find(gi => gi.id === i.id));
  }

  public get showCaptions(): boolean {
    return this.$store.state.showCaptions;
  }

  public toggleShowCaptions(): void {
    this.$store.commit('toggleShowCaptions');
  }

  public toggleNavigationMode(): void {
    this.$store.commit('cloud/toggleNavigationMode', ViewIdentifier.NAVIGATION_VIEW);
  }

  private get globalSelectedItemsCount(): number {
    return this.globalSelectedItems?.length;
  }

  private get globalSelectedItems(): ItemWithPosition[] {
    return this.$store.getters['selection/globalSelectionItems'];
  }

  public get notSelectedItemsInView(): ItemWithPosition[] {
    return this.viewItems.filter(i => !this.globalSelectedItems.find(item => item.id === i.id));
  }

  public get selectedItemsInViewLength(): number {
    return this.selectedItemsInView?.length;
  }

  public get notSelectedItemsInViewCount(): number {
    return this.notSelectedItemsInView?.length;
  }

  private get view(): MutantContentView {
    return this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW);
  }

  private get viewItems(): ItemWithPosition[] {
    return this.$store.getters['cloud/viewItemsMap'][ViewIdentifier.MAIN_VIEW].items;
  }

  public magnify() {
    this.$store.dispatch('magnify/toggle');
  }

  public copyItemsToNewFolder() {
    this.$store.dispatch('folder/copyItemsToNewFolder', {
      items: [...new Set(this.globalSelectedItems.map(i => i.item?.id))],
    });
  }

  private get objectId(): ObjectId {
    return this.view.objectIds[0];
  }

  public get actionButtons() {
    let showIsolate = false;
    const result: ActionButtonModel[] = [];
    if (this.$store.getters['cloud/viewHasItems'](ViewIdentifier.MAIN_VIEW)) {
      result.push(this.downloadAllButton);
    }
    if (this.hasSelection) {
      if (this.showIsolate) {
        result.push(this.isolateButton);
        showIsolate = true;
      }
      if (!this.$roleAwareness.isGuestUser) {
        result.push(this.deleteItemsButton);
        if (!showIsolate) {
          if (!this.actionButtonsWithIncreasedMarginLeft.includes(this.deleteItemsButton.key)) {
            this.actionButtonsWithIncreasedMarginLeft.push(this.deleteItemsButton.key);
          }
        } else {
          this.actionButtonsWithIncreasedMarginLeft = this.actionButtonsWithIncreasedMarginLeft.filter(button => button !== this.deleteItemsButton.key);
        }
      }
    }
    return result;
  }

  public createNewFolder() {
    this.$store.dispatch('cloud/resetReviewFilter', { windowId: ViewIdentifier.MAIN_VIEW });
    this.$store.dispatch('folder/createFolder', {
      isPublic: false,
      sync: true,
      windowId: ViewIdentifier.MAIN_VIEW,
    });
  }

  public openSharedLinkContextMenu() {
    if (this.sharedLinkContextMenuOpen) {
      this.$store.dispatch('context/closeMenu', ContextMenuType.SHARED_LINK_RECEIVER);
    } else {
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.SHARED_LINK_RECEIVER });
    }
  }

  public get sharedLinkContextMenuOpen() {
    return this.$store.getters['context/isOpen'](ContextMenuType.SHARED_LINK_RECEIVER);
  }

  private get showIsolate() {
    return this.selectedItemsInViewLength !== this.viewItems.length && this.selectedItemsInViewLength > 0 && !this.$roleAwareness.isGuestUser;
  }

  public get isHomepageView(): boolean {
    return this.$routeAwareness.isHomepageView;
  }

  public get showSharedLink(): boolean {
    return this.$store.getters.isSharedLink;
  }

  public get isUploadControlOpen(): boolean {
    return this.$store.getters['context/isOpen'](ContextMenuType.UPLOAD_CONTROL);
  }

  public get isMatchAndUploadOpen(): boolean {
    return this.$store.getters['context/isOpen'](ContextMenuType.MATCH_AND_UPLOAD_CONTROL);
  }

  public get hasSelection(): boolean {
    return this.$store.state.selection.globalSelectedItemsOrdered.length > 0;
  }

  @Watch('actionButtons')
  private onActionButtonsChanged() {
    setTimeout(() => {
      document.getElementById('layout-grid').style.setProperty('--download-expand-button', (document.getElementById('action-footer-download-button')?.getBoundingClientRect()?.x ?? 0) + 'px');
    }, 500);
  }
}
