

import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({})
export default class IlluminationControl extends Vue {
  @Prop()
  public illuminationGrade: number;

  @Prop()
  public maxIllumination: number;

  @Prop({ default: false })
  public disabled: boolean;

  private currentGrade: number = 0;

  public isHover = false;

  public gradeKey(type: string, index: number) {
    return `${type}-grade-key-${index}`;
  }

  public selectGrade() {
    if (this.currentGrade === this.maxIllumination) {
      this.currentGrade = 0;
    } else {
      this.currentGrade += 1;
    }
    this.$emit('select-grade', this.currentGrade);
  }

  public get icon(): string {
    if (this.disabled) {
      return 'icon-illumination-disabled mutant-icon--no-action';
    } else if (this.isHover) {
      return 'icon-illumination-highlighted';
    } else {
      return 'icon-illumination';
    }
  }

  public gradeCssClasses(i: number) {
    return {
      'grade--selected': (i <= this.illuminationGrade || i <= this.currentGrade),
      'grade--disabled': this.disabled,
    };
  }
}
