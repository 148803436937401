
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ReviewStars from '~/components/ui/ReviewStars.vue';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';

@Component({
  components: { ReviewStars },
})
export default class ReviewInfo extends Vue {
  @Prop()
  public item: ItemWithPosition;

  public get rating() {
    return this.item?.rating;
  }

  public toggleRating(rating: number) {
    const ratingToAssign = this.rating === rating ? 0 : rating;
    this.$store.dispatch('cloud/rateItemsByItemId', { itemId: this.item?.id, rating: ratingToAssign });
  }
}
