
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import Column from '../../ui/Column.vue';
import MutantContextMenu from '../../ui/MutantContextMenu.vue';
import Row from '../../ui/Row.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import { ContextMenuType } from '~/store/context/state';
import ContextMenuButton from '~/components/ui/buttons/ContextMenuButton.vue';
import InfoButton from '~/components/ui/buttons/InfoButton.vue';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { MatchItems, MatchUploadData, UploadMatchedItems } from '~/store/file/actions';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import MutantSlider from '~/components/ui/MutantSlider.vue';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import ProgressCircle from '~/components/ui/progress/ProgressCircle.vue';
import { ViewProgress } from '~/store/state';
import CancelButton from '~/components/dialogs/upload/CancelButton.vue';
import SharedLinkProgress from '~/components/ui/progress/SharedLinkProgress.vue';
import { UploadProcessStatus } from '~/store/file/state';
import { MutantContentView } from '~/models/views/MutantContentView';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import { TransferOption } from '~/models/TransferOption';
import { PipelineProgress } from '~/store/getters';
import MatchAndUploadContextMenu from '~/components/dialogs/upload/MatchAndUploadContextMenu.vue';
import InfoBadge from '~/components/dialogs/download/InfoBadge.vue';
import CheckboxButtonBadge from '~/components/ui/buttons/CheckboxButtonBadge.vue';
import CheckboxButton from '~/components/ui/buttons/CheckboxButton.vue';
import ThumbnailSlider from '~/components/ui/ThumbnailSlider.vue';
import CheckmarkBox from '~/components/ui/buttons/CheckmarkBox.vue';
import { ItemsMatchedDetails } from '~/store/file/getters';

@Component({
  components: {
    CheckmarkBox,
    CancelButton,
    ThumbnailSlider,
    CheckboxButton,
    CheckboxButtonBadge,
    InfoBadge,
    MatchAndUploadContextMenu,
    SharedLinkProgress,
    ProgressCircle,
    MutantSlider,
    InfoButton,
    ContextMenuHeaderFlashy,
    Column,
    MutantContextMenu,
    ContextMenuButton,
    Row,
  },
})
export default class MatchAndUploadControlMenu extends Vue {
  private uploadOptions: PipelineCommandType[] = [PipelineCommandType.UPLOAD_ORIGINALS];
  public isAddPicturesMenuOpen = false;
  public replaceExisingFiles = true;
  public isDragOver = false;
  public isHoverReplaceFilesCheckbox = false;
  public allResolutions = [768, 1024, 1280, 1536, 1792, 2048, 2304, 2560];

  public get selectedThumbnailWidth() {
    return this.$store.state.file.thumbnailQualityConfig.width;
  }

  public async chooseThumbnailWidth(value: number) {
    await this.$store.dispatch('file/setThumbnailQualityConfig', { width: value });
  }

  public get isUser(): boolean {
    return this.$roleAwareness.isUser;
  }

  public get showUploadProgress(): boolean {
    return this.isUploading;
  }

  public get isUploadDisabled(): boolean {
    return !this.isUser || this.uploadOptions.length === 0 || this.itemsToMatchCount === 0;
  }

  public get menuTitle() {
    if (this.showUploadProgress) {
      if (this.progressSteps[this.progressSteps.length - 1]?.percentage === 100) {
        return 'UPLOADED';
      }
      return 'UPLOADING...';
    }
    return 'MATCH VERSIONS';
  }

  public get isUploading(): boolean {
    return this.$store.state.file.uploadProcess?.status === UploadProcessStatus.UPLOADING;
  }

  public get aggregatedFilterOptions() {
    return this.$store.getters['cloud/aggregatedFilterOptions'];
  }

  @Watch('viewObjectId')
  public watchViewObjectId() {
    this.matchFilesToItems();
  }

  @Watch('aggregatedFilterOptions')
  public watchAggregatedFilterOptions() {
    this.matchFilesToItems();
  }

  public toggleReplaceExisingFilesAndTriggerMatching() {
    this.replaceExisingFiles = !this.replaceExisingFiles;
    this.matchFilesToItems();
  }

  public get isUploadFinished(): boolean {
    return this.$store.getters.pipelineFinished(this.viewObjectId);
  }

  public get startPosition() {
    return { top: window.innerHeight - 700, left: 100 };
  }

  public get itemsInMatchingProcess() {
    return this.$store.state.file.matchingProcess?.matchedItems || [];
  }

  public get itemsToMatchCount(): number {
    return this.itemsInMatchingProcess.length || 0;
  }

  public get itemsMatchedDetails(): ItemsMatchedDetails {
    return this.$store.getters['file/itemsMatchedDetails'];
  }

  public get hasRawFilesToUpload(): boolean {
    return this.$store.state.file.matchingProcess?.hasRawFiles;
  }

  public get uploadProgress(): ViewProgress {
    const activeProgress = this.progressSteps.find(step => step.percentage > 0 && step.percentage < 100) || this.progressSteps[this.progressSteps.length - 1];
    return this.progressSteps?.length
      ? {
          progressSteps: [activeProgress],
          totalSteps: 1,
        }
      : null;
  }

  public get progressSteps(): PipelineProgress[] {
    return this.$store.getters.pipelineProgress(this.viewObjectId) || [];
  }

  public get progressPercentage(): number {
    return this.uploadProgress?.progressSteps[0]?.percentage;
  }

  public get view(): MutantContentView {
    return this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW);
  }

  public get viewObjectId(): string {
    return this.view.objectIds[0]?.toUuid();
  }

  public get isUploadOriginalsActive() {
    return this.uploadOptions.includes(PipelineCommandType.UPLOAD_ORIGINALS);
  }

  public get isUploadRawsActive() {
    return this.uploadOptions.includes(PipelineCommandType.UPLOAD_RAWS);
  }

  public get isUploadFastContactSheetActive() {
    return this.uploadOptions.includes(PipelineCommandType.UPLOAD_THUMBNAILS);
  }

  public toggleAddPicturesMenu() {
    this.isAddPicturesMenuOpen = !this.isAddPicturesMenuOpen;
  }

  public closeAddPicturesMenu() {
    this.isAddPicturesMenuOpen = false;
  }

  public get pipelineOptions() {
    return !this.hasRawFilesToUpload && this.uploadOptions.includes(PipelineCommandType.UPLOAD_RAWS)
      ? this.uploadOptions.filter(option => option !== PipelineCommandType.UPLOAD_RAWS)
      : this.uploadOptions;
  }

  public matchFilesToItems() {
    this.$store.dispatch<ActionPayload<MatchItems>>({
      type: 'file/matchFilesToItems',
      payload: {
        replaceExistingFiles: this.replaceExisingFiles,
        pipelineId: this.viewObjectId,
        pipelineOptions: [...this.pipelineOptions],
      },
    });
  }

  public startMatchAndUpload() {
    if (!this.isUploadDisabled) {
      this.$store.dispatch<ActionPayload<UploadMatchedItems>>({
        type: 'file/uploadMatchedItems',
        payload: {
          folderId: this.viewObjectId,
          pipelineId: this.viewObjectId,
        },
      });
    }
  }

  public async shareContent() {
    if (this.isShared) {
      this.copyLink();
    } else {
      await this.share();
      this.copyLink();
    }
  }

  private copyLink() {
    this.$store.dispatch('cloud/copySharedLink', this.view.objectIds[0]);
  }

  private async share() {
    await this.$store.dispatch('link/createSharedLinkForCloudObject', this.currentCloudObject);
  }

  public get isShared() {
    return this.currentCloudObject?.object?.isShared;
  }

  public get isOffline() {
    return !this.currentCloudObject?.object?.isSynced;
  }

  public get currentCloudObject(): CloudObject<Folder | Selection> {
    return this.$store.getters['cloud/currentCloudObject'];
  }

  public cancelUpload() {
    this.$store.dispatch('file/dismissMatchAndUpload');
    this.close();
  }

  public toggleUploadOriginals() {
    if (this.isUploadOriginalsActive) {
      this.uploadOptions = this.uploadOptions.filter(option => option !== PipelineCommandType.UPLOAD_ORIGINALS);
    } else {
      this.uploadOptions = [...this.uploadOptions, PipelineCommandType.UPLOAD_ORIGINALS];
    }
    this.matchFilesToItems();
  }

  public toggleUploadRaws() {
    if (this.hasRawFilesToUpload) {
      if (this.isUploadRawsActive) {
        this.uploadOptions = this.uploadOptions.filter(option => option !== PipelineCommandType.UPLOAD_RAWS);
      } else {
        this.uploadOptions = [...this.uploadOptions, PipelineCommandType.UPLOAD_RAWS];
      }
      this.matchFilesToItems();
    }
  }

  public toggleUploadFastContactSheet() {
    if (this.isUploadFastContactSheetActive) {
      this.uploadOptions = this.uploadOptions.filter(option => option !== PipelineCommandType.UPLOAD_THUMBNAILS);
    } else {
      this.uploadOptions = [...this.uploadOptions, PipelineCommandType.UPLOAD_THUMBNAILS];
    }
    this.matchFilesToItems();
  }

  public browse() {
    this.$store.dispatch('setBrowseMode');
    this.close();
  }

  private endFinishedMatchingProcess() {
    if (this.$store.state.file.uploadProcess?.status === UploadProcessStatus.UPLOADING && this.progressPercentage === 100) {
      this.uploadOptions = [];
      this.$store.dispatch('file/endUploadProcess');
      this.$store.dispatch('file/endMatchingProcess');
    }
  }

  public close() {
    this.endFinishedMatchingProcess();
    this.$store.dispatch('context/closeMenu', ContextMenuType.MATCH_AND_UPLOAD_CONTROL);
  }

  public triggerFileUpload() {
    const fileInput = document.getElementById('file-input-remote-control');
    if (fileInput) {
      fileInput.click();
    }
  }

  public mounted() {
    setTimeout(() => {
      this.matchFilesToItems();
    });
  }

  public triggerFolderUpload() {
    const folderInput = document.getElementById('folder-input-remote-control');
    if (folderInput) {
      folderInput.click();
    }
  }

  cancelDragOver(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const { top, left, width, height } = this.$el.getBoundingClientRect();
    const leaveEventOffset = 0;
    if (event.clientX <= left + leaveEventOffset || event.clientX >= left + width - leaveEventOffset || event.clientY <= top + leaveEventOffset || event.clientY >= top + height - leaveEventOffset) {
      this.isDragOver = false;
    }
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.dataTransfer.types.some(t => t === TransferOption.MUTANT_TRANSFER_TYPE.toLowerCase() || t === 'Files')) {
      this.isDragOver = true;
    }
  }

  private async addFilesToMatchingProcess(event: InputEvent | DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
    this.closeAddPicturesMenu();
    this.endFinishedMatchingProcess();
    const externalContentEvent = new AddExternalContentEvent(event, { order: this.$store.getters['cloud/currentViewItems'](ViewIdentifier.MAIN_VIEW).length });
    await this.$store.dispatch<ActionPayload<MatchUploadData>>({
      type: 'file/addFilesToMatchingProcess',
      payload: {
        windowId: ViewIdentifier.MAIN_VIEW,
        event: externalContentEvent,
      },
    });
  }
}
