var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"badge"},[_c('div',{class:{
      'badge__yellow': _vm.infoBadgeData.color === 'yellow',
      'badge__orange': _vm.infoBadgeData.color === 'orange',
      'badge__green': _vm.infoBadgeData.color === 'green',
      'badge__grey': _vm.infoBadgeData.color === 'grey',
      'badge--highlighted': _vm.highlighted
    }},[_vm._v("\n    "+_vm._s(_vm.infoBadgeData.count)+"\n  ")]),_vm._v(" "),(_vm.infoBadgeData.title)?_c('div',{staticClass:"badge__title"},[_vm._v("\n    "+_vm._s(_vm.infoBadgeData.title)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.showSize && _vm.infoBadgeData.size)?_c('div',{staticClass:"badge__size"},[_vm._v("\n    "+_vm._s(_vm.infoBadgeData.size)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }