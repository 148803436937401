
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Column from '~/components/ui/Column.vue';
import Row from '~/components/ui/Row.vue';

@Component({
  components: {
    Column,
    Row,
  },
})
export default class ContextMenuHeaderFlashy extends Vue {
    @Prop(Boolean)
      isFloating: boolean;
}
