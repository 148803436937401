
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { ViewProgress } from '~/store/state';

@Component
export default class ProgressCircle extends Vue {
  @Prop({ default: 64 })
    radius: number;

  @Prop({ default: 5 })
    stroke: number;

  @Prop()
    progress: ViewProgress;

  @Prop(Boolean)
    isSuccess: boolean;

  public circles = [];
  private id = uuid();

  constructor() {
    super();
  }

  public get adjustedRadius() {
    return this.progress?.totalSteps != null && this.progress.totalSteps > 0 ? this.radius + this.stroke * this.progress.totalSteps : this.radius;
  }

  public circleStrokeDashOffset(idx): number {
    return this.calculateStrokeDashoffset(this.circles[idx].circumference, this.progress.progressSteps[idx].percentage);
  }

  private buildCircles() {
    return this.progress.progressSteps.map((_step, idx) => {
      const radius = this.calculateRadius(idx);
      const normalizedRadius = this.calculateNormalizedRadius(radius);
      const circumference = this.calculateCircumference(normalizedRadius);
      const cx = this.adjustedRadius;
      const cy = this.adjustedRadius;
      return {
        id: `circle-${this.id}-${idx}`,
        idx,
        color: this.getColor(idx),
        stroke: this.stroke,
        radius,
        normalizedRadius,
        circumference,
        cx,
        cy,
      };
    });
  }

  public getColor(idx) {
    if (this.progress.totalSteps === 1) {
      return '#f68609';
    }
    switch (idx) {
      case 0: return '#00ff00';
      case 1: return '#fffb00';
      case 2: return '#f68609';
      case 3: return '#ff001c';
      default: return '#f68609';
    }
  }

  public calculateRadius(idx: number) {
    return this.adjustedRadius - (this.stroke * Math.abs(idx - this.progress.totalSteps));
  }

  public calculateNormalizedRadius(radius) {
    return radius - (this.stroke * 2);
  }

  public calculateCircumference(normalizedRadius: number) {
    return normalizedRadius * 2 * Math.PI;
  }

  public calculateStrokeDashoffset(circumference: number, progress: number) {
    return circumference - progress / 100 * circumference;
  }

  @Watch('progress', { immediate: true })
  watchProgress(newValue: ViewProgress, oldValue: ViewProgress) {
    if (newValue?.totalSteps !== oldValue?.totalSteps) {
      this.circles = this.buildCircles();
    }
  }
}
