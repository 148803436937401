
import { Component, Vue } from 'nuxt-property-decorator';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import TextField from '~/components/ui/TextField.vue';
import ReviewStars from '~/components/ui/ReviewStars.vue';

@Component({
  components: { ReviewStars, TextField },
})
export default class ItemList extends Vue {
  public lastClicked = null;
  public editId: string = null;

  public get items(): ItemWithPosition[] {
    return this.$store.getters['cloud/viewItemsMap'][this.activeView].items;
  }

  public showAllText(editId: string) {
    return this.editId === editId;
  }

  public unsetEditOfCurrentItem() {
    const elementById = document.getElementById('item-list-item-name-' + this.editId);
    if (elementById != null) {
      elementById.blur();
      elementById.scrollLeft = 0;
      window.getSelection().removeAllRanges();
      this.$store.dispatch({ type: 'setActiveElementId', payload: '' });
    }
    this.editId = null;
  }

  toggleEditId(event: Event, editId: string) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.editId !== editId) {
      this.editId = editId;
      if (this.editId) {
        setTimeout(() => {
          ItemList.selectTextWithoutFileFormatEnding(document.getElementById('item-list-item-name-' + editId));
        }, 200);
      }
    } else {
      this.unsetEditOfCurrentItem();
    }
  }

  static selectTextWithoutFileFormatEnding(element: HTMLElement) {
    element.focus();
    const textElement = element.firstChild;
    if (textElement?.textContent?.length !== 0) {
      const range = document.createRange();
      const selection = window.getSelection();
      const offset = this.textLengthWithoutFileType(textElement);
      range.setStart(textElement, 0);
      range.setEnd(textElement, offset);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  private static textLengthWithoutFileType(textElement: ChildNode) {
    const pointSplitArr = textElement.textContent.split('.');
    return pointSplitArr.length > 2
      ? textElement.textContent.length - (pointSplitArr[pointSplitArr.length - 1].length + 1)
      : pointSplitArr[0].length;
  }

  async updateName(newName: string, itemId: string, oldName: string) {
    if (oldName !== newName) {
      await this.$store.dispatch('cloud/updateItemName', { itemId, name: newName });
    }
    this.unsetEditOfCurrentItem();
  }

  public get activeView(): ViewIdentifier {
    return this.$store.state.cloud.activeWindowId;
  }

  async chooseItem(item: ItemWithPosition, index) {
    this.lastClicked = item.id;
    await this.$store.dispatch('cloud/highlightItem', { originView: this.activeView, item, targetView: this.activeView, scroll: true });
    this.$store.commit('cloud/setCenteredItemInView', { position: index, view: ViewIdentifier.MAIN_VIEW });
  }

  public get isOwner() {
    return this.$store.getters['cloud/isOwnerOfView'](ViewIdentifier.MAIN_VIEW);
  }

  dragItem(event: DragEvent, item: ItemWithPosition) {
    event.dataTransfer.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.ITEM);
    event.dataTransfer.setData(TransferOption.MUTANT_ID, item.id);
  }
}
