
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import MutantContextMenuHeader from '~/components/ui/MutantContextMenuHeader.vue';
import MutantTagButton from '~/components/ui/MutantTagButton.vue';
import { RecentObject } from '~/models/RecentObject';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';

@Component({
  components: { MutantContextMenuHeader, MutantTagButton },
})
export default class RecentContentSection extends Vue {
  public lastClicked = null;
  public timeoutHandler = null;

  @Prop()
  viewId: ViewIdentifier;

  @Prop()
  maxWidth: number;

  public get style() {
    const obj: any = {};
    if (this.maxWidth) {
      obj.width = `${this.maxWidth}px`;
    }
    return obj;
  }

  public get recentContent(): RecentObject[] {
    return this.$store.getters.recentObjectActivity
      .slice(0, 10)
      .sort((a: RecentObject, b: RecentObject) => a.name > b.name ? 1 : -1);
  }

  public isActive({ objectId }: RecentObject): boolean {
    return this.lastClicked === objectId.toString() || this.$store.getters.isActiveObject(objectId);
  }

  public async chooseContent({ objectId }: RecentObject) {
    clearTimeout(this.timeoutHandler);
    this.lastClicked = objectId.toString();
    this.timeoutHandler = setTimeout(() => { this.lastClicked = null; }, 2000);
    await this.$store.dispatch('cloud/addToPane', { windowId: this.viewId, objectIds: [objectId] });
  }
}
