var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"view-options"},[_c('ViewColumns',{class:{ 'hidden': _vm.isHorizontalView }}),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.zoomInTooltip),expression:"zoomInTooltip"}],staticClass:"view-options__icon mutant-icon",class:{ 'view-options__icon--disabled': _vm.isHorizontalView,},on:{"click":function($event){return _vm.zoomIn()}}},[_c('fa',{staticClass:"zoom-icon",attrs:{"icon":['fas', 'plus'],"size":_vm.faIconSize}})],1),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.zoomOutTooltip),expression:"zoomOutTooltip"}],staticClass:"view-options__icon mutant-icon",class:{ 'view-options__icon--disabled': _vm.isHorizontalView},on:{"click":function($event){return _vm.zoomOut()}}},[_c('fa',{staticClass:"zoom-icon",attrs:{"icon":['fas', 'minus'],"size":_vm.faIconSize}})],1),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$store.getters.tooltip({ placement: 'bottom', content: 'toggle immersion view' })),expression:"$store.getters.tooltip({ placement: 'bottom', content: 'toggle immersion view' })"}],staticClass:"view-options__icon mutant-icon icon-immersion",class:{'icon-immersion--active': _vm.$store.state.cloud.showInImmersionMode,
             'icon-immersion--medium': _vm.iconSize === 'medium', 'icon-immersion--large': _vm.iconSize === 'large'},on:{"click":_vm.toggleImmersionMode}}),_vm._v(" "),(_vm.selectionId)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$store.getters.tooltip({ placement: 'bottom', content: 'moodboard view' })),expression:"$store.getters.tooltip({ placement: 'bottom', content: 'moodboard view' })"}],staticClass:"view-options__view-icon",class:{
      'view-options__view-icon--selected': _vm.currentSelectedViewType === _vm.viewType.MOODBOARD,
      'view-options__view-icon--large': _vm.iconSize === 'large'},on:{"click":function($event){return _vm.updateView(_vm.viewType.MOODBOARD)}}},[_c('div',{staticClass:"mutant-icon icon-moodboard",class:{'icon-moodboard--large': _vm.iconSize === 'large', 'icon-moodboard--medium': _vm.iconSize === 'medium'}})]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$store.getters.tooltip({ placement: 'bottom', content: 'contact sheet view' })),expression:"$store.getters.tooltip({ placement: 'bottom', content: 'contact sheet view' })"}],staticClass:"view-options__view-icon",class:{
      'view-options__view-icon--selected': _vm.currentSelectedViewType === _vm.viewType.CONTACT_SHEET,
      'view-options__view-icon--large': _vm.iconSize === 'large'
    },on:{"click":function($event){return _vm.updateView(_vm.viewType.CONTACT_SHEET)}}},[_c('div',{staticClass:"mutant-icon icon-contact-sheet",class:{'icon-contact-sheet--large': _vm.iconSize === 'large', 'icon-contact-sheet--medium': _vm.iconSize === 'medium'}})]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$store.getters.tooltip({ placement: 'bottom', content: 'grid view' })),expression:"$store.getters.tooltip({ placement: 'bottom', content: 'grid view' })"}],staticClass:"view-options__view-icon",class:{
      'view-options__view-icon--selected': _vm.currentSelectedViewType === _vm.viewType.GRID,
      'view-options__view-icon--large': _vm.iconSize === 'large'
    },on:{"click":function($event){return _vm.updateView(_vm.viewType.GRID)}}},[_c('div',{staticClass:"mutant-icon icon-grid",class:{'icon-grid--large': _vm.iconSize === 'large', 'icon-grid--medium': _vm.iconSize === 'medium'}})]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$store.getters.tooltip({ placement: 'bottom', content: 'mosaic view' })),expression:"$store.getters.tooltip({ placement: 'bottom', content: 'mosaic view' })"}],staticClass:"view-options__view-icon",class:{
      'view-options__view-icon--selected': _vm.currentSelectedViewType === _vm.viewType.MOSAIC,
      'view-options__view-icon--large': _vm.iconSize === 'large'
    },on:{"click":function($event){return _vm.updateView(_vm.viewType.MOSAIC)}}},[_c('div',{staticClass:"mutant-icon icon-mosaic",class:{'icon-mosaic--large': _vm.iconSize === 'large', 'icon-mosaic--medium': _vm.iconSize === 'medium'}})]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$store.getters.tooltip({ placement: 'bottom', content: 'horizontal view' })),expression:"$store.getters.tooltip({ placement: 'bottom', content: 'horizontal view' })"}],staticClass:"view-options__view-icon",class:{
      'view-options__view-icon--selected': _vm.isHorizontalView,
      'view-options__view-icon--large': _vm.iconSize === 'large'
    },on:{"click":function($event){return _vm.updateView(_vm.viewType.HORIZONTAL)}}},[_c('div',{staticClass:"mutant-icon icon-horizontal",class:{
        'icon-horizontal--large': _vm.iconSize === 'large',
        'icon-horizontal--medium': _vm.iconSize === 'medium'}})]),_vm._v(" "),(_vm.$store.getters.isReviewMode)?_c('IlluminationControl',{staticClass:"view-options__illumination",attrs:{"disabled":!_vm.isHorizontalView,"max-illumination":3,"illumination-grade":_vm.illuminationGrade},on:{"select-grade":function($event){return _vm.selectIlluminationGrade($event)}}}):_vm._e(),_vm._v(" "),_c('FullscreenControl',{staticClass:"view-options__fullscreen",attrs:{"icon-size":_vm.iconSize}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }