
import { Component, Prop, Vue } from '~/node_modules/nuxt-property-decorator';

@Component
export default class MutantHeader extends Vue {
    @Prop()
    public title!: string;

    @Prop()
    public theme!: string;

    constructor() {
      super();
    }

    public get titleStyle() {
      if (this.theme) {
        if (this.theme === 'dark') {
          return {
            color: '#000',
            opacity: 0.6,
          };
        }
      }
      return {};
    }
}
