
import { Component, Vue } from 'nuxt-property-decorator';
import throttle from 'lodash.throttle';
import WebPromo from '~/components/homepage/feature-highlights/WebPromo.vue';
import { WebConfig } from '~/Config';
import PlayButton from '~/components/homepage/feature-highlights/PlayButton.vue';
import SwitchButtonLandingPage from '~/components/homepage/feature-highlights/SwitchButtonLandingPage.vue';
import ScrollDownButton from '~/components/homepage/feature-highlights/ScrollDownButton.vue';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import DesktopInstructionMenu from '~/components/dialogs/desktop-instruction/DesktopInstructionMenu.vue';
import HomepageDesktopSplit from '~/components/homepage/HomepageDesktopSplit.vue';
import HomepageWebSplit from '~/components/homepage/HomepageWebSplit.vue';
import DesktopPromo from '~/components/homepage/feature-highlights/DesktopPromo.vue';
import ContactInfoMenu from '~/components/dialogs/contact-terms-privacy/ContactInfoMenu.vue';
import PlayButtonIsolated from '~/components/homepage/feature-highlights/PlayButtonIsolated.vue';
import { LandingPageEntry } from '~/models/LandingPageEntry';

@Component({
  components: {
    PlayButtonIsolated,
    ContactInfoMenu,
    DesktopInstructionMenu,
    ScrollDownButton,
    SwitchButtonLandingPage,
    DesktopPromo,
    PlayButton,
    WebPromo,
    HomepageDesktopSplit,
    HomepageWebSplit,
  },
})
export default class Homepage extends Vue {
  public webSplitId = 'homepage-web';
  public desktopSplitId = 'homepage-desktop';
  public isLandScapeMode = false;
  public isVideoPlaying = false;
  public landingPageEntry = LandingPageEntry;

  public isDragOver = false;
  public throttledDragOver = throttle(this.dragover, 100, { leading: true, trailing: false });
  private throttledDragOverTimer = null;
  private uploadHandlerInProgress = false;

  public playTeaser() {
    if (this.activeEntry === LandingPageEntry.DESKTOP) {
      this.playDesktopTeaser();
    } else {
      this.playWebTeaser();
    }
  }

  public get webContentId(): string {
    return this.webSplitId + '-content';
  }

  public get desktopContentId(): string {
    return this.desktopSplitId + '-content';
  }

  public get activeEntry(): LandingPageEntry {
    return this.$store.state.homepage.activeEntry;
  }

  public get isMobileWidth(): boolean {
    return this.$store.getters.isMobileWidth;
  }

  public toggleSwitchButton(value: LandingPageEntry) {
    this.$store.commit('homepage/toggleActiveEntry', value);
  }

  private hasExternalData(dragEvent: DragEvent) {
    return dragEvent.dataTransfer?.items?.length > 0;
  }

  public async handleDrop(dragEvent: DragEvent) {
    if (this.hasExternalData(dragEvent)) {
      dragEvent.preventDefault();
      if (this.$store.state.menuOpen) {
        await this.$store.dispatch('toggleMenu', null, { root: true });
      }
      await this.handleFiles(dragEvent);
    }
  }

  public async handleFiles(event: DragEvent | InputEvent) {
    if (!this.uploadHandlerInProgress) {
      this.uploadHandlerInProgress = true;
      await this.$store.dispatch('folder/uploadToNewFolder', new AddExternalContentEvent(event, { order: 0 }));
      setTimeout(() => {
        this.uploadHandlerInProgress = false;
      }, 1000);
    }
  }

  private dragover(dragEvent: DragEvent) {
    if (this.hasExternalData(dragEvent)) {
      this.isDragOver = true;
      clearTimeout(this.throttledDragOverTimer);
      this.throttledDragOverTimer = setTimeout(() => {
        this.isDragOver = false;
      }, 250);
    }
  }

  public scrollToWeb() {
    document.getElementById('homepage').scrollTo({
      top: document.getElementById(this.webContentId).offsetTop - (window.innerHeight * 0.65),
      behavior: 'smooth',
    });
  }

  public scrollToDesktop() {
    document.getElementById('homepage').scrollTo({
      top: document.getElementById(this.desktopContentId).offsetTop - (window.innerHeight * 0.65),
      behavior: 'smooth',
    });
  }

  playWebTeaser() {
    this.$store.commit('homepage/setActiveEntry', LandingPageEntry.WEB);
    (document.getElementById('mutant-video-desktop-teaser') as HTMLVideoElement)?.pause();
    (document.getElementById('mutant-video-web-teaser') as HTMLVideoElement)?.play();
  }

  playDesktopTeaser() {
    this.$store.commit('homepage/setActiveEntry', LandingPageEntry.DESKTOP);
    (document.getElementById('mutant-video-web-teaser') as HTMLVideoElement)?.pause();
    (document.getElementById('mutant-video-desktop-teaser') as HTMLVideoElement)?.play();
  }

  pausedVideo() {
    this.isVideoPlaying = false;
  }

  playVideo() {
    this.isVideoPlaying = true;
    this.$analytics.trackPlayVideo('teaser');
  }

  endVideo() {
    this.isVideoPlaying = false;
    this.$analytics.trackEndVideo('teaser');
  }

  progressVideo(event: Event) {
    const targetElement: HTMLVideoElement = event.target as HTMLVideoElement;
    const progress = Math.round((targetElement.currentTime / targetElement.duration) * 100);
    if (!this.progressEmitted && progress === 50) {
      this.$analytics.trackProgressVideo('teaser', progress);
      this.progressEmitted = true;
    }
  }

  public get mutantVideo(): string {
    const video = this.isMobileWidth ? '/videos/0_web_teaser_720.mp4' : '/videos/0_web_teaser_1080.mp4';
    return WebConfig.isProductionEnvironment() ? `${WebConfig.URL}/${video}` : video;
  }

  public get mutantDesktopPromoVideo(): string {
    const video = '/videos/0_promo_1080_desktop.mp4';
    return WebConfig.isProductionEnvironment() ? `${WebConfig.URL}${video}` : video;
  }

  private progressEmitted = false;

  constructor() {
    super();
    screen.orientation?.addEventListener('change', this.handleOrientationChange, false);
  }

  beforeDestroy() {
    screen.orientation?.removeEventListener('change', this.handleOrientationChange, false);
  }

  handleOrientationChange() {
    this.isLandScapeMode = screen.orientation?.type === 'landscape-primary';
  }

  public setScrollDirection() {
    const homepageElement = document.getElementById('homepage');
    this.$store.commit('homepage/setShowSwitchButtonInFooter', homepageElement?.scrollTop > 0);
  }
}
