
import { Component, Vue } from 'nuxt-property-decorator';
import { NotificationId } from '~/models/Notification';

@Component
export default class ReloadAlert extends Vue {
  mounted() {
    window.addEventListener('beforeunload', this.showReloadAlert);
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.showReloadAlert);
  }

  showReloadAlert(event) {
    const uploadProcess = this.$store.state.file.uploadProcess;
    const uploadNotFinished = uploadProcess?.objectId != null && !this.$store.getters.pipelineFinished(uploadProcess.objectId.toUuid());
    const matchingProcess = this.$store.state.file.matchingProcess;
    // If general upload process is active
    if (uploadNotFinished && matchingProcess == null) {
      // some browsers may show this message, others display a generic text, which is also fine;
      event.returnValue = 'The upload process is still running, reloading the page will terminate that process. Are you sure you want to leave?';
    } else if (matchingProcess != null) {
      // If matching process is not finished, either the upload has not started or upload is active
      if (uploadNotFinished || uploadProcess == null) {
        event.returnValue = 'The matching process is still running, reloading the page will terminate that process. Are you sure you want to leave?';
      }
    } else if (this.$store.state.notifications[NotificationId.SERVER_UNREACHABLE]) {
      event.returnValue = 'The server is currently unreachable, reloading the page is resulting in loosing cached data. Are you sure you want to leave ?';
    }
  }
}
