
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { IconDropDownModel } from '~/models/IconDropDownModel';

@Component
export default class IconDropDown extends Vue {
  @Prop()
  public isCollapsed: boolean;

  @Prop(Boolean)
  public collapsesUp: boolean;

  @Prop()
  public tooltipInactiveState: string;

  @Prop()
  public items: IconDropDownModel[];

  constructor() {
    super();
  }

  public tooltip(item: any) {
    return this.tooltipInactiveState != null
      ? this.$store.getters.tooltip({ placement: 'left', content: this.isCollapsed ? item.text : this.tooltipInactiveState })
      : null;
  }

  public get activeItem() {
    return this.items.find(i => i.isActive);
  }

  public get inactiveItems() {
    return [...this.items].filter(i => !i.isActive);
  }

  private collapseViewDropDown(event: any) {
    event?.stopImmediatePropagation();
    this.$emit('toggle-dropdown', event);
  }

  private selectItem(event: any, item: any) {
    event.stopImmediatePropagation();
    this.$emit('select-item', item);
  }
}
