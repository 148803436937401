
import throttle from 'lodash.throttle';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import MutantActiveIcon from '~/components/ui/MutantActiveIcon.vue';
import Selection from '~/models/selection/Selection';
import { ViewType } from '~/models/views/ViewType';
import Folder from '~/models/Folder';
import TextField from '~/components/ui/TextField.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import ViewOptions from '~/components/window/view/header/ViewOptions.vue';
import InfoBadge from '~/components/window/view/header/InfoBadge.vue';
import { InfoBadgeData, OverviewType } from '~/store/cloud/getters';
import FilterButton from '~/components/window/view/header/FilterButton.vue';
import SharedLinkCreation from '~/components/menu/right/tabs/shared-link/SharedLinkCreation.vue';
import { ObjectId } from '~/models/ObjectId';
import { LinkData } from '~/store/link/state';

@Component({
  components: {
    SharedLinkCreation,
    FilterButton,
    InfoBadge,
    ViewOptions,
    TextField,
    MutantActiveIcon,
  },
})
export default class MainViewHeader extends Vue {
  public viewType = ViewType;

  private throttledResizeHandler = null;
  private lastHeightValueEmitted = null;
  private resizeObserver: ResizeObserver = null;
  private headerTitleResizeObserver: ResizeObserver = null;

  @Prop()
  public viewId: string;

  @Prop()
  public title!: string;

  @Prop({ default: 'FOLDER' })
  public type?: string;

  @Prop(Boolean)
  public isFloating: boolean;

  public collapsedInfoButton = false;

  mounted() {
    if (this.viewId === ViewIdentifier.MAIN_VIEW) {
      this.throttledResizeHandler = throttle(this.handleResize, 100, { leading: true });
      this.registerResizeObserver();
      this.handleResize();
    }
  }

  beforeDestroy() {
    this.headerTitleResizeObserver.disconnect();
    this.resizeObserver.disconnect();
  }

  private adjustHeaderTitleSpacing() {
    if (this.isMobile) {
      if (this.$store.state.showFolderTitle) {
        (document.getElementsByClassName('shared-link-info')[0] as HTMLElement).style.marginLeft = '10px';
        const headerHeight = document.getElementsByClassName('main-view-header')[0].clientHeight;
        document.documentElement.style.setProperty('--folder-header-height', headerHeight + 30 + 'px');
      }
    } else {
      const titleElement: HTMLElement = document.getElementsByClassName('mutant-header')[0] as HTMLElement;
      if (titleElement) {
        const { clientHeight: titleHeight, clientWidth: titleWidth } = titleElement;
        const headerElement = document.getElementById(this.headerId);
        let offsetLeft = 10 + titleWidth;
        if (titleHeight + 20 > headerElement.clientHeight) {
          titleElement.style.background = '#000';
          titleElement.style.padding = '0 0 10px 10px';
          offsetLeft += 20;
        }
        (document.getElementsByClassName('shared-link-info')[0] as HTMLElement).style.marginLeft = offsetLeft + 'px';
      }
    }
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isScrapbook() {
    return this.$store.getters['folder/scrapbookId'] === this.objectId?.toUuid();
  }

  public get marginViewOptions(): { 'padding-right': string } | {} {
    if (!this.$store.state.cloudMenuRightOpen) {
      return { 'padding-right': this.$store.state.cloudMenuRightWidth + 'px' };
    }
    return {};
  }

  public get itemCountOverviewList(): InfoBadgeData[] {
    return this.$store.getters['cloud/itemCountOverviewList']([OverviewType.UPLOAD_PENDING, OverviewType.SELECTED]);
  }

  public get isActiveWindow() {
    return this.viewId === this.$store.state.cloud.activeWindowId;
  }

  public get headerId() {
    return `${this.viewId}-header`;
  }

  public get headerTitleId() {
    return `${this.viewId}-header-title`;
  }

  public get isTitleEditable(): boolean {
    return this.viewIsNotEmpty && this.isViewOwner && this.showTitle !== 'scrapbook';
  }

  public get activeViewType() {
    return this.$store.getters['cloud/window'](this.viewId).viewOptions.activeViewType;
  }

  public get isViewOwner(): boolean {
    const ownerId = this.$roleAwareness.currentUser?.id;
    return this.$store.getters['cloud/view'](this.viewId).owners?.includes(ownerId);
  }

  private registerResizeObserver() {
    if (ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.throttledResizeHandler);
      this.resizeObserver.observe(document.getElementById(this.headerId));

      this.headerTitleResizeObserver = new ResizeObserver(this.adjustHeaderTitleSpacing);
      this.headerTitleResizeObserver.observe(document.getElementsByClassName('mutant-header')[0]);
    }
  }

  private handleResize() {
    const headerHeight = document.getElementById(this.headerId).clientHeight;
    if (this.lastHeightValueEmitted !== headerHeight) {
      this.lastHeightValueEmitted = headerHeight;
      this.$store.dispatch('setMainViewHeaderHeight', headerHeight);
    }
    this.collapsedInfoButton = this.$el.clientWidth < 1300;
  }

  public get sharedLink(): LinkData {
    const objectId = this.objectId?.toString();
    return this.$store.getters['link/linkData'](objectId);
  }

  public get isSharedLinkInfoReady() {
    return this.isShared ? !!this.sharedLink?.accessId : true;
  }

  public get isShared() {
    if (this.isSelection) {
      return this.$store.getters['selection/selectionsById'](this.selectionId)?.isShared;
    }
    if (this.isFolder) {
      return this.$store.getters['folder/folderById'](this.folderId)?.isShared;
    }
    return false;
  }

  public get isSelection() {
    return !!this.selectionId;
  }

  public get isFolder() {
    return !!this.folderId;
  }

  public get showTitle(): string | null {
    let result;
    if (this.folderId) {
      result = this.folderName ?? '';
    } else {
      result = this.selectionName ?? '';
    }
    return result;
  }

  public get viewIsNotEmpty(): boolean {
    return this.$store.getters['cloud/view'](this.viewId).objectIds.length > 0;
  }

  public get folder(): Folder | null {
    return this.$store.getters['folder/folderById'](this.folderId);
  }

  public get folderName(): string | null {
    return this.folder?.name;
  }

  public get selectionName(): string | null {
    return this.selection?.name;
  }

  public get folderDescription(): string {
    return this.folder?.description ?? '';
  }

  public get selectionDescription(): string | null {
    return this.selection?.description;
  }

  public get selection(): Selection | null {
    return this.$store.getters['selection/selectionsById'](this.selectionId);
  }

  public get objectId(): ObjectId {
    return this.$store.getters['cloud/view'](this.viewId).objectIds[0];
  }

  public get folderId(): string | null {
    return this.$store.getters['cloud/view'](this.viewId).folderId;
  }

  public get selectionId(): string | null {
    return this.$store.getters['cloud/view'](this.viewId).selectionId;
  }

  public setActiveWindow() {
    this.$store.commit('cloud/setActiveWindowId', this.viewId);
  }

  public updateName(newName: string) {
    if ((this.folderId && newName !== this.folder.name) || (this.selectionId && newName !== this.selection.name)) {
      if (newName.length < 3) {
        newName = newName.padEnd(3, '_');
      }
      if (this.folderId) {
        this.$store.dispatch('folder/updateName', { name: newName, folderId: this.folder.id });
      } else {
        this.$store.dispatch('selection/updateSelectionName', {
          name: newName,
          selectionId: this.selectionId,
        });
      }
    }
    this.adjustHeaderTitleSpacing();
  }

  public async updateDescription(description: string) {
    if (this.folderId && description !== this.folderDescription) {
      await this.$store.dispatch('folder/updateDescription', { folderId: this.folder.id, description });
    } else if (this.selectionId && description !== this.selectionDescription) {
      await this.$store.dispatch('selection/updateDescription', {
        selectionId: this.selection.id,
        description,
      });
    }
  }
}
