
import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class WindowArea extends Vue {
  private isConstructed = true;

  mounted() {
    this.isConstructed = false;
  }

  public get isResized(): boolean {
    return this.isConstructed || this.$store.state.isLayoutDragged;
  }

  public get contentStyle() {
    const obj: any = {};
    if (this.$routeAwareness.isHomepageView) {
      obj.height = 'var(--window-area-height-homepage)';
    } else if (this.$store.getters.isReviewMode) {
      obj.height = 'var(--window-area-height-review-mode)';
    }
    return obj;
  }
}
