
import _debounce from 'lodash.debounce';
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { isEmail } from 'class-validator';
import FormValidationCircle from '~/components/dialogs/ui/FormValidationCircle.vue';
import NavigationElementNew from '~/components/dialogs/ui/NavigationElementNew.vue';
import NavigationElements from '~/components/dialogs/ui/NavigationElements.vue';
import Column from '~/components/ui/Column.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantTagButton from '~/components/ui/MutantTagButton.vue';
import Row from '~/components/ui/Row.vue';
import ProgressCircleAnimating from '~/components/ui/progress/ProgressCircleAnimating.vue';
import LoginSection from '~/components/dialogs/sign-up/sections/LoginSection.vue';
import { ContextMenuType } from '~/store/context/state';
import { ContactInfoMenuPoint } from '~/models/ContactInfoMenuPoint';
import ResendMailButton from '~/components/dialogs/account/ResendMailButton.vue';

@Component({
  components: {
    ProgressCircleAnimating,
    FormValidationCircle,
    MutantContextMenuDivider,
    NavigationElementNew,
    NavigationElements,
    MutantTagButton,
    Row,
    Column,
    ResendMailButton,
  },
})
export default class SignUpSection extends Vue {
  private static FORM_IDENTIFIER = 'signup-section';
  public isPending = false;
  public loginSuccess = false;

  private static formIdentifier(id: string) {
    return `${SignUpSection.FORM_IDENTIFIER}-${id}`;
  }

  @Prop({ default: 'SIGNUP' })
  public callToAction;

  @Prop(Boolean)
  public forDesktop: boolean;

  public debouncedUsernameSearch: any;

  public username: string = '';
  public password: string = '';
  public email: string = '';
  public acceptTerms = false;
  public formFieldIds = {
    username: SignUpSection.formIdentifier('username'),
    password: SignUpSection.formIdentifier('password'),
    email: SignUpSection.formIdentifier('email'),
    signup: SignUpSection.formIdentifier('signup'),
    acceptTerms: SignUpSection.formIdentifier('accept-terms'),
  };

  public userIsTyping = false;

  constructor() {
    super();
    this.debouncedUsernameSearch = _debounce(this.usernameSearch, 500);
  }

  public get desktopDownloadSize(): string {
    return this.$store.state.desktopReleaseData ? `(${(this.$store.state.desktopReleaseData.size / 1000000).toFixed(2)} MB)` : '';
  }

  created() {
    window.addEventListener('keydown', this.keyEventHandler);
  }

  mounted() {
    document.getElementById(this.formFieldIds.username)?.focus();
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyEventHandler);
  }

  public downloadDesktopApp() {
    this.$store.dispatch('downloadDesktopApp');
  }

  public useWebapp() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.LOGIN);
  }

  public openTermsOfUse() {
    const data = { type: ContextMenuType.TERMS_PRIVACY_CONTACT, data: ContactInfoMenuPoint.TERMS };
    if (this.$store.getters['context/isOpen'](data.type)) {
      this.$store.dispatch('context/updateMenuData', data);
    }
    this.$store.dispatch('context/openMenu', data);
  }

  async keyEventHandler(e: KeyboardEvent) {
    this.userIsTyping = true;
    if (e.key === 'Enter' && this.username.length && this.password.length) {
      await this.signup();
    }
  }

  @Watch('$store.state.user.signUp.isPending', { immediate: true })
  public watchPendingState(pending: boolean): void {
    setTimeout(() => {
      this.isPending = pending;
    }, LoginSection.SHOW_LOADER_TIME);
  }

  // Any is not ideal here, but can not find correct type for axios error
  @Watch('$store.state.user.signUp.error', { immediate: true })
  public watchError(error: any): void {
    if (error?.message != null && error?.status >= 400 && error?.status < 500) {
      this.checkUsername();
    }
  }

  @Watch('$store.state.user.login.success', { immediate: true })
  public watchLoginSuccess(loginSuccess: boolean): void {
    setTimeout(() => {
      this.loginSuccess = loginSuccess;
    }, LoginSection.SHOW_LOADER_TIME);
  }

  public checkUsername() {
    if (this.username.length > 1) {
      this.debouncedUsernameSearch(this.username);
    }
  }

  public get showErrorMessage() {
    return this.isUsernameTaken || this.containsWhitespace;
  }

  public get errorMsg() {
    return this.isUsernameTaken
      ? 'The username is already taken'
      : this.containsWhitespace ? 'Username must not contain whitespaces' : '';
  }

  public get containsWhitespace() {
    return /\s/.test(this.username);
  }

  public get isUsernameTaken(): boolean {
    return this.$store.state.user.usernameSearch.isTaken;
  }

  public get isUsernameValid(): boolean {
    return this.username.length >= 2 && !this.isUsernameTaken && !this.containsWhitespace;
  }

  public get isPasswordValid(): boolean {
    return this.password.length >= 6;
  }

  public get isEmailValid(): boolean {
    return isEmail(this.email);
  }

  public get isInputValid(): boolean {
    return this.isUsernameValid && this.isPasswordValid && this.isEmailValid && this.acceptTerms;
  }

  public showLogin() {
    this.$emit('show-login', true);
  }

  public usernameSearch(value: string) {
    this.$store.dispatch('user/searchUsername', value);
  }

  public toggleTerms() {
    this.acceptTerms = !this.acceptTerms;
  }

  public async signup() {
    if (this.isInputValid) {
      await this.$store.dispatch('user/signUp', {
        username: this.username.trim(),
        password: this.password,
        email: this.email,
      });
      this.userIsTyping = false;
      this.isPending = true;
    }
  }
}
