
import { Component, Vue } from 'nuxt-property-decorator';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import TextField from '~/components/ui/TextField.vue';
import { CloudObject } from '~/models/cloud/CloudObject';
import { ViewType } from '~/models/views/ViewType';
import IconList from '~/components/menu/right/tabs/view/IconList.vue';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import { ViewOptionsDropdownItem } from '~/models/ViewOptionsDropDownItem';
import ViewOptionsDropDown from '~/components/ui/ViewOptionsDropDown.vue';
import ViewOptionsBox from '~/components/ui/ViewOptionsBox.vue';
import ViewOptionsSlider from '~/components/ui/ViewOptionsSlider.vue';
import { ViewSortingOption } from '~/store/cloud/state';
import { ViewSortingCategory } from '~/models/ViewSortingCategory';
import { ViewSortingOrder } from '~/models/ViewSortingOrder';

enum ViewOptionsSelected {
  CLOUD_OBJECT = 'CLOUD_OBJECT',
  ITEMS = 'ITEMS'
}

@Component({
  components: { ViewOptionsSlider, ViewOptionsBox, ViewOptionsDropDown, MutantCheckMark, IconList, TextField },
})
export default class ViewOptionsSideMenu extends Vue {
  public selected: ViewOptionsSelected = ViewOptionsSelected.CLOUD_OBJECT;
  public viewType = ViewType;
  public viewOptionsSelected = ViewOptionsSelected;
  public editName: boolean = false;

  public viewTypeItems: ViewOptionsDropdownItem[] = [
    {
      name: 'moodboard',
      value: ViewType.MOODBOARD,
    },
    {
      name: 'contact sheet',
      value: ViewType.CONTACT_SHEET,
    },
    {
      name: 'mosaic',
      value: ViewType.MOSAIC,
    },
    {
      name: 'grid',
      value: ViewType.GRID,
    },
    {
      name: 'horizontal',
      value: ViewType.HORIZONTAL,
    },
  ];

  changeTabType(value: ViewOptionsSelected) {
    this.selected = value;
  }

  get layoutHasChanged(): boolean {
    return this.$store.getters['link/layoutHasChanged'];
  }

  public changeViewType(value: ViewType) {
    this.$store.dispatch('cloud/changeView', { windowId: this.activeWindowId, view: value });
  }

  public sortingCategoryItems: ViewOptionsDropdownItem[] = [
    { name: 'alphabetical', value: ViewSortingCategory.ALPHABETICAL },
    { name: 'last modified', value: ViewSortingCategory.LAST_MODIFIED },
    { name: 'custom', value: ViewSortingCategory.CUSTOM },
  ];

  public sortingOrderItems: ViewOptionsDropdownItem[] = [
    { name: 'ascending', value: ViewSortingOrder.ASCENDING },
    { name: 'descending', value: ViewSortingOrder.DESCENDING },
  ];

  public get isSortingOrderDeactivated() {
    return this.sortingCategoryActiveItem === ViewSortingCategory.CUSTOM;
  }

  public get sortingOrderActiveItem(): ViewSortingOrder {
    const sortedBy = this.currentWindow.sortedBy;
    switch (sortedBy) {
      case ViewSortingOption.CUSTOM_ORDER:
        return ViewSortingOrder.CUSTOM;
      case ViewSortingOption.ALPHABETICAL_ASC:
      case ViewSortingOption.LAST_MODIFIED_ASC:
        return ViewSortingOrder.ASCENDING;
      case ViewSortingOption.ALPHABETICAL_DESC:
      case ViewSortingOption.LAST_MODIFIED_DESC:
        return ViewSortingOrder.DESCENDING;
      default:
        return ViewSortingOrder.ASCENDING;
    }
  }

  changeSortingCategory(value: ViewSortingCategory) {
    if (value === ViewSortingCategory.ALPHABETICAL) {
      this.$store.dispatch('cloud/setViewSortingOption', { windowId: this.activeWindowId, option: this.sortingOrderActiveItem === ViewSortingOrder.ASCENDING ? ViewSortingOption.ALPHABETICAL_ASC : ViewSortingOption.ALPHABETICAL_DESC });
    } else if (value === ViewSortingCategory.LAST_MODIFIED) {
      this.$store.dispatch('cloud/setViewSortingOption', { windowId: this.activeWindowId, option: this.sortingOrderActiveItem === ViewSortingOrder.ASCENDING ? ViewSortingOption.LAST_MODIFIED_ASC : ViewSortingOption.LAST_MODIFIED_DESC });
    } else {
      this.$store.dispatch('cloud/setViewSortingOption', { windowId: this.activeWindowId, option: ViewSortingOption.CUSTOM_ORDER });
    }
  }

  changeSortingOrder(value: ViewSortingOrder) {
    if (value === ViewSortingOrder.ASCENDING) {
      this.$store.dispatch('cloud/setViewSortingOption', { windowId: this.activeWindowId, option: this.sortingCategoryActiveItem === ViewSortingCategory.ALPHABETICAL ? ViewSortingOption.ALPHABETICAL_ASC : ViewSortingOption.LAST_MODIFIED_ASC });
    } else if (value === ViewSortingOrder.DESCENDING) {
      this.$store.dispatch('cloud/setViewSortingOption', { windowId: this.activeWindowId, option: this.sortingCategoryActiveItem === ViewSortingCategory.ALPHABETICAL ? ViewSortingOption.ALPHABETICAL_DESC : ViewSortingOption.LAST_MODIFIED_DESC });
    } else {
      this.$store.dispatch('cloud/setViewSortingOption', { windowId: this.activeWindowId, option: ViewSortingOption.CUSTOM_ORDER });
    }
  }

  public get sortingCategoryActiveItem() {
    const sortedBy = this.currentWindow.sortedBy;
    if (sortedBy === ViewSortingOption.CUSTOM_ORDER) {
      return ViewSortingCategory.CUSTOM;
    } else if (sortedBy === ViewSortingOption.ALPHABETICAL_DESC || sortedBy === ViewSortingOption.ALPHABETICAL_ASC) {
      return ViewSortingCategory.ALPHABETICAL;
    } else {
      return ViewSortingCategory.LAST_MODIFIED;
    }
  }

  public toggleEditName() {
    this.editName = !this.editName;
    if (this.editName) {
      setTimeout(() => {
        document.getElementById('view-options-cloud-object-name').focus();
      }, 200);
    }
  }

  changeBackgroundTransparency(value: number) {
    this.$store.dispatch('cloud/setBackgroundOpacity', { windowId: this.activeWindowId, opacity: value / 10 });
  }

  changeElementsTransparency(value: number) {
    this.$store.dispatch('cloud/setElementsOpacity', { windowId: this.activeWindowId, opacity: 1 - (value / 10) });
  }

  changeMenusTransparency(value: number) {
    this.$store.dispatch('setMenusOpacity', 1 - (value / 10));
  }

  public get currentWindow() {
    return this.$store.getters['cloud/window'](this.activeWindowId);
  }

  public get backgroundTransparency() {
    return this.currentWindow?.backgroundOpacity * 10;
  }

  public get elementsTransparency() {
    return 10 - (this.currentWindow?.elementsOpacity * 10);
  }

  public get menusTransparency() {
    return 10 - (this.$store.state.transparency?.menus * 10);
  }

  public get viewTypes() {
    let resultViewTypes = this.viewTypeItems;
    if (this.cloudObject?.isFolder) {
      resultViewTypes = resultViewTypes.filter(v => v.name !== 'moodboard');
    }
    return resultViewTypes.filter(v => v.value !== this.activeViewType);
  }

  public get rowDistance() {
    switch (this.activeViewType) {
      case ViewType.GRID:
        return this.view?.viewOptions?.grid?.marginFactor ?? 0;
      case ViewType.MOSAIC:
        return this.view?.viewOptions?.mosaic?.marginFactor ?? 0;
      case ViewType.CONTACT_SHEET:
        return this.view?.viewOptions?.contactSheet?.marginFactor ?? 0;
      case ViewType.HORIZONTAL:
        return this.view?.viewOptions?.magnify?.marginFactor ?? 0;
      default:
        return 0;
    }
  }

  public get hasStepOption() {
    return [ViewType.CONTACT_SHEET, ViewType.MOSAIC, ViewType.GRID].includes(this.activeViewType);
  }

  public get minStep(): number {
    switch (this.activeViewType) {
      case ViewType.CONTACT_SHEET:
        return this.view?.viewOptions?.contactSheet?.minColumnCount ?? 0;
      case ViewType.MOSAIC:
        return this.view?.viewOptions?.mosaic?.minColumnCount ?? 0;
      case ViewType.GRID:
        return this.gridSliderRange.minStep ?? 0;
      default:
        return 0;
    }
  }

  public get maxStep(): number {
    switch (this.activeViewType) {
      case ViewType.MOSAIC:
        return this.view.viewOptions.mosaic.maxColumnCount ?? 0;
      case ViewType.CONTACT_SHEET:
        return this.view.viewOptions.contactSheet.maxColumnCount ?? 0;
      case ViewType.GRID:
        return this.gridSliderRange.maxStep ?? 0;
      default:
        return 0;
    }
  }

  public get gridSliderRange() {
    const { maxRowHeight, rowHeightInterval, minRowHeight, rowHeight } = this.view.viewOptions.grid;
    return {
      maxStep: (maxRowHeight - minRowHeight) / rowHeightInterval,
      currentStep: (maxRowHeight - rowHeight) / rowHeightInterval,
      minStep: 1,
    };
  }

  public get activeStep(): number {
    switch (this.activeViewType) {
      case ViewType.MOSAIC:
        return this.view.viewOptions.mosaic.columnCount ?? 0;
      case ViewType.CONTACT_SHEET:
        return this.view.viewOptions.contactSheet.columnCount ?? 0;
      case ViewType.GRID:
        return this.gridSliderRange?.currentStep ?? 0;
    }
    return 0;
  }

  public computeRowHeight(value: number) {
    const { maxRowHeight, rowHeightInterval } = this.view.viewOptions.grid;
    return maxRowHeight - rowHeightInterval * value;
  }

  public setStep(value: number) {
    switch (this.activeViewType) {
      case ViewType.MOSAIC:
        this.$store.dispatch('cloud/setMosaicColumnCount', { windowId: this.activeWindowId, columnCount: value });
        break;
      case ViewType.CONTACT_SHEET:
        this.$store.dispatch('cloud/setContactSheetColumnCount', { windowId: this.activeWindowId, columnCount: value });
        break;
      case ViewType.GRID:
        this.$store.dispatch('cloud/setGridRowHeight', { windowId: ViewIdentifier.MAIN_VIEW, rowHeight: this.computeRowHeight(value) });
        break;
    }
  }

  public increaseRowDistance() {
    this.changeRowDistance(this.rowDistance + 1);
  }

  public decreaseRowDistance() {
    this.changeRowDistance(this.rowDistance - 1);
  }

  public changeRowDistance(value: number) {
    switch (this.activeViewType) {
      case ViewType.GRID:
        this.$store.dispatch('cloud/setGridMarginFactor', { windowId: this.activeWindowId, marginFactor: value });
        break;
      case ViewType.MOSAIC:
        this.$store.dispatch('cloud/setMosaicMarginFactor', { windowId: this.activeWindowId, marginFactor: value });
        break;
      case ViewType.CONTACT_SHEET:
        this.$store.dispatch('cloud/setContactSheetMarginFactor', { windowId: this.activeWindowId, marginFactor: value });
        break;
      case ViewType.HORIZONTAL:
        this.$store.dispatch('cloud/setMagnifyMarginFactor', { windowId: this.activeWindowId, marginFactor: value });
        break;
    }
  }

  public get displayViewType() {
    return this.viewTypeItems.find(v => this.activeViewType === v.value)?.name;
  }

  public get view(): MutantContentView {
    return this.$store.getters['cloud/view'](this.activeWindowId);
  }

  public get isSelection() {
    return this.cloudObject?.isSelection;
  }

  public updateName(newName: string) {
    this.editName = false;
    this.$store.dispatch('cloud/updateName', { cloudObject: this.cloudObject, name: newName });
  }

  public get showResolutions() {
    return this.$store.state.showResolutions;
  }

  public get showInImmersionMode() {
    return this.$store.state.cloud.showInImmersionMode;
  }

  public get showOriginals() {
    return this.$store.state.showOriginals;
  }

  public toggleImmersionMode(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.$store.dispatch('cloud/toggleImmersionMode', this.activeWindowId);
  }

  public toggleShowOriginals(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.$store.commit('toggleShowOriginals');
  }

  public get showCaptions() {
    return this.$store.state.showCaptions;
  }

  public toggleShowResolutions(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.$store.commit('toggleShowResolutions');
  }

  public toggleShowCaptions(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.$store.commit('toggleShowCaptions');
  }

  public doNothing(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    // TODO: to be implemented. click handler added so the checkbox does not randomly conflict with other checkboxes
  }

  public get isOwner() {
    return this.cloudObject?.object?.owner?.id === this.$store.state.user?.user?.id;
  }

  public get activeViewType() {
    return this.view?.viewOptions?.activeViewType;
  }

  private get activeWindowId() {
    return this.$store.state.cloud.activeWindowId ?? ViewIdentifier.MAIN_VIEW;
  }

  public get cloudObject(): CloudObject<Folder | Selection> {
    return this.$store.getters['cloud/cloudObject'](this.view.objectIds[0]);
  }

  public changeView(viewType: ViewType) {
    if (viewType !== this.view.viewOptions.activeViewType) {
      this.$store.dispatch('cloud/changeView', { windowId: this.activeWindowId, view: viewType });
    }
  }

  public get cloudObjectName() {
    return this.cloudObject?.object?.name ?? '';
  }

  public get itemCount() {
    return this.cloudObject?.object?.itemCount ?? 0;
  }

  public get objectType() {
    return this.view.isFolderView ? 'FOLDER' : 'SELECTION';
  }

  public get objectTypeSelected() {
    return this.selected === ViewOptionsSelected.CLOUD_OBJECT;
  }

  public get itemsSelected() {
    return this.selected === ViewOptionsSelected.ITEMS;
  }
}
