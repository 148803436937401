var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"circle-button",class:{
    'circle-button--active': _vm.isActive,
    'circle-button--opacity': _vm.hasOpacity,
    'circle-button--disabled': _vm.isDisabled,
    'circle-button--clicked': _vm.isClicked,
    'circle-button--is-small': _vm.isSmall,
    'circle-button--is-large': _vm.isLarge
  },on:{"click":_vm.click,"mouseenter":function($event){_vm.isHover = true},"mouseleave":_vm.mouseleave,"dragover":function($event){return _vm.$emit('dragover', $event)}}},[(_vm.showTooltip)?_c('div',{staticClass:"circle-button__tooltip",class:{
      'circle-button__tooltip--left': _vm.tooltipPosition === 'left',
      'circle-button__tooltip--right': _vm.tooltipPosition === 'right',
      'circle-button__tooltip--top': _vm.tooltipPosition === 'top'
    }},[_c('transition',{attrs:{"appear":"","name":"tooltip"}},[_c('MutantBadgeButtonLarge',{attrs:{"is-active":_vm.isHover,"is-filled":""}},[_vm._v("\n        "+_vm._s(_vm.tooltip)+"\n      ")])],1)],1):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }