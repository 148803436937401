
import { Component, Vue } from 'nuxt-property-decorator';
import MutantCircleButton from '../../ui/MutantCircleButton.vue';

@Component({
  components: {
    MutantCircleButton,
  },
})
export default class NavigationElementNew extends Vue {

}
