
import { Component, Vue } from 'nuxt-property-decorator';
import MutantContextMenu from '../../ui/MutantContextMenu.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import Column from '~/components/ui/Column.vue';
import { ContextMenuType } from '~/store/context/state';
import Profile from '~/components/dialogs/account/Profile.vue';

@Component({
  components: {
    Column,
    ContextMenuHeaderFlashy,
    MutantContextMenu,
    Profile,
  },
})
export default class AccountContextMenu extends Vue {
  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.ACCOUNT_OVERVIEW);
  }
}
