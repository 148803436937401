
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import MutantBadge from '~/components/ui/MutantBadge.vue';
import TextField from '~/components/ui/TextField.vue';
import Selection from '~/models/selection/Selection';
import Folder from '~/models/Folder';
import { CloudObject } from '~/models/cloud/CloudObject';
import MutantCheckbox from '~/components/ui/MutantCheckbox.vue';

@Component({
  components: {
    MutantCheckbox,
    TextField,
    MutantBadge,
  },
})
export default class CloudMenuRow extends Vue {
    @Prop()
      cloudObject: CloudObject<Folder | Selection>;

    @Prop()
      itemCount: number;

    @Prop(Boolean)
      isDroppedOn: boolean;

    @Prop(Boolean)
      isHidden: boolean;

    @Prop(Boolean)
      isAlternated: boolean;

    @Prop(Boolean)
      multiselect: boolean;

    @Prop(Boolean)
      hasMultiselect: boolean;

    public isDragOver = false;
    public lastClicked = null;
    public timeoutHandler = null;

    public onDrop(event: DragEvent) {
      this.isDragOver = false;
      this.$emit('drop', event);
    }

    public setMultiselect() {
      if (!this.isScrapbook) {
        this.$emit('toggle-multiselect', this.cloudObject);
      }
    }

    public get nameContentId() {
      return `cloud-menu-object-name-${this.cloudObject.objectId.toUuid()}`;
    }

    public get isScrapbook() {
      return this.cloudObject.object?.name === 'scrapbook';
    }

    public get scrapbookNotAllowed() {
      return this.isScrapbook && this.hasMultiselect ? { placement: 'top', content: 'Scrapbook can not be modified' } : {};
    }

    public get isOwner() {
      return this.cloudObject?.object?.owner?.id === this.$store.state.user?.user?.id;
    }

    public get selectedItemCount(): number {
      if (this.cloudObject?.object?.id) {
        const items = this.cloudObject.isSelection
          ? this.$store.getters['selection/selectionItemsById'](this.cloudObject.object.id)
          : this.$store.getters['folder/folderItemsById'](this.cloudObject.object.id);
        return items ? this.$store.getters['selection/filterSelectedItems'](items)?.length : 0;
      }
      return 0;
    }

    public clicked($event) {
      if (this.hasMultiselect) {
        this.setMultiselect();
      } else {
        clearTimeout(this.timeoutHandler);
        this.lastClicked = this.cloudObject.objectId.toString();
        this.timeoutHandler = setTimeout(() => {
          this.lastClicked = null;
        }, 100);
        this.$emit('click', $event);
      }
    }

    public updateName(newName: string) {
      this.$store.dispatch('cloud/updateName', { name: newName, cloudObject: this.cloudObject });
    }

    public get objectName() {
      return this.cloudObject.object.name;
    }

    public get isSelected() {
      return this.lastClicked === this.cloudObject.objectId.toString() || this.$store.getters.isActiveObject(this.cloudObject.objectId);
    }
}
