
import { Component, Vue } from 'nuxt-property-decorator';
import Dropzone from '~/components/homepage/feature-highlights/Dropzone.vue';
import MutantVideo from '~/components/homepage/feature-highlights/MutantVideo.vue';
import LegalFooter from '~/components/homepage/feature-highlights/LegalFooter.vue';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: { LegalFooter, MutantVideo, Dropzone },
})
export default class WebPromo extends Vue {
  public activeSpeedComparison = 'display';

  public chooseSpeedComparison(value: string) {
    this.activeSpeedComparison = value;
  }

  public get supportedBrowserVersion(): boolean {
    return this.$store.state.workerSupport;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public signUp() {
    if (this.$store.getters['context/menu'](ContextMenuType.LOGIN)?.data?.showLogin) {
      this.$store.dispatch('context/updateMenuData', { type: ContextMenuType.LOGIN, isOpen: true, data: { showLogin: false } });
    } else {
      this.$store.dispatch('context/toggleMenu', { type: ContextMenuType.LOGIN, data: { showLogin: false } });
    }
  }
}
