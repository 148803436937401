
import _debounce from 'lodash.debounce';
import _differenceWith from 'lodash.differencewith';
import _throttle from 'lodash.throttle';
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { buffer, debounceTime, mergeWith, Subject } from 'rxjs';
import MutantSnippet from '~/components/window/view/snippet/MutantSnippet.vue';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { Point } from '~/models/ComplexMath';
import { DropAnimationType } from '~/models/views/DropAnimationType';
import Item from '~/models/item/Item';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { Moodboard } from '~/models/views/moodboard/Moodboard';
import { MoodboardOptions } from '~/models/views/moodboard/MoodboardOptions';
import { PlaceItemsEvent } from '~/models/PlaceItemsEvent';
import { MutantRectangle } from '~/models/MutantRectangle';
import { MutantView } from '~/models/views/MutantView';
import { RectangularSelection } from '~/models/views/RectangularSelection';
import { ResizeItemEvent } from '~/models/ResizeItemEvent';
import { SelectionMode } from '~/models/selection/SelectionMode';
import Snippet from '~/models/Snippet';
import {
  Position,
  PositionWithOptionalSize,
  SnippetMoveProcess,
  SnippetMover,
  SnippetMoverEvent,
  SnippetMoverMoveEndEvent,
  SnippetMoverMoveStartEvent
} from '~/models/views/SnippetMover';
import { SortItemsEvent, SortItemsEventExternal, SortItemsEventInternal } from '~/models/item/SortItemsEvent';
import { SortItemsEventType } from '~/models/item/SortItemsEventType';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import { ViewRange } from '~/models/views/ViewRange';
import { SortableView } from '~/models/views/SortableView';
import { ViewType } from '~/models/views/ViewType';
import { ContextMenuType } from '~/store/context/state';
import { GridBuilder } from '~/models/views/grid/GridBuilder';
import { MosaicBuilder } from '~/models/views/mosaic/MosaicBuilder';
import { MoodboardBuilder } from '~/models/views/moodboard/MoodboardBuilder';
import { HorizontalViewBuilder } from '~/models/views/horizontal/HorizontalViewBuilder';
import { Owner } from '~/models/selection/Owner';
import { DroppedOnViewEvent } from '~/models/DroppedOnViewEvent';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { SnippetHoverEffect } from '~/models/views/SnippetHoverEffect';
import { DropPositionDetails } from '~/models/DropPositionDetails';
import { ViewOffsets } from '~/models/views/ViewOffsets';
import { FolderItem } from '~/models/item/FolderItem';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ContactSheetBuilder } from '~/models/views/contactSheet/ContactSheetBuilder';
import ReviewInfo from '~/components/ui/ReviewInfo.vue';
import { LayoutType } from '~/models/LayoutType';
import AssetDetails from '~/components/ui/AssetDetails.vue';
import { CloudMenuTab } from '~/models/CloudMenuTab';

@Component({
  components: {
    AssetDetails,
    ReviewInfo,
    MutantSnippet,
  },
})
export default class SnippetView extends Vue {
  public static DRAG_TO_DESKTOP_MIN_HEIGHT = 200;
  public static DRAG_TO_DESKTOP_MIN_WIDTH = 150;
  private static REVIEW_MODE_HEIGHT = 30;
  public ViewType = ViewType;

  @Prop()
  public view: MutantContentView;

  @Prop({ default: 120 })
  public viewHeight!: number;

  @Prop()
  public width?: number;

  @Prop()
  public viewRange: ViewRange;

  @Prop()
  public scrollElementId: string;

  @Prop(Boolean)
  public isRemovable?: boolean;

  @Prop(Boolean)
  public isSelectable?: boolean;

  @Prop({ default: false })
  public isScrolling: boolean;

  @Prop(Boolean)
  public isSelectionWithinView?: boolean;

  @Prop({ default: SelectionMode.DEFAULT })
  public selectionMode?: SelectionMode;

  @Prop(Boolean)
  public isImmersiveView?: boolean;

  @Prop(Boolean)
  public allowContinuousSelection?: boolean;

  @Prop(Boolean)
  public allowTogglingSnippets?: boolean;

  @Prop(Boolean)
  public showSnippets?: boolean;

  @Prop(Boolean)
  public showHoverPreview?: boolean;

  @Prop({ default: 0 })
  public spacingBetweenSelectedItems?: number;

  @Prop(Boolean)
  public isDistorted?: boolean;

  @Prop()
  public highlightItemId?: string;

  @Prop({ default: false })
  public hasResizeObserver?: boolean;

  @Prop(Boolean)
  public disableInteractions?: boolean;

  @Prop(Boolean)
  public isInNavigationMode?: boolean;

  @Prop(Boolean)
  public hasFocusFrames?: boolean;

  @Prop(Boolean)
  public disableSelectionBorders?: boolean;

  @Prop(Boolean)
  public isHoverDisabled?: boolean;

  @Prop(Boolean)
  private isReferencedToSharedLink?: boolean;

  @Prop({ default: false })
  private overrideViewTransitions?: boolean;

  @Prop({ default: true })
  private hasViewTransitions: boolean;

  @Prop(Boolean)
  private ignoreHeightChanges?: boolean;

  @Prop()
  private marginBottom?: number;

  @Prop()
  private moodboardOptions?: MoodboardOptions;

  @Prop()
  private drag: boolean;

  public hoverItemId = null;
  public wasHoveredItemId = null;
  public hasWidthTransition = false;
  public hasHeightTransition = false;
  public throttledMouseLeave = null;
  public throttledMouseEnter = null;
  public throttledDragOver = null;
  public rectangularSelection: RectangularSelection = null;
  public resizeTriggerRange = {
    percentage: 0.09,
    minPixel: 10,
  };

  public activeTransition = 'snippet-group';

  private calculatedView: MutantView = null;
  private distortionMap = {};
  public itemSelectedBorder: number = 2;
  public itemSelectedUseOutline: boolean = false;
  private isInvertedSelectionActive: boolean = false;
  private activeMovementTransitions = true;
  private resizeObserver: any;
  private blockResizeHandler = false;
  private cssWillChange = 'auto';
  private invertedSelectionIntentTimedOut = true;
  private invertedSelectionIntentTriggeredByItem = null;
  private itemsCurrentlyDragged = [];
  private snippetsInStoppedView: Snippet[] = [];
  private viewTransitionTimeoutHandler = null;
  private blockResizeTimeoutHandler = null;
  private heightTransitionTimeoutHandler = null;
  private updateSnippetsInStoppedViewDebounced = null;
  private updateRectangularSelectionItemsThrottled = null;
  private internalSelection: Map<string, ItemWithPosition> = new Map<string, ItemWithPosition>();
  private dragClientX: number = null;
  private dragClientY: number = null;
  private rectangularSelectionAnimationFrame = null;
  private snippetMover: SnippetMover;
  private preventClicks = false;
  private preventClicksTimeoutHandler: any;
  private itemsInActiveAnimationThatNeedToBeHidden = new Map();
  private widthTransitionTimeoutHandler = null;
  private resizeViewSubject = new Subject<string>();
  private renderViewSubject = new Subject<string>();
  private viewChangeSubject = this.resizeViewSubject.pipe(mergeWith([this.renderViewSubject]));
  private viewChangeSubscription;
  private activeTransitionTimeoutHandler = null;

  public get viewType(): ViewType {
    return this.view?.viewOptions.activeViewType;
  }

  public get viewId(): string {
    return this.view?.id;
  }

  public get items(): ItemWithPosition[] {
    return this.$store.getters['cloud/viewItemsMap'][this.viewId].items || [];
  }

  public get columnCount(): number {
    return this.view?.viewOptions.mosaic.columnCount;
  }

  public get contactSheetColumnCount(): number {
    return this.view?.viewOptions.contactSheet.columnCount;
  }

  public get contactSheetTextSize(): number {
    return this.view?.viewOptions.contactSheet.textSize;
  }

  public get gridRowHeight(): number {
    return this.view?.viewOptions?.grid?.rowHeight;
  }

  public get mosaicColumnWidth(): number {
    return this.view?.viewOptions?.mosaic?.columnWidth;
  }

  public get isDarkMode(): boolean {
    return !this.$store.state.isLightMode;
  }

  public get isMainViewInReviewMode(): boolean {
    return this.$store.state.currentLayoutType === LayoutType.REVIEW_MODE
      && this.viewId === ViewIdentifier.MAIN_VIEW
      && this.viewType === ViewType.HORIZONTAL;
  }

  public get isNavigationViewInReviewMode(): boolean {
    return this.$store.state.currentLayoutType === LayoutType.REVIEW_MODE
      && this.isInNavigationMode;
  }

  public isFocusedItemInNavigationLane(snippet: Snippet) {
    return this.$store.getters.isReviewMode
      && this.isCenteredItem(snippet)
      && !this.isScrolling
      && this.viewId === ViewIdentifier.NAVIGATION_VIEW;
  }

  public get calculatedViewHeight(): number {
    return this.isMainViewInReviewMode ? this.viewHeight - SnippetView.REVIEW_MODE_HEIGHT : this.viewHeight;
  }

  // We separate the show icon logic so that the majority of the results can be cached
  // only moodboard needs an individual calculation
  public get showDragIcon(): boolean {
    const minWidth = SnippetView.DRAG_TO_DESKTOP_MIN_WIDTH;
    const minHeight = SnippetView.DRAG_TO_DESKTOP_MIN_HEIGHT;
    if (this.visibleSnippets.length > 0 && !this.$store.getters.isMobile) {
      const exampleSnippet = this.visibleSnippets[0];
      switch (this.viewType) {
        case ViewType.MOSAIC:
          // The column width has the total width of the column including 2xMargin so we subtract this to get
          // the real width of the image
          return (this.mosaicColumnWidth - this.view?.viewOptions?.mosaic.margin * 2) > minWidth;
        case ViewType.GRID:
          return this.gridRowHeight > minHeight;
        case ViewType.HORIZONTAL:
        case ViewType.CONTACT_SHEET:
          return exampleSnippet.height > minHeight;
        default:
          return false;
      }
    }
    return false;
  }

  public showDragIconForMoodboard(snippet: Snippet) {
    return snippet.width > SnippetView.DRAG_TO_DESKTOP_MIN_WIDTH;
  }

  public get itemMargin(): number {
    return this.isNavigationViewInReviewMode ? 5 : this.view?.viewSpacing;
  }

  public reviewKey(snippet: Snippet): string {
    return `review_mode_${this.viewId}_key_${snippet.item.id}`;
  }

  public snippetKey(snippet: Snippet): string {
    return `view_${this.viewId}_key_${snippet.item.id}`;
  }

  public get hoverEffect(): SnippetHoverEffect {
    if (this.isHoverDisabled || this.viewType === ViewType.MOODBOARD) {
      return SnippetHoverEffect.NONE;
    }
    if (this.isInNavigationMode) {
      return SnippetHoverEffect.NAVIGATION;
    }
    return SnippetHoverEffect.DEFAULT;
  }

  public get visibleSnippets(): Snippet[] {
    const visibleSnippets = this.calculatedView?.snippets.filter(s => this.isSnippetVisible(s)) || [];
    this.$emit('visible-snippets', visibleSnippets);
    return visibleSnippets;
  }

  public get viewHeightTotal(): string {
    const padding = this.marginBottom ? this.marginBottom : 0;
    const minHeight = this.calculatedViewHeight;
    const margin = this.viewType === ViewType.HORIZONTAL || this.viewType === ViewType.MOODBOARD ? 0 : 100;
    if (this.viewType === ViewType.HORIZONTAL || this.viewType === ViewType.MOODBOARD) {
      return this.calculatedView?.snippets?.length
        ? `${this.calculatedView.height + padding}px`
        : minHeight + 'px';
    }
    return this.calculatedView && this.calculatedView.height + margin > minHeight
      ? `${this.calculatedView.height + margin + padding}px`
      : minHeight + 'px';
  }

  public get viewWidthTotal(): string {
    return this.viewType === ViewType.HORIZONTAL && this.calculatedView?.snippets?.length ? `${this.calculatedView.width + this.viewWidth / 2}px` : '100%';
  }

  public get isMoodboardView(): boolean {
    return this.viewType === ViewType.MOODBOARD;
  }

  public get snippetViewStyle() {
    return {
      height: this.viewHeightTotal,
      minHeight: this.viewHeightTotal,
      width: this.viewWidthTotal,
      minWidth: this.viewWidthTotal,
    };
  }

  public get viewWidth(): number {
    if (this.width) {
      return this.width;
    }
    const viewElement = document.getElementById(this.viewId);
    return viewElement ? viewElement.offsetWidth : 0;
  }

  public getSnippetHeight(snippet: Snippet): number {
    if (this.viewType === ViewType.MOSAIC && this.showSnippets) {
      return snippet.height;
    } else {
      return snippet.item.viewPosition.height;
    }
  }

  public get isBarView(): boolean {
    return this.viewType === ViewType.HORIZONTAL;
  }

  public get hasSmallerBoxShadow(): boolean {
    return !(this.isMoodboardView || (this.isBarView && this.isDistorted));
  }

  public boxShadowSize(snippetHeight: number): number {
    const maxShadowSize = this.hasSmallerBoxShadow ? 3 : 8;
    const shadowFactor = 50;
    const shadowSize = snippetHeight / shadowFactor;
    return shadowSize > maxShadowSize ? maxShadowSize : shadowSize;
  }

  public get hasBoxShadow(): boolean {
    return this.isDarkMode && this.viewType !== ViewType.CONTACT_SHEET && (this.isMoodboardView || (this.isBarView && this.isDistorted) || this.itemMargin > 2);
  }

  public isFaded(snippet: Snippet): boolean {
    return this.isImmersiveView && !this.$store.getters['selection/isItemSelected'](snippet.item);
  }

  public isSelected(snippet: Snippet): boolean {
    return this.isSelectable
    && !this.disableSelectionBorders
    && !this.$store.state.cloud.filterForActiveSelections
    && this.$store.state.selection.showSelectedItems
    && (
      this.internalSelection.has(snippet.item.item.id)
      || (!this.isSelectionWithinView && this.$store.getters['selection/isItemSelected'](snippet.item.item))
    );
  }

  public selectedBy(snippet: Snippet): Owner[] {
    const selectedBy = !this.disableSelectionBorders && this.isSelectable && this.isSelected(snippet) && this.$store.state.selection.globalSelectedItems.get(snippet.item.id)?.selectedBy;
    return selectedBy || [];
  }

  public isHighlighted(snippet: Snippet): boolean {
    return this.highlightItemId === snippet.item.item.id;
  }

  public isSnippetVisible(snippet: Snippet): boolean {
    return !this.itemsInActiveAnimationThatNeedToBeHidden.has(snippet.item.id) && (this.isInViewRangeCached(snippet) || this.isHighlighted(snippet)) && !this.$store.state.selection.flaggedForDelete[snippet.item.id];
  }

  public hasFocusFrame(snippet: Snippet) {
    return this.isSelected(snippet) && this.isBigContactSheet;
  }

  public get isBigContactSheet(): boolean {
    return this.viewType === ViewType.HORIZONTAL && this.calculatedViewHeight >= 500;
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get isInvertedSelectionMode(): boolean {
    return this.viewType !== ViewType.HORIZONTAL && this.isInvertedSelectionActive;
  }

  public getSnippetId(item: ItemWithPosition): string {
    return SnippetView.getSnippetIdForView(this.viewId, item);
  }

  public static getSnippetIdForView(viewId: string, item: ItemWithPosition): string {
    return `${viewId}_${item.id}`;
  }

  public getSnippetIdForViewByItemId(viewId: string, itemId: string): string {
    return `${viewId}_${itemId}`;
  }

  public get hasMoveTransition(): boolean {
    return this.hasViewTransitions && (this.activeMovementTransitions || this.hasHeightTransition || this.hasWidthTransition);
  }

  public get rectangularSelectionStyle() {
    return {
      left: this.rectangularSelection.left + 'px',
      top: this.rectangularSelection.top + 'px',
      width: this.rectangularSelection.width + 'px',
      height: this.rectangularSelection.height + 'px',
    };
  }

  constructor() {
    super();
    this.throttledMouseEnter = _throttle(this.handleMouseEnter, 200);
    this.throttledMouseLeave = _throttle(this.handleMouseLeave, 200);
    this.throttledDragOver = _throttle(this.handleDragOver, 100, { leading: true, trailing: false });
    this.updateSnippetsInStoppedViewDebounced = _debounce(this.updateSnippetsInStoppedView, 500);
    this.updateRectangularSelectionItemsThrottled = _throttle(this.updateRectangularSelectionItems, 300, {
      leading: true,
      trailing: false,
    });

    const debouncedViewChangeSubject = this.viewChangeSubject.pipe(debounceTime(50));

    this.viewChangeSubscription = this.viewChangeSubject.pipe(buffer(debouncedViewChangeSubject)).subscribe((data) => {
      // this prohibits the view from resizing while a render is done
      if (!data.includes('render')) {
        this.resizeView();
      } else if (data.includes('resize')) {
        // reschedule resize operation
        this.resizeViewSubject.next('resize');
      }
    });
  }

  public handleDrop(event: DragEvent) {
    if (!this.isMobile) {
      event.preventDefault();
      const originViewId = event.dataTransfer?.getData(TransferOption.MUTANT_VIEW_ID) || this.$store.state.dragInfo?.viewId;
      // Can be files or links
      const hasExternalData = event.dataTransfer && (event.dataTransfer.items?.length || event.dataTransfer.files?.length || event.dataTransfer.getData('URL'));
      const hasDifferentViewOrigin = originViewId && originViewId !== this.viewId;
      if (hasExternalData || hasDifferentViewOrigin) {
        // TODO: calculate fixed animate position for the corresponding Snippet Mover
        const moveId = event.dataTransfer?.getData(TransferOption.MUTANT_MOVE_ID) || this.$store.state.dragInfo?.moveId;
        const position: Position = event.clientX
          ? {
              left: event.clientX,
              top: event.clientY,
            }
          : {
              left: (event as any).changedTouches[0].clientX,
              top: (event as any).changedTouches[0].clientY,
            };
        const dropPositionDetails = new DropPositionDetails(position, this.viewOffsets());
        if (moveId) {
          this.dropItemsIntoView(moveId, originViewId, dropPositionDetails);
        } else {
          this.dropExternalDataIntoView(event, dropPositionDetails);
        }
      }
    }
  }

  // We don't need to animate any items here, so we only calculate the position where the external data is placed into the view
  private dropExternalDataIntoView(event: DragEvent, dropPositionDetails: DropPositionDetails) {
    let position: any = {};
    if (this.viewType === ViewType.MOODBOARD) {
      const boardPosition = (this.calculatedView as Moodboard).calculatePercentagePositionOnBoard({
        x: dropPositionDetails.relativePosition.left,
        y: dropPositionDetails.relativePosition.top,
      });
      const targetWidth = 0.2;
      const positionOffset = targetWidth / 2;
      position = {
        x: boardPosition.x - positionOffset,
        y: boardPosition.y - positionOffset,
        width: targetWidth,
      };
    } else {
      position.order = this.getDropPositionBasedOnClosestSnippet(dropPositionDetails.relativePosition);
    }
    const addExternalContentEvent = new AddExternalContentEvent(
      event,
      position
    );
    this.$parent?.$emit('add-external-content', addExternalContentEvent);
  }

  private dropItemsIntoView(moveId: string, originViewId: string, dropPositionDetails: DropPositionDetails) {
    if (this.viewType === ViewType.MOODBOARD) {
      this.dropItemsOnMoodboard(moveId, dropPositionDetails);
    } else {
      this.dropItemsOnOrderedView(moveId, originViewId, dropPositionDetails);
    }
  }

  private approximateSortItemsEventBasedOnDropPosition(itemsMoved: ItemWithPosition[], relativeDropPosition: Position, targetViewIsOrigin: boolean): SortItemsEvent {
    const dropPosition: number = this.getDropPositionBasedOnClosestSnippet(relativeDropPosition);
    const lastSnippetInView = this.calculatedView.snippets[this.calculatedView.snippets.length - 1];
    const lastSnippetOrder = lastSnippetInView?.item.itemPosition.order || 0;
    return targetViewIsOrigin
      ? new SortItemsEventInternal(
        dropPosition,
        itemsMoved,
        SortItemsEventType.FILL
      )
      : new SortItemsEventExternal(
        dropPosition,
        itemsMoved,
        SortItemsEventType.FILL,
        {
          start: dropPosition,
          end: dropPosition > lastSnippetOrder ? dropPosition : lastSnippetOrder,
        }
      );
  }

  calculateDropAnimationTargetPosition(originViewId: string, viewOffsets: ViewOffsets, snippetsWithTargetPositions: Snippet[]): Map<string, PositionWithOptionalSize> {
    const sortAnimationPointMap = new Map<string, PositionWithOptionalSize>();
    const { windowLeft, windowTop, scrollLeft, scrollTop } = viewOffsets;
    snippetsWithTargetPositions.forEach(s => sortAnimationPointMap.set(SnippetView.getSnippetIdForView(originViewId, s.item.item), {
      top: s.item.viewPosition.top + windowTop - scrollTop,
      left: s.item.viewPosition.left + windowLeft - scrollLeft,
      width: s.item.viewPosition.width,
      height: s.item.viewPosition.height,
    }));
    return sortAnimationPointMap;
  }

  private dropItemsOnOrderedView(moveId: string, originViewId: string, dropPositionDetails: DropPositionDetails) {
    const relativePosition = dropPositionDetails.relativePosition;
    // we need to use json.parse / json.stringify here, else we somehow manipulate the store or work on a state reference to the items here (crazy bug!)
    const itemsMoved: ItemWithPosition[] = this.snippetMover.itemsMoved(moveId)
      .map(i => JSON.parse(JSON.stringify(i)));
    const targetView: MutantContentView = this.$store.getters['cloud/view'](this.viewId);
    const originView: MutantContentView = this.$store.getters['cloud/view'](originViewId);
    const targetViewIsOrigin = targetView.matches(originView);
    const sortItemsEvent: SortItemsEvent = this.approximateSortItemsEventBasedOnDropPosition(itemsMoved, relativePosition, targetViewIsOrigin);
    if (sortItemsEvent instanceof SortItemsEventExternal && targetView.isSingleFolderView) {
      sortItemsEvent.setItemsInternalToViewByCondition(this.items, (i) => (i.folderId || i.item.folderId) === targetView.folderId);
    }
    const snippetsWithTargetPositions: Snippet[] = (this.calculatedView as SortableView).preCalculatePartialSorting(sortItemsEvent);
    const dropAnimationTargetPositions: Map<string, PositionWithOptionalSize> = this.calculateDropAnimationTargetPosition(originViewId, dropPositionDetails.viewOffsets, snippetsWithTargetPositions);
    if (sortItemsEvent instanceof SortItemsEventExternal) {
      // we need to reset the ids we changed for the view sorting calculation, so we can properly reference the element copies in the SnippetMover for animating them into their new place
      for (const id of dropAnimationTargetPositions.keys()) {
        const parsedId = id.split('_')[1];
        if (sortItemsEvent.internalToOriginalIdMap.has(parsedId)) {
          const originalId = sortItemsEvent.internalToOriginalIdMap.get(parsedId);
          if (originalId !== parsedId) {
            dropAnimationTargetPositions.set(this.getSnippetIdForViewByItemId(originViewId, originalId), dropAnimationTargetPositions.get(id));
            dropAnimationTargetPositions.delete(id);
          }
        }
      }
    }
    // We add a new uuid for items here (not optimal! - should be done based on more information outside of this view)
    // a) so we can force hide those items for the duration of the animation
    // b) so it is a new distinct item for this view, the state and the database later on (so we don't run in an uuid conflict and can't add it)
    if (sortItemsEvent instanceof SortItemsEventExternal) {
      sortItemsEvent.items = sortItemsEvent.items.map(i => ({
        ...i,
        id: ((targetView.isEmpty && (i as FolderItem).folderId) || targetView.isSingleFolderView) ? (i.itemId || i.item.id) : uuid(), // we need to do this here for now, so we don't get duplicate ids later on when we copy instead of move items
      }));
    }
    // Hide elements for moved items that already exist in this view or are added while the drop animation is still in process
    const itemsInActiveAnimationThatNeedToBeHidden: ItemWithPosition[] = sortItemsEvent.items;
    // TODO: Use css variable for timing
    this.hideItemsForPeriod(itemsInActiveAnimationThatNeedToBeHidden, 500);
    this.$store.dispatch('droppedOnView', {
      viewId: this.viewId,
      sortPointMap: dropAnimationTargetPositions,
      type: DropAnimationType.SORT,
    });
    this.$parent?.$emit('sort-items', sortItemsEvent);
  }

  private hideItemsForPeriod(items: ItemWithPosition[], period: number) {
    const itemIdsToHide: string[] = items.map(item => item.id);
    const mapWithItemsToHide = new Map(this.itemsInActiveAnimationThatNeedToBeHidden);
    itemIdsToHide.forEach(id => mapWithItemsToHide.set(id, id));
    this.itemsInActiveAnimationThatNeedToBeHidden = mapWithItemsToHide;
    setTimeout(() => {
      // reset hidden animated items after given period is completed, so the original item is displayed again
      const newMapWithItemsToHide = new Map(this.itemsInActiveAnimationThatNeedToBeHidden);
      itemIdsToHide.forEach(id => newMapWithItemsToHide.delete(id));
      this.itemsInActiveAnimationThatNeedToBeHidden = newMapWithItemsToHide;
    }, period);
  }

  private dropItemsOnMoodboard(moveId: string, dropPositionDetails: DropPositionDetails) {
    const relativePosition = dropPositionDetails.relativePosition;
    const position = dropPositionDetails.position;
    const moveProcess: SnippetMoveProcess = this.snippetMover.moveProcess(moveId);
    const {
      left,
      leftScaleDiff,
      top,
      topScaleDiff,
      widthScaled: width,
    } = moveProcess.dragElementOffsetValues;
    relativePosition.left = relativePosition.left - left + leftScaleDiff;
    relativePosition.top = relativePosition.top - top + topScaleDiff;
    position.left = position.left - left + leftScaleDiff;
    position.top = position.top - top + topScaleDiff;
    const relativeItemPosition = new Point(relativePosition.left, relativePosition.top);
    const boardPosition = (this.calculatedView as Moodboard).calculatePercentagePositionOnBoard({
      x: relativeItemPosition.x,
      y: relativeItemPosition.y,
    });
    const positionMap = new Map<string, Point>();
    const moveItems = this.snippetMover.itemsMoved(moveId).map(i => JSON.parse(JSON.stringify(i)));
    moveItems.forEach((i: Item) => {
      positionMap.set(i.id, new Point(boardPosition.x, boardPosition.y));
    });
    const boardWidth = (this.calculatedView as Moodboard).calculatePercentageWidthOnBoard(width);
    this.$store.dispatch('droppedOnView', {
      viewId: this.viewId,
      position,
      type: DropAnimationType.PLACE,
    });
    this.$parent?.$emit('place-items', new PlaceItemsEvent(moveItems, positionMap, boardWidth));
  }

  public initRectangularSelection(event: DragEvent) {
    if (this.drag && !this.isMobile) {
      const { clientX, clientY } = event;
      const elementBelowMouse = document.elementFromPoint(clientX, clientY);
      if (elementBelowMouse.classList.contains('snippet-view')) {
        const img = new Image();
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        event.dataTransfer.clearData();
        event.dataTransfer.setDragImage(img, 0, 0);
        this.rectangularSelection = {
          originalX: event.clientX,
          originalY: event.clientY,
          left: event.clientX,
          top: event.clientY,
          width: 0,
          height: 0,
          addItemsToExistingSelection: event.shiftKey,
          alreadyResettedItemsFromPreviousSelection: false,
          items: [],
        };
      }
    }
  }

  public stopRectangularSelection() {
    if (this.rectangularSelection) {
      cancelAnimationFrame(this.rectangularSelectionAnimationFrame);
      const { items } = this.rectangularSelection;
      this.rectangularSelection = null;
      if (!this.isSelectionWithinView) {
        this.internalSelection.clear();
        const actor = this.$roleAwareness.currentUser;
        this.$store.dispatch('selection/addItemsToSelection', { items, actor });
      }
    }
  }

  mounted() {
    if (this.hasResizeObserver) {
      this.registerResizeObserver();
    }
    this.updateSnippetsInStoppedViewDebounced();
    this.snippetMover = new SnippetMover(this.viewId, this.$el as HTMLElement);
    this.snippetMover.on(SnippetMoverEvent.MOVE_START, (data: SnippetMoverMoveStartEvent) => {
      this.$store.dispatch('startDraggingItems', data);
    });
    this.snippetMover.on(SnippetMoverEvent.MOVE_END, ({
      moveId,
      targetSnippet,
      position,
      positionRelative,
      dragOffsets,
      dragItemCenter,
    }: SnippetMoverMoveEndEvent) => {
      clearTimeout(this.preventClicksTimeoutHandler);
      this.preventClicks = true;
      this.preventClicksTimeoutHandler = setTimeout(() => this.preventClicks = false, 250);
      if (position.top > window.innerHeight && position.left > 0 && position.left < window.innerWidth) {
        this.$store.dispatch('downloadDragInfo');
        this.snippetMover.zoomIntoPosition(moveId, position);
      } else if (this.$store.state.dropInfo) {
        this.snippetMover.zoomIntoPosition(moveId, this.$store.state.dropInfo.position);
        this.$store.commit('setDropInfo', null);
      } else if (this.$store.state.droppedOnView) {
        const data: DroppedOnViewEvent = this.$store.state.droppedOnView;
        if (data.type === DropAnimationType.PLACE) {
          this.snippetMover.moveIntoPosition(moveId, position, true, true);
        } else if (data.type === DropAnimationType.SORT) {
          this.snippetMover.sortIntoPosition(moveId, data.sortPointMap);
        }
        this.$store.dispatch('finishDropOnView');
      } else if (this.viewType === ViewType.MOODBOARD) {
        this.snippetMover.moveIntoPosition(moveId, position, true);
        const positionMap = new Map<string, Point>();
        const moveItems = this.snippetMover.itemsMoved(moveId).map(i => JSON.parse(JSON.stringify(i)));
        const relativeItemPosition = new Point(positionRelative.left - dragOffsets.left, positionRelative.top - dragOffsets.top);
        const boardPosition = (this.calculatedView as Moodboard).calculatePercentagePositionOnBoard({
          x: relativeItemPosition.x,
          y: relativeItemPosition.y,
        });
        const { x: originalX, y: originalY } = targetSnippet.item.itemPosition;
        const offsetX = boardPosition.x - originalX;
        const offsetY = boardPosition.y - originalY;
        moveItems.forEach((i: ItemWithPosition) => {
          positionMap.set(i.id, new Point(i.position.x + offsetX, i.position.y + offsetY));
        });
        this.$parent?.$emit('place-items', new PlaceItemsEvent(moveItems, positionMap));
      } else {
        const windowOffsets = this.snippetMover.moveProcess(moveId).windowOffsets;
        const dropPosition: number = this.getDropPositionBasedOnClosestSnippet(dragItemCenter, this.viewType !== ViewType.HORIZONTAL);
        const itemsMoved = this.snippetMover.itemsMoved(moveId);
        const sortItemsEvent: SortItemsEvent = new SortItemsEventInternal(
          dropPosition,
          itemsMoved,
          SortItemsEventType.FILL
        );
        const sortPointMap = new Map<string, PositionWithOptionalSize>();
        const snippetsWithTargetPositions = (this.calculatedView as SortableView).preCalculatePartialSorting(sortItemsEvent);
        snippetsWithTargetPositions.forEach(s => sortPointMap.set(this.getSnippetId(s.item.item), {
          top: s.item.viewPosition.top + windowOffsets.fixedTop,
          left: s.item.viewPosition.left + windowOffsets.fixedLeft,
          width: s.item.viewPosition.width,
          height: s.item.viewPosition.height,
        }));
        this.snippetMover.sortIntoPosition(moveId, sortPointMap);
        this.$parent?.$emit('sort-items', sortItemsEvent);
      }
      this.$store.dispatch('stopDraggingItems');
    });
  }

  beforeDestroy() {
    this.unobserveResizeObserver();
    if (this.viewChangeSubscription) {
      this.viewChangeSubscription.unsubscribe();
    }
  }

  openContextMenu(event: MouseEvent, item: ItemWithPosition) {
    if (item.item.type === 1 && !this.isMobile) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.ITEM, data: { item, viewId: this.viewId }, event });
    }
  }

  private viewOffsets() {
    const windowTop = this.scrollViewElement.getBoundingClientRect().top;
    const windowLeft = this.scrollViewElement.getBoundingClientRect().left;
    const scrollTop = this.scrollViewElement.scrollTop;
    const scrollLeft = this.scrollViewElement.scrollLeft;
    return {
      windowTop,
      windowLeft,
      scrollTop,
      scrollLeft,
    };
  }

  public handleDragOver(event: DragEvent) {
    if (this.rectangularSelection) {
      this.handleDragOverRectangularSelection(event);
    }
  }

  private resetItemsFromCurrentSelection() {
    this.$store.dispatch('selection/dismissItemsFromGlobalSelection', this.items);
    this.rectangularSelection.alreadyResettedItemsFromPreviousSelection = true;
  }

  private updateRectangularSelectionItems(currentSelectionRect: MutantRectangle) {
    if (this.rectangularSelection) {
      const offsetToWindowTop = this.scrollViewElement.getBoundingClientRect().top;
      const offsetToWindowLeft = this.scrollViewElement.getBoundingClientRect().left;
      const scrollOffsetTop = this.scrollViewElement.scrollTop;
      const scrollOffsetLeft = this.scrollViewElement.scrollLeft;
      const currentSelectionRectAdjustedToScrollView = {
        ...currentSelectionRect,
        left: currentSelectionRect.left - offsetToWindowLeft + scrollOffsetLeft,
        top: currentSelectionRect.top - offsetToWindowTop + scrollOffsetTop,
      };
      const currentSelection = this.snippetsInStoppedView
        .map(s => s)
        .filter(snippet => this.rectanglesIntersecting(currentSelectionRectAdjustedToScrollView, {
          top: snippet.item.viewPosition.top,
          height: snippet.item.viewPosition.height,
          width: snippet.item.viewPosition.width,
          left: snippet.item.viewPosition.left,
        }))
        .map((snippet) => snippet.item.item);
      const itemsAdded = _differenceWith(currentSelection, this.rectangularSelection.items, (itemA: ItemWithPosition, itemB: ItemWithPosition) => itemA.id === itemB.id);
      const itemsRemoved = _differenceWith(this.rectangularSelection.items, currentSelection, (itemA: ItemWithPosition, itemB: ItemWithPosition) => itemA.id === itemB.id);
      itemsAdded.forEach((item: ItemWithPosition) => {
        this.internalSelection.set(item.id, item);
      });
      itemsRemoved.forEach((item: ItemWithPosition) => {
        this.internalSelection.delete(item.id);
      });
      this.rectangularSelection.items = currentSelection;
    }
  }

  private handleDragOverRectangularSelection(event: DragEvent) {
    this.dragClientX = event.clientX;
    this.dragClientY = event.clientY;
    this.rectangularSelectionAnimationFrame = requestAnimationFrame(this.animateRectangularSelectionFrame);
  }

  private animateRectangularSelectionFrame() {
    const {
      originalX,
      originalY,
      addItemsToExistingSelection,
      alreadyResettedItemsFromPreviousSelection,
    } = this.rectangularSelection;
    const newWidth = Math.abs(this.dragClientX - originalX);
    const newHeight = Math.abs(this.dragClientY - originalY);
    const currentSelectionRect: MutantRectangle = {
      left: this.dragClientX < originalX ? this.dragClientX : originalX,
      top: this.dragClientY < originalY ? this.dragClientY : originalY,
      width: newWidth,
      height: newHeight,
    };
    if (!addItemsToExistingSelection && !alreadyResettedItemsFromPreviousSelection && newWidth * newHeight > 50) {
      this.resetItemsFromCurrentSelection();
    }
    this.rectangularSelection = {
      ...this.rectangularSelection,
      ...currentSelectionRect,
    };
    this.updateRectangularSelectionItemsThrottled(currentSelectionRect);
  }

  // TODO: improve performance by precalculating left + width / top + height on rects
  private rectanglesIntersecting(rectA: MutantRectangle, rectB: MutantRectangle): boolean {
    return rectA.left < rectB.left + rectB.width
      && rectA.left + rectA.width > rectB.left
      && rectA.top < rectB.top + rectB.height
      && rectA.height + rectA.top > rectB.top;
  }

  public reviewStyle(snippet: Snippet) {
    const obj: any = {
      top: `${this.getSnippetHeight(snippet)}px`,
      left: `${snippet.item.viewPosition.left}px`,
      width: `${snippet.item.viewPosition.width}px`,
      opacity: this.getItemOpacity(snippet),
    };
    return obj;
  }

  public fileInfoStyle(snippet: Snippet) {
    const obj: any = {
      left: `${snippet.item.viewPosition.left}px`,
      width: `${snippet.item.viewPosition.width}px`,
      opacity: this.getItemOpacity(snippet),
    };
    return obj;
  }

  public isCenteredItem(snippet: Snippet) {
    return this.$store.state.cloud.highlightInfo?.item?.itemId === snippet.item.item.itemId;
  }

  public get offCenterItemsOpacity() {
    return this.$store.getters['cloud/offCenterItemsOpacity'];
  }

  public getItemOpacity(snippet: Snippet): string {
    return this.isCenteredItem(snippet) ? '1' : this.offCenterItemsOpacity;
  }

  public get reviewInfoAnimated(): boolean {
    return this.offCenterItemsOpacity !== '0';
  }

  public snippetWrapperStyle(snippet: Snippet, idx: number) {
    const snippetHeight = this.getSnippetHeight(snippet);
    const reviewModeOffset = this.isMainViewInReviewMode ? 25 : 0;
    const navigationLaneReviewOffset = this.isNavigationViewInReviewMode ? snippetHeight * 0.05 : 0;
    const itemSizeFactor = this.isNavigationViewInReviewMode ? 0.9 : 1;
    const width = snippet.item.viewPosition.width * itemSizeFactor;
    const leftOffset = (snippet.item.viewPosition.width - width) / 2;

    const obj: any = {
      width: `${width}px`,
      height: `${(snippetHeight - reviewModeOffset) * itemSizeFactor}px`,
      zIndex: `${snippet.item.zindex || this.calculatedView.snippets.length - idx}`,
      top: `${snippet.item.viewPosition.top + reviewModeOffset + navigationLaneReviewOffset}px`,
      left: `${snippet.item.viewPosition.left + leftOffset}px`,
      cssWillChange: this.cssWillChange,
    };
    if (snippet.item.transform) {
      obj.transform = snippet.item.transform;
      obj.transformOrigin = 'center';
    }
    // Enlarges (zooms) snippet slightly on hover when showHoverPreview is active
    if (this.showHoverPreview) {
      if (snippet.item.id === this.hoverItemId || snippet.item.id === this.wasHoveredItemId) {
        obj.transition = 'transform 0.5s';
      }
      if (snippet.item.id === this.hoverItemId) {
        obj.transformOrigin = 'center center';
        obj.transform = snippet.item.transform ? `${snippet.item.transform} scale(${1.05})` : `scale(${1.05})`;
      }
    }
    return obj;
  }

  public snippetStyle(snippet) {
    const snippetHeight = this.getSnippetHeight(snippet);
    const obj: any = {};
    if (this.hasBoxShadow) {
      const shadowSize = this.boxShadowSize(snippetHeight);
      if (this.hasSmallerBoxShadow) {
        obj.boxShadow = `${shadowSize / 1.5 + 1}px ${shadowSize / 1.5 + 1}px ${shadowSize * 2}px ${shadowSize / 2}px rgba(0,0,0,0.35)`;
      } else {
        obj.boxShadow = `${shadowSize}px ${shadowSize}px ${shadowSize * 2 + 2}px ${shadowSize / 2}px rgba(0,0,0,0.35)`;
      }
    }
    return obj;
  }

  handleRemove(snippet: Snippet) {
    this.$emit('remove-item', snippet.item.item);
  }

  // TODO: refactor into SnippetResizer
  isInResizeTriggerRange(event: DragEvent, snippet: Snippet) {
    const originalElement = document.getElementById(`${this.viewId}_${snippet.item.id}`);
    if (originalElement) {
      const scrollOffsetTop = this.scrollViewElement.scrollTop;
      const scrollOffsetLeft = this.scrollViewElement.scrollLeft;
      const offsetToWindowTop = this.scrollViewElement.getBoundingClientRect().top;
      const offsetToWindowLeft = this.scrollViewElement.getBoundingClientRect().left;
      const { width, height } = snippet.item.viewPosition;
      let { top, left } = snippet.item.viewPosition;
      left = left - scrollOffsetLeft + offsetToWindowLeft;
      top = top - scrollOffsetTop + offsetToWindowTop;
      const snippetRotation = snippet.item.itemPosition?.rotation || 0;
      let projectedPoint;
      if (snippetRotation) {
        const origin = new Point(left + width / 2, top + height / 2);
        projectedPoint = new Point(event.clientX, event.clientY).rotate(origin, snippetRotation);
      } else {
        projectedPoint = new Point(event.clientX, event.clientY);
      }
      let resizeTriggerRange = this.resizeTriggerRange.percentage * width;
      resizeTriggerRange = resizeTriggerRange > this.resizeTriggerRange.minPixel ? resizeTriggerRange : this.resizeTriggerRange.minPixel;
      const offsetX = projectedPoint.x - left;
      const offsetY = projectedPoint.y - top;
      return offsetX < resizeTriggerRange
        || offsetX > width - resizeTriggerRange
        || offsetY < resizeTriggerRange
        || offsetY > height - resizeTriggerRange;
    }
    return false;
  }

  dragStart(event: DragEvent, snippet: Snippet) {
    if (this.drag && !this.isMobile) {
      event.dataTransfer.setData('MUTANT_ITEM_ID', snippet.item.id);
      // event.dataTransfer.setData('DownloadURL', await this.getDownloadUrl(snippet)); TODO: only add this when user has the intent to drag the item onto the desktop
      event.dataTransfer.setData(TransferOption.MUTANT_VIEW_ID + '_' + this.viewId, 'true');
      event.dataTransfer.setData(TransferOption.MUTANT_VIEW_ID, this.viewId);
      if (this.isReferencedToSharedLink) {
        event.dataTransfer.setData(TransferOption.MUTANT_VIEW_REFERENCED_TO_SHARED_LINK, 'true');
      }
      // TODO: simplify if-else
      if (this.isMoodboardView && this.isInResizeTriggerRange(event, snippet) && !this.$store.getters['selection/isOneOfItemsSelected'](this.items)) {
        this.handleItemResize(event, snippet);
      } else {
        let moveItems: ItemWithPosition[] = [snippet.item.item];
        // Use items from internal selection if view supports it
        if (!this.disableSelectionBorders && this.isSelectable && this.isSelectionWithinView) {
          moveItems = this.internalSelection.size ? [...this.internalSelection.values()] : moveItems;
        } else if (!this.disableSelectionBorders && this.isSelectable && (this.$store.getters['selection/isOneOfItemsSelected'](this.items))) {
          if (!this.$store.getters['selection/isItemSelected'](snippet.item.item)) {
            // Inverted Selection is still active, so we don't want to drag/move an item here
            if (!this.invertedSelectionIntentTimedOut) {
              const img = new Image();
              img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
              event.dataTransfer.clearData();
              event.dataTransfer.effectAllowed = 'all';
              event.dataTransfer.dropEffect = 'move';
              event.dataTransfer.setDragImage(img, 0, 0);
              this.isInvertedSelectionActive = true;
              return;
            }
          } else {
            moveItems = this.$store.getters['selection/filterSelectedItems'](this.items);
          }
        }
        this.moveSnippets(event, snippet, moveItems, !this.isMoodboardView, this.isMoodboardView);
      }
    }
  }

  touchStart(event: DragEvent, snippet: Snippet) {
    // only handle touch event if it is not mobile
    if (!this.isMobile) {
      if (this.isMoodboardView && this.isInResizeTriggerRange(event, snippet) && !this.$store.getters['selection/isOneOfItemsSelected'](this.items)) {
        this.handleItemResize(event, snippet);
      } else {
        let moveItems = [snippet.item.item];
        if (!this.disableSelectionBorders && this.isSelectable && this.isSelectionWithinView) {
          moveItems = this.internalSelection.size ? [...this.internalSelection.values()] : moveItems;
        } else if (!this.disableSelectionBorders && this.isSelectable && this.$store.getters['selection/isOneOfItemsSelected'](this.items)) {
          if (!this.$store.getters['selection/isItemSelected'](snippet.item.itemData)) {
            if (!this.invertedSelectionIntentTimedOut) {
              this.isInvertedSelectionActive = true;
              return;
            }
          } else {
            moveItems = this.$store.getters['selection/filterSelectedItems'](this.items);
          }
        }
        this.moveSnippets(event, snippet, moveItems, !this.isMoodboardView, this.isMoodboardView);
      }
    }
  }

  private moveSnippets(event: DragEvent, snippet: Snippet, moveItems: ItemWithPosition[], popOut: boolean, keepRelativePositions: boolean = false) {
    this.adjustSnippetMoverDragBoundaries();
    this.snippetMover.moveSnippets(
      event,
      snippet,
      moveItems,
      popOut,
      keepRelativePositions
    );
  }

  private adjustSnippetMoverDragBoundaries() {
    if (this.viewId === ViewIdentifier.MAIN_VIEW && this.$store.state.cloudMenuRightOpen && this.$store.state.activeCloudMenuTab === CloudMenuTab.DROPZONE) {
      this.snippetMover.setMoveBoundaryRight(this.$store.state.cloudMenuRightWidth || 0);
    }
  }

  async getDownloadUrl(snippet: Snippet) {
    const asset = this.$imageLoader.getOptimalAssetByWidth(snippet.item.itemData, snippet.width);
    return asset ? `${asset.mimeType}:${asset.name}:${await this.$imageLoader.loadAssetUrl(snippet.item.itemData, asset)}` : '';
  }

  // TODO: refactor into SnippetResizer
  handleItemResize(event: DragEvent, snippet: Snippet) {
    const item = snippet.item.item;
    const metaKeyPressed = event.metaKey;
    if (this.itemsCurrentlyDragged.some(i => i.id === item.id)) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }
    this.itemsCurrentlyDragged = [item];
    const that = this;
    if (!metaKeyPressed) {
      const img = new Image();
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
      event.dataTransfer.effectAllowed = 'all';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setDragImage(img, 0, 0);
    }
    const originalElement = document.getElementById(`${this.viewId}_${item.id}`);
    const elementCopy = originalElement.cloneNode(true) as HTMLElement;
    const originalElementCopy = originalElement.cloneNode() as HTMLElement;
    originalElement.style.opacity = '0';
    originalElement.classList.add('snippet-wrapper--no-transitions');
    const scrollOffsetTop = this.scrollViewElement.scrollTop;
    const scrollOffsetLeft = this.scrollViewElement.scrollLeft;
    const offsetToWindowTop = this.scrollViewElement.getBoundingClientRect().top;
    const offsetToWindowLeft = this.scrollViewElement.getBoundingClientRect().left;
    const htmlString = `<div>${elementCopy.outerHTML}</div>`;
    event.dataTransfer.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.ITEM);
    event.dataTransfer.setData(TransferOption.MUTANT_ID, item.id);
    event.dataTransfer.setData('text/html', htmlString);
    if (!metaKeyPressed) {
      elementCopy.style.zIndex = `${parseInt(elementCopy.style.zIndex, 10) - 10}`;
      elementCopy.classList.add('snippet-wrapper--is-resized');
      elementCopy.style.left = parseInt(elementCopy.style.left.split('px')[0], 10) + offsetToWindowLeft - scrollOffsetLeft + 'px';
      elementCopy.style.top = parseInt(elementCopy.style.top.split('px')[0], 10) + offsetToWindowTop - scrollOffsetTop + 'px';
      document.body.appendChild(elementCopy);
    }
    const { width, height } = snippet.item.viewPosition;
    let { left, top } = snippet.item.viewPosition;
    left = left - scrollOffsetLeft + offsetToWindowLeft;
    top = top - scrollOffsetTop + offsetToWindowTop;
    const centerOrigin = new Point(left + width / 2, top + height / 2);
    const startRotation = snippet.item.itemPosition?.rotation || 0;
    let dragPoint = new Point(event.clientX, event.clientY);
    dragPoint = startRotation ? dragPoint.rotate(centerOrigin, 360 - startRotation) : dragPoint;
    const distanceFromOriginStart = dragPoint.distanceTo(centerOrigin);
    let deg = 0;
    let animationFrame;
    let newWidth = width;
    let newHeight = height;
    let offsetX = 0;
    let offsetY = 0;
    let mousePosition = new Point(event.clientX, event.clientY);
    const dragAngleToCenter = centerOrigin.angleTo(mousePosition);
    const startDeg = dragAngleToCenter - startRotation;

    function onDrag(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if (event.clientX > 0 && event.clientY > 0) {
        mousePosition = new Point(event.clientX, event.clientY);
      }
    }

    function updateDragItemPosition() {
      const distanceFromOrigin: number = mousePosition.distanceTo(centerOrigin);
      const distanceChange = distanceFromOrigin - distanceFromOriginStart;
      const angle = Math.atan(width / height);
      offsetX = Math.sin(angle) * distanceChange;
      offsetY = Math.cos(angle) * distanceChange;
      const newAngle = centerOrigin.angleTo(mousePosition);
      deg = Math.trunc(newAngle - startDeg + 360) % 360;
      newWidth = width + 2 * offsetX;
      newHeight = height + 2 * offsetY;
      const rotation = `${deg}deg`;
      const scaleX = newWidth / width;
      const scaleY = newHeight / height;
      elementCopy.style.position = 'fixed';
      elementCopy.style.zIndex = `${parseInt(elementCopy.style.zIndex, 10) + 3000}`;
      elementCopy.style.transform = `scaleX(${scaleX}) scaleY(${scaleY}) rotate(${rotation})`;
      elementCopy.style.transformOrigin = 'center';
      animationFrame = requestAnimationFrame(updateDragItemPosition);
    }

    function onDragEnd(_event: DragEvent) {
      cancelAnimationFrame(animationFrame);
      const { left, top } = snippet.item.viewPosition;
      const { x, y } = (that.calculatedView as Moodboard).calculatePercentagePositionOnBoard({
        x: left - offsetX,
        y: top - offsetY,
      });
      const convertedWidth = (that.calculatedView as Moodboard).calculatePercentageWidthOnBoard(newWidth);
      const resizeItemEvent: ResizeItemEvent = new ResizeItemEvent(item, convertedWidth, deg, x, y);
      that.$parent?.$emit('resize-item', resizeItemEvent);
      elementCopy.style.zIndex = '9999';
      elementCopy.style.opacity = '1';
      elementCopy.style.boxShadow = '0px 0px 25px 7px rgba(0,0,0,0)';
      that.itemsCurrentlyDragged = [];
      const originalElementReference = document.getElementById(originalElementCopy.id);
      originalElementReference.style.opacity = originalElementCopy.style.opacity;
      setTimeout(() => {
        elementCopy.remove();
        originalElement.classList.remove('snippet-wrapper--no-transitions');
      }, 0);
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', onDragEnd);
      that.$store.dispatch('stopDraggingItems');
    }

    if (!metaKeyPressed) {
      animationFrame = requestAnimationFrame(updateDragItemPosition);
      document.addEventListener('mousemove', onDrag);
    }
    document.addEventListener('mouseup', onDragEnd);
  }

  getDropPositionBasedOnClosestSnippet(position: { left: number, top: number }, sortToEnd = true): number {
    const maxDistanceFromClosestSnippet = 200;
    let closestSnippetIndex: number = null;
    let closestSnippetWidth = 0;
    let proximityToClosestSnippet = 0;
    for (let idx = 0; idx < this.calculatedView.snippets.length; idx++) {
      const snippet = this.calculatedView.snippets[idx];
      const proximity = Math.abs((position.left - (snippet.item.viewPosition.left + snippet.item.viewPosition.width / 2))) + Math.abs((position.top - (snippet.item.viewPosition.top + snippet.item.viewPosition.height / 2)));
      if (closestSnippetIndex === null || proximity < proximityToClosestSnippet) {
        closestSnippetIndex = idx;
        closestSnippetWidth = snippet.width;
        proximityToClosestSnippet = proximity;
      }
    }
    return (proximityToClosestSnippet > closestSnippetWidth / 2 + maxDistanceFromClosestSnippet) && sortToEnd ? this.calculatedView.snippets.length : closestSnippetIndex || 0;
  }

  dragEnd() {
    this.isInvertedSelectionActive = false;
  }

  dragEnter(event: DragEvent, item: ItemWithPosition) {
    if (this.isSelectable && this.isInvertedSelectionMode) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.toggleItemSelectionIntent(item);
      this.toggleItemSelection(item);
    }
  }

  openModal(snippet: Snippet) {
    this.$store.dispatch('detailView/open', {
      currentItem: snippet.item.item,
      linkedViewId: this.viewId,
    });
  }

  handleMouseEnter(item: Item) {
    this.hoverItemId = item.id;
  }

  updateSnippetsInStoppedView() {
    if (this.scrollViewElement) {
      const scrollOffsetTop = this.scrollViewElement.scrollTop;
      const scrollOffsetLeft = this.scrollViewElement.scrollLeft;
      const { width, height } = this.scrollViewElement.getBoundingClientRect();
      const currentViewRange = {
        start: this.viewType === ViewType.HORIZONTAL ? scrollOffsetLeft - width : scrollOffsetTop - height,
        end: this.viewType === ViewType.HORIZONTAL ? scrollOffsetLeft + width * 2 : scrollOffsetTop + height * 2,
      };
      this.snippetsInStoppedView = this.calculatedView?.snippets.filter(snippet => this.isInViewRange(snippet, currentViewRange)) || [];
    }
  }

  resetWasHoveredItemIdIfItMatches(id: string) {
    if (this.wasHoveredItemId === id) {
      this.wasHoveredItemId = null;
    }
  }

  handleMouseLeave(item: ItemWithPosition) {
    this.hoverItemId = null;
    this.wasHoveredItemId = item.id;
    setTimeout(() => this.resetWasHoveredItemIdIfItMatches(item.id), 500);
  }

  handleClickIntent(item: ItemWithPosition) {
    if (this.preventClicks) {
      this.invertedSelectionIntentTimedOut = true;
      return;
    }
    if (this.$store.getters['context/isOpen'](ContextMenuType.ITEM)) {
      this.$store.dispatch('context/closeMenu', ContextMenuType.ITEM);
    }
    if (this.isInNavigationMode) {
      this.highlightItem(item);
    } else {
      this.toggleItemSelectionIntent(item);
    }
  }

  handleClick(item: ItemWithPosition) {
    if (this.preventClicks) {
      this.invertedSelectionIntentTimedOut = true;
      return;
    }
    if (this.isInNavigationMode) {
      this.highlightItem(item);
    } else {
      this.toggleItemSelection(item);
    }
  }

  handleMetaClick(item: ItemWithPosition) {
    if (this.isInNavigationMode) {
      this.toggleItemSelectionIntent(item);
      this.toggleItemSelection(item);
    } else {
      this.highlightItem(item);
    }
  }

  private get activeView(): ViewIdentifier {
    return this.$store.state.cloud.activeWindowId;
  }

  highlightItem(item: ItemWithPosition) {
    const originView = this.viewId;
    this.$store.dispatch('cloud/highlightItem', { originView, item, targetView: this.activeView, scroll: true });
    this.$store.dispatch('cloud/setCenteredItemInView', { position: item.position.order });
  }

  toggleItemSelectionIntent(item: ItemWithPosition) {
    if (this.isSelectable) {
      if (this.isSelectionWithinView) {
        if (this.internalSelection.has(item.id)) {
          this.internalSelection.delete(item.id);
        } else {
          this.internalSelection.set(item.id, item);
        }
        this.$forceUpdate();
      } else if (this.$store.getters['selection/isItemSelected'](item)) {
        this.$store.commit('selection/deSelectItem', item);
      } else {
        const actor = this.$roleAwareness.currentUser;
        this.$store.commit('selection/selectItem', { item, actor });
      }
    }
  }

  toggleItemSelection(item: ItemWithPosition) {
    if (this.isSelectable && !this.isSelectionWithinView) {
      if (this.$store.getters['selection/isItemSelected'](item)) {
        this.$store.dispatch('selection/addItemsToSelection', { items: [item] });
        this.invertedSelectionIntentTimedOut = false;
        this.invertedSelectionIntentTriggeredByItem = item.id;
        setTimeout(() => {
          if (this.invertedSelectionIntentTriggeredByItem === item.id) {
            this.invertedSelectionIntentTimedOut = true;
          }
        }, 1250);
      } else {
        this.$store.dispatch('selection/removeItemsFromSelection', { items: [item] });
      }
    }
  }

  calculateSelectedItemBorderSizeByWidth(width: number) {
    if (width >= 500) {
      return 9;
    }
    if (width >= 400) {
      return 8;
    }
    if (width >= 300) {
      return 7;
    }
    if (width >= 175) {
      return 6;
    }
    if (width >= 75) {
      return 5;
    }
    return 4;
  }

  public isInViewRange(snippet: Snippet, viewRange: ViewRange) {
    const { top, left } = snippet.item.viewPosition;
    return this.viewType === ViewType.HORIZONTAL
      ? left >= viewRange.start && left <= viewRange.end
      : top >= viewRange.start && top <= viewRange.end;
  }

  public isInViewRangeCached(snippet: Snippet) {
    const { top, left } = snippet.item.viewPosition;
    if (!this.viewRange) {
      return true;
    }
    return this.viewType === ViewType.HORIZONTAL
      ? left >= this.viewRange.start && left <= this.viewRange.end
      : top >= this.viewRange.start && top <= this.viewRange.end;
  }

  resizeView(): void {
    this.cssWillChange = 'left, top, height, width';
    if (this.calculatedView && (this.calculatedView.height > 0 || this.calculatedView.width > 0)) {
      clearTimeout(this.viewTransitionTimeoutHandler);
      this.activeMovementTransitions = this.overrideViewTransitions;
      const height = this.viewType === ViewType.MOODBOARD ? this.moodboardOptions.height : this.calculatedViewHeight;
      this.calculatedView.resize(this.viewWidth, height);
      if (!this.activeMovementTransitions) {
        this.viewTransitionTimeoutHandler = setTimeout(() => {
          this.activeMovementTransitions = true;
        }, 500);
      }
      this.$emit('resize', {
        height: this.calculatedView.height,
        width: this.calculatedView.width,
      });
      if (this.isMainViewInReviewMode || this.isNavigationViewInReviewMode) {
        this.goToHighlightedItem(this.highlightItemId, 'auto');
      }
    }
    this.cssWillChange = 'auto';
  }

  renderSelectedViewType(): void {
    switch (this.viewType) {
      case ViewType.CONTACT_SHEET:
        this.renderContactSheet();
        break;
      case ViewType.GRID:
        this.renderGrid();
        break;
      case ViewType.MOODBOARD:
        this.renderMoodboard();
        break;
      case ViewType.HORIZONTAL:
        if (this.isDistorted) {
          this.buildDistortionMap();
        }
        this.renderHorizontalView();
        break;
      default:
        this.renderMosaic();
    }
  }

  renderView(): void {
    this.renderViewSubject.next('render');
    this.cssWillChange = 'left, top, height, width';
    clearTimeout(this.blockResizeTimeoutHandler);
    this.blockResizeHandler = true;
    this.itemSelectedUseOutline = this.itemMargin === 0;
    this.renderSelectedViewType();
    this.blockResizeTimeoutHandler = setTimeout(() => {
      this.blockResizeHandler = false;
    }, 500);
    this.$emit('render', this.calculatedView);
    this.cssWillChange = 'auto';
  }

  renderHorizontalView(): void {
    let barViewBuilder = new HorizontalViewBuilder()
      .setItems(this.items)
      .setDisplayCenteredItems(this.isMainViewInReviewMode)
      .setViewWidth(this.viewWidth)
      .setItemHeight(this.calculatedViewHeight)
      .setItemSpacing(this.itemMargin);
    if (this.isDistorted) {
      barViewBuilder = barViewBuilder
        .setItemOverlap(5)
        .setDistortionMap(this.distortionMap);
    }
    this.calculatedView = barViewBuilder.build();
    this.itemSelectedBorder = this.calculateSelectedItemBorderSizeByHeight(this.calculatedViewHeight);
  }

  renderMosaic(): void {
    this.calculatedView = new MosaicBuilder()
      .setColumnCount(this.columnCount)
      .setItems(this.items)
      .setMarginBetweenItems(this.itemMargin)
      .setViewWidth(this.viewWidth)
      .withFullSnippetHeight(this.showSnippets)
      .build();
    this.itemSelectedBorder = this.calculateSelectedItemBorderSizeByWidth(this.calculatedView ? this.calculatedView.width / this.columnCount : 0);
  }

  renderGrid(): void {
    this.calculatedView = new GridBuilder()
      .setViewWidth(this.viewWidth)
      .setItems(this.items)
      .setMarginBetweenItems(this.itemMargin)
      .setRowHeight(this.gridRowHeight)
      .build();
    this.itemSelectedBorder = this.calculateSelectedItemBorderSizeByHeight(this.gridRowHeight);
  }

  renderContactSheet(): void {
    this.calculatedView = new ContactSheetBuilder()
      .setViewWidth(this.viewWidth)
      .setItems(this.items)
      .setMinViewWidth(this.view.viewOptions.contactSheet.minColumnWidth)
      .setMaxWidth(this.view.viewOptions.contactSheet.maxColumnWidth)
      .setMarginBetweenItems(this.itemMargin)
      .setTextSize(this.contactSheetTextSize)
      .setColumnCount(this.contactSheetColumnCount)
      .setMinColumnCount(this.view.viewOptions.contactSheet.minColumnCount)
      .setMaxColumnCount(this.view.viewOptions.contactSheet.maxColumnCount)
      .build();
    this.itemSelectedBorder = this.calculateSelectedItemBorderSizeByWidth(0);
  }

  renderMoodboard(): void {
    let calculatedView = new MoodboardBuilder()
      .setViewWidth(this.moodboardOptions?.width || this.viewWidth)
      .setViewHeight(this.moodboardOptions?.height || this.gridRowHeight)
      .setItems(this.items);
    if (this.moodboardOptions.backgroundWidth) {
      calculatedView = calculatedView
        .setBackgroundDimensions({
          width: this.moodboardOptions.backgroundWidth,
          height: this.moodboardOptions.backgroundHeight,
        })
        .setBackgroundFit(this.moodboardOptions.backgroundFit);
    }
    this.calculatedView = calculatedView.build();
    this.itemSelectedBorder = 2;
  }

  calculateSelectedItemBorderSizeByHeight(height: number) {
    if (height >= 450) {
      return 8;
    }
    if (height >= 350) {
      return 7;
    }
    if (height >= 250) {
      return 6;
    }
    if (height >= 125) {
      return 5;
    }
    return 4;
  }

  private randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private buildDistortionMap() {
    this.items.forEach(item => {
      if (!this.distortionMap[item.id]) {
        this.distortionMap[item.id] = {
          yAxis: this.randomInteger(-2, 8),
          rotation: this.randomInteger(-2, 2),
        };
      }
    });
  }

  private unobserveResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$el);
    }
  }

  private registerResizeObserver() {
    // @ts-ignore
    if (ResizeObserver) {
      // @ts-ignore
      this.resizeObserver = new ResizeObserver(() => this.resizeViewSubject.next('resize')).observe(this.$el);
    }
  }

  private get scrollViewElement() {
    return this.scrollElementId ? document.getElementById(this.scrollElementId) : this.$el?.parentElement;
  }

  @Watch('viewRange')
  onWatchViewRangeChanged() {
    this.updateSnippetsInStoppedViewDebounced();
  }

  @Watch('moodboardOptions')
  onWatchMoodboardOptionsChanged(newValue: MoodboardOptions, oldValue: MoodboardOptions) {
    if (this.viewType === ViewType.MOODBOARD) {
      if (newValue.height !== oldValue?.height) {
        this.resizeViewSubject.next('resize');
      }
      if (newValue.backgroundFit !== oldValue.backgroundFit) {
        (this.calculatedView as Moodboard)
          .setBackgroundFit(newValue.backgroundFit);
      }
    }
  }

  public get shouldResizeView(): string {
    return this.width.toString() + this.calculatedViewHeight;
  }

  private async goToHighlightedItem(highlightItemId: string, scrollBehavior: ScrollBehavior) {
    while (!this.calculatedView) {
      await new Promise(resolve => setTimeout(resolve, 250));
    }
    const highlightSnippet: Snippet = this.calculatedView.snippets.find(snippet => snippet.item.id === highlightItemId);
    if (highlightSnippet) {
      const { top, left, height, width } = highlightSnippet.item.viewPosition;
      this.$emit('item-highlight-position', { top, left, height, width, scrollBehavior });
    }
  }

  @Watch('viewHeight')
  onWatchBarHeightChanged(newValue: number, oldValue: number) {
    if (!this.ignoreHeightChanges) {
      this.hasHeightTransition = oldValue && (newValue >= oldValue + 250 || newValue <= oldValue - 250);
      if (this.hasHeightTransition) {
        clearTimeout(this.heightTransitionTimeoutHandler);
        this.heightTransitionTimeoutHandler = setTimeout(() => this.hasHeightTransition = false, 1000);
      }
    }
  }

  @Watch('highlightItemId', { immediate: true })
  async onHighlightItemChange(highlightItemId: string) {
    if (this.$store.state.cloud.highlightInfo?.scroll) {
      await this.goToHighlightedItem(highlightItemId, 'smooth');
    }
  }

  public get shouldReRenderView(): string {
    return this.view?.viewHash;
  }

  public rerenderView(activeMovement: boolean) {
    clearTimeout(this.viewTransitionTimeoutHandler);
    clearTimeout(this.activeTransitionTimeoutHandler);
    this.activeTransition = 'snippet-group';
    // this makes sure that we only use the fade-in group transition if the view is actually rerendered
    // e.g. new items are added or view is changed
    this.activeTransitionTimeoutHandler = setTimeout(() => {
      this.activeTransition = null;
    }, 1000);
    this.activeMovementTransitions = activeMovement;
    this.renderView();
    this.updateSnippetsInStoppedViewDebounced();
  }

  @Watch('shouldReRenderView', { immediate: true })
  onWatchViewChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.rerenderView(!!oldVal);
    }
  }

  @Watch('shouldResizeView')
  onWatchWidthChanged(newVal: string, oldVal: string) {
    if (!this.ignoreHeightChanges && newVal !== oldVal) {
      this.resizeViewSubject.next('resize');
      this.updateSnippetsInStoppedViewDebounced();
    }
  }

  @Watch('isMainViewInReviewMode')
  onWatchIsReviewMode(oldVal, newVal) {
    if (oldVal !== newVal) {
      this.rerenderView(true);
    }
  }

  @Watch('items')
  onWatchItemCountChanged(newItems: Item[], oldItems: Item[] | null) {
    if (oldItems !== newItems) {
      this.rerenderView(!!oldItems);
    }
    this.hasWidthTransition = !(!oldItems || newItems.length > oldItems.length);
    if (this.hasWidthTransition) {
      clearTimeout(this.widthTransitionTimeoutHandler);
      this.widthTransitionTimeoutHandler = setTimeout(() => {
        this.hasWidthTransition = false;
        clearTimeout(this.widthTransitionTimeoutHandler);
      }, 1000);
    }
    this.activeMovementTransitions = true;
  }

  @Watch('hasResizeObserver')
  onWatchHasResizeObserverChange(hasResizeObserver) {
    if (hasResizeObserver) {
      this.registerResizeObserver();
    } else {
      this.unobserveResizeObserver();
    }
  }
}
