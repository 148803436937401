
import { Component, Vue } from 'nuxt-property-decorator';
import { ViewType } from '~/models/views/ViewType';

@Component
export default class ShareOverlay extends Vue {
  public createSharedMosaic() {
    this.$store.dispatch('link/createSharedView', ViewType.MOSAIC);
  }

  public createSharedContactSheet() {
    this.$store.dispatch('link/createSharedView', ViewType.HORIZONTAL);
  }

  public createSharedMoodboard() {
    this.$store.dispatch('link/createSharedView', ViewType.MOODBOARD);
  }
}
