
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Row from '~/components/ui/Row.vue';
import MutantBadge from '~/components/ui/MutantBadge.vue';
import { RatingsFileTypes } from '~/store/cloud/getters';

@Component({
  components: {
    MutantBadge,
    Row,
  },
})
export default class TagWithBadge extends Vue {
  @Prop()
  public filteredCount: RatingsFileTypes;

  @Prop(Boolean)
  public selected: boolean;

  public get rawToolTip() {
    return {
      placement: 'top',
      content: `${this.filteredCount?.raws} raw images`,
    };
  }

  public get originalsTooltip() {
    return {
      placement: 'top',
      content: `${this.filteredCount?.originals} originals images`,
    };
  }

  public get totalTooltip() {
    return {
      placement: 'top',
      content: `${this.filteredCount?.total} total images`,
    };
  }
}
