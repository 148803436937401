
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { User } from '~/models/user/User';
import MutantActiveIcon from '~/components/ui/MutantActiveIcon.vue';
import MutantProfileImage from '~/components/ui/MutantProfileImage.vue';
import UserPanelDropdown from '~/components/layout/header/user-panel/UserPanelDropdown.vue';
import Folder from '~/models/Folder';
import { UserRole } from '~/models/user/UserRole';
import { ContextMenuType } from '~/store/context/state';
import InfoButton from '~/components/ui/buttons/InfoButton.vue';

@Component({
  components: {
    InfoButton,
    UserPanelDropdown,
    MutantProfileImage,
    MutantActiveIcon,
  },
})
export default class TopNavigationMenu extends Vue {
  public UserRole = UserRole;
  @Prop()
  public folder!: Folder;

  @Prop()
  public user!: User;

  @Prop(Boolean)
  public hideUsername: boolean;

  public isUserPanelOpen = false;
  public showLogin = true;
  public isDragOver = false;

  constructor() {
    super();
    window.addEventListener('paste', this.handlePasteEvent, false);
  }

  public get profile() {
    return this.$store.state.profile.data;
  }

  public get isGuestUser(): boolean {
    return this.$roleAwareness.isGuestUser;
  }

  beforeDestroy() {
    window.removeEventListener('paste', this.handlePasteEvent);
  }

  handlePasteEvent(event) {
    if (this.isUserPanelOpen) {
      event.stopImmediatePropagation();
    }
  }

  public toggleUserPanel() {
    if (this.$roleAwareness.isGuestUser) {
      if (this.$store.getters['context/isOpen'](ContextMenuType.LOGIN)) {
        this.$store.dispatch('context/closeMenu', ContextMenuType.LOGIN);
      } else {
        this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { showLogin: true } });
      }
    } else {
      this.isUserPanelOpen = !this.isUserPanelOpen;
    }
  }

  public closeUserPanel() {
    this.isUserPanelOpen = false;
  }

  public get websocketStatusColor() {
    return this.$store.state.isConnectedToWebsocket ? 'yellowgreen' : 'red';
  }
}
