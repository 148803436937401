
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import MutantCheckbox from '~/components/ui/MutantCheckbox.vue';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import MutantContextMenuHeader from '~/components/ui/MutantContextMenuHeader.vue';
import MutantDropDown from '~/components/ui/MutantDropDown.vue';
import Folder from '~/models/Folder';
import { MessageRoute } from '~/models/MessageRoute';
import { ObjectId } from '~/models/ObjectId';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';

@Component({
  components: {
    MutantDropDown,
    MutantCheckMark,
    MutantCheckbox,
    MutantContextMenuHeader,
  },
})
export default class ReceiveContentSection extends Vue {
  @Prop()
    viewId: ViewIdentifier;

  mounted() {
    this.$store.dispatch('user/fetchMessageRoutes');
  }

  public get isReceiveContentDestination(): boolean {
    return this.viewUuid === this.viewMessageRoute?.folderId || this.viewUuid === this.viewMessageRoute?.selectionId;
  }

  public get scrapbook(): Folder {
    return this.$store.getters['folder/scrapbook'];
  }

  public toggleReceiveContentHere() {
    if (this.isReceiveContentDestination) {
      this.$store.dispatch('user/removeMessageRoute', this.viewMessageRoute?.id);
    } else {
      const objectId: ObjectId = this.$store.getters['cloud/window'](this.viewId).viewIds[0];
      const messageRoute: any = {};
      if (objectId.isSelectionId) {
        messageRoute.selectionId = objectId.toUuid();
      } else if (objectId.isFolderId) {
        messageRoute.folderId = objectId.toUuid();
      }
      this.$store.dispatch('user/addMessageRoute', messageRoute);
    }
  }

  private get viewUuid() {
    return this.$store.getters['cloud/viewObjectId'](this.viewId).toUuid();
  }

  public get viewMessageRoute(): MessageRoute {
    return this.$store.state.user.messageRoutes[0];
  }

  public async visitMessageRoute() {
    const objectId = this.viewMessageRoute
      ? (this.viewMessageRoute.folderId && ObjectId.fromFolderId(this.viewMessageRoute.folderId))
      || (this.viewMessageRoute.selectionId && ObjectId.fromSelectionId(this.viewMessageRoute.selectionId))
      : ObjectId.fromFolderId(this.scrapbook.id);
    await this.$store.dispatch('cloud/addToPane', { windowId: this.viewId, objectIds: [objectId] });
  }
}
