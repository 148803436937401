
import { Component, Vue } from 'nuxt-property-decorator';
import { CloudMenuTab } from '~/models/CloudMenuTab';
import MenuRightContent from '~/components/menu/right/MenuRightContent.vue';
import ItemOverviewBadges from '~/components/menu/right/ItemOverviewBadges.vue';
import MutantGrabberMenu, { Direction } from '~/components/menu/left/MutantGrabberMenu.vue';
import MutantBurger from '~/components/ui/MutantBurger.vue';

@Component({
  components: { MutantBurger, MutantGrabberMenu, ItemOverviewBadges, MenuRightContent },
})
export default class MenuRight extends Vue {
  public cloudMenuTab = CloudMenuTab;
  public menuBadgesId = 'menu-badges-right-menu';
  public isHover = false;
  public direction = Direction;

  public get menuWidth() {
    return this.$store.state.cloudMenuRightWidth;
  }

  updatePersistState() {
    this.$store.dispatch('persistCloudMenuRight');
  }

  updateMenuWidth(value: number) {
    this.$store.dispatch('updateCloudMenuRightWidth', value);
  }

  public get isShared() {
    return this.$store.getters.isSharedLink && !this.$store.getters['link/isLinkOwner'];
  }

  public get menuOverviewStyle(): { width: string, position?: string, right?: string, top?:string } {
    let style: { width: string, position?: string, right?: string, top?:string } = { width: this.$store.state.cloudMenuRightWidth + 'px' };
    if (!this.isCloudMenuRightOpen) {
      style = {
        ...style,
        position: 'absolute',
        right: '0',
        top: '0',
      };
      this.isHover = false;
    }
    return style;
  }

  public get tabStyle(): { width: string, position?: string, right?: string, top?:string } {
    const style = this.menuOverviewStyle;
    if (!this.isCloudMenuRightOpen) {
      style.top = document.getElementById(this.menuBadgesId).clientHeight + 'px';
    }
    return style;
  }

  public get activeCloudMenuTab(): CloudMenuTab {
    return this.$store.getters.activeCloudMenuTab;
  }

  public set activeCloudMenuTab(activeTab: CloudMenuTab) {
    this.$store.dispatch('setActiveCloudMenuTab', activeTab);
  }

  public get isCloudMenuRightOpen(): boolean {
    return this.$store.state.cloudMenuRightOpen;
  }

  public toggleTab(activeCloudMenuTab: CloudMenuTab) {
    if (!this.isCloudMenuRightOpen || activeCloudMenuTab === this.activeCloudMenuTab) {
      this.toggleCloudMenuRight();
    }
    this.activeCloudMenuTab = activeCloudMenuTab;
  }

  public onMouseEnter() {
    this.isHover = true;
  }

  public onMouseLeave() {
    if (!this.isCloudMenuRightOpen) {
      this.isHover = false;
    }
  }

  public toggleCloudMenuRight() {
    if (this.isCloudMenuRightOpen) {
      this.isHover = false;
    }
    this.$store.dispatch('toggleCloudMenuRight');
  }
}
