
import { Component, Vue } from 'nuxt-property-decorator';
import MagnifyContent from '~/components/window/magnify/MagnifyContent.vue';
import MagnifyControls from '~/components/window/magnify/MagnifyControls.vue';
import MutantBadgeButton from '~/components/ui/MutantBadgeButton.vue';
import MutantCheckbox from '~/components/ui/MutantCheckbox.vue';
import MutantControlButton from '~/components/ui/MutantControlButton.vue';
import MutantResizeGrabber from '~/components/ui/MutantResizeGrabber.vue';
import MutantSlider from '~/components/ui/MutantSlider.vue';
import { UnitSize } from '~/models/UnitSize';
import { DEFAULT_MAGNIFY_HEIGHT, MagnifyViewContentType } from '~/store/magnify/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import MainView from '~/components/window/view/MainView.vue';
import { MutantContentView } from '~/models/views/MutantContentView';
import ResizableWindowWrapper from '~/components/layout/footer/ResizableWindowWrapper.vue';

export interface MagnifyBoundaries {
  top: number;
  bottom: number;
}

@Component({
  components: {
    ResizableWindowWrapper,
    MainView,
    MagnifyContent,
    MagnifyControls,
    MutantResizeGrabber,
    MutantBadgeButton,
    MutantSlider,
    MutantControlButton,
    MutantCheckbox
  }
})
export default class MagnifyView extends Vue {
  public static VIEW_ID = 'magnify';
  private static STORAGE_KEY_SELECTION_HEIGHT = 'magnifyHeight';
  private static MIN_HEIGHT = 100;
  private static DEFAULT_HEIGHT = 400;
  private static FOOTER_HEADER_MARGIN = 106;
  private static DEFAULT_ATTACHED_BOTTOM_OFFSET = 250;

  public activeHeightTransition = true;
  public showSelectionBar = true;
  public showSaveSelectionDialog = false;

  private dragStartClientY: number = 0;
  private selectionHeightAtStart: number = 180;
  private bottomPositionAtStart: number = null;
  private windowHeightAtDragStart: number = null;
  private windowWidthAtDragStart: number = null;
  private magnifyPositionAtStart: number = null;
  private tempHeight: number = null;
  private tempBottomPosition: number = null;
  private topMousePosition;
  private animationFrame;
  private lastKnownHeight: number;
  private isDragging: boolean = false;
  private isDraggingTimeoutHandler = null;
  private magnifyBoundaries: MagnifyBoundaries = null;
  private draggingTimeoutHandler: any;
  private size: { width: number, height: number} = { width: 0, height: 0};
  private showFocusFrames: boolean = true;

  public get viewId(): string {
    return MagnifyView.VIEW_ID;
  }

  public get magnifyContentStyle() {
    return {
      width: '100%',
      height: this.magnifyHeight + 'px',
    };
  }

  public get itemsLength(): number {
    return this.$store.getters['cloud/viewItemsMap'][ViewIdentifier.OVERLAY_VIEW].items?.length;
  }

  public get magnifyTitle(): string {
    return this.$store.getters['magnify/magnifyTitle'];
  }


  public get magnifyHeight(): number {
    if (this.tempHeight) {
      return this.tempHeight;
    }
    return this.$store.state.magnify.height;
  }

  public get magnifyWidth(): number {
    return window.innerWidth;
  }

  public get magnifyView(): MutantContentView {
    return this.$store.getters['cloud/view'](ViewIdentifier.OVERLAY_VIEW);
  }

  public set magnifyHeight(height: number) {
    this.$store.commit('magnify/updateMagnifyHeight', height);
  }

  public get magnifySize(): UnitSize {
    return this.$store.getters['cloud/currentViewDownloadSize'](ViewIdentifier.OVERLAY_VIEW);
  }

  public get maxHeightToggleRange() {
    return this.maxHeight;
  }

  constructor() {
    super();
  }

  async created() {
    if (this.$store.state.selection.globalSelectedItemsOrdered.length > 0) {
      await this.$store.dispatch('cloud/addToPane', { windowId: ViewIdentifier.OVERLAY_VIEW, objectIds: this.$store.getters['cloud/view'](ViewIdentifier.GLOBAL_SELECTION_VIEW).objectIds });
    } else {
      await this.$store.dispatch('cloud/addToPane', { windowId: ViewIdentifier.OVERLAY_VIEW, objectIds: this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW).objectIds });
    }
    const persistedHeight = localStorage.getItem(MagnifyView.STORAGE_KEY_SELECTION_HEIGHT);
    const parsedHeight = persistedHeight && parseInt(persistedHeight, 10);
    // window height minus footer and header sizes
    // TODO: move and use footer and header sizes to/from Store
    if (parsedHeight && parsedHeight >= this.maxHeight || MagnifyView.DEFAULT_HEIGHT > this.maxHeight) {
      this.magnifyHeight = this.maxHeight;
    } else {
      this.magnifyHeight = parsedHeight || MagnifyView.DEFAULT_HEIGHT;
    }
    window.addEventListener('keydown', this.keyEventHandler);
  }

  beforeDestroy() {
    this.persistSelectionHeight(this.magnifyHeight);
    window.removeEventListener('keydown', this.keyEventHandler);
  }

  keyEventHandler(e: KeyboardEvent) {
    // @ts-ignore
    if (e.key === 'Enter' && e.target?.tagName === 'BODY') {
      this.toggleSaveSelectionDialog();
    }
  }

  public get magnifyStyle() {
    const obj: any = {};
    const bottomOffset = this.tempBottomPosition ? this.tempBottomPosition : this.$store.state.magnify.bottomOffset;
    obj.bottom = bottomOffset + 'px';
    obj.left = 0;
    return {
      position: 'absolute',
      zIndex: 1500,
      background: this.magnifyBackgroundStyle,
      ...obj
    };
  }

  public get magnifyBackgroundStyle() {
    return `linear-gradient(to bottom, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0.95) 15%, rgba(0,0,0, 0.95) 85%, rgba(0,0,0, 1) 100%)`;
  }

  public get maxHeight(): number {
    return window.innerHeight - this.$store.state.magnify.HEADER_HEIGHT - this.$store.state.magnify.FOOTER_HEIGHT;
  }

  public magnifyFolder() {
    this.$store.commit('magnify/setViewContent', {type: MagnifyViewContentType.FOLDER});
  }

  public toggleMagnifyTopHeight() {
    const enlargeMagnify = this.magnifyHeight >= this.maxHeightToggleRange;
    this.magnifyHeight = enlargeMagnify ? MagnifyView.DEFAULT_HEIGHT : this.maxHeight;
  }

  toggleFullscreenMagnify() {
    const enlargeMagnify = this.magnifyHeight >= this.maxHeightToggleRange;
    this.magnifyHeight = enlargeMagnify ? MagnifyView.DEFAULT_HEIGHT : this.maxHeight;
    const bottomOffset = enlargeMagnify ? (window.innerHeight - DEFAULT_MAGNIFY_HEIGHT) / 2 : 0;
    this.$store.commit('magnify/setBottomOffset', bottomOffset);
  }

  toggleSelectionFrame() {
    this.showFocusFrames = !this.showFocusFrames;
  }

  toggleSelectedItems() {
    this.$store.commit('selection/toggleSelectedItems');
  }

  toggleSaveSelectionDialog() {
    if (!this.$store.state.user.user) {
      alert('Please login/signup to save your collection.');
    } else {
      this.showSaveSelectionDialog = !this.showSaveSelectionDialog;
    }
  }

  async toggleSelectionBar() {
    this.$store.commit('magnify/toggleView');
  }

  persistSelectionHeight(height: number) {
    localStorage.setItem(MagnifyView.STORAGE_KEY_SELECTION_HEIGHT, height.toString());
  }

  async startDraggingMagnifyHeight(event: any) {
    clearTimeout(this.isDraggingTimeoutHandler);
    this.isDragging = true;
    clearTimeout(this.draggingTimeoutHandler);
    this.$store.commit('setLayoutDragging', true);
    this.activeHeightTransition = false;
    this.showSelectionBar = true;
    await this.$nextTick();
    this.dragStartClientY = event.clientY;
    this.selectionHeightAtStart = this.magnifyHeight;
    this.windowHeightAtDragStart = this.$store.state.windowHeight;
    this.windowWidthAtDragStart = this.$store.state.windowSize;
    this.magnifyBoundaries = this.calculateMagnifyBoundaries();
    event.target.style.opacity = 0;
  }

  private calculateMagnifyBoundaries(): MagnifyBoundaries {
    const topBoundary = 10
    const bottomBoundary = window.innerHeight - 10;
    return {
      top: topBoundary,
      bottom: bottomBoundary
    };
  }

  dragMagnifyHeight(event: any) {
    this.topMousePosition = event.clientY;
    this.animationFrame = requestAnimationFrame(this.updateMagnifyHeightFromHeader);
  }

  updateMagnifyHeightFromHeader() {
    if (!this.isDragging || this.topMousePosition < this.magnifyBoundaries.top || this.topMousePosition >= this.magnifyBoundaries.bottom - (<any> MagnifyView).MIN_HEIGHT) {
      return;
    }
    let newHeight = this.selectionHeightAtStart + this.dragStartClientY - this.topMousePosition;
    if (this.lastKnownHeight !== newHeight) {
      this.lastKnownHeight = newHeight;
      this.tempHeight = newHeight > MagnifyView.MIN_HEIGHT ? newHeight : MagnifyView.MIN_HEIGHT;
    }
  }

  endDraggingMagnifyHeight() {
    cancelAnimationFrame(this.animationFrame);
    this.isDraggingTimeoutHandler = setTimeout(() => {
      this.isDragging = false;
      this.tempHeight = null;
      this.lastKnownHeight = null;
    }, 150);
    this.activeHeightTransition = true;
    this.magnifyHeight = this.tempHeight;
    this.draggingTimeoutHandler = setTimeout(() => this.$store.commit('setLayoutDragging', false), 500);
  }

  async startDraggingMagnify(event: any) {
    this.isDragging = true;
    clearTimeout(this.draggingTimeoutHandler);
    this.$store.commit('setLayoutDragging', true);
    this.showSelectionBar = true;
    await this.$nextTick();
    this.dragStartClientY = event.clientY;
    this.magnifyPositionAtStart = this.$store.state.magnify.bottomOffset;
    this.magnifyBoundaries = this.calculateMagnifyBoundaries();
    event.target.style.opacity = 0;
  }

  dragMagnify(event: any, fromHeader: boolean) {
    this.topMousePosition = event.clientY;
    this.animationFrame = requestAnimationFrame(() => this.updateMagnifyPosition(fromHeader));
  }

  updateMagnifyPosition(fromHeader: boolean) {
    if (this.isDragging && this.topMousePosition > 0 && this.topMousePosition < window.innerWidth) {
      if (fromHeader) {
        if (this.topMousePosition < 20
        || this.topMousePosition + this.magnifyHeight + this.$store.state.magnify.FOOTER_HEIGHT + 12 >= this.magnifyBoundaries.bottom) {
          return;
        }
      } else {
        if (this.topMousePosition >= this.magnifyBoundaries.bottom
        || this.topMousePosition - this.magnifyHeight - this.$store.state.magnify.HEADER_HEIGHT - 12 <= 0) {
          return;
        }
      }
      const newBottomPosition = this.magnifyPositionAtStart + this.dragStartClientY - this.topMousePosition;
      this.tempBottomPosition = newBottomPosition > 0 ? newBottomPosition : 1;
    }
  }

  endDraggingMagnify(event: any) {
    cancelAnimationFrame(this.animationFrame);
    this.isDragging = false;
    event.target.style.opacity = 1;
    if (this.tempBottomPosition) {
      this.$store.commit('magnify/setBottomOffset', this.tempBottomPosition <= 0 ? 1 : this.tempBottomPosition);
    }
    this.draggingTimeoutHandler = setTimeout(() => {
      this.magnifyPositionAtStart = null;
      this.tempBottomPosition = null;
      this.$store.commit('setLayoutDragging', false)
      }, 500);
  }

  async startDraggingMagnifyFooter(event: any) {
    this.activeHeightTransition = false;
    await this.$nextTick();
    this.isDragging = true;
    clearTimeout(this.draggingTimeoutHandler);
    this.$store.commit('setLayoutDragging', true);
    this.dragStartClientY = event.clientY;
    this.selectionHeightAtStart = this.magnifyHeight;
    this.bottomPositionAtStart = this.$store.state.magnify.bottomOffset;
    this.magnifyBoundaries = this.calculateMagnifyBoundaries();
  }

  dragMagnifyFooter(event: any) {
    this.topMousePosition = event.clientY;
    this.animationFrame = requestAnimationFrame(this.updateMagnifyHeightFromFooter);
  }

  updateMagnifyHeightFromFooter() {
    if (!this.isDragging ||
      this.topMousePosition < this.magnifyBoundaries.top + MagnifyView.MIN_HEIGHT + 50 ||
      this.topMousePosition > this.magnifyBoundaries.bottom) {
      return;
    }
    let newHeight = this.selectionHeightAtStart + this.topMousePosition - this.dragStartClientY;
    if (newHeight > MagnifyView.MIN_HEIGHT) {
      this.tempBottomPosition = this.bottomPositionAtStart + this.dragStartClientY - this.topMousePosition;
      if (this.lastKnownHeight !== newHeight) {
        this.lastKnownHeight = newHeight;
        this.tempHeight = newHeight;
      }
    }
  }

  endDraggingMagnifyFooter() {
    cancelAnimationFrame(this.animationFrame);
    this.isDraggingTimeoutHandler = setTimeout(() => {
      this.isDragging = false;
      this.tempHeight = null;
      this.tempBottomPosition = null;
    }, 250);
    this.activeHeightTransition = true;
    if (this.tempHeight) {
      this.magnifyHeight = this.tempHeight;
    }
    if (this.tempBottomPosition) {
      this.$store.commit('magnify/setBottomOffset', this.tempBottomPosition);
    }
    this.draggingTimeoutHandler = setTimeout(() => this.$store.commit('setLayoutDragging', false), 500);
  }
}
