
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ContextMenuBase from '~/components/ui/ContextMenuBase.vue';

@Component({
  components: {
    ContextMenuBase,
  },
})
export default class DownloadAllContextMenu extends Vue {
  @Prop(Boolean)
    vertical?: boolean;

  @Prop()
    width?: number;

  @Prop()
    startPosition?: { top: number; left?: number; };

  @Prop(Boolean)
    isExpanded: boolean;

  @Prop(Boolean)
    isDraggable: boolean;

  @Prop(Boolean)
    closable: boolean;

  @Prop({
    default: () => ({
      left: 0, top: 150, bottom: 100, right: 0,
    }),
  })
    dragBounds: { left: number, top: number, right: number, bottom: number };

  constructor() {
    super();
  }

  public get contentStyle(): any {
    const obj: any = {
      flexDirection: this.vertical ? 'column' : 'row',
    };
    if (this.width) {
      if (!this.$store.getters.isMobile) {
        obj.width = `${this.width}px`;
      } else {
        const windowWidth = window.innerWidth - 20;
        const windowHeight = window.innerHeight - 20;
        obj.width = `${windowWidth}px`;
        obj.maxHeight = `${windowHeight}px`;
      }
    }
    return obj;
  }
}
