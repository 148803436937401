
import { Component, Vue } from 'nuxt-property-decorator';

@Component({
  components: { },
})
export default class MutantLogo extends Vue {
  public get isOpen() {
    return this.$store.state.menuOpen;
  }
}
