
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator';

  @Component
export default class CloudMenuHeader extends Vue {
    @Prop()
      isCollapsed: boolean;

    isCollapsedInternal = null;

    public collapse(event) {
      this.isCollapsedInternal = !this.isCollapsed;
      this.$emit('click', event);
    }

    @Watch('isCollapsed')
    watchIsCollapsedExternal(value) {
      this.isCollapsedInternal = value;
    }
}
