
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import CheckboxButton from '~/components/ui/buttons/CheckboxButton.vue';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: { CheckboxButton },
})
export default class DesktopDownloadButton extends Vue {
  @Prop(Boolean) public fullView: boolean;

  async mounted() {
    await this.$store.dispatch('fetchDesktopRelease');
    this.$forceUpdate();
  }

  public pruneDesktopVersion(version: string) {
    return version.split(' (')[0];
  }

  public openDownloadMenu() {
    if (this.$roleAwareness.isGuestUser) {
      this.$store.dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { isLogin: false, isSignupForDesktop: true } });
    } else {
      this.$store.dispatch('downloadDesktopApp');
    }
  }
}
