
import { Component, Vue } from 'nuxt-property-decorator';
import TextFieldBig from '~/components/ui/TextFieldBig.vue';
import Column from '~/components/ui/Column.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import ActionButtonModel from '~/models/ActionButtonModel';
import ActionButton from '~/components/layout/footer/ActionButton.vue';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import { ContextMenuType } from '~/store/context/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import ProgressCircleAnimating from '~/components/ui/progress/ProgressCircleAnimating.vue';

@Component({
  components: {
    ActionButton,
    MutantContextMenu,
    ContextMenuHeaderFlashy,
    Column,
    TextFieldBig,
    ProgressCircleAnimating,
  },
})
export default class RequestOriginalsContextMenu extends Vue {
  public message: string = '';
  public responseMessage: string = '';
  public isProcessing: boolean = false;
  public fileCountForRequest = this.$store.getters['cloud/viewItemsMap'][ViewIdentifier.MAIN_VIEW]?.items?.length;

  public requestButton: ActionButtonModel = {
    name: () => `REQUEST ${this.fileCountForRequest}  ORIGINAL FILES`,
    action: async () => {
      await this.requestOriginals();
    },
    isHighlighted: false,
    key: 'review-tab-extract-originals',
  };

  public get sharedLinkOwner(): string {
    return this.$store.getters['link/linkOwnerName'] ?? 'Shared Link Creator';
  }

  public async requestOriginals() {
    try {
      this.isProcessing = true;
      await this.$store.dispatch('link/requestOriginals', this.message);
      this.responseMessage = 'Your request has been sent.';
    } catch (e) {
      this.responseMessage = 'Your request could not be processed. Try again later.';
    } finally {
      setTimeout(() => {
        this.isProcessing = false;
      }, 900);
      setTimeout(() => {
        this.close();
      }, 4000);
    }
  }

  public changeValue(text: string) {
    this.message = text;
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.REQUEST_ORIGINALS);
  }
};
