
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import InteractiveFooterGrabber from '~/components/layout/footer/InteractiveFooterGrabber.vue';
import InteractiveFooterTab from '~/components/layout/footer/InteractiveFooterTab.vue';
import { FooterTab } from '~/models/FooterTab';
import MainView from '~/components/window/view/MainView.vue';
import ResizableWindowWrapper from '~/components/layout/footer/ResizableWindowWrapper.vue';
import { MutantContentView } from '~/models/views/MutantContentView';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';

@Component({
  components: {
    ResizableWindowWrapper,
    MainView,
    InteractiveFooterGrabber,
    InteractiveFooterTab,
  },
})
export default class InteractiveFooter extends Vue {
  public static MIN_HEIGHT = 120;
  public footerTab = FooterTab;

  @Prop(Boolean)
    isOpen: boolean;

  public footerSize: { width: number, height: number } = { width: null, height: null };

  public get isHomepageView(): boolean {
    return this.$routeAwareness.isHomepageView;
  }

  mounted() {
    this.footerSize = { height: this.$store.state.interactiveFooterHeight, width: this.$el.clientWidth };
  }

  public get isSharedLinkActive(): boolean {
    return !!this.$store.getters['link/linkDataExistsForCurrentCloudObject'];
  }

  public get activeTab(): FooterTab {
    return this.$store.state.activeFooterTab;
  }

  public get selectionView(): MutantContentView {
    return this.$store.getters['cloud/view'](ViewIdentifier.GLOBAL_SELECTION_VIEW);
  }

  public get navigationView(): MutantContentView {
    return this.$store.getters['cloud/view'](ViewIdentifier.NAVIGATION_VIEW);
  }

  public get isSelectionLaneActive(): boolean {
    return this.$store.state.activeFooterTab === FooterTab.SELECTION;
  }

  public get isMagnifyLaneActive(): boolean {
    return this.$store.state.activeFooterTab === FooterTab.NAVIGATION;
  }

  public get selectionCount(): number {
    return this.$store.getters['selection/globalSelectionItems']?.length;
  }

  public get selectedItemCount(): number | string {
    const count = this.selectionCount;
    return count > 0 ? `(${count})` : '';
  }

  public adjustFooterSize(size: any) {
    this.footerSize = size;
  }

  public chooseTab(tab: FooterTab) {
    if (this.isHomepageView && this.isSharedLinkActive) {
      this.$router.push({ name: 'shared-id', params: { id: this.$store.getters['link/linkDataForCurrentCloudObject']?.accessId } });
    }
    if (tab === FooterTab.NAVIGATION && !this.magnifyHasSameElements() && !this.$store.state.isNavigationLaneOpen) {
      this.$store.dispatch('cloud/addToPane', { windowId: ViewIdentifier.NAVIGATION_VIEW, objectIds: this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW).objectIds });
    }
    // TODO: move into single action
    if (this.activeTab !== tab) {
      this.$store.commit('setActiveFooterTab', tab);
      if (!this.$store.state.isNavigationLaneOpen) {
        this.$store.dispatch('openShortcutLane');
      }
    } else if (this.$store.state.isNavigationLaneOpen) {
      this.$store.dispatch('closeShortcutLane');
    } else {
      this.$store.dispatch('openShortcutLane');
    }
  }

  private get activeWindowId() {
    return this.$store.state.cloud.activeWindowId;
  }

  private magnifyHasSameElements(): boolean {
    return this.$store.getters['cloud/view'](this.activeWindowId).objectIds === this.$store.getters['cloud/view'](ViewIdentifier.NAVIGATION_VIEW).objectIds;
  }

  @Watch('activeWindowId')
  changeActiveWindowId(newWindowId: ViewIdentifier, oldWindow: ViewIdentifier) {
    if (newWindowId !== oldWindow && this.activeTab === FooterTab.NAVIGATION && !this.magnifyHasSameElements() && this.$store.state.isNavigationLaneOpen) {
      this.$store.dispatch('cloud/addToPane', { windowId: ViewIdentifier.NAVIGATION_VIEW, objectIds: this.$store.getters['cloud/view'](newWindowId).objectIds });
    }
  }
}
