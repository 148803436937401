
import { Component, Vue } from 'nuxt-property-decorator';
import ReviewStars from '~/components/ui/ReviewStars.vue';
import MutantBadge from '~/components/ui/MutantBadge.vue';
import ReviewActions from '~/components/menu/right/tabs/review/ReviewActions.vue';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import TagsSection from '~/components/menu/right/tabs/review/folder-tags/TagsSection.vue';
import RatingSection from '~/components/menu/right/tabs/review/RatingSection.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import AssetAndFileTypeSection from '~/components/menu/right/tabs/review/AssetAndFileTypeSection.vue';
import ViewNavigation from '~/components/window/view/footer/ViewNavigation.vue';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';

@Component({
  components: {
    ViewNavigation,
    AssetAndFileTypeSection,
    RatingSection,
    TagsSection,
    MutantCheckMark,
    ReviewActions,
    MutantBadge,
    ReviewStars,
  },
})
export default class ReviewTab extends Vue {
  public get showInImmersionMode(): boolean {
    return this.$store.state.cloud.showInImmersionMode;
  }

  public mainViewId = ViewIdentifier.MAIN_VIEW;

  public toggleShowOriginals(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.$store.commit('toggleShowOriginals');
  }

  public get currentCenteredItem(): ItemWithPosition {
    return this.$store.getters['cloud/currentCenteredItem'](ViewIdentifier.MAIN_VIEW);
  }

  public toggleRating(rating: number) {
    const ratingToAssign = this.currentCenteredItem?.rating === rating ? 0 : rating;
    this.$store.dispatch('cloud/rateItemsByItemId', { itemId: this.currentCenteredItem.id, rating: ratingToAssign });
  }

  public get showOriginals() {
    return this.$store.state.showOriginals;
  }

  public toggleImmersionMode(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.$store.dispatch('cloud/toggleImmersionMode', ViewIdentifier.MAIN_VIEW);
  }
}
