
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Column from '~/components/ui/Column.vue';
import { Asset, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';

@Component({
  components: { Column },
})
export default class VersionList extends Vue {
  @Prop()
    assets: Asset[];

  @Prop()
    itemId: string;

  @Prop()
    currentVersion: number;

  public isDownloadable(asset: Asset) {
    return (this.isRawVersion(asset) || this.isOriginalVersion(asset)) && (asset.file || asset.id || asset.base64);
  }

  public assetSize(asset: Asset) {
    return asset.matchedWith != null ? 'tbd.' : `${asset.width} x ${asset.height}`;
  }

  public isRawVersion(asset: Asset) {
    return asset.version === RAW_ASSET_VERSION;
  }

  public isOriginalVersion(asset: Asset) {
    return asset.version === ORIGINAL_ASSET_VERSION;
  }

  public toolTipDownload(asset: Asset) {
    return `Download ${this.isOriginalVersion(asset) ? 'Original' : 'Raw'} image`;
  }

  public isCurrentSize(asset: Asset): boolean {
    return asset.version === this.currentVersion;
  }

  public emitAssetChange(asset: Asset, event: Event) {
    this.$emit('asset-change', asset, event);
  }

  downloadAsset(asset: Asset, event: Event) {
    event.stopImmediatePropagation();
    asset.itemId = this.itemId;
    this.$store.dispatch('file/downloadSingleFileAssets', [asset]);
    this.$store.dispatch('showNotificationOnLargeAssetDownload', [asset], { root: true });
  }
}
