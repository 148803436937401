
import throttle from 'lodash.throttle';
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { isTouchEvent } from '~/models/views/SnippetMover';

export enum Direction {
  RIGHT = 'right',
  LEFT = 'left',
  UP = 'up',
  DOWN = 'down',
}

@Component({})
export default class MutantGrabberMenu extends Vue {
  public isHoverGrabber = false;
  public isDragging = false;

  private menuWidthAtStart!: number;
  private dragStartClientX!: number;
  private dragStartClientY!: number;

  @Prop()
  public menuWidth: number;

  @Prop({ default: false })
  public showSeparationLine: boolean;

  @Prop({ default: 300 })
  public minMenuWidth: number;

  @Prop({ default: 10000 })
  public maxMenuSize: number;

  @Prop()
  public widthStyleName!: string;

  @Prop({ default: Direction.RIGHT })
  public direction: Direction;

  @Prop(Boolean)
  private adjustLayoutContent!: boolean;

  public get horizontally() {
    return this.direction === Direction.LEFT || this.direction === Direction.RIGHT;
  }

  startDraggingMenuWidth(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.$store.dispatch('dragLayout', true);
    if (this.adjustLayoutContent) {
      this.$store.dispatch('adjustWindowsToDrag', true);
    }
    this.dragStartClientX = event.clientX;
    this.dragStartClientY = event.clientY;
    this.menuWidthAtStart = this.menuWidth;
    this.isDragging = true;
    event.target.style.opacity = 0;

    let clientX;
    let clientY;
    const dragMenuHorizontally = (moveEvent: DragEvent | TouchEvent) => {
      if (isTouchEvent(moveEvent)) {
        if (moveEvent.changedTouches) {
          const left = moveEvent.changedTouches[0].clientX;
          if (left > 0) {
            clientX = left;
          }
        }
      } else {
        moveEvent.preventDefault();
        moveEvent.stopImmediatePropagation();
        if (moveEvent.clientX > 0) {
          clientX = moveEvent.clientX;
        }
      }
    };
    const dragMenuVertically = (moveEvent: DragEvent | TouchEvent) => {
      if (isTouchEvent(moveEvent)) {
        if (moveEvent.changedTouches) {
          const bottom = moveEvent.changedTouches[0].clientY;
          if (bottom > 0) {
            clientY = bottom;
          }
        }
      } else {
        moveEvent.preventDefault();
        moveEvent.stopImmediatePropagation();
        if (moveEvent.clientY > 0) {
          clientY = moveEvent.clientY;
        }
      }
    };
    let animationFrame;
    const adjustFooterHeightHorizontally = () => {
      if (clientX && clientX > 0 && clientX <= window.innerWidth) {
        let newPaneWidth;
        if (this.direction === Direction.RIGHT) {
          newPaneWidth = this.menuWidthAtStart + clientX - this.dragStartClientX;
        } else {
          newPaneWidth = this.dragStartClientX - clientX + this.menuWidthAtStart;
        }
        this.setMenuWidth(newPaneWidth > this.minMenuWidth ? newPaneWidth : this.minMenuWidth);
      }
      animationFrame = requestAnimationFrame(adjustFooterHeightHorizontally);
    };
    const adjustFooterHeightVertically = () => {
      if (clientY && clientY > 0 && clientY <= window.innerHeight) {
        let newPaneWidth;
        if (this.direction === Direction.DOWN) {
          newPaneWidth = this.menuWidthAtStart + clientY - this.dragStartClientY;
        } else {
          newPaneWidth = this.dragStartClientY - clientY + this.menuWidthAtStart;
        }
        const biggerThan = newPaneWidth < this.minMenuWidth ? this.minMenuWidth : this.maxMenuSize;
        const result = newPaneWidth > this.minMenuWidth && newPaneWidth < this.maxMenuSize ? newPaneWidth : biggerThan;
        this.setMenuWidth(result);
      }
      animationFrame = requestAnimationFrame(adjustFooterHeightVertically);
    };
    animationFrame = requestAnimationFrame(this.horizontally ? adjustFooterHeightHorizontally : adjustFooterHeightVertically);
    const throttledDrag = throttle(this.horizontally ? dragMenuHorizontally : dragMenuVertically, 15, { leading: true, trailing: false });
    const endDraggingMenuWidth = (moveEvent) => {
      moveEvent.preventDefault();
      moveEvent.stopImmediatePropagation();
      cancelAnimationFrame(animationFrame);
      document.removeEventListener('pointermove', throttledDrag, false);
      document.removeEventListener('touchmove', throttledDrag);
      document.removeEventListener('mousemove', throttledDrag);
      document.removeEventListener('pointerup', endDraggingMenuWidth, false);
      document.removeEventListener('touchend', endDraggingMenuWidth);
      document.removeEventListener('mouseup', endDraggingMenuWidth);
      this.isDragging = false;
      this.$emit('finished-drag');
      this.$store.dispatch('dragLayout', false);
    };
    document.addEventListener('pointermove', throttledDrag, false);
    document.addEventListener('touchmove', throttledDrag);
    document.addEventListener('mousemove', throttledDrag, false);
    document.addEventListener('touchend', endDraggingMenuWidth, false);
    document.addEventListener('mouseup', endDraggingMenuWidth);
    document.addEventListener('pointerup', endDraggingMenuWidth, false);
  }

  setMenuWidth(value: number) {
    if (value !== this.menuWidth) {
      this.$emit('update-width', value);
      document.documentElement.style.setProperty(this.widthStyleName, value + 'px');
    }
  }
}
