
import { Component, Vue } from 'nuxt-property-decorator';
import TextField from '~/components/ui/TextField.vue';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import { ViewOptionsDropdownItem } from '~/models/ViewOptionsDropDownItem';
import ViewOptionsDropDown from '~/components/ui/ViewOptionsDropDown.vue';
import { QualityConfiguration } from '~/models/thumbnailer/Thumbnailer';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';

@Component({
  components: { ViewOptionsDropDown, MutantCheckMark, TextField },
})
export default class PipelineOptionsSideMenu extends Vue {
  public get imageQualityLevels(): ViewOptionsDropdownItem[] {
    return [
      { name: '60%', value: 0.60 },
      { name: '65%', value: 0.65 },
      { name: '70%', value: 0.7 },
      { name: '75%', value: 0.75 },
      { name: '80%', value: 0.8 },
      { name: '85%', value: 0.85 },
      { name: '90%', value: 0.9 },
      { name: '95%', value: 0.95 },
      { name: '100%', value: 1 },
    ];
  }

  public updateMaxFileWidth(maxFileWidth: number) {
    this.updateThumbnailQualityConfig({ maxFileWidth });
  }

  public updateMaxFileSize(maxFileSize: number) {
    this.updateThumbnailQualityConfig({ maxFileSize });
  }

  public updateWidth(width: number) {
    this.updateThumbnailQualityConfig({ width });
  }

  public updateImageQuality(imageQuality: number) {
    this.updateThumbnailQualityConfig({ imageQuality });
  }

  public updateThumbnailQualityConfig(thumbnailConfig: Partial<QualityConfiguration>) {
    this.$store.dispatch('file/setThumbnailQualityConfig', thumbnailConfig);
  }

  public get thumbnailQualityConfig() {
    return this.$store.getters['file/thumbnailQualityConfig'];
  }

  public get imageQuality(): string {
    return `${this.thumbnailQualityConfig?.imageQuality * 100}%`;
  }

  public get isGuestUser(): boolean {
    return this.$store.getters['user/isGuest'];
  }

  public get isActiveUploadThumbnails(): boolean {
    return this.$store.getters['file/isActiveUploadThumbnailOption'];
  }

  public get isActiveUploadOriginals(): boolean {
    return this.$store.getters['file/isActiveUploadOriginalsOption'];
  }

  public get isActiveUploadRaws(): boolean {
    return this.$store.getters['file/isActiveUploadRawsOption'];
  }

  public toggleActiveUploadThumbnails() {
    this.$store.dispatch('file/togglePipelineStep', PipelineCommandType.UPLOAD_THUMBNAILS);
  }

  public toggleActiveUploadOriginals() {
    this.$store.dispatch('file/togglePipelineStep', PipelineCommandType.UPLOAD_ORIGINALS);
  }

  public toggleActiveUploadRaws() {
    this.$store.dispatch('file/togglePipelineStep', PipelineCommandType.UPLOAD_RAWS);
  }
}
