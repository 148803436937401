
import { Component, Vue } from 'nuxt-property-decorator';
import { transformToUnit } from '~/models/Asset';
import { ContextMenuType } from '~/store/context/state';

@Component
export default class UploadVolumeStatus extends Vue {
  public openAccountMenu() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.ACCOUNT_OVERVIEW });
  }

  public get tooltip() {
    const content = this.isExceeded
      ? 'Your monthly upload volume. Upgrade your account to increase the limit.'
      : 'Your monthly upload volume.';
    return {
      placement: 'bottom',
      content,
    };
  }

  public get isExceeded(): boolean {
    return this.userLimits.isExceeded;
  }

  public get usedLimit(): string {
    const limit = transformToUnit(this.userLimits.uploadVolumeUsed);
    return `${limit.size} ${limit.unit}`;
  }

  public get totalLimit(): string {
    const limit = transformToUnit(this.userLimits.uploadVolumeLimit);
    return `${limit.size} ${limit.unit}`;
  }

  public get usedLimitWidth() {
    return this.userLimits.uploadVolumeUsed / this.userLimits.uploadVolumeLimit * 100 + '%';
  }

  public get usedLimitBackground() {
    const limitUsedRate = this.userLimits.uploadVolumeUsed / this.userLimits.uploadVolumeLimit;
    const redValue = Math.round(limitUsedRate * 255);
    const greenValue = Math.round((1 - limitUsedRate) * 255);
    return `rgb(${redValue}, ${greenValue}, 0)`;
  }

  public get userLimits() {
    return this.$store.state.user.userLimits;
  }
}
