import { render, staticRenderFns } from "./DownloadButton.vue?vue&type=template&id=24835830&scoped=true&"
import script from "./DownloadButton.vue?vue&type=script&lang=ts&"
export * from "./DownloadButton.vue?vue&type=script&lang=ts&"
import style0 from "./DownloadButton.vue?vue&type=style&index=0&id=24835830&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24835830",
  null
  
)

export default component.exports