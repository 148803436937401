
import { Component } from 'nuxt-property-decorator';
import { ResizableWindow } from '~/components/window/ResizableWindow';

@Component
export default class MainPane extends ResizableWindow {
  constructor() {
    super();
  }

  public get isLayoutDragged(): boolean {
    return this.$store.state.isLayoutDragged;
  }
}
