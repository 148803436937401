
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class ContextMenuButton extends Vue {
  @Prop(Boolean)
    green: boolean;

  @Prop(Boolean)
    darkGreen: boolean;

  @Prop(Boolean)
    orange: boolean;

  @Prop(Boolean)
    isActive: boolean;

  @Prop(Boolean)
    small: boolean;

  @Prop(Boolean)
    isDisabled: boolean;

  @Prop(Boolean)
    hasDropup: boolean;

  public handleClick(event: MouseEvent) {
    if (!this.isDisabled) {
      this.$emit('click', event);
    }
  }
}
