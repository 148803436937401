var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mutant-checkmark",class:{
    'mutant-checkmark--on': _vm.isActive,
    'mutant-checkmark--off': !_vm.isActive,
    'mutant-checkmark--focus': _vm.wasClicked,
    'mutant-checkmark--disabled': _vm.disabled
  },on:{"click":_vm.handleClick,"mouseleave":_vm.handleMouseLeave}},[_c('label',{class:{ 'off': !_vm.isActive, 'on': _vm.isActive },attrs:{"for":_vm.id}},[_vm._m(0),_vm._v(" "),_c('input',{staticClass:"checkbox-hidden",attrs:{"id":_vm.id,"type":"checkbox"}})]),_vm._v(" "),_c('div',{staticClass:"mutant-checkmark__content"},[_vm._t("default")],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"checkmark"},[_c('div',{staticClass:"checkmark-stem"}),_vm._v(" "),_c('div',{staticClass:"checkmark-kick"})])
}]

export { render, staticRenderFns }