
import { Component, Vue } from 'nuxt-property-decorator';
import { ViewType } from '~/models/views/ViewType';
import MainWindow from '~/components/window/MainWindow.vue';
import SidePaneWindow from '~/components/window/SidePaneWindow.vue';

@Component({
  components: {
    MainWindow,
    SidePaneWindow,
  },
  layout: 'default',
  transition: {
    name: 'scale-transition',
    mode: 'out-in',
  },
})
export default class CloudId extends Vue {
  public view = ViewType;

  head() {
    const obj: any = {};
    const currentMainViewContent = this.$store.getters['cloud/currentMainView'];
    if (currentMainViewContent && currentMainViewContent.name) {
      obj.title = currentMainViewContent.name;
    }
    return obj;
  }

  async mounted() {
    await this.$store.dispatch('cloud/initializeCloudContent');
  }
}
