
  import { Vue, Component } from 'nuxt-property-decorator';

  @Component({
    components: {}
  })
  export default class SharedLinkMenu extends Vue {
    public code = [];

    constructor() {
      super();
    }

    mounted() {
      if (this.$store.state.link.menu.accessCode) {
        this.code = this.$store.state.link.menu.accessCode.split('');
      }
      const focusElementNum = this.code.length;
      const codeInputElements = document.getElementsByClassName('code-input__box');
      (<HTMLInputElement> codeInputElements.item(focusElementNum)).focus();
    }

    public get codeWithMinLength() {
      return this.code.length > 4 ? [...this.code, ''] : [...this.code, ...new Array(5 - this.code.length)];
    }

    public async handleKeyInput(event: KeyboardEvent, idx: number) {
      if (event.key === 'Backspace') {
        if (idx < this.code.length) {
          this.code = [...this.code.slice(0, idx), '', ...this.code.slice(idx + 1, this.code.length)];
          this.focusPreviousCodeInputBox(idx);
        } else {
          this.code.pop();
          if (idx === 1) {
            this.focusPreviousCodeInputBox(idx);
          } else {
            this.focusPreviousCodeInputBox(idx - 1);
          }
        }
      } else if (event.key === 'ArrowLeft') {
        this.focusPreviousCodeInputBox(idx);
      } else if (event.key === 'ArrowRight') {
        this.focusNextCodeInputBox(idx);
      } else if (event.key === 'Escape') {
        this.code = [];
        this.focusNextCodeInputBox(-1);
      } else if (event.key === 'Enter') {
        event.preventDefault();
        this.ok();
      }
    }

    public async handleCodeInput(event: InputEvent, idx: number) {
      if (idx < this.code.length) {
        this.code = [...this.code.slice(0, idx), event.data, ...this.code.slice(idx + 1, this.code.length)];
      } else {
        this.code.push(event.data);
      }
      await this.focusNextEmptyCodeInput();
    }

    private focusCurrentCodeInput(event: MouseEvent) {
      event.preventDefault();
      (<HTMLInputElement> event.target).select();
    }

    private focusPreviousCodeInputBox(currentBoxIdx: number) {
      if (currentBoxIdx > 0 && currentBoxIdx < this.codeWithMinLength.length) {
        const codeInputElements = document.getElementsByClassName('code-input__box');
        (<HTMLInputElement> codeInputElements.item(currentBoxIdx - 1)).select();
      }
    }

    private focusNextCodeInputBox(currentBoxIdx: number) {
      if (currentBoxIdx < this.codeWithMinLength.length - 1) {
        const codeInputElements = document.getElementsByClassName('code-input__box');
        (<HTMLInputElement> codeInputElements.item(currentBoxIdx + 1)).select();
      }
    }

    private async focusNextEmptyCodeInput() {
      const codeInputElements = document.getElementsByClassName('code-input__box');
      let nextEmptyCharacterIdx = this.code.length;
      for (let i = 0; i <= this.code.length; i++) {
        if (this.code[i] === '') {
          nextEmptyCharacterIdx = i;
          break;
        }
      }
      if (nextEmptyCharacterIdx === this.code.length) {
        await this.$forceUpdate();
      }
      (<HTMLInputElement> codeInputElements.item(nextEmptyCharacterIdx)).select();
    }

    public ok() {
      const accessCode = this.code.join('');
      const sharedLinkData: any = {
        accessCode
      };
      const {selectionId, folderId, accessId} = this.$store.state.link.menu;
      if (accessId) {
        sharedLinkData.accessId = accessId;
        this.$store.dispatch('link/updateSharedLink', sharedLinkData);
      } else if (selectionId) {
        sharedLinkData.withOptions = true;
        sharedLinkData.selectionId = selectionId;
        this.$store.dispatch('link/createSharedLinkForSelection', sharedLinkData);
      } else {
        sharedLinkData.withOptions = true;
        sharedLinkData.folderId = folderId;
        this.$store.dispatch('link/createSharedLink', sharedLinkData);
      }
      this.cancel();
    }

    public cancel() {
      this.$store.dispatch('link/closeSharedLinkMenu');
      this.code = [];
    }

    public get menuStyle() {
      const offsetX = 5;
      const offsetY = 5;
      const {left, top} = this.$store.state.link.menu.position;

      if (left > window.innerWidth - 300) {
        if (top > window.innerHeight - 300) {
          return {
            right: `${window.innerWidth - left + offsetX}px`,
            bottom: `${window.innerHeight - top - offsetY}px`
          };
        }
        return {right: `${window.innerWidth - left + offsetX}px`, top: `${top - offsetY}px`};
      }
      if (top > window.innerHeight - 300) {
        return {left: `${left + offsetX}px`, bottom: `${window.innerHeight - top + offsetY}px`};
      }
      return {left: `${left + offsetX}px`, top: `${top - offsetY}px`};
    }
  }
