
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import MutantProfileImage from '~/components/ui/MutantProfileImage.vue';
import { Contact } from '~/models/user/Contact';
import { User } from '~/models/user/User';
import { Destination } from '~/components/menu/right/tabs/contacts/ContactsTab.vue';
import { WarningMessages } from '~/models/MessageTypes';
import { NotificationType } from '~/models/Notification';
import ReviewSectionHeader from '~/components/menu/right/tabs/review/ReviewSectionHeader.vue';

@Component({
  components: {
    ReviewSectionHeader,
    MutantProfileImage,
  },
})
export default class ContactList extends Vue {
  public contactName = '';
  public clicked = false;
  public isHoverId: string = '';
  public isExpanded = true;

  @Prop()
  public selectedContact: string;

  public get hasDragInfo(): boolean {
    return this.$store.state.dragInfo != null;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  created() {
    if (!this.$store.state.user.contacts.length) {
      this.$store.dispatch('user/loadContacts');
    }
  }

  async addContact() {
    if (this.isContactValid && this.hasCorrectLength && this.currentUser.username !== this.contactName.toLowerCase()) {
      const contact = await this.$store.dispatch('user/addContact', this.contactName);
      if (contact != null) {
        this.$emit('click', null, { id: contact.id, userId: contact.userId, name: contact.username });
      }
    } else if (!this.hasCorrectLength) {
      this.$store.dispatch('setNotificationMessage',
        {
          payload: {
            message: WarningMessages.INVALID_USER_LENGTH,
            type: NotificationType.ERROR,
            duration: 5000,
          },
        }
      );
    }
  }

  public drop(contact: Contact) {
    if (this.hasDragInfo) {
      this.$emit('send-to', { id: contact.id, userId: contact.userId, name: contact.username } as Destination);
    }
  }

  public dropOnSelf() {
    if (this.hasDragInfo) {
      this.$emit('send-to', {
        id: null,
        userId: this.currentUser.id,
        name: null,
      } as Destination);
    }
  }

  get currentUser(): User {
    return this.$store.getters['user/currentUser'] ?? '';
  }

  public get isContactValid() {
    return !(/\s/.test(this.contactName));
  }

  public get hasCorrectLength() {
    return this.contactName?.length > 1 && this.contactName?.length <= 36;
  }

  deleteContact(event: Event, contact: Contact) {
    event.stopImmediatePropagation();
    this.$store.dispatch('user/deleteContact', contact);
    if (this.selectedContact === contact.id) {
      this.$emit('click', null, { id: null, userId: this.currentUser.id, name: this.currentUser.username });
    }
  }

  public toggleEnabled(event: Event, contact: Contact) {
    event.stopImmediatePropagation();
    this.$store.dispatch('user/patchContact', { id: contact.id, isEnabled: !contact.isEnabled });
    this.clicked = true;
  }

  public get contacts(): Contact[] {
    return this.$store.state.user.contacts;
  }
}
