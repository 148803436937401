
import { Component, Vue } from 'nuxt-property-decorator';
import DesktopInstructionContextMenu from '~/components/dialogs/desktop-instruction/DesktopInstructionContextMenu.vue';
import { ContextMenuType } from '~/store/context/state';
import AcceptTermsCheckmark from '~/components/dialogs/desktop-instruction/AcceptTermsCheckmark.vue';
import TermsOfUseDesktop from '~/components/dialogs/desktop-instruction/TermsOfUseDesktop.vue';
import SignUpSection from '~/components/dialogs/sign-up/sections/SignUpSection.vue';
import LoginSection from '~/components/dialogs/sign-up/sections/LoginSection.vue';

enum MenuPoint {
  INSTALLATION,
  LOGIN,
  SIGNUP,
  TERMS,
}

@Component({
  components: {
    LoginSection,
    SignUpSection,
    TermsOfUseDesktop,
    AcceptTermsCheckmark,
    DesktopInstructionContextMenu,
  },
})
export default class DesktopInstructionMenu extends Vue {
  public hasAcceptedTerms = false;
  public isHoverAcceptTerms = false;
  public menuPoint = MenuPoint.INSTALLATION;
  public menuPoints = MenuPoint;

  public changeMenu(menuPoint: MenuPoint) {
    this.menuPoint = menuPoint;
  }

  public get desktopData() {
    return this.$store.getters['context/menu'](ContextMenuType.DESKTOP_DOWNLOAD_INSTRUCTIONS)?.data;
  }

  public get versionNumber() {
    return this.desktopData.version;
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.DESKTOP_DOWNLOAD_INSTRUCTIONS);
  }

  public download() {
    if (this.hasAcceptedTerms && this.desktopData != null) {
      this.$store.dispatch('downloadDesktopApp');
    }
  }
}
