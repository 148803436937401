
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { MatchUploadData, UploadData } from '~/store/file/actions';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ContextMenuType } from '~/store/context/state';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import { UploadProcessStatus } from '~/store/file/state';
import { UploadOption } from '~/models/UploadOption';

export type ButtonType = 'upload' | 'match_and_upload';

interface ButtonConfig {
  uploadButtonTitle: string;
  uploadFolderTitle: string;
  uploadButtonFolderInputId: string
  uploadFilesTitle: string;
  uploadButtonFileInputId: string;
  replaceExistingFilesOption: boolean;
  ignoreExistingFilesOption: boolean;
  contextMenuType: ContextMenuType
}
// todo: can be removed then MatchAndUploadControl gets refactored, use UploadButton then
@Component({
  components: { MutantCheckMark },
})
export default class OldUploadButton extends Vue {
  public buttonConfigMap: Map<ButtonType, ButtonConfig> = new Map([
    ['upload', {
      uploadButtonTitle: 'UPLOAD',
      uploadFolderTitle: 'ADD FOLDERS',
      uploadButtonFolderInputId: 'upload-folder-input-button',
      uploadFilesTitle: 'ADD FILES',
      uploadButtonFileInputId: 'upload-file-input-button',
      replaceExistingFilesOption: true,
      ignoreExistingFilesOption: true,
      contextMenuType: ContextMenuType.UPLOAD_CONTROL,
    }],
    ['match_and_upload', {
      uploadButtonTitle: 'MATCH',
      uploadFolderTitle: 'MATCH WITH FOLDERS',
      uploadButtonFolderInputId: 'match-and-upload-folder-input-button',
      uploadFilesTitle: 'MATCH WITH FILES',
      uploadButtonFileInputId: 'match-and-upload-file-input-button',
      replaceExistingFilesOption: false,
      ignoreExistingFilesOption: false,
      contextMenuType: ContextMenuType.MATCH_AND_UPLOAD_CONTROL,
    }],
  ]);

  @Prop()
  public buttonType: ButtonType;

  @Prop()
  public disabled: boolean;

  public isMenuOpen = false;
  public replaceExisingFiles = false;
  public ignoreExisingFiles = false;

  public get showUploadProgress() {
    const isUploading = this.$store.state.file?.uploadProcess?.status === UploadProcessStatus.UPLOADING;
    const isMatchingProcessActive = this.$store.state.file?.matchingProcess != null;
    return this.buttonType === 'upload' ? !isMatchingProcessActive && isUploading : isMatchingProcessActive && isUploading;
  }

  public get conf(): ButtonConfig {
    return this.buttonConfigMap.get(this.buttonType);
  }

  public get menuOpen(): boolean {
    return (this.isMenuOpen || this.remoteControlOpen) && !this.disabled;
  }

  public get menuClosed(): boolean {
    return !this.isMenuOpen && !this.remoteControlOpen && !this.disabled;
  }

  public get progressPercentage() {
    return this.progressSteps.length > 0 ? this.progressSteps.reduce((sum, step) => sum + step.percentage, 0) / this.progressSteps.length : 0;
  }

  public get progressSteps() {
    return this.$store.getters.pipelineProgress(this.viewObjectId) || [];
  }

  public get viewObjectId() {
    return this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW).objectIds[0]?.toUuid();
  }

  public get isLandingPageActive() {
    return this.$routeAwareness.isHomepageView || this.$routeAwareness.isLandingPage;
  }

  public get uploadInProgress() {
    if (this.buttonType === 'upload') {
      return this.$store.state.file.uploadProcess != null;
    }
    return this.$store.state.file.matchingProcess != null;
  }

  public toggleMenu(event: MouseEvent) {
    event.stopImmediatePropagation();
    if (!this.disabled) {
      if (!this.remoteControlOpen && (this.uploadInProgress || this.isLandingPageActive)) {
        this.$store.dispatch('context/openMenu', { type: this.conf.contextMenuType });
      } else if (this.remoteControlOpen) {
        this.$store.dispatch('context/closeMenu', this.conf.contextMenuType);
      } else {
        this.isMenuOpen = !this.isMenuOpen;
      }
    }
  }

  public closeMenu() {
    this.isMenuOpen = false;
  }

  public get remoteControlOpen() {
    return this.$store.getters['context/isOpen'](this.conf.contextMenuType);
  }

  @Watch('remoteControlOpen', { immediate: true })
  public onRemoteControlOpen() {
    this.isMenuOpen = false;
  }

  uploadFiles(e: any) {
    e.preventDefault();
    if (e.dataTransfer || e.target) {
      this.$store.dispatch('context/openMenu', { type: this.conf.contextMenuType });
      if (this.buttonType === 'upload') {
        this.$store.dispatch<ActionPayload<UploadData>>({
          type: 'file/upload',
          payload: {
            windowId: ViewIdentifier.MAIN_VIEW,
            pipelineCommands: [PipelineCommandType.CREATE_THUMBNAILS],
            event: new AddExternalContentEvent(e, { order: this.mainViewItems.length }),
            uploadOptions: this.buildUploadOptions,
          },
        });
      } else {
        this.$store.dispatch<ActionPayload<MatchUploadData>>({
          type: 'file/initializeMatchingProcess',
          payload: {
            windowId: ViewIdentifier.MAIN_VIEW,
            event: new AddExternalContentEvent(e, { order: this.mainViewItems.length }),
          },
        });
      }
    }
    // forcing chrome to always fire change event, even if same file is selected again
    e.target.value = null;
  }

  public get buildUploadOptions(): UploadOption[] {
    const result: UploadOption[] = [];
    if (this.ignoreExisingFiles) {
      result.push(UploadOption.IGNORE_EXISTING_FILES);
    } else if (this.replaceExisingFiles) {
      result.push(UploadOption.REPLACE_EXISTING_FILES);
    }
    return result;
  }

  public toggleReplaceExisingFiles() {
    this.replaceExisingFiles = !this.replaceExisingFiles;
    this.ignoreExisingFiles = false;
  }

  public toggleIgnoreExistingFiles() {
    this.ignoreExisingFiles = !this.ignoreExisingFiles;
    this.replaceExisingFiles = false;
  }

  public get mainViewItems(): ItemWithPosition[] {
    return this.$store.getters['cloud/viewItemsMap'][ViewIdentifier.MAIN_VIEW].items;
  }

  public triggerFileUpload() {
    const fileInput = document.getElementById(this.conf.uploadButtonFileInputId);
    if (fileInput) {
      fileInput.click();
    }
  }

  public triggerFolderUpload() {
    const folderInput = document.getElementById(this.conf.uploadButtonFolderInputId);
    if (folderInput) {
      folderInput.click();
    }
  }
}
