
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import InfoBadge from '~/components/dialogs/download/InfoBadge.vue';
import { UnitSize } from '~/models/UnitSize';

@Component({
  components: { InfoBadge },
})
export default class DownloadInfo extends Vue {
  @Prop()
  public itemsInObject: number;

  @Prop()
  public currentAssetSize: UnitSize;

  @Prop()
  public rawAssetCount: number;
}
