
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class MutantBadgeButtonLarge extends Vue {
  @Prop()
  public width!: number;

  @Prop(Boolean)
  public isFilled: boolean;

  @Prop(Boolean)
  public isActive: boolean;

  @Prop(Boolean)
  public isSlim: boolean;

  @Prop(Boolean)
  public isDraggable: boolean;

  @Prop(Boolean)
  public isLight: boolean;

  @Prop(Boolean)
  public hasBorder: boolean;
}
