
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { filter, Subscription } from 'rxjs';
import { Asset, ORIGINAL_ASSET_VERSION } from '~/models/Asset';
import Item from '~/models/item/Item';
import { ItemType } from '~/models/item/ItemType';
import Snippet from '~/models/Snippet';
import { Owner } from '~/models/selection/Owner';
import { sortedAssets } from '~/models/Assets';
import { SnippetHoverEffect } from '~/models/views/SnippetHoverEffect';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { WebConfig } from '~/Config';
import { ViewType } from '~/models/views/ViewType';
import { ViewItem } from '~/models/views/ViewItem';
import { FolderItem } from '~/models/item/FolderItem';
import { SelectionItem } from '~/models/selection/SelectionItem';
import { BROWSER_SUPPORTED_IMAGE_TYPES } from '~/models/SupportedImageTypes';
import Column from '~/components/ui/Column.vue';
import VersionList from '~/components/ui/VersionList.vue';
import { MobileInteractionEvent } from '~/plugins/mobileInteractionEvents';

@Component({
  components: { VersionList, Column },
})
export default class MutantSnippet extends Vue {
  @Prop({ default: false })
  public disableSelectionBorders: boolean;

  @Prop()
  public snippet!: Snippet;

  @Prop()
  public viewType!: ViewType;

  @Prop({ default: 12 })
  public contactSheetTextSize: number;

  @Prop()
  public showDragIcon: boolean;

  @Prop({ default: false })
  public isScrolling: boolean;

  public imgSrc: string = '';
  public isDeselected = false;
  public isHover = false;
  public isInActionBounds = false;

  @Prop()
  public showDetails: boolean;

  @Prop(Boolean)
  public showOverlay!: boolean;

  @Prop(Boolean)
  public showRemoveIndicator?: boolean;

  @Prop(Boolean)
  public hasHighlightAnimation?: boolean;

  @Prop(Boolean)
  public isSelected!: boolean;

  @Prop(Boolean)
  public isSelectable?: boolean;

  @Prop()
  public selectedBy!: Owner[];

  @Prop(Boolean)
  public isFaded!: boolean;

  @Prop()
  public itemSelectedBorder!: number;

  @Prop(Boolean)
  public itemSelectedUseOutline!: boolean;

  @Prop(Boolean)
  public showSnippetContent!: boolean;

  @Prop(Boolean)
  public hasSnippetOptions: boolean;

  @Prop(Boolean)
  public hasFocusFrame?: boolean;

  @Prop()
  public viewId?: ViewIdentifier;

  @Prop({ default: SnippetHoverEffect.DEFAULT })
  public hoverEffect?: SnippetHoverEffect;

  @Prop(Boolean)
  public showResizeTools: boolean;

  @Prop({ default: { minPixel: 9, percentage: 0.08 } })
  public resizeTriggerRange: {minPixel: number, percentage: number};

  public showItemOverlay: boolean = true;
  public prepareForPlaceholderReplacement = false;
  public cursor = 'default';

  private static FOCUS_FRAME_PADDING = 16;
  private static LARGE_ITEM_WIDTH = 300;
  private static MEDIUM_ITEM_WIDTH = 150;
  private static SMALL_ITEM_WIDTH = 60;
  private static TINY_ITEM_WIDTH = 30;

  private clickCount: number = 0;
  private clickTimer: any = null;
  private clickDelay: number = 225;
  private videoPlaying = false;
  private resizeTriggerRangePixel: number = 5;
  private preloadAssetSubscription: Subscription = null;
  private preloadInProgress = false;
  private currentAssetHash = '';
  private currentAsset: Asset;
  private dataUrl = '';
  private downloadUrlAssetId = null;

  constructor() {
    super();
    this.currentAsset = this.$imageLoader.getOptimalAssetByWidth(this.item, this.itemWidth);
  }

  public get imageId() {
    return 'mutant-snippet-' + this.snippet?.item?.id;
  }

  public get isLarge() {
    return this.itemWidth > MutantSnippet.LARGE_ITEM_WIDTH;
  }

  public get isMedium() {
    return this.itemWidth > MutantSnippet.MEDIUM_ITEM_WIDTH;
  }

  public get isSmall() {
    return this.itemWidth > MutantSnippet.SMALL_ITEM_WIDTH;
  }

  public get isTiny() {
    return this.itemWidth <= MutantSnippet.TINY_ITEM_WIDTH;
  }

  public get hasMatchedAsset(): boolean {
    return this.assets.some(a => a.isMatched === true);
  }

  public get isSupportedImageType(): boolean {
    return this.currentAsset.version < ORIGINAL_ASSET_VERSION || BROWSER_SUPPORTED_IMAGE_TYPES.includes(this.currentAsset.mimeType);
  }

  public get showOriginals() {
    return this.$store.state.showOriginals;
  }

  public async handleAssetChange(asset: Asset, event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    await this.selectVersionByAsset(asset);
  }

  async selectVersionByAsset(asset: Asset) {
    this.imgSrc = asset.base64 || await this.$imageLoader.loadAssetUrl(this.item, asset);
    this.currentAsset = asset;
  }

  public get fadeSnippets() {
    return this.isFaded && !this.isReviewMode;
  }

  public get isCenteredItem() {
    return this.$store.state.cloud.highlightInfo?.item?.item?.id === this.snippet?.item?.item?.id;
  }

  public get opacityStyle() {
    const opacityStyle = {
      transition: this.$store.getters['cloud/offCenterItemsOpacity'] !== '0' ? 'opacity 0.3s ease-in' : 'none',
    };
    if (this.isCenteredItem && this.isReviewMode && !this.isScrolling) {
      return {
        opacity: '1',
        ...opacityStyle,
      };
    } else if (!this.isCenteredItem && this.isReviewMode && !this.isScrolling) {
      return {
        opacity: this.$store.getters['cloud/offCenterItemsOpacity'],
        ...opacityStyle,
      };
    } else {
      return {};
    }
  }

  public get isReviewMode() {
    return this.$store.getters.isReviewMode
      && this.viewId === ViewIdentifier.MAIN_VIEW
      && this.isHorizontalView;
  }

  public get isCenteredItemInNavigationLane() {
    return this.$store.getters.isReviewMode
      && this.isCenteredItem
      && !this.isScrolling
      && this.viewId === ViewIdentifier.NAVIGATION_VIEW;
  }

  public get isOffCenteredItemInNavigationLane() {
    return this.$store.getters.isReviewMode
      && !this.isCenteredItem
      && this.viewId === ViewIdentifier.NAVIGATION_VIEW;
  }

  public get itemHeight(): number {
    return this.snippet.item.viewPosition.height;
  }

  public get itemWidth(): number {
    return this.snippet.item.viewPosition.width;
  }

  public get isHoverDisabled() {
    return this.hoverEffect === SnippetHoverEffect.NONE || this.isReviewMode;
  }

  public get isHoverNavigation() {
    return this.hoverEffect === SnippetHoverEffect.NAVIGATION;
  }

  public get isHoverDefault() {
    return this.hoverEffect === SnippetHoverEffect.DEFAULT;
  }

  public get currentItemOrder() {
    return this.snippet.item.itemPosition.order + 1;
  }

  public static calculateMosaicItemPaddingPercentage(itemHeight: number) {
    return MutantSnippet.FOCUS_FRAME_PADDING / itemHeight;
  }

  public drag(event: DragEvent) {
    if (this.dataUrl !== '') {
      const downloadUrl = `${this.currentAsset.mimeType}:${this.currentAsset.name}:${this.dataUrl}`;
      event.dataTransfer.effectAllowed = 'all';
      event.dataTransfer.dropEffect = 'copy';
      // Default for macOS and multiple other applications to download the associated file
      event.dataTransfer.setData('DownloadURL', downloadUrl);
      // Default for the browser to display the image as a data url preview, also used e.g. by Whatsapp Web
      event.dataTransfer.setData('text/plain', this.dataUrl);
      // May help other applications to display the image as a data url preview
      event.dataTransfer.setData(this.currentAsset.mimeType, this.dataUrl);
      const dragImage = document.getElementById(this.imageId);
      if (dragImage) {
        event.dataTransfer.setDragImage(dragImage, this.itemWidth / 2, this.itemHeight / 2);
      }
    }
    event.stopPropagation();
  }

  public dragging(event: any) {
    event.stopPropagation();
  }

  async preloadDataUrl(event) {
    if (this.currentAsset && this.downloadUrlAssetId !== this.currentAsset.id) {
      this.downloadUrlAssetId = this.currentAsset.id;
      this.dataUrl = this.isDataUrl(this.imgSrc) ? this.imgSrc : await this.$imageLoader.loadUrlAsBase64(this.imgSrc);
    }
    event.stopPropagation();
    event.preventDefault();
  }

  isDataUrl(url: string): boolean {
    return url.startsWith('data:');
  }

  public static calculatePaddingOffset(itemWidth: number, itemHeight: number): { left: number, top: number } {
    const paddingRatio = MutantSnippet.calculateMosaicItemPaddingPercentage(itemHeight);
    const left = itemWidth * paddingRatio;
    const top = itemHeight * paddingRatio;
    return {
      left,
      top,
    };
  }

  public get item(): Item {
    return this.snippet.item.itemData;
  }

  public isOriginalVersionOnline(asset: Asset): boolean {
    return asset.version === ORIGINAL_ASSET_VERSION && !asset.isOffline;
  }

  public hasOriginalVersionOnline(): boolean {
    return this.assets.some(a => this.isOriginalVersionOnline(a));
  }

  public get assets(): Asset[] {
    return sortedAssets(this.item.assets);
  }

  mounted() {
    this.$el.addEventListener('touchstart', this.touchstart, { passive: true });
  }

  beforeDestroy() {
    this.preloadAssetSubscription.unsubscribe();
    this.$el.removeEventListener('touchstart', this.touchstart);
  }

  public touchstart(event: TouchEvent) {
    this.$emit('touchstart', event);
  }

  public videoStarted(): void {
    this.videoPlaying = true;
  }

  public videoStopped(): void {
    this.videoPlaying = false;
  }

  public get player() {
    // @ts-ignore
    return this.$refs.youtube.player;
  }

  public get videoPaused(): boolean {
    return this.videoPlaying === false;
  }

  public get youtubePlayerOptions() {
    return {
      controls: 0,
      modestbranding: 1,
      loop: 1,
      rel: 0,
      mute: 0,
      showinfo: 0,
      enablejsapi: 1,
      origin: WebConfig.URL,
    };
  }

  public async playVideo() {
    await this.player.playVideo();
    this.videoPlaying = true;
  }

  public get selectedByList() {
    return this.selectedBy.map(s => s.username).join(', ');
  }

  public get descriptionStyle() {
    return {
      'max-width': `${this.itemWidth}px`,
      'font-size': `${this.contactSheetTextSize}px`,
      'min-height': `${this.contactSheetTextSize}px`,
    };
  }

  public get isContactSheet() {
    return this.viewType === ViewType.CONTACT_SHEET;
  }

  public get isHorizontalView() {
    return this.viewType === ViewType.HORIZONTAL;
  }

  public get isGridView() {
    return this.viewType === ViewType.GRID;
  }

  public get calculateShutterDimension(): number {
    return this.isHorizontalView || this.isGridView ? this.itemHeight / 8 : this.itemWidth / 6;
  }

  public get dragIconStyle() {
    const dragIconDimensions = this.calculateShutterDimension;
    const offsetTop = this.isContactSheet
      ? `calc(50% - ${(dragIconDimensions) / 2}px + 15px`
      : `calc(50% - ${(dragIconDimensions) / 2}px)`;
    return {
      left: `calc(50% - ${(dragIconDimensions) / 2}px)`,
      height: `${dragIconDimensions}px`,
      top: offsetTop,
      width: `${dragIconDimensions}px`,
    };
  }

  public get overlayStyle() {
    return this.isContactSheet || this.hasFocusFrame || this.isHoverNavigation
      ? {}
      : {
          border: this.getBorderSize(),
          outline: this.getOutlineSize(),
          pointerEvents: this.isInActionBounds ? 'none' : 'all',
        };
  }

  handleMouseEnter(e: MouseEvent) {
    this.isHover = true;
    if (this.item.type === ItemType.YOUTUBE) {
      const mouseButtonDown = e.buttons === undefined
        ? e.which === 1
        : e.buttons === 1;
      this.isInActionBounds = !mouseButtonDown && this.videoPlaying;
    }
    if (this.showResizeTools) {
      this.$el.addEventListener('mousemove', this.handleMouseMove);
    }
    this.$emit('mouseenter', e);
  }

  handleMouseLeave(e: MouseEvent) {
    this.isInActionBounds = false;
    this.isDeselected = false;
    this.isHover = false;
    if (this.showResizeTools) {
      this.cursor = 'default';
      this.$el.removeEventListener('mousemove', this.handleMouseMove);
    }
    this.$emit('mouseleave', e);
  }

  // TODO: simplify if/else cursor building
  handleMouseMove(event: MouseEvent) {
    const triggerRange = this.resizeTriggerRangePixel;
    if (event.offsetX < triggerRange) {
      if (event.offsetY < triggerRange) {
        this.cursor = 'nwse-resize';
      } else if (event.offsetY > this.itemHeight - triggerRange) {
        this.cursor = 'nesw-resize';
      } else {
        this.cursor = 'ew-resize';
      }
    } else if (event.offsetX > this.itemWidth - triggerRange) {
      if (event.offsetY < triggerRange) {
        this.cursor = 'nesw-resize';
      } else if (event.offsetY > this.itemHeight - triggerRange) {
        this.cursor = 'nwse-resize';
      } else {
        this.cursor = 'ew-resize';
      }
    } else if (event.offsetY < triggerRange || event.offsetY > this.itemHeight - triggerRange) {
      this.cursor = 'ns-resize';
    } else {
      this.cursor = 'default';
    }
  }

  handleRemoveIndicatorClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.$emit('remove', event);
  }

  public get showCaptions(): boolean {
    return this.$store.state.showCaptions;
  }

  public get showResolutions(): boolean {
    return this.$store.state.showResolutions;
  }

  public get isPlaceholder(): boolean {
    return !!this.item.isPlaceholder;
  }

  public get paddingInfos(): { vertical: number, horizontal: number } {
    const offsets = MutantSnippet.calculatePaddingOffset(this.itemWidth, this.itemHeight);
    return {
      vertical: offsets.top + 1,
      horizontal: offsets.left + 1,
    };
  }

  private get paddingOffset(): {left: number, top: number} {
    return this.hasFocusFrame ? MutantSnippet.calculatePaddingOffset(this.itemWidth, this.itemHeight) : { left: 0, top: 0 };
  }

  public get videoDimensions(): { width, height } {
    const { left, top } = this.paddingOffset;
    return {
      width: this.itemWidth - (2 * left),
      height: this.itemHeight - (2 * top),
    };
  }

  public get videoThumbnailStyle() {
    const { left, top } = this.paddingOffset;
    return {
      left: left + 'px',
      top: top + 'px',
      width: `calc(100% - ${2 * left}px`,
      height: `calc(100% - ${2 * top}px`,
    };
  }

  private preloadImg(item: Item, folderOrSelectionId: string): void {
    if (item.type === ItemType.IMAGE && !item.isPlaceholder) {
      try {
        if (this.imgSrc && item.isSynced != null && !item.isSynced) {
          return;
        }
        this.preloadInProgress = true;
        const asset = this.$imageLoader.getOptimalAssetByWidth(item, this.itemWidth);
        if (asset && (this.imgSrc === '' || !asset.hash || this.currentAssetHash !== asset.hash)) {
          this.preloadAsset(item, asset, folderOrSelectionId);
        }
      } catch (err) {
        this.$log.error(`Error while preloading image for item ${item.id}`, err);
      } finally {
        this.preloadInProgress = false;
      }
    }
  }

  private preloadAsset(item: Item, asset: Asset, folderOrSelectionId: string): void {
    if (this.preloadAssetSubscription != null) {
      this.preloadAssetSubscription.unsubscribe();
    }
    this.preloadAssetSubscription = this.$imageLoader.preloadSubject$
      .pipe(filter(event => event.itemId === item.id && event.assetId === asset.id))
      .subscribe(event => {
        this.preloadAssetSubscription.unsubscribe();
        this.imgSrc = event.assetSrc;
        this.currentAssetHash = asset.hash ? asset.hash : this.currentAssetHash;
        this.currentAsset = asset;
      });
    this.$imageLoader.preloadAssetUrl(item, asset, folderOrSelectionId, this.currentItemOrder);
  }

  private folderOrSelectionId(viewItem: ViewItem) {
    return (viewItem.item as FolderItem).folderId || (viewItem.item as SelectionItem).selectionId;
  }

  getBorderSize() {
    if (this.itemSelectedUseOutline) {
      return this.getSelectionHighlight(this.itemSelectedBorder > 1 ? this.itemSelectedBorder / 2 : this.itemSelectedBorder);
    }
    return this.getSelectionHighlight(this.itemSelectedBorder);
  }

  getOutlineSize() {
    if (this.itemSelectedUseOutline) {
      return this.getSelectionHighlight(this.itemSelectedBorder > 1 ? this.itemSelectedBorder / 2 : this.itemSelectedBorder);
    }
    return '1px solid transparent';
  }

  getSelectionHighlight(borderSize: number) {
    if (this.isSelected) {
      return `${borderSize}px solid ${this.getSelectionHighlightColor()}`;
    }
    return '1px solid transparent';
  }

  getSelectionHighlightColor() {
    if (this.selectedBy && this.selectedBy.length && this.selectedBy[0].id && this.$store.state.selection.selectedColors.size) {
      return this.$store.state.selection.selectedColors.get(this.selectedBy[0].id);
    }
    return '#fb9f00';
  }

  handleMobileEvent({ event, type }: MobileInteractionEvent) {
    event.stopImmediatePropagation();
    switch (type) {
      case 'long-press-event':
        this.$emit('open-item');
        break;
      case 'tap-event': {
        this.clickCount++;
        if (this.clickCount === 1) {
          this.isDeselected = this.isSelected;
          this.$emit('toggle-pre-select-item');
          this.clickTimer = setTimeout(() => {
            this.clickCount = 0;
            this.$emit('toggle-select-item');
          }, this.clickDelay);
        } else if (this.clickCount === 2) {
          clearTimeout(this.clickTimer);
          this.clickCount = 0;
          this.$emit('toggle-pre-select-item');
          this.$emit('open-item');
        }
      }
    }
  }

  handleClick(e: MouseEvent) {
    if (!this.$store.getters.isMobile) {
      if (e.getModifierState('Meta')) {
        this.$emit('meta-click', e);
        return;
      }
      this.clickCount++;
      if (this.clickCount === 1) {
        this.isDeselected = this.isSelected;
        this.$emit('toggle-pre-select-item');
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
          this.$emit('toggle-select-item');
        }, this.clickDelay);
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer);
        this.clickCount = 0;
        this.$emit('toggle-pre-select-item');
        this.$emit('open-item');
      }
    }
  }

  @Watch('item', { immediate: true })
  public watchItemAssets(newItem: Item, oldItem: Item) {
    if (newItem.isPlaceholder) {
      this.prepareForPlaceholderReplacement = true;
    } else if (this.prepareForPlaceholderReplacement) {
      setTimeout(() => {
        this.prepareForPlaceholderReplacement = false;
      }, 1000);
    }
    if (oldItem && !this.preloadInProgress && newItem.assets.length > oldItem.assets.length) {
      const folderOrSelectionId = this.folderOrSelectionId(this.snippet.item);
      this.preloadImg(this.item, folderOrSelectionId);
    }
  }

  @Watch('itemWidth', { immediate: true })
  public watchItemWidth(newWidth: number, oldWidth: number) {
    if (newWidth) {
      const range = this.resizeTriggerRange.percentage * newWidth;
      this.resizeTriggerRangePixel = range > this.resizeTriggerRange.minPixel ? range : this.resizeTriggerRange.minPixel;
      if (!this.preloadInProgress || newWidth !== oldWidth) {
        const folderOrSelectionId = this.folderOrSelectionId(this.snippet.item);
        this.preloadImg(this.item, folderOrSelectionId);
      }
    }
  }

  @Watch('assets', { immediate: true })
  public async watchAssets(newAssets: Asset[]) {
    if (newAssets.length !== 1) {
      const optimalAssetByWidth = this.$imageLoader.getOptimalAssetByWidth(this.item, this.itemWidth);
      if (newAssets.every(a => a.id !== this.currentAsset.id) || newAssets.every((a) => a.isOffline)) {
        this.$imageLoader.clearCachedAsset(this.currentAsset);
        this.currentAsset = optimalAssetByWidth;
        await this.selectVersionByAsset(this.currentAsset);
      }
      if (optimalAssetByWidth != null && this.assets.length !== newAssets.length) {
        this.currentAsset = optimalAssetByWidth;
        const folderOrSelectionId = this.folderOrSelectionId(this.snippet.item);
        this.preloadAsset(this.item, this.currentAsset, folderOrSelectionId);
      }
    }
  }
}
