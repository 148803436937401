
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { debounce, Subject, timer } from 'rxjs';
import MutantSlider from '~/components/ui/MutantSlider.vue';
import MutantCheckbox from '~/components/ui/MutantCheckbox.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';

@Component({
  components: {
    MutantSlider,
    MutantCheckbox,
  },
})
export default class MagnifyControls extends Vue {
  @Prop(Boolean)
  public isAttached: boolean;

  public isNavigationMode = false;
  public isSelectionFrame = false;
  private magnifyMarginChangeObserver: Subject<Event> = new Subject();

  constructor() {
    super();
    this.magnifyMarginChangeObserver
      .pipe(debounce(() => timer(200)))
      .subscribe(event => this.$store.dispatch('cloud/setMagnifyMarginFactor', { windowId: ViewIdentifier.OVERLAY_VIEW, marginFactor: event }));
  }

  beforeDestroy() {
    this.magnifyMarginChangeObserver.unsubscribe();
  }

  public closeMagnify() {
    this.$store.dispatch('magnify/close');
  }

  public toggleSelectionFrame() {
    this.isSelectionFrame = !this.isSelectionFrame;
    this.$emit('toggle-selection-frame');
  }

  public toggleFullscreenMagnify() {
    this.$emit('toggle-fullscreen');
  }

  public setMagnifyMarginFactor(event: Event) {
    this.magnifyMarginChangeObserver.next(event);
  }
}
