
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import MutantBadgeButtonLarge from '~/components/ui/MutantBadgeButtonLarge.vue';

  @Component({
    components: { MutantBadgeButtonLarge },
  })
export default class MutantCircleButton extends Vue {
    @Prop(Boolean)
    isActive!: boolean;

    @Prop(Boolean)
    hasOpacity!: boolean;

    @Prop(Boolean)
    showTooltip?: boolean;

    @Prop()
    tooltip?: string;

    @Prop()
    tooltipPosition?: string;

    @Prop(Boolean)
    isDisabled!: boolean;

    @Prop(Boolean)
    isSmall?: boolean;

    @Prop(Boolean)
    isLarge?: boolean;

    public isClicked = false;
    public isHover = false;

    public click(event: MouseEvent) {
      this.isClicked = true;
      setTimeout(() => {
        this.isClicked = false;
      }, 400);
      this.$emit('click', event);
    }

    public mouseleave(event: MouseEvent) {
      this.isClicked = false;
      this.isHover = false;
      this.$emit('mouseleave', event);
    }
}
