
import { Component, Vue } from 'nuxt-property-decorator';
import ExternalProgressIndicator from '~/components/ui/progress/ExternalProgressIndicator.vue';
import MutantCounter from '~/components/ui/MutantCounter.vue';
import { ExternalUploadProgress, Progress } from '~/store/file/state';

@Component({
  components: {
    ExternalProgressIndicator,
    MutantCounter,
  },
})
export default class ProgressIndicatorContextMenu extends Vue {
  public get numberOfFiles(): number {
    return this.isExternalProgress ? this.externalProgress.totalItems : this.progress?.maxFiles || 0;
  }

  public get externalProgress(): ExternalUploadProgress {
    return this.$store.state.file.externalUploadProgress;
  }

  public get progress(): Progress {
    return this.$store.getters['file/activeProgress'];
  }

  public get progressInPercent(): number {
    return this.isExternalProgress ? this.externalProgress.progressInPercent : this.progress?.percentTransferred || 0;
  }

  public get isExternalProgress(): boolean {
    return this.externalProgress && this.externalProgress.progressInPercent > 0;
  }

  public get isProgressFinished(): boolean {
    return this.isExternalProgress ? this.externalProgress.progressInPercent === 100 : this.progress?.finished;
  }
}
