
import { Component, Prop, Vue } from 'nuxt-property-decorator';

type OpenToDirection = 'bottom' | 'top';

@Component
export default class InfoButton extends Vue {
  @Prop({ default: '' })
    text: string;

  @Prop(Boolean)
    small: boolean;

  @Prop()
    openTo?: OpenToDirection;

  public keepTooltipAlive = false;

  public toggleKeepTooltip(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.keepTooltipAlive = !this.keepTooltipAlive;
  }
}
