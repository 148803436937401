
import moment from 'moment';
import { Component, Vue } from 'nuxt-property-decorator';
import { UserProfile } from '~/models/user/UserProfile';
import FlipArrow from '~/components/ui/FlipArrow.vue';
import Column from '~/components/ui/Column.vue';
import ActionButtonModel from '~/models/ActionButtonModel';
import { ContextMenuType } from '~/store/context/state';
import ActionButton from '~/components/layout/footer/ActionButton.vue';
import { UserDetails } from '~/models/user/UserDetails';
import ResendMailButton from '~/components/dialogs/account/ResendMailButton.vue';
import MutantProfileImage from '~/components/ui/MutantProfileImage.vue';
import UploadVolumeStatus from '~/components/layout/header/UploadVolumeStatus.vue';
import { WarningMessages } from '~/models/MessageTypes';
import { NotificationType } from '~/models/Notification';

interface BasicAccountInfo {
  key: string,
  field: string,
  value: string | boolean,
  editable?: boolean
  icon?: string[]
}

@Component({
  components: {
    UploadVolumeStatus,
    ResendMailButton,
    ActionButton,
    FlipArrow,
    Column,
    MutantProfileImage,
  },
})
export default class Profile extends Vue {
  public isDragOver = false;
  public imageSrc = '';
  public isHoverId = '';
  public currentFieldInput = null;
  public currentFieldKey = null;

  public fileInputId = 'profile-picture-menu';

  public upgradeAcountButton: ActionButtonModel = {
    key: 'upgrade-account',
    name: () => 'UPGRADE ACCOUNT',
    action: () => this.$store.dispatch('context/openMenu', { type: ContextMenuType.ACCOUNT_UPGRADE }),
    isHighlighted: false,
  };

  constructor() {
    super();
  }

  public get profile(): UserProfile {
    return this.$store.state.profile?.data;
  }

  public triggerFileUpload() {
    const fileInput = document.getElementById(this.fileInputId);
    if (fileInput) {
      fileInput.click();
    }
  }

  async uploadProfilePic(event: InputEvent) {
    event.preventDefault();
    const file: File = (event.target as HTMLInputElement)?.files?.item(0);
    if (file != null) {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        await this.$store.dispatch('profile/uploadProfilePicture', file);
      } else {
        this.$store.dispatch('setNotificationMessage', { payload: { message: WarningMessages.UPLOAD_PROFILE_IMAGE_TYPE_MISMATCH, type: NotificationType.ERROR } });
      }
    }
  }

  public get accountMenuBackground() {
    return this.imageSrc !== ''
      ? `linear-gradient(to bottom, rgba(8%,8%,8%,0) 0%, rgba(8%,8%,8%,0.8) 200px, rgb(8%, 8%, 8%) 250px), url(${this.imageSrc})`
      : 'linear-gradient(to bottom, rgba(8%,8%,8%,0) 0%, rgba(8%,8%,8%,0.8) 200px, rgba(8%, 8%, 8%) 250px)';
  }

  public get basicAccountInfo(): BasicAccountInfo[] {
    const userDetails: UserDetails = this.$store.getters['user/userDetails'];
    if (userDetails) {
      const { size, unit } = this.$store.getters['folder/totalSizeInCloud'];
      return [{
        key: 'username',
        field: 'User Account Name',
        value: userDetails.username,
      }, {
        key: 'email',
        field: 'Email Address',
        value: userDetails.email,
      }, {
        key: 'emailVerified',
        field: 'Email Verified',
        value: userDetails.emailVerified,
        editable: false,
        icon: userDetails.emailVerified ? ['fas', 'check'] : ['fas', 'exclamation'],
      }, {
        key: 'accountType',
        field: 'Account Type',
        value: this.$store.getters['user/supremeRoleName'],
      }, {
        key: 'created',
        field: 'Sign Up Date',
        value: moment(userDetails.created).format('YYYY/MM/DD'),
      }, {
        key: 'cloudSize',
        field: 'Cloud Size',
        value: `${size.toFixed(2)} ${unit}`,
      }, {
        key: 'uploadVolume',
        field: 'Monthly Upload Volume',
        value: null,
        editable: false,
      }];
    }
    return [];
  }

  public setCurrentFieldInput({ key, value }) {
    this.currentFieldInput = {
      key,
      originalValue: value,
    };
  }

  public updateField(event: KeyboardEvent) {
    if (this.currentFieldInput) {
      const { key, originalValue } = this.currentFieldInput;
      const fieldElement = document.getElementById(`INPUT_${key}`);
      const newValue = fieldElement ? fieldElement.innerText.trimEnd() : originalValue;
      if (newValue !== originalValue) {
        this.$store.dispatch('user/updateField', {
          key,
          value: newValue,
        });
      }
      fieldElement.blur();
      this.currentFieldInput = null;
    } else if (event.key === 'Enter') {
      const fieldElement = document.getElementById(`INPUT_${this.currentFieldKey}`);
      fieldElement && fieldElement.blur();
    }
  }

  async loadProfileImage() {
    if (this.profile && this.profile.id) {
      this.imageSrc = await this.$imageLoader.loadProfileImageInBase64(this.profile.id);
    }
  }

  isHover(key: string): boolean {
    return this.isHoverId === key;
  }

  get userVerified() {
    return this.$store.getters['user/userDetails']?.emailVerified;
  }

  get verifyEmailToolTip() {
    return !this.userVerified
      ? { content: 'Please verify your email', placement: 'top-start' }
      : undefined;
  }

  get upgradeAccountToolTip() {
    return !this.$store.getters['user/isAccountUpgradeOptionGiven']
      ? { content: 'Please verify your email, so you can upgrade your account.', placement: 'top-start' }
      : undefined;
  }

  openPasswordResetContextMenu() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.CHANGE_PASSWORD });
  }

  openForgotPasswordContextMenu() {
    this.$store.dispatch('context/openMenu', { type: ContextMenuType.FORGOT_PASSWORD });
  }
}
