
import { Component, Vue } from 'nuxt-property-decorator';
import { ContextMenuType } from '~/store/context/state';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import Column from '~/components/ui/Column.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import ChangePasswordSection from '~/components/dialogs/password-reset/ChangePasswordSection.vue';

@Component({
  components: {
    ChangePasswordSection,
    ContextMenuHeaderFlashy,
    Column,
    MutantContextMenu,
  },
})
export default class ChangePasswordContextMenu extends Vue {
  public get isResetByOldPassword() {
    return this.$router.currentRoute.query['password-reset-code'] == null;
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.CHANGE_PASSWORD);
  }
};
