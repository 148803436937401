
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class AcceptTermsCheckmark extends Vue {
  @Prop(Boolean)
    checked: boolean;

  @Prop(Boolean)
    isHover: boolean;
}
