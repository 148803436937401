var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"highlight-box",class:{
    'highlight-box--small': _vm.small,
    'highlight-box--medium': _vm.medium,
    'highlight-box--large': _vm.large,
    'highlight-box--info': _vm.info,
    'highlight-box--darkened': _vm.darkened
  },on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }