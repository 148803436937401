
import { Component } from 'nuxt-property-decorator';
import ReviewSectionHeader from '~/components/menu/right/tabs/review/ReviewSectionHeader.vue';
import MutantBadge from '~/components/ui/MutantBadge.vue';
import TagWithBadge from '~/components/menu/right/tabs/review/folder-tags/TagWithBadge.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { FilterOption } from '~/store/cloud/state';
import ReviewFilterCommon from '~/components/menu/right/tabs/review/ReviewFilterCommon.vue';

@Component({
  components: { TagWithBadge, MutantBadge, ReviewSectionHeader },
})
export default class AssetAndFileTypeSection extends ReviewFilterCommon {
  public isExpanded = true;
  public FilterOption = FilterOption;

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public get selectionCount(): number {
    return this.$store.getters['selection/globalSelectionItemsInView'](ViewIdentifier.MAIN_VIEW).length;
  }

  public get offlineItemsCount(): number {
    return this.$store.getters['file/uploadPendingItems']?.length;
  }
}
