
import { Component, Vue } from 'nuxt-property-decorator';
import { ContextMenuType } from '~/store/context/state';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import Column from '~/components/ui/Column.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import ForgetPasswordSection from '~/components/dialogs/forgot-password/ForgetPasswordSection.vue';

@Component({
  components: {
    ForgetPasswordSection,
    ContextMenuHeaderFlashy,
    Column,
    MutantContextMenu,
  },
})
export default class ForgotPasswordContextMenu extends Vue {
  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.FORGOT_PASSWORD);
  }
};
