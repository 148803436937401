
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';

@Component
export default class DownloadArea extends Vue {
  @Prop({ default: uuid() })
  public elementId;

  public isHover = false;

  public onDrop(event: MouseEvent | DragEvent) {
    event.preventDefault();
    if (this.$store.state.dragInfo) {
      this.$store.commit('setDropInfo', {
        elementId: this.elementId,
        position: { left: event.clientX, top: event.clientY },
      });
      this.$store.dispatch('downloadDragInfo');
    }
  }
}
