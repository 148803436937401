
import throttle from 'lodash.throttle';
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import Item from '~/models/item/Item';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ViewHandler } from '~/models/views/MutantView';
import { ShortcutType } from '~/models/ShortcutType';
import { SortItemsEvent } from '~/models/item/SortItemsEvent';
import { ViewType } from '~/models/views/ViewType';
import { HighlightInfo, ViewSortingOption } from '~/store/cloud/state';
import SnippetView from '~/components/window/view/SnippetView.vue';

@Component({
  components: {
    SnippetView,
  },
})
export default class MagnifyContent extends Vue {
  @Prop()
    viewId: string;

  @Prop(Boolean)
    isDragging: boolean;

  @Prop({ default: null })
    scrollFactor: number;

  @Prop(Boolean)
    heightTransition: boolean;

  @Prop()
    height: number;

  @Prop()
    items: ItemWithPosition[];

  public magnifyItems: Item[] = [];
  public isScrolling = false;
  public throttledScrollParameterCalculation: any = null;
  public viewRange = { start: 0, end: this.$store.state.windowSize * 4 };
  public isSelectionFrame = true;
  public isNavigationMode = false;

  private scrollTriggeredBySystem = false;
  private uploadHandlerInProgress = false;
  private viewScrollingTimeoutHandler = null;
  private scrollTriggeredBySystemTimeoutHandler = null;
  private internalScrollFactor = 0;

  constructor() {
    super();
    this.throttledScrollParameterCalculation = throttle(this.calculateScrollParameters, 100, { leading: true });
  }

  public get isSelectionMagnified(): boolean {
    return this.$store.getters['magnify/isSelectionMagnified'];
  }

  public get highlightItemId(): string {
    const highlightInfo: HighlightInfo = this.$store.state.cloud.highlightInfo;
    return highlightInfo && this.viewId !== highlightInfo.originView ? highlightInfo.item.item.id : null;
  }

  public get isMagnifiedItemSelected() {
    return this.$store.getters['selection/isOneOfItemsSelected'](this.items);
  }

  public get isFolderMagnified(): boolean {
    return this.$store.getters['magnify/isFolderMagnified'];
  }

  public get isShortcutMagnified(): boolean {
    return this.$store.getters['magnify/isShortcutMagnified'];
  }

  public get magnifiedFolderId(): string | null {
    if (this.isFolderMagnified) {
      return this.$store.getters['cloud/currentMainView'].folderId;
    }
    if (this.isShortcutMagnified && this.$store.state.magnify.viewContent.shortcut.type === ShortcutType.FOLDER) {
      return this.$store.state.magnify.viewContent.shortcut.id;
    }
    return null;
  }

  onScroll(event: MouseEvent) {
    this.throttledScrollParameterCalculation(event);
  }

  onScrollStart() {
    clearTimeout(this.viewScrollingTimeoutHandler);
    this.isScrolling = true;
  }

  onScrollEnd() {
    this.viewScrollingTimeoutHandler = setTimeout(() => {
      this.isScrolling = false;
    }, 250);
  }

  handleDrop(e: DragEvent) {
    e.preventDefault();
    if (this.$store.state.currentDragViewId !== 'main-view') {
      if (e.dataTransfer && !this.uploadHandlerInProgress) {
        this.uploadHandlerInProgress = true;
        const files = e.dataTransfer.files;
        const folderId = this.magnifiedFolderId;
        if (files && files.length) {
          this.$store.dispatch('file/uploadFilesTo', { folderId, files });
        } else {
          const mutantItemId = e.dataTransfer.getData('MUTANT_ITEM_ID');
          const url = e.dataTransfer.getData('URL');
          if (mutantItemId) {
            this.$store.dispatch('folder/addExternalItemTo', { folderId, itemId: mutantItemId });
          } else if (url) {
            this.$store.dispatch('folder/extractUrl', { folderId, url, originalEvent: e });
          }
        }
        setTimeout(() => {
          this.uploadHandlerInProgress = false;
        }, 1000);
      }
    }
  }

  private calculateScrollParameters(event: MouseEvent) {
    const element = document.getElementById('magnify-scroll-view');
    this.viewRange = ViewHandler.calculateViewRange({
      previousViewRange: this.viewRange,
      viewType: ViewType.HORIZONTAL,
      scrollViewElement: element,
    });
    if (this.isDragging || this.scrollTriggeredBySystem) {
      event.stopImmediatePropagation();
      event.preventDefault();
      return;
    }
    this.$emit('scroll', event);
    if (this.scrollFactor === null) {
      const element = document.getElementById('magnify-scroll-view');
      if (element.scrollLeft > 0) {
        this.internalScrollFactor = element.scrollLeft / element.scrollWidth;
      }
    }
  }

  public sortItems(event: SortItemsEvent) {
    const originalSortOrder = this.$store.state.magnify.sortedBy;
    if (this.$store.getters['magnify/magnifiedFolderId']) {
      if (this.confirmSorting(originalSortOrder)) {
        // TODO: merge sortItems and setSortingOption into one magnify action
        this.$store.dispatch('folder/sortItems', {
          folderId: this.$store.getters['magnify/magnifiedFolderId'],
          event,
          originalSortOrder,
        });
        this.$store.commit('magnify/setSortingOption', ViewSortingOption.CUSTOM_ORDER);
      }
    } else if (this.$store.getters['magnify/magnifiedSelectionId']) {
      if (this.confirmSorting(originalSortOrder)) {
        this.$store.dispatch('selection/sortItems', {
          selectionId: this.$store.getters['magnify/magnifiedSelectionId'],
          event,
          originalSortOrder,
        });
        this.$store.commit('magnify/setSortingOption', ViewSortingOption.CUSTOM_ORDER);
      }
    }
  }

  public confirmSorting(originalSortOrder: ViewSortingOption) {
    return originalSortOrder === ViewSortingOption.CUSTOM_ORDER || confirm('The view is sorted by last modified date. Are you sure you want to overwrite your custom sorting order?');
  }

  adjustScrollView(sizes: { height: number, width: number }): void {
    if (!this.isDragging) {
      clearTimeout(this.scrollTriggeredBySystemTimeoutHandler);
      this.scrollTriggeredBySystem = true;
      document.getElementById('magnify-scroll-view').scrollLeft = (this.scrollFactor ? this.scrollFactor : this.internalScrollFactor) * sizes.width;
      this.scrollTriggeredBySystemTimeoutHandler = setTimeout(() => {
        this.scrollTriggeredBySystem = false;
      }, 1000);
    }
  }

  onItemHighlightPosition(highlightItemPosition: { left: number, top: number, width: number, height: number }) {
    const scrollElement = document.getElementById('magnify-scroll-view');
    // eslint-disable-next-line prefer-const
    let { top, left, width } = highlightItemPosition;
    left -= scrollElement.clientWidth / 2 - width / 2;
    scrollElement.scrollTo({
      top,
      left,
      behavior: 'smooth',
    });
  }

  private scrollToLatestElement() {
    const magnifyElement = document.getElementsByClassName('magnify-content');
    const magnify = magnifyElement.item(0);
    if (magnify) {
      // TODO: calculate scaled item width of new item here and use it for magnify placeholder
      magnify.scrollTo({
        left: magnify.scrollWidth,
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  @Watch('height', { immediate: true })
  onHeightChange(newHeight: number) {
    if (newHeight) {
      const scrollElement = document.getElementById('magnify-scroll-view');
      if (scrollElement) {
        this.viewRange = ViewHandler.calculateViewRange({
          previousViewRange: this.viewRange,
          viewType: ViewType.HORIZONTAL,
          scrollViewElement: scrollElement,
        });
      }
    }
  }

  @Watch('items', { immediate: true })
  async onItemsChange(newItems: Item[]) {
    if (newItems) {
      const newItemsFiltered = JSON.parse(JSON.stringify(newItems)).filter(i => !('isPlaceholder' in i && i.isPlaceholder));
      const addedItem = newItemsFiltered.length && newItemsFiltered[newItemsFiltered.length - 1];
      const scrollToItem = newItemsFiltered.length && newItemsFiltered.length === this.magnifyItems.length + 1 && !this.magnifyItems.some(i => i.id === addedItem.id);
      this.magnifyItems = newItemsFiltered;
      await this.$forceUpdate();
      await this.$nextTick();
      if (scrollToItem) {
        this.scrollToLatestElement();
      }
    }
  }
}
