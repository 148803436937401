
import { Component, Vue } from 'nuxt-property-decorator';
import { Asset } from '~/models/Asset';
import { ItemType } from '~/models/item/ItemType';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { DetailViewContentType } from '~/store/detailView/actions';
import AssetDetails from '~/components/ui/AssetDetails.vue';
import ReviewInfo from '~/components/ui/ReviewInfo.vue';
import { DetailViewState } from '~/store/detailView/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import FullscreenControl from '~/components/window/view/header/FullscreenControl.vue';
import { SwipeEvent } from '~/plugins/swipe';

@Component({
  components: { FullscreenControl, ReviewInfo, AssetDetails },
})
export default class DetailView extends Vue {
  public modalId = 'modal';
  public modalContentId = 'modal-content';
  public imageId = 'modal-img';
  public isHover = false;
  private isFullScreenActive: boolean;

  public get isOpenAnimationFinished(): boolean {
    return this.detailView.isOpenAnimationFinished;
  }

  private get assets(): Asset[] {
    return this.$store.getters['detailView/sortedAssets'];
  }

  public get isFirst(): boolean {
    return this.currentItem.position.order === 0;
  }

  public get viewItems(): ItemWithPosition[] {
    return this.$store.getters['cloud/viewItemsMap'][ViewIdentifier.MAIN_VIEW]?.items;
  }

  public get isLast(): boolean {
    return this.currentItem.position.order === this.viewItems.length - 1;
  }

  public get content(): string {
    return this.detailView.content;
  }

  public get detailView(): DetailViewState {
    return this.$store.state.detailView;
  }

  public get showAssetDetails(): boolean {
    return this.detailView.showAssetDetails;
  }

  // note: have to get the original item, otherwise changes like rating, do not get reflected in here.
  public get currentItem(): ItemWithPosition {
    return this.$store.getters['cloud/getViewItemById'](ViewIdentifier.MAIN_VIEW, this.detailView.currentItem.itemId);
  }

  public get currentAsset(): Asset {
    return this.detailView.currentAsset;
  }

  created() {
    this.$store.dispatch('detailView/scaleItemIntoImageSliderPosition');
  }

  mounted() {
    const modalElement = document.getElementById(this.modalId);
    if (modalElement) {
      modalElement.focus();
    }
  }

  public get modalContentStyle() {
    return {
      width: `${this.itemWidth}px`,
    };
  }

  public get itemType(): ItemType {
    return this.currentItem.item.type;
  }

  public get dragIconStyle() {
    const dragIconDimensions = this.itemHeight / 8;
    return {
      left: `calc(50% - ${(dragIconDimensions) / 2}px)`,
      height: `${dragIconDimensions}px`,
      top: `calc(50% - ${(dragIconDimensions) / 2}px)`,
      width: `${dragIconDimensions}px`,
    };
  }

  public get itemDimensions(): { width: number, height: number, left: number, top: number } {
    return this.$store.getters['detailView/itemDimensions'];
  }

  public get itemHeight(): number {
    return this.itemDimensions.height;
  }

  public get itemWidth(): number {
    return this.itemDimensions.width;
  }

  public toggleAssetDetails() {
    this.$store.dispatch('detailView/toggleAssetDetails');
  }

  onSwipe(swipeEvent: SwipeEvent) {
    const image = document.getElementById(this.imageId);
    const {
      type,
      direction,
      distanceX,
      duration,
    } = swipeEvent;
    let translateX = 0;
    if (type === 'move') {
      if (!this.isFirst && direction === 'right') {
        translateX = distanceX;
      } else if (!this.isLast && direction === 'left') {
        translateX = -distanceX;
      }
      image.style.transform = `translateX(${translateX}px)`;
    } else if (type === 'finish') {
      if (direction === 'down') {
        this.close();
      }
      if (distanceX >= image.offsetWidth / 2 || (duration <= 300 && distanceX >= image.offsetWidth / 15)) {
        let position: number;
        if (!this.isFirst && direction === 'right') {
          position = image.offsetWidth;
          setTimeout(() => this.prev(), 300);
        } else if (!this.isLast && direction === 'left') {
          position = -image.offsetWidth;
          setTimeout(() => this.next(), 300);
        }
        image.style.transform = `translateX(${position}px)`;
      } else {
        image.style.transform = 'translateX(0)';
      }
    }
  }

  mouseEnterImage(_event: MouseEvent) {
    this.isHover = true;
  }

  mouseLeaveImage(_event: MouseEvent) {
    this.isHover = false;
  }

  public drag(event: DragEvent) {
    event.dataTransfer.setData('DownloadURL', this.getDownloadUrl(this.currentAsset));
    event.dataTransfer.setDragImage(document.getElementById(this.imageId), this.itemWidth / 2, this.itemHeight / 2);
    event.stopImmediatePropagation();
  }

  public dragging(event: any) {
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  getDownloadUrl(asset: Asset) {
    return asset ? `${asset.mimeType}:${asset.name}:${this.content}` : '';
  }

  public async selectAssetVersion(asset: Asset, event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    await this.$store.dispatch('detailView/loadSpecificContent', { item: this.currentItem, asset, type: DetailViewContentType.CURRENT });
    this.$store.commit('detailView/setCurrentAsset', asset);
  }

  downloadAsset(asset: Asset) {
    asset.itemId = this.currentItem.item.id;
    this.$store.dispatch('file/downloadSingleFileAssets', [asset]);
    this.$store.dispatch('showNotificationOnLargeAssetDownload', [asset], { root: true });
  }

  next() {
    this.$store.dispatch('detailView/next');
  }

  prev() {
    this.$store.dispatch('detailView/previous');
  }

  close() {
    if (this.isFullScreenActive) {
      document.exitFullscreen();
    }
    this.$store.dispatch('detailView/close');
  }

  public get modalImageStyle() {
    return {
      left: `calc(50% - ${this.itemWidth / 2}px)`,
      position: 'absolute',
      top: `calc(50% - ${this.itemHeight / 2}px)`,
    };
  }

  onFullscrenStateChange(state) {
    this.isFullScreenActive = state.fullscreenActive;
  }

  getAssetSize(asset: Asset) {
    const size = asset.size / 1000000;
    if (size >= 1) {
      return `${Math.round(size * 100) / 100} mb`;
    }
    return `${Math.round(size * 1000)} kb`;
  }
}
