
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { InfoBadgeData } from '~/store/cloud/getters';

@Component({})
export default class InfoBadge extends Vue {
  @Prop()
  public infoBadgeData: InfoBadgeData;

  @Prop(Boolean)
  public showSize: boolean;

  @Prop(Boolean)
  public highlighted: boolean;
}
