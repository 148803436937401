
import { Component, Vue } from 'nuxt-property-decorator';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import MutantContextMenuDivider from '~/components/ui/MutantContextMenuDivider.vue';
import MutantContextMenuHeader from '~/components/ui/MutantContextMenuHeader.vue';
import MutantContextMenuItem from '~/components/ui/MutantContextMenuItem.vue';
import MutantTagButton from '~/components/ui/MutantTagButton.vue';
import { getDownloadSize, getLargestAsset } from '~/models/Asset';
import Item from '~/models/item/Item';
import { UnitSize } from '~/models/UnitSize';
import { ViewType } from '~/models/views/ViewType';
import { ContextMenuType } from '~/store/context/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { MutantWindowPayload } from '~/models/MutantWindowPayload';

@Component({
  components: {
    MutantTagButton,
    MutantContextMenuHeader,
    MutantContextMenuItem,
    MutantContextMenu,
    MutantContextMenuDivider,
  },
})
export default class MutantItemContextMenu extends Vue {
  public ViewType = ViewType;

  public get startPosition() {
    const { pageX, pageY } = this.$store.getters['context/menu'](ContextMenuType.ITEM)?.event || { pageX: 0, pageY: 0 };
    return {
      left: pageX + 5,
      top: pageY + 5,
    };
  }

  public get isAllowedToModify() {
    return this.isOwner;
  }

  public get isGuest() {
    return this.$store.getters['user/isGuest'];
  }

  public get isOwner() {
    return this.$store.getters['user/isOwnerOfCurrentFolder'](this.viewId);
  }

  public get viewId(): ViewIdentifier {
    return this.$store.getters['context/menu'](ContextMenuType.ITEM)?.data.viewId;
  }

  public get itemWithPosition(): ItemWithPosition {
    return this.$store.getters['context/menu'](ContextMenuType.ITEM)?.data?.item;
  }

  public get item(): Item {
    return this.$store.getters['context/menu'](ContextMenuType.ITEM)?.data.item?.item;
  }

  public get itemDownloadSize(): UnitSize {
    const asset = getLargestAsset(this.item?.assets);
    return asset ? getDownloadSize([asset]) : { size: 0, unit: 'MB' };
  }

  public get isMultiFolderSelection(): boolean {
    return this.selectionItemCount !== this.folderSelectionItems.length;
  }

  // TODO: use main view instead of legacy current folder
  public get currentFolder() {
    return this.$store.getters['cloud/currentFolder'](this.viewId);
  }

  public get currentSelectionItems(): ItemWithPosition[] {
    return this.$store.getters['selection/globalSelectionItems'];
  }

  public get hasCurrentObjectSelectedItems(): boolean {
    return this.folderSelectionItems.length > 0;
  }

  public get folderSelectionItems(): ItemWithPosition[] {
    return this.$store.getters['selection/globalSelectionItemsInView'](this.viewId);
  }

  public get selectionItemCount(): number {
    return this.$store.state.selection.globalSelectedItemsOrdered.length;
  }

  public get selectionDownloadSize(): UnitSize {
    return getDownloadSize(this.selectionAssets);
  }

  public get selectionAssets() {
    return this.$store.getters['selection/globalSelectionAssetsByHighestVersion'];
  }

  public get isHorizontalView(): boolean {
    return this.$store.getters['cloud/window'](this.viewId).viewOptions.activeViewType === ViewType.HORIZONTAL;
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.ITEM);
  }

  public chooseView(view: ViewType) {
    this.$store.dispatch('cloud/changeView', { windowId: ViewIdentifier.MAIN_VIEW, view });
    this.close();
  }

  public zoomIn() {
    this.$store.dispatch<ActionPayload<MutantWindowPayload>>({ type: 'cloud/zoomIn', payload: { windowId: ViewIdentifier.MAIN_VIEW } });
  }

  public zoomOut() {
    this.$store.dispatch<ActionPayload<MutantWindowPayload>>({ type: 'cloud/zoomOut', payload: { windowId: ViewIdentifier.MAIN_VIEW } });
  }

  public downloadItem() {
    const asset = getLargestAsset(this.item?.assets);
    this.$store.dispatch('file/downloadSingleFileAssets', [asset]);
    this.$store.dispatch('showNotificationOnLargeAssetDownload', [asset], { root: true });
    this.close();
  }

  public downloadSelection() {
    this.$store.dispatch('file/downloadAssets', {
      assets: this.selectionAssets,
      zipFolderName: this.$store.getters['selection/globalSelection'].name,
    });
    this.close();
  }

  public removeItem() {
    const mainView = this.$store.getters['cloud/currentMainView'];
    if (mainView.isFolderView) {
      this.$store.dispatch('folder/removeItems', {
        folderId: this.currentFolder.id,
        items: [this.itemWithPosition],
      });
    } else {
      this.$store.dispatch('selection/removeItemsFromSelection', {
        items: [this.itemWithPosition],
        selectionId: mainView.selectionId,
      });
    }
    this.close();
  }

  public removeSelection() {
    this.$store.dispatch('selection/deleteItemsFromFolder', this.currentFolder.id);
    this.close();
  }

  public moveItemToNewFolder() {
    this.moveItemsToNewFolder([this.itemWithPosition]);
  }

  public moveSelectionToNewFolder() {
    this.moveItemsToNewFolder(this.currentSelectionItems);
  }

  public moveFolderSelectionToNewFolder() {
    this.moveItemsToNewFolder(this.folderSelectionItems);
  }

  public copyGlobalSelectionToNewFolder() {
    this.copyItemsToNewFolder(this.currentSelectionItems);
  }

  public copySelectionItemsToNewFolder() {
    this.copyItemsToNewFolder(this.folderSelectionItems);
  }

  public copyItemToNewFolder() {
    this.copyItemsToNewFolder([this.itemWithPosition]);
  }

  public copyItemsToNewFolder(items: ItemWithPosition[]) {
    this.$store.dispatch('folder/copyItemsToNewFolder', {
      items: items.map((i) => i.item.id),
    });
  }

  public moveItemsToNewFolder(items: ItemWithPosition[]) {
    this.$store.dispatch('folder/saveItemsAsNewFolder', {
      items,
      windowId: this.viewId,
    });
  }
}
