
import { Component, Vue } from 'nuxt-property-decorator';
import _throttle from 'lodash.throttle';
import CloudMenu from '~/components/menu/left/CloudMenu.vue';
import RecentCloudActivityList from '~/components/menu/right/tabs/recent/RecentCloudActivityList.vue';
import DownloadReaderContextMenu from '~/components/dialogs/mutant-reader/DownloadReaderContextMenu.vue';
import LoginContextMenu from '~/components/dialogs/sign-up/LoginContextMenu.vue';
import FolderIndicatorContextMenu from '~/components/window/view/dialogs/FolderIndicatorContextMenu.vue';
import MutantFolderItemContextMenu from '~/components/menu/left/dialogs/MutantFolderItemContextMenu.vue';
import MutantItemContextMenu from '~/components/window/view/dialogs/MutantItemContextMenu.vue';
import HeaderNavigation from '~/components/layout/header/HeaderNavigation.vue';
import InteractiveFooter from '~/components/layout/footer/InteractiveFooter.vue';
import MagnifyView from '~/components/window/magnify/MagnifyView.vue';
import MutantMenu from '~/components/menu/left/MutantMenu.vue';
import ViewOptionsSideMenu from '~/components/menu/right/tabs/view/ViewOptionsSideMenu.vue';
import MutantSelectionContextMenu from '~/components/menu/left/dialogs/MutantSelectionContextMenu.vue';
import SharedLinkMenu from '~/components/share/SharedLinkMenu.vue';
import ShareOverlay from '~/components/share/ShareOverlay.vue';
import DetailView from '~/components/ui/DetailView.vue';
import MutantTabSectionTab from '~/components/ui/tab-section/MutantTabSectionTab.vue';
import AccountOptionsMenu from '~/components/layout/header/user-panel/AccountUpgradeMenu.vue';
import { ContextMenuType } from '~/store/context/state';
import ChangePasswordContextMenu from '~/components/dialogs/password-reset/ChangePasswordContextMenu.vue';
import AccountContextMenu from '~/components/dialogs/account/AccountContextMenu.vue';
import WindowArea from '~/components/window/WindowArea.vue';
import ActionFooter from '~/components/layout/footer/ActionFooter.vue';
import ValidationContextMenu from '~/components/dialogs/sign-up/ValidationContextMenu.vue';
import DownloadArea from '~/components/window/view/DownloadArea.vue';
import { NavigationInstructionDirection } from '~/models/views/NavigationInstruction';
import ItemList from '~/components/menu/right/tabs/items/ItemList.vue';
import NotificationBannerWrapper from '~/components/ui/NotificationBannerWrapper.vue';
import MainViewSideMobileActions from '~/components/window/view/MainViewSideMobileActions.vue';
import { StorageKey } from '~/models/storage/StorageKey';
import { TabMessage, TabMessageType } from '~/store/user/actions';
import ForgotPasswordContextMenu from '~/components/dialogs/forgot-password/ForgotPasswordContextMenu.vue';
import RequestOriginalsContextMenu from '~/components/dialogs/review/RequestOriginalsContextMenu.vue';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import SharedLinkReceiverContextMenu from '~/components/share/SharedLinkReceiverContextMenu.vue';
import MenuRight from '~/components/menu/right/MenuRight.vue';
import UploadControlMenu from '~/components/dialogs/upload/UploadControlMenu.vue';
import MatchAndUploadControlMenu from '~/components/dialogs/upload/MatchAndUploadControlMenu.vue';
import DownloadAllMenu from '~/components/dialogs/download/DownloadAllMenu.vue';
import MainViewHeader from '~/components/window/view/header/MainViewHeader.vue';
import { MutantContentView } from '~/models/views/MutantContentView';
import FeedbackContextMenu from '~/components/dialogs/feedback/FeedbackContextMenu.vue';
import ReloadAlert from '~/components/dialogs/ReloadAlert.vue';
import ContactInfoMenu from '~/components/dialogs/contact-terms-privacy/ContactInfoMenu.vue';
import DesktopInstructionMenu from '~/components/dialogs/desktop-instruction/DesktopInstructionMenu.vue';
import ExitReviewButton from '~/components/layout/header/ExitReviewButton.vue';
import ReviewViewHeader from '~/components/layout/header/ReviewViewHeader.vue';
import MainViewSideMobileItemCount from '~/components/window/view/MainViewSideMobileItemCount.vue';
import MutantProfileImage from '~/components/ui/MutantProfileImage.vue';
import ContactsMenuMobile from '~/components/menu/ContactsMenuMobile.vue';

@Component({
  components: {
    ContactsMenuMobile,
    MutantProfileImage,
    MainViewSideMobileItemCount,
    ReviewViewHeader,
    ExitReviewButton,
    DesktopInstructionMenu,
    ContactInfoMenu,
    MainViewHeader,
    DownloadAllMenu,
    MenuRight,
    ForgotPasswordContextMenu,
    MainViewSideMobileActions,
    NotificationBannerWrapper,
    ItemList,
    ValidationContextMenu,
    ActionFooter,
    AccountContextMenu,
    ChangePasswordContextMenu,
    AccountOptionsMenu,
    ShareOverlay,
    MutantSelectionContextMenu,
    DownloadReaderContextMenu,
    LoginContextMenu,
    ViewOptionsSideMenu,
    RecentCloudActivityList,
    MutantTabSectionTab,
    InteractiveFooter,
    MutantItemContextMenu,
    HeaderNavigation,
    FolderIndicatorContextMenu,
    MutantFolderItemContextMenu,
    RequestOriginalsContextMenu,
    SharedLinkMenu,
    DetailView,
    MagnifyView,
    MutantMenu,
    CloudMenu,
    WindowArea,
    DownloadArea,
    SharedLinkReceiverContextMenu,
    UploadControlMenu,
    MatchAndUploadControlMenu,
    FeedbackContextMenu,
    ReloadAlert,
  },
  errorCaptured: () => {
    // TODO: handle error, display a popup dialog
    return true;
  },
})
export default class MutantLayout extends Vue {
  public initialTransition = true;
  public contextMenu = ContextMenuType;
  private windowResizeTimeoutHandler = null;
  private navigateViewThrottled = null;
  private activeElement;
  private mobileDetectedBefore = false;

  constructor() {
    super();
    window.addEventListener('resize', this.resizeWindow, false);
    window.addEventListener('keydown', this.handleKeyDown, false);
    window.addEventListener('dragenter', this.dragCanceling, false);
    window.addEventListener('dragover', this.dragCanceling, false);
    window.addEventListener('storage', this.reactToLocalStorageChange, false);
    this.navigateViewThrottled = _throttle(this.navigateView, 50, { trailing: true, leading: true });
  }

  mounted() {
    setTimeout(() => {
      this.setActionFooterCSSSizes();
    }, 1500);
  }

  private setActionFooterCSSSizes() {
    this.initialTransition = false;
    document.getElementById('layout-grid').style.setProperty('--upload-expand-button', (document.getElementById('action-footer-upload-button')?.getBoundingClientRect()?.x ?? 0) + 'px');
    document.getElementById('layout-grid').style.setProperty('--match-expand-button', (document.getElementById('action-footer-match-button')?.getBoundingClientRect()?.x ?? 0) + 'px');
    document.getElementById('layout-grid').style.setProperty('--share-expand-button', (document.getElementById('action-footer-share-button')?.getBoundingClientRect()?.x ?? 0) + 'px');
    document.getElementById('layout-grid').style.setProperty('--download-expand-button', (document.getElementById('action-footer-download-button')?.getBoundingClientRect()?.x ?? 0) + 'px');
  }

  created() {
    this.$store.dispatch('initializeApplication');
  }

  dragCanceling(e: any) {
    e.preventDefault();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWindow);
    window.removeEventListener('dragenter', this.dragCanceling);
    window.removeEventListener('dragover', this.dragCanceling);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('storage', this.reactToLocalStorageChange);
  }

  // We detect if the user logged in or switched to a different account on another tab,
  // if this is the case we refresh this tab to restore state
  private reactToLocalStorageChange() {
    const localStorageData = window.localStorage.getItem(StorageKey.TAB_MESSAGE);
    const tabMessage: TabMessage = localStorageData && JSON.parse(localStorageData);
    if (
      tabMessage != null
        && this.$store.state.originId
        && tabMessage.originId !== this.$store.state.originId
        && [TabMessageType.LOGIN, TabMessageType.LOGOUT].includes(tabMessage.type)
    ) {
      this.$log.info(`Handle tab message ${tabMessage.id} of type ${tabMessage.type} from origin ${tabMessage.originId}`);
      // Remove tab message so the same one is not handled again
      setTimeout(() => localStorage.removeItem(StorageKey.TAB_MESSAGE));
      // We add a small random timeout here to not stress the api too much should the user have a lot of tabs open
      setTimeout(() => location.reload(), Math.floor(Math.random() * 3_000));
    }
  }

  public get hasItemsSelected(): boolean {
    return this.$store.getters['selection/globalSelectionHasItems'];
  }

  public get isMobileDevice(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isLayoutInitialized(): boolean {
    return this.$store.state.layoutInitialized;
  }

  public get mutantView(): MutantContentView {
    return this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW);
  }

  private resizeWindow() {
    this.$store.commit('setMobileSpecificValues');
    if (this.isMobileDevice) {
      this.mobileDetectedBefore = true;
      // Try workaround for chrome on iOS, does not change sizes if going from landscape to portrait
      setTimeout(() => this.$store.dispatch('initializeMobileSizes', {}, { root: true }), 10);
    } else if (this.mobileDetectedBefore) {
      this.mobileDetectedBefore = false;
      this.$store.dispatch('resetMobileSizes', {}, { root: true });
    }
    if (!this.windowResizeTimeoutHandler) {
      this.$store.commit('setLayoutDragging', true);
      this.$store.commit('setAdjustWindowsToDrag', true);
    } else {
      clearTimeout(this.windowResizeTimeoutHandler);
    }
    this.windowResizeTimeoutHandler = setTimeout(() => {
      clearTimeout(this.windowResizeTimeoutHandler);
      this.windowResizeTimeoutHandler = null;
      this.$store.commit('setLayoutDragging', false);
      this.$store.commit('setAdjustWindowsToDrag', false);
      this.$store.commit('setWindowSize');
      this.setActionFooterCSSSizes();
    }, 100);
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get isLandscape() {
    return this.$store.getters.isLandscape;
  }

  public closeMenu(type: ContextMenuType) {
    this.$store.dispatch('context/closeMenu', type);
  }

  public closeFolderIndicatorContextMenu() {
    this.closeMenu(ContextMenuType.VIEW);
  }

  public closeFolderItemContextMenu() {
    this.closeMenu(ContextMenuType.FOLDER);
  }

  public closeSelectionContextMenu() {
    this.closeMenu(ContextMenuType.SELECTION);
  }

  public closeItemContextMenu() {
    this.closeMenu(ContextMenuType.ITEM);
  }

  public closeValidationContextMenu() {
    this.closeMenu(ContextMenuType.VALIDATION);
  }

  public closeSharedLinkMenu() {
    this.closeMenu(ContextMenuType.SHARED_LINK);
  }

  public closeRequestOriginalsContextMenu() {
    this.closeMenu(ContextMenuType.REQUEST_ORIGINALS);
  }

  public closeFeedbackContextMenu() {
    this.closeMenu(ContextMenuType.SEND_FEEDBACK);
  }

  public get isHomepageView(): boolean {
    return this.$routeAwareness.isHomepageView;
  }

  public get activeView(): ViewIdentifier {
    return this.$store.state.cloud.activeWindowId;
  }

  private navigateView(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.$store.dispatch('cloud/navigateView', { direction: NavigationInstructionDirection.LEFT, view: this.activeView });
    }
    if (event.key === 'ArrowRight') {
      this.$store.dispatch('cloud/navigateView', { direction: NavigationInstructionDirection.RIGHT, view: this.activeView });
    }
  }

  public handleKeyDown(e: KeyboardEvent) {
    if ((e.target as HTMLElement)?.tagName === 'BODY') {
      if ((e.key === 'Esc' || e.key === 'Escape')) {
        e.stopImmediatePropagation();
        e.preventDefault();
        this.$store.dispatch('selection/dismissGlobalSelection');
      }
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.stopImmediatePropagation();
        e.preventDefault();
        this.navigateViewThrottled(e);
      }
      if (e.key === ' ') {
        this.$store.dispatch('detailView/open', {
          currentItem: this.$store.state.cloud?.highlightInfo?.item,
          linkedViewId: ViewIdentifier.MAIN_VIEW,
        });
      }
    }
    if (e.altKey && e.key === 'µ') {
      e.stopImmediatePropagation();
      this.$store.dispatch('magnify/toggle');
    }
  }
}
