
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import PlayButtonIsolated from '~/components/homepage/feature-highlights/PlayButtonIsolated.vue';
import { WebConfig } from '~/Config';

@Component({
  components: { PlayButtonIsolated },
})
export default class MutantVideo extends Vue {
  @Prop()
    lowResSrc?: string;

  @Prop()
    highResSrc?: string;

  @Prop()
    poster?: string;

  public isVideoPlaying = false;
  public id;

  constructor() {
    super();
    this.id = `video-element-${uuid()}`;
  }

  public get videoSrc() {
    const videoSrc = this.$store.getters.isMobile && this.lowResSrc != null ? this.lowResSrc : this.highResSrc;
    return WebConfig.isProductionEnvironment() ? `${WebConfig.URL}${videoSrc}` : videoSrc;
  }

  public playVideo() {
    const video = document.getElementById(this.id) as HTMLVideoElement;
    video.play();
  }
}
