
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({})
export default class InfoBadge extends Vue {
  @Prop(Boolean)
    grey: boolean;

  @Prop(Boolean)
    red: boolean;

  @Prop(Boolean)
    green: boolean;

  public isHoverDetails = false;

  public hasSlot(name) {
    return !!this.$slots[name];
  }

  public showDetails() {
    if (this.hasSlot('details')) {
      this.isHoverDetails = true;
    }
  }

  public hideDetails() {
    if (this.hasSlot('details')) {
      this.isHoverDetails = false;
    }
  }
}
