
import { Component, Vue } from 'nuxt-property-decorator';
import Column from '../../ui/Column.vue';
import MutantContextMenu from '../../ui/MutantContextMenu.vue';
import MutantContextMenuDivider from '../../ui/MutantContextMenuDivider.vue';
import InfoSection from '../sections/InfoSection.vue';
import ContextMenuHeader from '~/components/dialogs/ui/ContextMenuHeader.vue';
import LoginSection from '~/components/dialogs/sign-up/sections/LoginSection.vue';
import SignUpSection from '~/components/dialogs/sign-up/sections/SignUpSection.vue';
import ContextMenuHeaderFlashy from '~/components/dialogs/ui/ContextMenuHeaderFlashy.vue';
import MutantHighlightBox from '~/components/ui/MutantHighlightBox.vue';
import { ContextMenuType } from '~/store/context/state';

@Component({
  components: {
    SignUpSection,
    MutantHighlightBox,
    ContextMenuHeaderFlashy,
    LoginSection,
    InfoSection,
    Column,
    MutantContextMenuDivider,
    ContextMenuHeader,
    MutantContextMenu,
  },
})
export default class LoginContextMenu extends Vue {
  public get showLogin(): boolean {
    return this.$store.getters['context/menu'](ContextMenuType.LOGIN)?.data?.showLogin;
  }

  public get isSignupForDesktop(): boolean {
    return this.$store.getters['context/menu'](ContextMenuType.LOGIN)?.data?.isSignupForDesktop;
  }

  switchToSignUp() {
    this.$store.dispatch('context/updateMenuData', {
      type: ContextMenuType.LOGIN,
      data: {
        showLogin: false,
      },
    });
  }

  switchToLogin() {
    this.$store.dispatch('context/updateMenuData', {
      type: ContextMenuType.LOGIN,
      data: {
        showLogin: true,
      },
    });
  }

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.LOGIN);
  }
}
