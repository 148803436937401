
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ContextMenuButton from '~/components/ui/buttons/ContextMenuButton.vue';
import Row from '~/components/ui/Row.vue';
import Column from '~/components/ui/Column.vue';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import { UnitSize } from '~/models/UnitSize';
import { Asset, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';

enum DownloadOption {
  ZIP,
  SINGLE
}

@Component({
  components: { Column, Row, ContextMenuButton },
})
export default class ExpandDownloadButtons extends Vue {
  @Prop()
  public cloudObject: CloudObject<Folder | Selection>;

  public downloadOptions = DownloadOption;

  public selectedOption: DownloadOption = DownloadOption.ZIP;

  public get rawFileSize(): UnitSize {
    return this.$store.getters['cloud/assetSizeForVersion'](this.cloudObject, RAW_ASSET_VERSION);
  }

  public get originalFileSize(): UnitSize {
    return this.$store.getters['cloud/assetSizeForVersion'](this.cloudObject, ORIGINAL_ASSET_VERSION);
  }

  public selectOption(downloadOption: DownloadOption) {
    this.selectedOption = downloadOption;
  }

  public downloadRaw() {
    this.download(this.$store.getters['cloud/assetsForCloudObjectAndVersion'](this.cloudObject, RAW_ASSET_VERSION));
  }

  public downloadJpgs() {
    this.download(this.$store.getters['cloud/largestAssetsForCloudObject'](this.cloudObject));
  }

  public download(assets: Asset[]) {
    if (this.selectedOption === DownloadOption.ZIP) {
      this.$store.dispatch('file/downloadAssets', {
        assets,
        zipFolderName: this.cloudObject?.object?.name,
      });
    } else {
      this.$store.dispatch('file/downloadSingleFileAssets', assets);
    }
  }
}
