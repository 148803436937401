
import { Component, Vue } from 'nuxt-property-decorator';
import { MutantContentView } from '~/models/views/MutantContentView';
import MainPane from '~/components/window/MainPane.vue';
import MainView from '~/components/window/view/MainView.vue';
import { ViewType } from '~/models/views/ViewType';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import MainViewPipelineControl from '~/components/window/view/footer/MainViewPipelineControl.vue';

@Component({
  components: {
    MainViewPipelineControl,
    MainPane,
    MainView,
  },
})
export default class MainWindow extends Vue {
  public paneSize: { width: number; height: number;} = null;

  public get mutantView(): MutantContentView {
    return this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW);
  }

  public get height() {
    if (this.mutantView.viewOptions.activeViewType === ViewType.HORIZONTAL) {
      const footerOffset = parseInt(getComputedStyle(document.body).getPropertyValue('--footer-menu-height').split('px')[0], 10);
      return this.paneSize.height - footerOffset;
    }
    return this.paneSize.height;
  }

  public get paneWasRendered() {
    return this.paneSize !== null;
  }

  public adjustSize(size: { width: number, height: number }) {
    this.paneSize = size;
  }
}
