
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class ContextMenuButton extends Vue {
  @Prop(Boolean)
    isActive: boolean;

  @Prop(Boolean)
    isDisabled: boolean;

  @Prop()
    disabledTooltip: string;

  public get tooltip() {
    return this.isDisabled ? { placement: 'top', content: 'YOLO' } : null;
  }
}
