
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import ActionButtonModel from '~/models/ActionButtonModel';
import ActionButton from '~/components/layout/footer/ActionButton.vue';

@Component({
  components: { ActionButton },
})
export default class SplitButton extends Vue {
  public hover = false;
  public idPostfix = uuid();
  public fileInputId = 'file-input-' + this.idPostfix;
  public folderInputId = 'folder-input-' + this.idPostfix;

  @Prop()
  public hoverText: string;

  @Prop()
  public deactivateButtons: boolean;

  public placeholderButton: ActionButtonModel = {
    name: () => {
      return this.hoverText;
    },
    action: () => { },
    isHighlighted: false,
    key: 'split-button-placeholder-' + this.idPostfix,
  };

  public emitChange(event: any) {
    this.$emit('change-event', event);
  }

  public folderButton: ActionButtonModel = {
    name: () => {
      return 'FOLDER';
    },
    action: () => {
      document.getElementById(this.folderInputId).click();
    },
    isHighlighted: false,
    key: 'split-button-folder-button-' + this.idPostfix,
  };

  public fileButton: ActionButtonModel = {
    name: () => 'FILE',
    action: () => {
      document.getElementById(this.fileInputId).click();
    },
    isHighlighted: false,
    key: 'split-button-file-button-' + this.idPostfix,
  };

  public mouseenter() {
    if (!this.deactivateButtons) {
      this.hover = true;
    }
  }

  public mouseleave() {
    this.hover = false;
  }
}
