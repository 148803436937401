
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ContextMenuType } from '~/store/context/state';
import MutantCheckMark from '~/components/ui/MutantCheckMark.vue';
import { UploadProcessStatus } from '~/store/file/state';

export type ButtonType = 'upload' | 'match_and_upload';

interface ButtonConfig {
  uploadButtonTitle: string;
  uploadButtonFolderInputId: string
  uploadButtonFileInputId: string;
  contextMenuType: ContextMenuType
}

@Component({
  components: { MutantCheckMark },
})
export default class UploadButton extends Vue {
  public buttonConfigMap: Map<ButtonType, ButtonConfig> = new Map([
    ['upload', {
      uploadButtonTitle: 'UPLOAD',
      uploadButtonFolderInputId: 'upload-folder-input-button',
      uploadButtonFileInputId: 'upload-file-input-button',
      contextMenuType: ContextMenuType.UPLOAD_CONTROL,
    }],
    ['match_and_upload', {
      uploadButtonTitle: 'MATCH',
      uploadButtonFolderInputId: 'match-and-upload-folder-input-button',
      uploadButtonFileInputId: 'match-and-upload-file-input-button',
      contextMenuType: ContextMenuType.MATCH_AND_UPLOAD_CONTROL,
    }],
  ]);

  @Prop()
  public buttonType: ButtonType;

  @Prop()
  public disabled: boolean;

  public isMenuOpen = false;

  public get showUploadProgress() {
    const isUploading = this.$store.state.file?.uploadProcess?.status === UploadProcessStatus.UPLOADING;
    const isMatchingProcessActive = this.$store.state.file?.matchingProcess != null;
    return this.buttonType === 'upload' ? !isMatchingProcessActive && isUploading : isMatchingProcessActive && isUploading;
  }

  public get conf(): ButtonConfig {
    return this.buttonConfigMap.get(this.buttonType);
  }

  public get menuOpen(): boolean {
    return (this.isMenuOpen || this.remoteControlOpen) && !this.disabled;
  }

  public get menuClosed(): boolean {
    return !this.isMenuOpen && !this.remoteControlOpen && !this.disabled;
  }

  public get progressPercentage() {
    return this.progressSteps.length > 0 ? this.progressSteps.reduce((sum, step) => sum + step.percentage, 0) / this.progressSteps.length : 0;
  }

  public get progressSteps() {
    return this.$store.getters.pipelineProgress(this.viewObjectId) || [];
  }

  public get viewObjectId() {
    return this.$store.getters['cloud/view'](ViewIdentifier.MAIN_VIEW).objectIds[0]?.toUuid();
  }

  public get isLandingPageActive() {
    return this.$routeAwareness.isHomepageView || this.$routeAwareness.isLandingPage;
  }

  public toggleMenu(event: MouseEvent) {
    event.stopImmediatePropagation();
    if (!this.disabled) {
      if (!this.remoteControlOpen) {
        this.$store.dispatch('context/openMenu', { type: this.conf.contextMenuType });
      } else if (this.remoteControlOpen) {
        this.$store.dispatch('context/closeMenu', this.conf.contextMenuType);
      } else {
        this.isMenuOpen = !this.isMenuOpen;
      }
    }
  }

  public closeMenu() {
    this.isMenuOpen = false;
  }

  public get remoteControlOpen() {
    return this.$store.getters['context/isOpen'](this.conf.contextMenuType);
  }

  @Watch('remoteControlOpen', { immediate: true })
  public onRemoteControlOpen() {
    this.isMenuOpen = false;
  }
}
