
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import MutantNewContentButton from '~/components/ui/MutantNewContentButton.vue';
import { Asset, AssetWithFolderTag, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';
import { DownloadOption, FileOption, SelectedItemsOption } from '~/models/DownloadOptions';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import ThumbnailSlider from '~/components/ui/ThumbnailSlider.vue';
import ContextMenuButton from '~/components/ui/buttons/ContextMenuButton.vue';
import Column from '~/components/ui/Column.vue';
import ItemSize from '~/components/ui/ItemSize.vue';
import Row from '~/components/ui/Row.vue';
import { UnitSize } from '~/models/UnitSize';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { AssetList } from '~/models/asset/AssetList';
import MutantContextMenu from '~/components/ui/MutantContextMenu.vue';
import { ContextMenuType } from '~/store/context/state';
import DownloadAllContextMenu from '~/components/dialogs/download/DownloadAllContextMenu.vue';
import CancelButton from '~/components/dialogs/upload/CancelButton.vue';
import CheckboxButton from '~/components/ui/buttons/CheckboxButton.vue';
import CheckboxButtonBadge from '~/components/ui/buttons/CheckboxButtonBadge.vue';
import InfoBadge from '~/components/dialogs/download/InfoBadge.vue';
import { AssetListBuilder } from '~/models/asset/AssetListBuilder';
import { byIncludesVersions } from '~/models/asset/filters/byIncludesVersions';
import InfoButton from '~/components/ui/buttons/InfoButton.vue';
import CheckmarkBox from '~/components/ui/buttons/CheckmarkBox.vue';
import SwitchButton from '~/components/ui/buttons/SwitchButton.vue';
import SwitchButtonOption from '~/components/ui/buttons/SwitchButtonOption.vue';
import FilterButton from '~/components/window/view/header/FilterButton.vue';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { CloudObject } from '~/models/cloud/CloudObject';
import DownloadInfo from '~/components/dialogs/download/DownloadInfo.vue';
import { SwipeEvent } from '~/plugins/swipe';

@Component({
  components: {
    DownloadInfo,
    FilterButton,
    SwitchButtonOption,
    SwitchButton,
    CheckmarkBox,
    InfoButton,
    InfoBadge,
    CheckboxButtonBadge,
    CheckboxButton,
    CancelButton,
    DownloadAllContextMenu,
    MutantContextMenu,
    Row,
    ItemSize,
    Column,
    ContextMenuButton,
    ThumbnailSlider,
    MutantNewContentButton,
  },
})
export default class DownloadAllMenu extends Vue {
  public width = 380;
  public isExpanded = false;
  public isHoverCheckbox = false;
  public downloadOptions = DownloadOption;
  public selectedItemsOptions = SelectedItemsOption;
  public fileOption = FileOption;
  public selectedThumbnailWidth: number = 0;
  public selectedDownloadOption: DownloadOption = DownloadOption.ZIP;
  public selectedItemsOption: SelectedItemsOption = SelectedItemsOption.ALL;
  public selectedFileOptions: FileOption[] = [];
  public selectedKeepFolderStructure = true;
  public wasMenuClosedAfterGlow = false;
  public viewId = ViewIdentifier.MAIN_VIEW;
  public controlId = 'download-dialog';
  private downloadControlElement: HTMLElement;

  public close() {
    this.$store.dispatch('context/closeMenu', ContextMenuType.DOWNLOAD_ALL);
    this.downloadControlElement.style.transform = `translateY(${this.downloadControlElement.offsetHeight}px)`;
  }

  mounted() {
    this.initializeFileOptions();
    this.downloadControlElement = document.getElementById(this.controlId);

    this.selectedItemsOption = this.usefulSelectedItemsOptionDefault;
    this.selectedThumbnailWidth = this.allResolutions[this.allResolutions.length - 1];
  }

  private initializeFileOptions() {
    if (this.assetListByFileOptions([FileOption.ORIGINALS], this.selectedItemsOption).assets.length > 0) {
      this.selectedFileOptions.push(FileOption.ORIGINALS);
    } else {
      this.selectedFileOptions.push(FileOption.THUMBNAILS);
    }
  }

  public get displaySelectedThumbnailWidth(): string {
    return `<= ${this.selectedThumbnailWidth} px`;
  }

  public get allResolutions(): number[] {
    const TOO_SIMILAR_THRESHOLD = 100;
    return Array.from<number>(
      this.$store.getters['cloud/viewItemsBySelectedItemsOption'](ViewIdentifier.MAIN_VIEW, this.selectedFileOptions)
        .flatMap((itemWithPosition: ItemWithPosition) => itemWithPosition?.item?.assets)
        .filter((asset: Asset) => asset.version !== ORIGINAL_ASSET_VERSION)
        .map((asset: Asset) => asset.width)
        .sort((a: number, b: number) => a < b ? -1 : 1)
        .reduce((prev: Set<number>, curr: number) => prev.add(curr), new Set<number>())
    ).reduce((previousValue: { value: number, count: number }[], currentValue: number, currentIndex: number) => {
      if (currentIndex !== 0) {
        const previousValueElement = previousValue[previousValue.length - 1];
        if (currentValue - previousValueElement?.value > TOO_SIMILAR_THRESHOLD || previousValueElement.count >= 2) {
          previousValue.push({
            value: currentValue,
            count: 1,
          });
        } else {
          previousValue[previousValue.length - 1] = {
            value: currentValue,
            count: ++previousValueElement.count,
          };
        }
      } else {
        previousValue.push({
          value: currentValue,
          count: 1,
        });
      }
      return previousValue;
    }, [])
      .map((value: { value: number, count: number }) => value.value);
  }

  public get startPosition() {
    const defaultOffsetFromFooter = 750;
    const defaultOffsetFromSide = this.width * 2;
    const safeDefaultLeft = 5;
    const safeDefaultTop = 20;
    let left = window.innerWidth - defaultOffsetFromSide;
    let top = window.innerHeight - defaultOffsetFromFooter;
    // if the defaults are too near the outside of the screen, move them to safe defaults
    if (left < safeDefaultLeft) {
      left = safeDefaultLeft;
    }
    if (top < safeDefaultTop) {
      top = safeDefaultTop;
    }
    return { top, left };
  }

  onSwipe(swipeEvent: SwipeEvent) {
    const {
      type,
      direction,
      distanceY,
    } = swipeEvent;
    let translateY = 0;
    if (type === 'move' && direction === 'down') {
      translateY = distanceY;
      this.downloadControlElement.style.transform = `translateY(${translateY}px)`;
    } else if (type === 'finish') {
      if (distanceY >= this.downloadControlElement.offsetHeight / 2 && direction === 'down') {
        this.close();
      } else {
        this.downloadControlElement.style.transform = 'translateY(0)';
      }
    }
  }

  public get isItemSelected(): boolean {
    return this.$store.getters['selection/globalSelectionHasItems'];
  }

  public get isViewFiltered(): boolean {
    return this.$store.getters['cloud/filterHasEntries'];
  }

  public chooseThumbnailWidth(value: number) {
    this.selectedThumbnailWidth = value;
  }

  public get rawFileSize(): UnitSize {
    return this.assetListByFileOptions([FileOption.RAWS], this.selectedItemsOption).asUnitSize;
  }

  public get rawAssetCountInSelectedAssets(): number {
    return new AssetListBuilder()
      .withAssets(this.assetListBySelectedFileOptions.assets)
      .withFilter(byIncludesVersions([RAW_ASSET_VERSION]))
      .build()
      .assets
      .length;
  }

  public get thumbnailAssets(): AssetList<AssetWithFolderTag> {
    return this.assetListByFileOptions([FileOption.THUMBNAILS], this.selectedItemsOption, this.selectedThumbnailWidth);
  }

  public get thumbnailSize(): UnitSize {
    return this.thumbnailAssets.asUnitSize;
  }

  public get originalAssets(): AssetList<AssetWithFolderTag> {
    return this.assetListByFileOptions([FileOption.ORIGINALS], this.selectedItemsOption);
  }

  public get originalsFileSize(): UnitSize {
    return this.originalAssets.asUnitSize;
  }

  public get rawAssets(): AssetList<AssetWithFolderTag> {
    return this.assetListByFileOptions([FileOption.RAWS], this.selectedItemsOption);
  }

  public selectOption(downloadOption: DownloadOption) {
    this.selectedDownloadOption = downloadOption;
  }

  public selectItemsOption(selectedItemsOption: SelectedItemsOption) {
    if (selectedItemsOption === SelectedItemsOption.ALL
      || (selectedItemsOption === SelectedItemsOption.FILTERED && this.isViewFiltered)
      || (selectedItemsOption === SelectedItemsOption.SELECTED && this.isItemSelected)) {
      this.selectedItemsOption = selectedItemsOption;
    }
  }

  public hasOption(option: FileOption): boolean {
    return this.selectedFileOptions.includes(option);
  }

  public selectFileOption(option: FileOption) {
    if (this.hasOption(option)) {
      this.selectedFileOptions = this.selectedFileOptions.filter(s => s !== option);
    } else if (this.fileOptionIsSelectable(option)) {
      this.selectedFileOptions.push(option);
    }
  }

  public toggleKeepFolderStructure() {
    this.selectedKeepFolderStructure = !this.selectedKeepFolderStructure;
  }

  private fileOptionIsSelectable(option: FileOption) {
    return option === FileOption.THUMBNAILS ? this.thumbnailSize?.size > 0 : true;
  }

  public get itemsInObject() {
    return this.$store.getters['cloud/viewItemsByDownloadOptions']({
      windowId: ViewIdentifier.MAIN_VIEW,
      fileOptions: this.selectedFileOptions,
      selectedItemsOption: this.selectedItemsOption,
      thumbnailWidth: this.selectedThumbnailWidth,
    }).items.length;
  }

  public assetListByFileOptions(fileOptions: FileOption[], selectedItemsOption: SelectedItemsOption, thumbnailWidth?: number): AssetList<AssetWithFolderTag> {
    return this.$store.getters['cloud/viewAssetsByDownloadOptions']({ windowId: ViewIdentifier.MAIN_VIEW, fileOptions, selectedItemsOption, thumbnailWidth });
  }

  public get assetListBySelectedFileOptions(): AssetList<AssetWithFolderTag> {
    return this.assetListByFileOptions(this.selectedFileOptions, this.selectedItemsOption, this.selectedThumbnailWidth);
  }

  public get currentAssetSize(): UnitSize {
    return this.assetListBySelectedFileOptions.asRoundedUnitSize;
  }

  public get cloudObject(): CloudObject<Folder | Selection> {
    return this.$store.getters['cloud/currentCloudObject'];
  }

  public get disableDownloadButton() {
    return this.itemsInObject <= 0;
  }

  downloadAll() {
    if (!this.disableDownloadButton) {
      this.download(this.relevantAssets);
      this.wasMenuClosedAfterGlow = true;
      this.selectedFileOptions = [];
      this.close();
    }
  }

  private get usefulSelectedItemsOptionDefault(): SelectedItemsOption {
    if (this.isItemSelected) {
      return SelectedItemsOption.SELECTED;
    } else if (this.isViewFiltered) {
      return SelectedItemsOption.FILTERED;
    }
    return SelectedItemsOption.ALL;
  }

  public get considerKeepFolderStructure(): boolean {
    if (this.selectedDownloadOption !== DownloadOption.ZIP) {
      return false;
    }
    const relevantAssets = this.relevantAssets;
    return relevantAssets.length === 0
      ? this.$store.getters['cloud/activeViewHasItemsWithFolderTags']
      : relevantAssets.some(a => a.folderTagId !== null);
  }

  public get relevantAssets(): AssetWithFolderTag[] {
    return this.assetListBySelectedFileOptions.assets;
  }

  public download(assets: AssetWithFolderTag[]) {
    if (this.selectedDownloadOption === DownloadOption.ZIP) {
      this.$store.dispatch('file/downloadAssets', {
        assets,
        zipFolderName: this.cloudObject?.object?.name,
        keepFolderStructure: this.considerKeepFolderStructure ? this.selectedKeepFolderStructure : false,
      });
    } else {
      this.$store.dispatch('file/downloadSingleFileAssets', assets);
    }
  }

  @Watch('isViewFiltered')
  watchIsViewFiltered() {
    this.selectedItemsOption = this.usefulSelectedItemsOptionDefault;
    if (!this.isExpanded) {
      this.initializeFileOptions();
    }
  }

  @Watch('isItemSelected')
  watchIsItemSelected(value) {
    if (value) {
      this.wasMenuClosedAfterGlow = false;
    }
    this.selectedItemsOption = this.usefulSelectedItemsOptionDefault;
    if (!this.isExpanded) {
      this.initializeFileOptions();
    }
  }

  @Watch('allResolutions')
  watchAllResolutions(value: number[]) {
    if (value.length > 0) {
      const currentWidth = this.selectedThumbnailWidth;
      // Takes the previous value and find the nearest new value
      this.selectedThumbnailWidth = value.reduce((prev, curr) => Math.abs(curr - currentWidth) > Math.abs(prev - currentWidth) ? prev : curr, value[0]);
    }
  }
}
