
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { MutantContentView } from '~/models/views/MutantContentView';
import ProgressCircle from '~/components/ui/progress/ProgressCircle.vue';
import { FileProcessingPipeline } from '~/models/pipeline/FileProcessingPipeline';
import MutantCircleButton from '~/components/ui/MutantCircleButton.vue';
import { ViewProgress } from '~/store/state';
import { UploadProcessStatus } from '~/store/file/state';

@Component({
  components: {
    ProgressCircle,
    MutantCircleButton,
  },
})
export default class MainViewPipelineControl extends Vue {
  @Prop()
    view: MutantContentView;

  public progressLabel = '';
  private resetProgressLabelTimeoutHandler = null;

  public activePipeline(): FileProcessingPipeline {
    return this.$pipelineManager.getPipeline(this.viewObjectId);
  }

  public get viewObjectId(): string {
    return this.view.objectIds[0]?.toUuid();
  }

  public get viewProgress(): ViewProgress {
    return this.$store.state.viewProgress[this.viewObjectId];
  }

  public get viewHasProgressIndicator(): boolean {
    const uploadProcessStatus = this.$store.state.file.uploadProcess?.status;
    const isIndexing = uploadProcessStatus === UploadProcessStatus.INDEXING;
    return (uploadProcessStatus == null || isIndexing) && !!this.$store.getters.hasPipelineProgress(this.viewObjectId) && !this.isUploadFinished;
  }

  public get isUploadFinished(): boolean {
    return this.viewProgress.progressSteps[this.viewProgress.progressSteps.length - 1].percentage === 100;
  }

  public get tooltip(): string {
    return `continue ${this.progressLabel?.split('<br>').join(' ')}`;
  }

  private resetProgressLabel() {
    this.resetProgressLabelTimeoutHandler = setTimeout(() => {
      this.progressLabel = '';
    }, 1000);
  }

  private setLabel() {
    const label = this.activePipeline().activeStep?.infoLabel;
    if (label != null && label !== this.progressLabel) {
      this.progressLabel = label;
    }
    if (label == null) {
      this.resetProgressLabel();
    }
  }

  @Watch('viewProgress', { immediate: true })
  watchViewProgress(progress: ViewProgress) {
    if (progress?.progressSteps.some(step => step.percentage !== FileProcessingPipeline.PROGRESS_COMPLETED)) {
      clearTimeout(this.resetProgressLabelTimeoutHandler);
      if (progress.progressSteps[progress.progressSteps.length - 1].percentage === 1) {
        // this handles the case where only one command is being processed and the pipeline is not pushing new progress events for a while because of longer processing time.
        // TODO: We should track important pipeline state in the store instead of fetching it from the non reactive pipeline instance
        setTimeout(() => this.setLabel(), 50);
      } else {
        this.setLabel();
      }
    }
  }
}
