
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import CheckmarkCircle from '~/components/ui/buttons/CheckmarkCircle.vue';
import CheckboxButtonBadge from '~/components/ui/buttons/CheckboxButtonBadge.vue';

@Component({
  components: { CheckboxButtonBadge, CheckmarkCircle },
})
export default class CheckboxButton extends Vue {
  @Prop(Boolean)
    isActive: boolean;

  @Prop(Boolean)
    isDisabled: boolean;

  @Prop(Boolean)
    red: boolean;
}
