
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class CancelButton extends Vue {
  @Prop(Boolean)
    isDisabled: boolean;

  public cancel(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (!this.isDisabled) {
      this.$emit('click', event);
    }
  }
}
