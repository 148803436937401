
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import ScrollDownButton from '~/components/homepage/feature-highlights/ScrollDownButton.vue';
import DesktopDownloadButton from '~/components/homepage/feature-highlights/DesktopDownloadButton.vue';

@Component({
  components: { DesktopDownloadButton, ScrollDownButton },
})
export default class HomepageDesktopSplit extends Vue {
  @Prop(Boolean)
    isActive: boolean;

  @Prop(Boolean)
    showOverlayFlash: boolean;

  @Prop()
    id: string;

  public isHoverDesktopVideo = false;
}
