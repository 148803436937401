
import { Vue, Component, Prop } from 'nuxt-property-decorator';

export enum RadiantBadgeColors {
    GREEN = 'green',
    ORANGE = 'orange',
    ORANGE_DARK = 'orange-dark',
    JUNGLE = 'jungle',
    BROWN_GREEN = 'brown-green',
    LIGHT = 'light'
  }

  @Component
export default class MutantRadiantBadge extends Vue {
}
