
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  components: { },
})
export default class AssetDetails extends Vue {
  @Prop()
  public name: string;
}
